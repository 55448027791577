import { HeaderProps } from "../types"

const Header = (props: HeaderProps) => {
  const { title } = props
  return (
    <p
      className="font-bold"
      style={{
        fontSize: 20,
        lineHeight: "32px",
        color: "#111111",
      }}
    >
      {title}
    </p>
  )
}

export default Header
