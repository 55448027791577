/* eslint-disable react-hooks/exhaustive-deps */
import Typography from "components/Typography"
import { useBoolean, useString } from "helpers/hooks"
import { useContext, useEffect, useRef, useState } from "react"
import { ReactComponent as CloseIcon } from "assets/images/icons/icon-close-modal.svg"
import { Avatar } from "components/Avatar"
import {
  checkPermissionPage,
  customLocalStorageHandler,
  formatDateForConversationMessage,
  umamiTracking,
} from "helpers/utils"
import { GetCommentsData } from "components/comment/types"
import {
  getComponentCommentMiddleware,
  postComponentCommentMiddleware,
  postComponentCommentResolveMiddleware,
} from "../services"
import InputDefault from "components/Input/InputDefault"
import { ReactComponent as IconSend } from "assets/images/icons/icon-send.svg"
import { STATUS_RESPONSE } from "types"
import { ReactComponent as IconResolve } from "assets/images/icons/icon-resolve-comment.svg"
import { ReactComponent as IconResolveActive } from "assets/images/icons/icon-resolve-comment-active.svg"
import Tippy from "@tippyjs/react"
import { cloneDeep, omit } from "lodash"
import { HashLoader } from "react-spinners"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { toast } from "react-toastify"
import { PermissionProjectComponentPage } from "../contexts/PermissionProjectComponentPage.context"
import CheckedDefault from "components/Checked/CheckedDefault"
import { TAB_URL_PROJECT_COMPONENT } from "../types"
import {
  onRedirectConversationComponent,
  onRedirectProjectComponent,
} from "helpers/redirect"
import { configureStore } from "stores/configureStore"
import { openModal, closeModal } from "reducers/modal"
import { getConversationMessagesMiddleware } from "pages/conversations/conversations.api"
import { EVENT } from "constants/events"
import { useParams } from "react-router-dom"
import { NAME_LOCALSTORAGE } from "constants/localStorage"

interface Props {
  idComponent: string
  isOpen: boolean
  urlComponent: string
  titlePage: string
  projectComponentHistoryId: string
  onClose: () => void
  isSendConversation?: boolean
  idConversation?: string
  isInvitee?: boolean
  isCloseConversation?: boolean
  handleChangeHistoryTree?: (idHistory: string) => void
  projectBuildComponentId: string
  viewOnly?: boolean
  isSentInvite?: boolean
}
const CommentCard = (props: Props) => {
  const {
    idComponent,
    projectComponentHistoryId,
    isOpen,
    onClose,
    urlComponent,
    titlePage,
    isSendConversation = false,
    idConversation,
    isInvitee = false,
    isCloseConversation = false,
    projectBuildComponentId = "",
    handleChangeHistoryTree,
    viewOnly,
    isSentInvite,
  } = props
  const { archiveProject, viewOnlyShare, archiveComponent, archiveBuild } =
    useContext(PermissionProjectComponentPage)
  const notYetCommented = useBoolean(false)
  const [comments, setComments] = useState<GetCommentsData[]>([])
  const isLoading = useBoolean(false)
  const isLoadingSend = useBoolean(false)
  const messageSend = useString("")
  const checkedSendConversation = useBoolean(true)
  const ref = useRef<HTMLDivElement>(null)
  let { storageData, handleSetLocalStorage } = customLocalStorageHandler(
    NAME_LOCALSTORAGE.COMMENT
  )

  const storeInputKey = idConversation
    ? `${idComponent}_${projectBuildComponentId}_${idConversation}`
    : `${idComponent}_${projectBuildComponentId}`

  useEffect(() => {
    if (!isOpen || !idComponent || !urlComponent) {
      return
    }

    setComments([])
    setDraftComment()
    getComments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, urlComponent])

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTo(0, ref.current.scrollHeight)
    }
  }, [comments.length])
  useEffect(() => {
    if (idConversation) {
      getConversationMessagesMiddleware(idConversation, {
        page: 1,
        pageSize: 1,
      }).then((dataRes) => {
        notYetCommented.setValue(dataRes.is_not_yet_commented_by_users)
      })
    }
  }, [idConversation])

  const setDraftComment = () => {
    const storeInput =
      storageData && storageData[storeInputKey]
        ? storageData[storeInputKey]
        : ""
    messageSend.setValue(storeInput)
  }

  const getComments = async () => {
    isLoading.setValue(true)
    try {
      const dataRes = await getComponentCommentMiddleware(urlComponent)
      setComments(dataRes)
      isLoading.setValue(false)
    } catch (error) {
      isLoading.setValue(false)
      setComments([])
    }
  }

  const handleChangeInput = (event) => {
    messageSend.setValue(event.target.value)
    if (!storageData) {
      storageData = {}
    }
    storageData[storeInputKey] = event.target.value
    handleSetLocalStorage(storageData)
  }
  const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      event.preventDefault()
      onSubmitButton()
    }
  }

  const onSubmitButton = () => {
    let hasPermission = false
    umamiTracking(EVENT.COMMENT.CREATE)
    if (!idConversation) {
      hasPermission = checkPermissionPage({
        project: archiveProject,
        viewShare: viewOnlyShare,
        component: archiveComponent,
        notificationProject: true,
        notificationViewShare: true,
        notificationComponent: true,
        build: archiveBuild,
        notificationBuild: true,
      })
    }
    if (
      notYetCommented.value &&
      checkedSendConversation.value &&
      !isInvitee &&
      !isSentInvite
    ) {
      configureStore.dispatch(
        openModal({
          type: "Delete",
          props: {
            deleteModal: {
              title: `You are about to send a message and invitation to the invitee, which will appear in their email inbox. Please ensure the invitee's email is accurate.`,
              content:
                "This pop-up window will not reappear once you press Proceed.",
              label: `Warning`,
              onSubmit: () =>
                handleSendMessage(
                  messageSend,
                  isLoadingSend,
                  notYetCommented,
                  comments,
                  setComments,
                  projectComponentHistoryId,
                  checkedSendConversation.value,
                  titlePage,
                  idComponent,
                  isInvitee,
                  hasPermission,
                  idConversation
                ),
              titleButton: "Proceed",
              styleTitle: {
                textAlign: "center",
                // width: 360,
                marginBottom: 10,
              },
              contentWrapperStyles: {
                paddingTop: 10,
                paddingBottom: 36,
                textAlign: "center",
              },
            },
          },
        })
      )
      return
    }
    handleSendMessage(
      messageSend,
      isLoadingSend,
      notYetCommented,
      comments,
      setComments,
      projectComponentHistoryId,
      checkedSendConversation.value,
      titlePage,
      idComponent,
      isInvitee,
      hasPermission,
      idConversation
    )
  }

  const handleSendMessage = (
    messageState: any,
    loadingState: any,
    notYetCommentedState: any,
    commentList: any,
    setCommentList: any,
    componetHistoryId: string,
    isPublicConversation: any,
    apiPath: string,
    componentId: string,
    isFromConversation: boolean,
    hasPermission: boolean,
    conversationId?: string
  ) => {
    if (!conversationId && hasPermission) {
      return
    }
    if (!messageState.value) {
      return
    }
    loadingState.setValue(true)
    closeModal()
    const dataRequest = {
      content: messageState.value,
      project_component_history_id: componetHistoryId,
      conversation_id: conversationId,
      is_public_to_conversation: isPublicConversation,
    }
    if (!isPublicConversation || !conversationId) {
      delete dataRequest.is_public_to_conversation
    }
    if (!conversationId) {
      delete dataRequest.conversation_id
    }
    if (isPublicConversation) {
      notYetCommentedState.setValue(false)
    }
    const URLComment = isFromConversation
      ? `/api/project-${apiPath}/conversation/${conversationId}/${componentId}/comment`
      : `/api/project-${apiPath}/${componentId}/comment`
    postComponentCommentMiddleware(
      URLComment,
      dataRequest,
      (type: STATUS_RESPONSE, messenger: string, data?: GetCommentsData) => {
        loadingState.setValue(false)
        if (type === STATUS_RESPONSE.SUCCESS && data) {
          messageState.setValue("")
          const newComments = cloneDeep(commentList)
          newComments.push(data)
          setCommentList(newComments)
          let newStorageData = omit(storageData, [storeInputKey])
          handleSetLocalStorage(newStorageData)
          return
        }
        if (messenger) {
          toast(<LabelNotificationPage messenger={messenger} type={type} />)
        }
      }
    )
  }
  const onResolveComment = (idComment: string) => () => {
    if (
      (!idConversation &&
        checkPermissionPage({
          project: archiveProject,
          viewShare: viewOnlyShare,
          component: archiveComponent,
          notificationProject: true,
          notificationViewShare: true,
          notificationComponent: true,
          build: archiveBuild,
          notificationBuild: true,
        })) ||
      !idComment
    ) {
      return
    }
    isLoadingSend.setValue(true)
    const URLComment = isInvitee
      ? `/api/project-${titlePage}/conversation/${idConversation}/comment/${idComment}/resolve`
      : `/api/project-${titlePage}/comment/${idComment}/resolve`
    postComponentCommentResolveMiddleware(
      URLComment,
      (type: STATUS_RESPONSE, messenger: string, data?: GetCommentsData) => {
        isLoadingSend.setValue(false)
        if (type === STATUS_RESPONSE.SUCCESS && data) {
          const newComments = cloneDeep(comments)
          const index = newComments.findIndex((el) => el.id === data.id)
          if (index > -1) {
            newComments[index].relation_id = data.relation_id
            newComments[index].resolved_at = data.resolved_at
            newComments[index].resolved_by = data.resolved_by
            newComments[index].resolved_user = data.resolved_user
          }
          setComments(newComments)
        } else {
          toast(<LabelNotificationPage messenger={messenger} type={type} />)
        }
      }
    )
  }
  const disableInput = () => {
    if (isCloseConversation) {
      return true
    }
    if (idConversation) {
      return false
    }
    return (
      isLoadingSend.value ||
      checkPermissionPage({
        project: archiveProject,
        viewShare: viewOnlyShare,
        component: archiveComponent,
        build: archiveBuild,
      })
    )
  }
  const renderTextResolvedAt = (resolvedAt?: string) => {
    if (!resolvedAt) {
      return "No data"
    }
    return `${formatDateForConversationMessage(resolvedAt)}`
  }
  const onRedirectPage = (originComment: GetCommentsData) => () => {
    if (
      !originComment.project_component_id ||
      !originComment.component_type ||
      !originComment.project_component_history_id
    ) {
      return
    }
    if (idConversation) {
      redirectConversation(originComment)
      return
    }
    if (!handleChangeHistoryTree) {
      return
    }
    if (titlePage === TAB_URL_PROJECT_COMPONENT.COMPONENT) {
      handleChangeRedirectBuildComponent(originComment)
      return
    }

    if (!originComment.project_build_component_id) {
      onClose()
      onRedirectProjectComponent(
        TAB_URL_PROJECT_COMPONENT.COMPONENT,
        originComment.component_type,
        originComment.project_component_id,
        "history",
        {
          idHistoryTreeState: originComment.project_component_history_id,
        }
      )
      return
    }
    if (projectBuildComponentId !== originComment.project_build_component_id) {
      redirectBuildComponent(originComment)
    }
    handleChangeHistoryTree(originComment.project_component_history_id)
  }
  const handleChangeRedirectBuildComponent = (
    originComment: GetCommentsData
  ) => {
    if (!originComment.project_build_component_id && handleChangeHistoryTree) {
      handleChangeHistoryTree(originComment.project_component_history_id)
      return
    }
    redirectBuildComponent(originComment)
  }
  const redirectConversation = (originComment: GetCommentsData) => {
    if (!idConversation) {
      return
    }
    onRedirectConversationComponent(
      originComment.component_type,
      originComment.project_component_id,
      originComment.project_build_component_id
        ? originComment.project_build_component_id
        : projectBuildComponentId,
      originComment.project_component_history_id,
      idConversation
    )
  }
  const redirectBuildComponent = (originComment: GetCommentsData) => {
    onClose()
    onRedirectProjectComponent(
      TAB_URL_PROJECT_COMPONENT.BUILD_COMPONENT,
      originComment.component_type,
      originComment.project_component_id,
      originComment.project_build_component_id,
      {
        idHistoryTreeState: originComment.project_component_history_id,
      }
    )
  }

  return (
    <div
      className="flex flex-col relative w-full md:w-[320px] rounded-md h-full md:h-[calc(100vh_-_200px)]"
      style={{
        background: "#FFFFFF",
        boxShadow: " 0px 0px 20px rgba(0, 0, 0, 0.12)",
      }}
    >
      <div className="h-50-custom px-4 flex items-center justify-between">
        <Typography
          variant="h6"
          style={{
            color: " #222222",
            lineHeight: "26px",
            fontWeight: 600,
          }}
        >
          Message
        </Typography>
        <div
          className="flex justify-center items-center h-26-custom w-26-custom hover-button-modal"
          onClick={onClose}
        >
          <CloseIcon className="cursor-pointer" />
        </div>
      </div>
      <div
        className="flex flex-col pl-4 relative"
        ref={ref}
        style={{
          marginTop: 22,
          height: isSendConversation
            ? "calc(100% - 190px)"
            : "calc(100% - 156px)",
          overflow: "auto",
          marginBottom: 16,
        }}
      >
        {comments.length
          ? comments.map((comment, index) => (
              <div
                key={index}
                className="flex flex-col items-center mb-2.5 mr-4 p-2"
                style={{
                  background: "#F7F7F7",
                  borderRadius: 4,
                }}
              >
                <div className="flex items-center justify-between w-full">
                  <div className="flex items-center pr-1">
                    <div
                      style={{
                        width: 24,
                      }}
                    >
                      <Avatar
                        width="24px"
                        height="24px"
                        name={comment.user.fullname}
                        avatar={comment.user.avatar}
                        textcolor="white"
                        contentsize="11px"
                        justOneLetter={true}
                      />
                    </div>
                    <div className="flex items-center flex-wrap ml-2">
                      <p
                        className="font-semibold mr-2"
                        style={{
                          fontSize: 13,
                          lineHeight: "22px",
                          color: "#111111",
                        }}
                      >
                        {comment.user.fullname}
                      </p>
                      <div className="flex items-center">
                        <p
                          className="font-normal"
                          style={{
                            fontSize: 11,
                            lineHeight: "22px",
                            color: "#7A7A7A",
                          }}
                        >
                          {comment?.created_at
                            ? `${formatDateForConversationMessage(
                                comment?.created_at
                              )}`
                            : "No data"}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div>
                    {comment.resolved_by ? (
                      <IconResolveActive />
                    ) : (
                      <Tippy
                        className="custom-tippy-menu-left"
                        placement="top"
                        content={
                          <span
                            className="font-normal"
                            style={{
                              fontSize: 13,
                              lineHeight: "22px",
                            }}
                          >
                            Resolve
                          </span>
                        }
                      >
                        <div
                          className="cursor-pointer custom-focus-visible"
                          onClick={onResolveComment(comment.id)}
                        >
                          <IconResolve />
                        </div>
                      </Tippy>
                    )}
                  </div>
                </div>
                <div className="flex flex-col w-full mt-3">
                  <p className="font-normal text-[13px] leading-[22px] text-[#7A7A7A]">
                    {comment.content}
                  </p>
                  {comment.link_text ? (
                    <div className="flex mt-2 text-[12px] leading-[18px] text-[#111111]">
                      <p className="font-medium ">Commented on</p>
                      <p
                        className="font-bold  ml-1"
                        onClick={onRedirectPage(comment)}
                      >
                        {comment.link_text}
                      </p>
                    </div>
                  ) : null}
                </div>
                {comment.resolved_by && comment.resolved_user.id ? (
                  <div
                    className="mt-2 flex items-center justify-between pt-2 w-full flex-wrap grow"
                    style={{
                      borderTop: "1px solid #E4E4E4",
                    }}
                  >
                    <div className="flex items-center">
                      <p
                        className="font-normal whitespace-pre	"
                        style={{
                          fontSize: 13,
                          lineHeight: "22px",
                          color: "#111111",
                          width: 80,
                        }}
                      >
                        Resolved by:
                      </p>
                      <div className="flex items-center ml-2">
                        <p
                          className="font-semibold "
                          style={{
                            fontSize: 13,
                            lineHeight: "22px",
                            color: "#111111",
                          }}
                        >
                          {comment.resolved_user.fullname}
                        </p>
                      </div>
                    </div>
                    <p
                      className="font-normal"
                      style={{
                        fontSize: 11,
                        lineHeight: "22px",
                        color: "#7A7A7A",
                      }}
                    >
                      {renderTextResolvedAt(comment?.resolved_at)}
                    </p>
                  </div>
                ) : null}
              </div>
            ))
          : null}
      </div>
      {!viewOnly ? (
        <div className="flex flex-col px-4 pt-4">
          <div
            className=" flex items-center relative"
            style={{
              height: 68,
            }}
          >
            <InputDefault
              placeholder="Send message"
              value={messageSend.value}
              onChange={handleChangeInput}
              disabled={disableInput()}
              style={{
                paddingRight: 48,
              }}
              onKeyPress={onKeyPress}
            />
            <div
              onClick={onSubmitButton}
              className="absolute cursor-pointer"
              style={{
                bottom: 22,
                right: 12,
              }}
            >
              <IconSend
                className={`${
                  !idConversation &&
                  checkPermissionPage({
                    project: archiveProject,
                    viewShare: viewOnlyShare,
                    component: archiveComponent,
                    build: archiveBuild,
                  })
                    ? "disabled-icon-button-create"
                    : ""
                }`}
              />
            </div>
          </div>
        </div>
      ) : null}

      {isLoadingSend.value && (
        <div className="flex justify-center items-center absolute top-0 left-0 right-0 bottom-0">
          <HashLoader color="#F7AC1B" loading={true} size={50} />
        </div>
      )}
    </div>
  )
}
export default CommentCard
