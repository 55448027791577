import LabelHeader, {
  getContentLabelStyleProps,
} from "pages/project-component/molecules/LabelHeader"
import { ProjectComponentBuildDetail } from "pages/project-build/project-build.type"
import { ProjectDetail } from "pages/projects/types"
import { ProjectComponentDetail } from "pages/project-component/types"
import { formatDate, formatDateForConversationMessage } from "helpers/utils"
import ActivityLogDefault from "components/activity-log/ActivityLogDefault"
import { MODEL_ACTIVITY_LOG } from "components/activity-log/activity-log.type"
import { TAB_URL_PROJECT_COMPONENT } from "../types"
import {
  onRedirectProject,
  onRedirectProjectBuild,
  onRedirectProjectComponent,
  projectComponentLink,
} from "helpers/redirect"
import { useBoolean, useWindowSize } from "helpers/hooks"
import { useTippyLayout } from "components/TippyCustomzie"
import { HeaderContent } from "components/HeaderInfo/HeaderInfo"
import { useAppSelector } from "hooks/useApp"

interface Props {
  projectDetail: ProjectDetail
  projectBuild: ProjectComponentBuildDetail
  projectComponentDetail: ProjectComponentDetail
  updatedAtComponent: string
  titlePage: string
  idProjectBuildComponent: string
}
const IDInfoProjectComponentDetailCard = (props: Props) => {
  const {
    projectBuild,
    projectComponentDetail,
    projectDetail,
    updatedAtComponent,
    titlePage,
    idProjectBuildComponent,
  } = props
  const user = useAppSelector((state) => state.userInfo)
  const { isMobile } = useWindowSize()
  const contentLabelStyleProps = getContentLabelStyleProps(!isMobile)

  const showProjectInfo = useBoolean()
  const { TippyLayout /* closeLayout, openLayout  */ } = useTippyLayout()

  const onClickLabelComponent = () => {
    if (titlePage === TAB_URL_PROJECT_COMPONENT.COMPONENT) {
      return
    }
    onRedirectProjectComponent(
      TAB_URL_PROJECT_COMPONENT.COMPONENT,
      projectComponentDetail.type.key,
      projectComponentDetail.id
    )
  }

  const renderProjectInfo = () => (
    <div className="flex flex-col md:flex-row items-center">
      <LabelHeader
        {...contentLabelStyleProps}
        title="Project ID:"
        label={projectDetail.code}
        isClick
        path={`/project-detail/${projectDetail.id}`}
      />
      {projectBuild.id &&
      titlePage === TAB_URL_PROJECT_COMPONENT.BUILD_COMPONENT ? (
        <LabelHeader
          {...contentLabelStyleProps}
          title="Build ID:"
          label={projectBuild.code}
          isClick
          ///build/:status/:idProject/:idProjectBuild
          path={`/build/${projectBuild.status}/${projectDetail.id}/${projectBuild.id}`}
          search={{
            tab: projectBuild.created_by === user.id ? "my-builds" : "others",
            selectArchivedFilter:
              projectBuild.is_archived || projectDetail.is_archived
                ? true
                : false,
          }}
        />
      ) : null}

      <LabelHeader
        {...contentLabelStyleProps}
        title="Component ID:"
        label={projectComponentDetail.code}
        isClick={titlePage === TAB_URL_PROJECT_COMPONENT.BUILD_COMPONENT}
        path={`${projectComponentLink(
          TAB_URL_PROJECT_COMPONENT.COMPONENT,
          projectComponentDetail.type.key,
          projectComponentDetail.id
        )}`}
      />
      <LabelHeader
        {...contentLabelStyleProps}
        title="Created:"
        label={formatDate(projectComponentDetail?.created_at, "DD MMM yyyy")}
      />
      <LabelHeader
        {...contentLabelStyleProps}
        title="Last update:"
        label={
          updatedAtComponent
            ? `${formatDateForConversationMessage(updatedAtComponent)}`
            : "No update"
        }
      />
    </div>
  )

  const renderProjectContent = () =>
    isMobile ? (
      <TippyLayout
        visible={showProjectInfo.value}
        headerContent={
          <HeaderContent onClick={() => showProjectInfo.setValue(false)} />
        }
        mainContent={renderProjectInfo()}
      >
        <LabelHeader
          title="Project Info"
          isBorderRight
          // onClickTitle={openLayout}
          onClickTitle={() => {
            showProjectInfo.setValue(true)
          }}
        />
      </TippyLayout>
    ) : (
      renderProjectInfo()
    )

  return (
    <div className="flex items-center justify-end">
      {renderProjectContent()}
      <ActivityLogDefault
        model={
          titlePage === TAB_URL_PROJECT_COMPONENT.COMPONENT
            ? MODEL_ACTIVITY_LOG.PROJECT_COMPONENTS
            : MODEL_ACTIVITY_LOG.PROJECT_BUILD_COMPONENTS
        }
        idRelation={
          titlePage === TAB_URL_PROJECT_COMPONENT.COMPONENT
            ? projectComponentDetail.id
            : idProjectBuildComponent
        }
      />
    </div>
  )
}
export default IDInfoProjectComponentDetailCard
