import IconEye from "assets/images/icons/icon-eye.svg"
import IconEyeSlash from "assets/images/icons/icon-eye-slash.svg"
import { LayerAsignmentDetail, LayerRender, LayerVisibilityMap } from "../types"
import SelectDefault from "components/Select/SelectDefault"
import { isFinite } from "lodash"

interface Props {
  layer: LayerRender
  indexLayer: number
  layerAssignment: LayerAsignmentDetail[]
  isDisabled?: boolean
  canViewGerber: boolean
  layerVisibility: LayerVisibilityMap
  handleUpdateLayer: (
    idLayer: string,
    newSequence: number,
    oldSequence: number,
    sortIndex: number
  ) => void
  handleChangeLayer: (index: number, show: boolean) => () => void
}
const LayerPCBItemCard = (props: Props) => {
  const {
    layer,
    layerAssignment,
    isDisabled,
    canViewGerber,
    indexLayer,
    layerVisibility,
    handleUpdateLayer,
    handleChangeLayer,
  } = props
  const getDataSelect = (newSequence: number) => {
    const newData = layerAssignment.find(
      (el) => Number(el.value) === Number(newSequence)
    )
    if (newData) {
      return newData
    }
    return layerAssignment[0]
  }
  const handleChangeSelect =
    (idLayer: string, oldSequence: number) =>
    (valueSelect: LayerAsignmentDetail) => {
      if (isFinite(valueSelect.value)) {
        handleUpdateLayer(
          idLayer,
          valueSelect.value,
          oldSequence,
          valueSelect.sort_index
        )
      }
    }
  return (
    <div
      className="h-9 rounded mb-3 grid px-2 hover-action-show-icon-eye"
      style={{
        backgroundColor: "#F7F7F7",
        gridTemplateColumns: "calc(100% - 216px) 36px 180px",
      }}
    >
      <div className="flex items-center">
        <p
          className="font-medium"
          style={{
            fontSize: 12,
            lineHeight: "18px",
            color: "#111111",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {layer.file_name}
        </p>
      </div>
      <div
        className="flex items-center justify-end"
        style={{
          borderRight: "1px solid #E4E4E4",
        }}
      >
        {canViewGerber ? (
          <div className={layerVisibility[indexLayer] ? "show-icon-eye" : ""}>
            <img
              src={layerVisibility[indexLayer] ? IconEye : IconEyeSlash}
              alt="icon"
              style={{
                height: 20,
              }}
              className={"cursor-pointer mr-2"}
              onClick={handleChangeLayer(
                indexLayer,
                layerVisibility[indexLayer]
              )}
            />
          </div>
        ) : null}
      </div>
      <div className="flex items-center ml-2">
        <SelectDefault
          options={layerAssignment}
          selectedOption={getDataSelect(layer.sequence)}
          handleChange={handleChangeSelect(layer.id, layer.sequence)}
          isDisabled={isDisabled}
          controlWidth={170}
          menuStyle={{
            width: 170,
            marginTop: 10,
          }}
          menuPlacement="bottom"
          singleValueStyle={{
            fontWeight: 500,
            fontSize: 12,
            lineHeight: "18px",
            color: "#111111",
          }}
          filed="name"
        />
      </div>
    </div>
  )
}
export default LayerPCBItemCard
