import { SelectedDefaultProp } from "components/Select/types"
import { STATUS_BUILD, BuildStatusNumber } from "components/Status/types"
import { TippyCustomzie } from "components/TippyCustomzie"
import { includes } from "lodash"
import LabelStatusBuild from "pages/project-component/molecules/LabelStatusBuild"
import ActionItem from "pages/projects/molecules/ActionItem"
import { ProjectComponentBuildDetail } from "../project-build.type"

interface Props {
  isOpenSelectStatus: {
    value: boolean
    setValue: (newValue: boolean) => void
  }
  listStatus: SelectedDefaultProp[]
  projectBuild: ProjectComponentBuildDetail
  handleChangeStatus: (newStatus: SelectedDefaultProp) => () => void
  handleOpenSelectStatus: () => void
  isSelectStatus: boolean
}
const ChangeStatusBuildCard = (props: Props) => {
  const {
    isOpenSelectStatus,
    listStatus,
    projectBuild,
    isSelectStatus,
    handleChangeStatus,
    handleOpenSelectStatus,
  } = props
  // in production statuses
  let dropdownStatus = [
    STATUS_BUILD.IN_PRODUCTION,
    STATUS_BUILD.CANCELLED,
    STATUS_BUILD.COMPLETED,
  ]
  // RFQ statuses
  if (
    [STATUS_BUILD.RFQ, STATUS_BUILD.TEST_SETUP].includes(projectBuild.status)
  ) {
    dropdownStatus = [STATUS_BUILD.RFQ, STATUS_BUILD.TEST_SETUP]
  }
  if (projectBuild.proceed_status === BuildStatusNumber.IN_PROGRESS) {
    dropdownStatus = [
      STATUS_BUILD.IN_PROGRESS,
      STATUS_BUILD.CANCELLED,
      STATUS_BUILD.COMPLETED,
    ]
  }
  return (
    <TippyCustomzie
      containerClass="component-card-menu reset-tippy"
      placement="bottom-start"
      interactive
      arrow={false}
      animation="scale"
      visible={isOpenSelectStatus.value}
      offset={[-12, 6]}
      onClickOutside={() => isOpenSelectStatus.setValue(false)}
      content={
        <div
          style={{
            background: "#222222",
            borderRadius: 4,
            paddingTop: 8,
            paddingBottom: 8,
            width: 110,
          }}
        >
          {listStatus
            .filter((el) => {
              return (
                includes(dropdownStatus, el.label) &&
                el.label !== projectBuild.status
              )
            })
            .map((status, index) => (
              <ActionItem
                key={index}
                title={status.label}
                onClick={handleChangeStatus(status)}
              />
            ))}
        </div>
      }
      allowHTML
    >
      <div onClick={handleOpenSelectStatus}>
        <LabelStatusBuild
          status={
            projectBuild.status ? projectBuild.status : STATUS_BUILD.DRAFT
          }
          isSelect={isSelectStatus}
        />
      </div>
    </TippyCustomzie>
  )
}
export default ChangeStatusBuildCard
