export const arrTreeColors = [
  "#56A012",
  "#0A5AF5",
  "#EA4545",
  "#F7AC1B",
  "rgb(54 83 20)",
  "rgb(45 212 191)",
  "rgb(67 56 202)",
  "rgb(192 38 211)",
  "rgb(190 24 93)",
  "rgb(190 18 60)",
]

export enum COMMIT_DEFAULT {
  SYNCED = "Version has been synced",
  REVERTED = "Version has been reverted",
  TRANSCENDED = "Version has been duplicated",
}
