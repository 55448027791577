import Axios, { AxiosResponse } from "axios"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
import { STATUS_RESPONSE } from "types"
import {
  BuildConversationDetail,
  BuildInviteesConversationDetail,
  CONVERSATION_ROLE,
  ConversationDetail,
  ConversationMessagesDetail,
  CustomStatus,
  InviteeAddColumnDetail,
  Note,
  ParamsBuildConversationProps,
} from "./conversations.type"

export const getListConversationByBuildMiddleware = async (
  urlTab: string,
  params?: ParamsBuildConversationProps
) => {
  const response: AxiosResponse<{
    data: BuildConversationDetail[]
  }> = await Axios.get(`/api/conversation/${urlTab}`, { params })
  return response.data.data
}
export const getListConversationMiddleware = async (
  tab: string,
  params?: ParamsBuildConversationProps
) => {
  const response: AxiosResponse<{
    data: ConversationDetail[]
  }> = await Axios.get(`/api/conversation/${tab}`, { params })
  return response.data.data
}
export const getConversationByBuildMiddleware = async (
  projectBuildId: string,
  urlConversationByBuild: string
) => {
  const response: AxiosResponse<{
    data: BuildInviteesConversationDetail[]
  }> = await Axios.get(
    `/api/conversation/${urlConversationByBuild}/${projectBuildId}`
  )
  return response.data.data
}
export const getConversationMessagesMiddleware = async (
  conversationId: string,
  params?: {
    page: number
    pageSize: number
  }
) => {
  const response: AxiosResponse<{
    data: ConversationMessagesDetail
  }> = await Axios.get(`/api/conversation/${conversationId}/messages`, {
    params,
  })
  return response.data.data
}

export const postConversationLikeBuildMiddleware = (
  idProjectBuild: string,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.post(`/api/conversation/like-build/${idProjectBuild}`)
    .then(() => {
      callback(STATUS_RESPONSE.SUCCESS, "")
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.FAVORITE_PROJECT_BUILD_ERROR
      )
    })
}

export const postConversationSendMessageMiddleware = (
  conversationId: string,
  data: {
    content: string
    files: {
      file_name: string
      file: string
    }[]
  },
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.post(`/api/conversation/${conversationId}/send-message`, data)
    .then(() => {
      callback(STATUS_RESPONSE.SUCCESS, "")
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.CONVERSATION_SEND_MESSAGE_ERROR
      )
    })
}
export const postAddInviteeColumnMiddleware = (
  conversationId: string,
  projectComponentHistoryId: string,
  dataRequest: InviteeAddColumnDetail[],
  isUpdateColumn: boolean,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.post(
    `/api/bom/${projectComponentHistoryId}/${conversationId}/add-invitee-column`,
    {
      data: dataRequest,
    }
  )
    .then(() => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        `${isUpdateColumn ? "Updated" : "Added"} column successfully!`
      )
    })
    .catch((error) => {
      const newMessageError = `${
        isUpdateColumn ? "Updated" : "Added"
      } column failed!`
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message
          ? error.response?.data?.message
          : newMessageError
      )
    })
}

export const getNotificationConversationMiddleware = async (
  conversationId: string
) => {
  const response: AxiosResponse<{
    data: {
      is_notified: boolean
    }
  }> = await Axios.get(`/api/conversation/${conversationId}/is-notify`)
  return response.data.data
}
export const postNotificationConversationMiddleware = (
  conversationId: string,
  label: string,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.post(`/api/conversation/${conversationId}/update-is-notify`)
    .then(() => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        `Turn ${label} email notification successfully!`
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message
          ? error.response?.data?.message
          : `Turn ${label} email notification fail!`
      )
    })
}

export const archiveConversationMiddleware = async (conversationId: string) => {
  const res = await Axios.post(`/api/conversation/archive/${conversationId}`)
  return res.data
}
export const getCustomStatusesMiddleware = async () => {
  const res = await Axios.get("/api/conversation/custom-statuses/get")
  return res.data.data
}
export const createCustomStatusMiddleware = async (
  customStatus: CustomStatus
) => {
  const res: AxiosResponse<{
    data: CustomStatus
  }> = await Axios.post(`/api/conversation/custom-status/create`, {
    custom_status: { name: customStatus.name, color: customStatus.color },
  })
  return res.data.data
}
export const updateCustomStatusesMiddleware = async (
  customStatus: CustomStatus
) => {
  const res = await Axios.post(
    `/api/conversation/custom-statuses/${customStatus.id}/update`,
    {
      custom_status: { name: customStatus.name, color: customStatus.color },
    }
  )
  return res.data
}
export const deleteCustomstatusMiddleware = async (
  customStatus: CustomStatus
) => {
  const res = await Axios.delete(
    `/api/conversation/custom-status/${customStatus.id}/delete`
  )
  return res.data
}
export const updateCustomStatusMiddleware = async (
  conversationId: string,
  customStatus?: CustomStatus
) => {
  const res = await Axios.post(
    `/api/conversation/${conversationId}/custom-status`,
    { custom_status_id: customStatus?.id }
  )
  return res.data
}
export const updateNotesMiddleware = async (
  conversationId: string,
  notes: string
) => {
  const res = await Axios.post(`/api/conversation/notes/${conversationId}`, {
    notes,
  })
  return res.data
}

export const deleteNoteImageMiddleware = async (
  conversationId: string,
  fileKey: string
) => {
  const res = await Axios.delete(
    `/api/conversation/notes/${conversationId}/delete-image/${encodeURIComponent(
      fileKey
    )}`
  )
  return res.data.data
}

export const deleteNotesFolder = async (conversationId: string) => {
  const res = await Axios.delete(
    `/api/conversation/${conversationId}/notes/delete-folder`
  )
  return res.data
}

export const likeConversationMiddleware = async (conversationId: string) => {
  const res = await Axios.post(`/api/conversation/like/${conversationId}`)
  return res.data
}

export const getNoteMiddleware = async (conversationId: string) => {
  const res: AxiosResponse<{
    data: Note
  }> = await Axios.get(`/api/conversation/get-notes/${conversationId}`)
  return res.data
}

export const getConversationRoleMiddleWare = async (
  projectBuildComponentId: string,
  conversationId: string
) => {
  const res: AxiosResponse<{
    data: {
      role: CONVERSATION_ROLE
    }
  }> = await Axios.get(
    `/api/conversation/${projectBuildComponentId}/${conversationId}/get-role`
  )
  return res.data.data
}
