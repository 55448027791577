import { STATUS_BUILD } from "components/Status/types"
import { ProjectComponentDetail } from "pages/project-component/types"

export interface ProjectComponentBuildDetail {
  code: string
  component_types: string
  components: ProjectComponentDetail[]
  created_at: string
  id: string
  is_archived: number
  name: string
  project_id: string
  status: STATUS_BUILD
  updated_at: string
  is_read_additional_info: boolean
  is_read_extra_info: boolean
  extra_count: number
  invitee_count: number
  additional_count: number
  proceed_status?: number
  created_by: string
}
export interface MenuTabBuildDetail {
  label: string
  count: number
  isRead: boolean
}
export interface ProjectBuildAdditionalDetail {
  id: string
  description: string
  files: {
    file_name: string
    file: string
  }[]
  created_at: string
  created_by: string
  user: {
    avatar: string
    id: string
    name: string
  }
}
export interface ProjectBuildExtraDetail {
  id: string
  description: string
  files: {
    file_name: string
    file: string
  }[]
  created_at: string
  created_by: string
  user: {
    avatar: string
    id: string
    name: string
  }
}
export interface ProjectBuildInvitessDetail {
  id: string
  name: string
  email: string
  cc: string[]
  avatar: string
  created_at: string
  created_by: string
  is_sent: number
  project_build_components: InviteeBuilComponentDetail[]
  status: BUILD_INVITEE_STATUS
  message: string
  is_read: boolean
  project_build_id: string
  conversation_id: string
  can_modify_component: 0 | 1
  conversation_code: string
}
export enum BUILD_INVITEE_STATUS {
  ACTIVATE = 1,
  DEACTIVATE = 2,
}
export interface FormVendorRequest {
  invitee: { email?: string; name: string }
  cc: string[]
  id?: string
  is_sent?: number
}

export interface CreateAdditionalRequest {
  description: string
  files: CreateAdditionalFileRequest[]
}

export interface CreateAdditionalFileRequest {
  file_name: string
  file: string
}
export interface InviteeBuilComponentDetail {
  id: string
  code: string
  name: string
  project_build_component_id: string
  project_build_id: string
  type: number
  type_key: string
  is_read?: boolean
  status: number
  version: string
  project_component_id: string
  project_component_history_id: string
  updated_at: string
  is_read_invitee_commit: boolean
}

export const emptyProjectBuildDetail: ProjectComponentBuildDetail = {
  code: "",
  component_types: "",
  components: [],
  created_at: "",
  id: "",
  is_archived: 0,
  name: "Untitled build",
  project_id: "",
  status: STATUS_BUILD.DRAFT,
  updated_at: "",
  additional_count: 0,
  extra_count: 0,
  is_read_additional_info: false,
  is_read_extra_info: false,
  invitee_count: 0,
  created_by: "",
}

export enum MENU_TAB_BUILD {
  COMPONENT = "Components",
  ADDITIONAL = "Additional info",
  EXTRA = "Extra info ",
  INVITEES = "Invitees",
  CONVERSATION = "Conversation",
}

export const emptyInvitessDetail: ProjectBuildInvitessDetail = {
  created_at: "",
  created_by: "",
  email: "",
  avatar: "",
  id: "",
  is_sent: 0,
  cc: [],
  name: "",
  project_build_components: [],
  status: BUILD_INVITEE_STATUS.DEACTIVATE,
  message: "",
  is_read: false,
  conversation_id: "",
  project_build_id: "",
  can_modify_component: 0,
  conversation_code: "",
}

export const emptyInviteeBuildComponentDetail: InviteeBuilComponentDetail = {
  code: "",
  project_build_component_id: "",
  name: "",
  type: 0,
  type_key: "",
  is_read: true,
  id: "",
  project_build_id: "",
  status: 0,
  version: "",
  project_component_history_id: "",
  project_component_id: "",
  updated_at: "",
  is_read_invitee_commit: true,
}

export interface WorkedVendor {
  name: string
  email: string
}
