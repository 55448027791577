import Button from "components/Button/Button"
import { CalendarCustom } from "components/Calendar/Calendar"
import CheckedDefault from "components/Checked/CheckedDefault"
import { ClearAll } from "components/Filter"
import { FilterCollapse } from "components/Filter/FilterCollapse"
import { FilterHeader } from "components/Filter/FilterHeader"
import InputDefault from "components/Input/InputDefault"
import { TippyCustomzie, useTippyLayout } from "components/TippyCustomzie"
import { useBoolean, useWindowSize } from "helpers/hooks"
import { formatDate } from "helpers/utils"
import { useAppSelector } from "hooks/useApp"
import { includes } from "lodash"
import ActionIconHistory from "pages/project-component-detail/molecules/ActionIconHistory"

import { useContext } from "react"

import "react-date-range/dist/styles.css" // main style file
import "react-date-range/dist/theme/default.css" // theme css file
import { ConversationsPageContext } from "../contexts/ConversationsPage.context"
import {
  MenuTabConversation,
  MENU_TAB_CONVERSATION,
  SelectionRangeDetail,
  GROUP_BY_OPTIONS,
  GROUP_BY_OPTIONS_KEY,
  UNREAD_KEY_BY_TAB,
} from "../conversations.type"
import FormSelectFilter from "../molecules/FormSelectFilter"
import ActionItem from "pages/projects/molecules/ActionItem"
import { ReactComponent as SelectIcon } from "assets/images/icons/icon-arrow-down-select-tippy.svg"
interface Props {
  isSearch: boolean
  inputSearch: string
  activeMenu: string
  isFilter: boolean
  isShowFavorite: boolean
  selectionRange: SelectionRangeDetail
  isCalendarRanges: boolean
  endDateCreate: Date | null
  startDateCreate: Date | null
  isCalendarLastUpdate: boolean
  selectionDate: Date | null
  dateLastUpdate: Date | null
  isActiveIconFilter: boolean
  onChangeCheckboxFavorite: () => void
  onChangeInputSearch: (event) => void
  onKeyPress: (event: React.KeyboardEvent<HTMLDivElement>) => void
  onOpenInputSearch: () => void
  setIsFilter: (newIsFilter: boolean) => void
  onChangeTab: (newTab: string) => () => void
  onChangeDate: (ranges: any) => void
  onApplySelectionRanges: () => void
  onCancelSelectionRanges: (
    startDateCreate: Date | null,
    endDateCreate: Date | null
  ) => void
  onChangeModalSelectRanges: (
    newValue: boolean,
    startDate: Date | null,
    endDate: Date | null
  ) => () => void
  onClearInputSelectionRanges: () => void
  onChangeModalDateLastUpdate: (
    newValue: boolean,
    dateLastUpdate?: Date | null
  ) => () => void
  onChangeDateLastUpdate: (newDate: any) => void
  onApplySelectionDateLastUpdate: () => void
  onClearInputSelectionDateLastUpdate: () => void
  onCancelSelectionlastUpdate: (oldDate: Date | null) => void
  handleChangeFilerAll: (newAll: boolean) => () => void
  onApplyFilter: () => void
  onOpenModalFiler: (newOpen: boolean) => void
  onRemoveInputSearch: () => void
  rollbackFilter: () => void
  onChangeCheckboxDelete: () => void
  isShowDeleted: boolean
  isShowUnread: boolean
  onChangeCheckBoxUnread: () => void
  isShowArchive: boolean
  onChangeCheckBoxArchive: () => void
  unreadByFilter: number
  isShowDraft: boolean
  onChangeCheckBoxDraft: () => void
  groupOption: string
  onChangeGroupOption: (option: string) => void
  // type: "Conversation" | "Build overview" = "Conversation"
}
const ConversationFilter = (props: Props) => {
  const {
    isSearch,
    inputSearch,
    activeMenu,
    isFilter,
    isShowFavorite,
    selectionRange,
    endDateCreate,
    startDateCreate,
    onChangeInputSearch,
    onKeyPress,
    onOpenInputSearch,
    onChangeTab,
    onChangeCheckboxFavorite,
    onChangeDate,
    isCalendarRanges,
    onApplySelectionRanges,
    onCancelSelectionRanges,
    onChangeModalSelectRanges,
    onClearInputSelectionRanges,
    isCalendarLastUpdate,
    selectionDate,
    dateLastUpdate,
    onChangeModalDateLastUpdate,
    onChangeDateLastUpdate,
    onApplySelectionDateLastUpdate,
    onCancelSelectionlastUpdate,
    onClearInputSelectionDateLastUpdate,
    handleChangeFilerAll,
    onApplyFilter,
    onOpenModalFiler,
    isActiveIconFilter,
    onRemoveInputSearch,
    rollbackFilter,
    onChangeCheckboxDelete,
    isShowDeleted,
    isShowUnread,
    isShowArchive,
    isShowDraft,
    onChangeCheckBoxArchive,
    onChangeCheckBoxUnread,
    onChangeCheckBoxDraft,
    unreadByFilter,

    groupOption,
    onChangeGroupOption,
  } = props
  const type = "Conversations"
  const {
    cpnStatusOptions,
    cpnTypeOptions,
    objSelectType,
    objSelectStatus,
    setObjSelectStatus,
    setObjSelectType,
    customStatuses,
    setObjSelectCustomStatus,
    objSelectCustomStatus,
  } = useContext(ConversationsPageContext)
  const unreadConversation = useAppSelector((state) => state.unreadConversation)
  const { isMobile } = useWindowSize()
  const { TippyLayout } = useTippyLayout()
  const showTippy = useBoolean()

  const checkAllFilter = () => {
    if (dateLastUpdate || startDateCreate || endDateCreate) {
      return false
    }
    if (
      !isShowFavorite &&
      !isShowDeleted &&
      !isShowUnread &&
      !isShowArchive &&
      !isShowDraft &&
      !Object.values(objSelectType).filter((el) => el).length &&
      !Object.values(objSelectStatus).filter((el) => el).length
    ) {
      if (activeMenu === MENU_TAB_CONVERSATION.TO_ME) {
        return !Object.values(objSelectCustomStatus).filter((el) => el).length
      }
      return true
    }
    return false
  }

  const renderConversationFilter = () => (
    <div>
      <ActionIconHistory
        onClick={() => onOpenModalFiler(!isFilter)}
        icon="filter"
        tooltip="Filter"
        styleIcon={{
          marginRight: 0,
        }}
        classActiveIcon={isActiveIconFilter ? "action-icon-filter" : ""}
      />
    </div>
  )

  const renderCreateDate = () => (
    <CalendarCustom
      label="Creation period"
      type="date-range"
      visible={isCalendarRanges}
      currentDate={[selectionRange]}
      selectedDate={
        startDateCreate && endDateCreate ? (
          <p className="font-normal text-14 leading-24 text-orange-5">
            {`${formatDate(startDateCreate, "DD MMM yyyy")} - ${formatDate(
              endDateCreate,
              "DD MMM yyyy"
            )}`}
          </p>
        ) : null
      }
      onSelectDate={onChangeDate}
      showClear={!!(startDateCreate && endDateCreate)}
      onClear={onClearInputSelectionRanges}
      onCancel={() => {
        onCancelSelectionRanges(startDateCreate, endDateCreate)
      }}
      onApply={onApplySelectionRanges}
      onOpen={onChangeModalSelectRanges(
        !isCalendarRanges,
        startDateCreate,
        endDateCreate
      )}
    />
  )

  const renderUpdateDate = () => (
    <CalendarCustom
      label="Last message date"
      placehoder="Search up to this date"
      visible={isCalendarLastUpdate}
      currentDate={selectionDate}
      selectedDate={
        dateLastUpdate ? (
          <p className="font-normal text-14 leading-24 text-orange-5">
            {`${formatDate(dateLastUpdate, "DD MMM yyyy")}`}
          </p>
        ) : null
      }
      onSelectDate={onChangeDateLastUpdate}
      showClear={!!dateLastUpdate}
      onClear={onClearInputSelectionDateLastUpdate}
      onCancel={() => onCancelSelectionlastUpdate(dateLastUpdate)}
      onApply={onApplySelectionDateLastUpdate}
      onOpen={onChangeModalDateLastUpdate(!isCalendarLastUpdate)}
    />
  )

  /// render conversion filter content on mobile
  const renderConversionFilterDynamic = () => (
    <div>
      <ClearAll
        onClick={handleChangeFilerAll(checkAllFilter())}
        active={!checkAllFilter()}
      />

      <CheckedDefault
        className="justify-between flex-row-reverse h-[52px] bg-white border-t-[1px] border-b-[1px] px-6"
        textColor="black"
        tilteClass="ml-0"
        checked={isShowFavorite}
        onClick={onChangeCheckboxFavorite}
        title="Show favorite only"
      />
      <CheckedDefault
        className="justify-between flex-row-reverse h-[52px] bg-white border-t-[1px] border-b-[1px] px-6"
        textColor="black"
        tilteClass="ml-0"
        checked={isShowUnread}
        onClick={onChangeCheckBoxUnread}
        title="Show unread only"
      />
      <CheckedDefault
        className="justify-between flex-row-reverse h-[52px] bg-white border-t-[1px] border-b-[1px] px-6"
        textColor="black"
        tilteClass="ml-0"
        checked={isShowArchive}
        onClick={onChangeCheckBoxArchive}
        title="Show archive"
      />
      <CheckedDefault
        className="justify-between flex-row-reverse h-[52px] bg-white border-t-[1px] border-b-[1px] px-6"
        textColor="black"
        tilteClass="ml-0"
        checked={isShowDraft}
        onClick={onChangeCheckBoxDraft}
        title="Show draft only"
      />
      <CheckedDefault
        className="justify-between flex-row-reverse h-[52px] bg-white border-t-[1px] border-b-[1px] px-6"
        textColor="black"
        tilteClass="ml-0"
        checked={isShowDeleted}
        onClick={onChangeCheckboxDelete}
        title="Show deleted only"
      />

      <div className="flex flex-col">
        <FilterCollapse
          label="Component type"
          multiActive
          data={cpnTypeOptions.map((el) => ({
            value: el.value,
            label: el.key,
          }))}
          chosenValue={objSelectType}
          onChange={setObjSelectType}
        />

        <FilterCollapse
          label="Build status"
          multiActive
          data={cpnStatusOptions
            .filter((el) => !includes(["Draft", "Committed"], el.key))
            .map((el) => ({
              value: el.value,
              label: el.key,
            }))}
          chosenValue={objSelectStatus}
          onChange={setObjSelectStatus}
        />
        <FilterCollapse
          label="Conversation status"
          multiActive
          data={customStatuses.map((el) => ({
            value: el.id,
            label: el.name,
          }))}
          chosenValue={objSelectCustomStatus}
          onChange={setObjSelectCustomStatus}
        />

        <div className="flex flex-col px-6 mb-6">
          {renderCreateDate()}
          {renderUpdateDate()}
        </div>
      </div>
    </div>
  )

  /// conversion filter content on desktop
  const renderConversionFilterContent = () => (
    <div
      className="pt-3.5 pb-3"
      style={{
        background: "#222222",
        borderRadius: 4,
        width: 468,
      }}
    >
      <div className="flex items-center justify-between mb-3 px-3">
        <p className="hidden md:block font-semibold text-16 leading-26 text-white">
          Filter
        </p>
        <ClearAll
          onClick={handleChangeFilerAll(checkAllFilter())}
          active={!checkAllFilter()}
        />
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex">
          <div className="flex flex-col w-6/12 pl-3 gap-2">
            <CheckedDefault
              checked={isShowFavorite}
              onClick={onChangeCheckboxFavorite}
              title="Show favorite only"
            />
            <CheckedDefault
              checked={isShowUnread}
              onClick={onChangeCheckBoxUnread}
              title="Show unread only"
            />
          </div>
          <div className="flex flex-col w-6/12 pl-3 gap-2">
            <CheckedDefault
              checked={isShowDeleted}
              onClick={onChangeCheckboxDelete}
              title="Show deleted only"
            />

            <CheckedDefault
              checked={isShowArchive}
              onClick={onChangeCheckBoxArchive}
              title="Show archive"
            />
          </div>
        </div>
        <CheckedDefault
          className="w-full pl-3"
          checked={isShowDraft}
          onClick={onChangeCheckBoxDraft}
          title="Show draft only"
        />
      </div>

      <div className="flex">
        <FormSelectFilter
          title="Component type"
          dataSelects={cpnTypeOptions}
          objActiveSelect={objSelectType}
          setObjActiveSelect={(data) => {
            if (isShowDeleted) {
              return
            }
            if (setObjSelectType) {
              setObjSelectType(data)
            }
          }}
        />
        <FormSelectFilter
          title="Build status"
          dataSelects={cpnStatusOptions}
          styleRoot={{
            borderRight: "none",
          }}
          objActiveSelect={objSelectStatus}
          setObjActiveSelect={(data) => {
            if (isShowDeleted) {
              return
            }
            if (setObjSelectStatus) {
              setObjSelectStatus(data)
            }
          }}
        />
        {(activeMenu === MENU_TAB_CONVERSATION.TO_ME ||
          activeMenu === MENU_TAB_CONVERSATION.CC) && (
          <FormSelectFilter
            title="Conversation status"
            dataSelects={customStatuses.map((el) => ({
              key: el.name,
              value: el.id,
            }))}
            styleRoot={{
              borderRight: "none",
              height: "242px",
            }}
            objActiveSelect={objSelectCustomStatus}
            setObjActiveSelect={(data) => {
              if (isShowDeleted) {
                return
              }
              if (setObjSelectCustomStatus) {
                setObjSelectCustomStatus(data)
              }
            }}
          />
        )}
      </div>

      <div className="flex flex-col mt-2 px-3">
        {renderCreateDate()}
        {renderUpdateDate()}
      </div>

      <div className="flex justify-end mt-3 mr-3">
        <Button
          title="Apply"
          onClick={onApplyFilter}
          widthBtn={120}
          heightBtn={42}
        />
      </div>
    </div>
  )

  /// render conversion filter content
  const renderConversionFilterLayout = () =>
    isMobile ? (
      <TippyLayout
        visible={isFilter}
        headerContent={
          <FilterHeader setVisible={onOpenModalFiler} onApply={onApplyFilter} />
        }
        mainContent={renderConversionFilterDynamic()}
      >
        {renderConversationFilter()}
      </TippyLayout>
    ) : (
      <TippyCustomzie
        containerClass="component-card-menu"
        placement="bottom-end"
        interactive
        arrow={false}
        animation="scale"
        visible={isFilter}
        onClickOutside={() => {
          rollbackFilter()
          onOpenModalFiler(!isFilter)
        }}
        offset={[-265, 5]}
        content={renderConversionFilterContent()}
        allowHTML
      >
        {renderConversationFilter()}
      </TippyCustomzie>
    )

  return (
    <div className="flex flex-col gap-2 justify-between mb-2 pr-6 min-h-[32px]">
      <div className="flex justify-between items-center w-full">
        <div className="font-semibold text-black text-base"> {type}</div>
        <div className="flex gap-3">
          {isSearch ? (
            <div className="custom-icon-remove-search mx-2">
              <ActionIconHistory
                onClick={onRemoveInputSearch}
                disabled
                icon="close-modal"
                tooltip="Remove search"
                styleIcon={{
                  marginRight: 0,
                }}
              />
            </div>
          ) : (
            <ActionIconHistory
              onClick={onOpenInputSearch}
              icon="search"
              tooltip="Search"
              styleIcon={{
                marginRight: 8,
              }}
            />
          )}

          {renderConversionFilterLayout()}
        </div>
      </div>
      {isSearch ? (
        <InputDefault
          onChange={onChangeInputSearch}
          autoFocus
          placeholder="Enter search"
          onKeyPress={onKeyPress}
          value={inputSearch}
          style={{
            height: 30,
            flexGrow: 1,
            maxWidth: isMobile ? undefined : "222px",
          }}
        />
      ) : (
        <div className="flex align gap-2">
          {MenuTabConversation.map((menu, index) => {
            const unReadCount = unreadConversation[UNREAD_KEY_BY_TAB[menu]]
            return (
              <div className="relative" key={index}>
                <div
                  key={index}
                  className=" font-body font-medium cursor-pointer text-center rounded-4 flex items-center border-[#E4E4E4] border-[1px]"
                  style={{
                    fontSize: 12,
                    height: "26px",
                    lineHeight: "18px",
                    color: activeMenu === menu ? "white" : "#111111",
                    background: activeMenu === menu ? "#111111" : "white",
                  }}
                  onClick={onChangeTab(menu)}
                >
                  <p className="px-2">{menu}</p>
                </div>
                {unReadCount !== 0 ? (
                  <div
                    className=" flex items-center justify-center absolute w-5 h-4 -top-[10px] -right-[6px] text-white z-10"
                    style={{ background: "#EA4545", borderRadius: "999px" }}
                  >
                    <p
                      className="font-body font-semibold px-1 py-[1px]"
                      style={{ fontSize: "10px" }}
                    >
                      {unReadCount}
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            )
          })}
        </div>
      )}

      {/* group by */}
      <div className="mb-3">
        <TippyCustomzie
          containerClass="component-card-menu"
          placement="bottom-start"
          interactive
          arrow={false}
          animation="scale"
          visible={showTippy.value}
          onClickOutside={() => showTippy.setValue(false)}
          content={
            <div
              style={{
                background: "#222222",
                borderRadius: 4,
                paddingTop: 8,
                paddingBottom: 8,
                width: 165,
              }}
            >
              {GROUP_BY_OPTIONS.map((item) => {
                if (
                  item.value === GROUP_BY_OPTIONS_KEY.custom_status &&
                  activeMenu === MENU_TAB_CONVERSATION.FROM_ME
                )
                  return null
                return (
                  <ActionItem
                    key={item.key}
                    title={`${item.value}`}
                    onClick={() => {
                      onChangeGroupOption(item.key)
                      showTippy.setValue(false)
                    }}
                  />
                )
              })}
            </div>
          }
          allowHTML
        >
          <div className="flex gap-1">
            <div
              className="flex p-[6px] hover-action-icon cursor-pointer"
              onClick={() => showTippy.setValue(!showTippy.value)}
            >
              <SelectIcon />
            </div>
            <p
              className="font-semibold font-body text-black"
              style={{ fontSize: 13, lineHeight: "22px", fontStyle: "normal" }}
            >
              Group by : {GROUP_BY_OPTIONS_KEY[groupOption]}
            </p>
          </div>
        </TippyCustomzie>
      </div>
    </div>
  )
}
export default ConversationFilter
