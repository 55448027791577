import { PrivacyPartDetail } from "../types"
import { formatDate } from "helpers/utils"
interface Props {
  name: string
  update: string
  listPrivacy: PrivacyPartDetail[]
}
const PrivacyPolicyCard = (props: Props) => {
  const { name, update, listPrivacy } = props
  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between">
        <p
          style={{
            fontWeight: 700,
            lineHeight: "32px",
            fontSize: 20,
            color: "#111111",
          }}
        >
          {name}
        </p>
        <p
          style={{
            fontWeight: 400,
            lineHeight: "24px",
            fontSize: 14,
            color: "#7A7A7A",
          }}
        >
          Updated {formatDate(update, "MMM yyyy")}
        </p>
      </div>
      <div className="mt-10">
        {listPrivacy.length
          ? listPrivacy.map((privacy, index) => (
              <div
                className="flex flex-col mb-6 font-nornal text-13 leading-23 "
                style={{ color: "#111111" }}
                key={index}
                dangerouslySetInnerHTML={{
                  __html: privacy.answer,
                }}
              ></div>
            ))
          : null}
      </div>
    </div>
  )
}

export default PrivacyPolicyCard
