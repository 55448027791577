import Axios, { AxiosResponse } from "axios"
import {
  CategoryDetail,
  CategoryRequest,
  ParamsProjectsProps,
  ProjectCount,
  ProjectDetail,
} from "../types"
import { STATUS_RESPONSE } from "types"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
import { ProjectComponentDetail } from "pages/project-component/types"
import {
  BuildConversationDetail,
  ConversationDetail,
} from "pages/conversations/conversations.type"

export const getCategoriesMiddleware = async (
  url: string,
  params?: CategoryRequest
) => {
  const response: AxiosResponse<{
    data: CategoryDetail[]
  }> = await Axios.get(url, {
    params,
  })
  return response.data.data
}
export const getProjectCountMiddleware = async () => {
  const response: AxiosResponse<{
    data: ProjectCount
  }> = await Axios.get(`/api/project/get-project-count`)
  return response.data.data
}

export const postCategoryMiddleware = (
  request: {
    name: string
  },
  callback: (
    type: STATUS_RESPONSE,
    messenger: string,
    data?: CategoryDetail
  ) => void
) => {
  Axios.post(`/api/category/create`, request)
    .then(
      (
        response: AxiosResponse<{
          data: CategoryDetail
        }>
      ) => {
        callback(STATUS_RESPONSE.SUCCESS, "", response.data.data)
      }
    )
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.POST_CATEGORY_ERROR
      )
    })
}

export const getProjectsMiddleware = async (
  params: ParamsProjectsProps,
  url: string
) => {
  const response: AxiosResponse<{
    data: ProjectDetail[]
  }> = await Axios.get(url, {
    params,
  })
  return response.data.data
}

export const getProjectDetailMiddleware = async (
  idProject: string,
  idConversation?: string
) => {
  let url = `/api/project/${idProject}`
  if (idConversation) {
    url += `?conversation_id=${idConversation}`
  }

  const response: AxiosResponse<{
    data: ProjectDetail
  }> = await Axios.get(url)
  return response.data.data
}

export const postProjectMiddleware = (
  request: {
    name: string
    description: string
    category_ids: string[]
  },
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.post(`/api/project/create`, request)
    .then((_response: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.POST_PROJECT_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.POST_PROJECT_ERROR
      )
    })
}

export const putProjectMiddleware = (
  idProject: string,
  request: {
    name: string
    description: string
    category_ids: string[]
  },
  callback: (
    type: STATUS_RESPONSE,
    messenger: string,
    data?: ProjectDetail
  ) => void
) => {
  Axios.put(`/api/project/${idProject}/update`, request)
    .then(
      (
        response: AxiosResponse<{
          data: ProjectDetail
        }>
      ) => {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.PUT_PROJECT_SUCCESS,
          response.data.data
        )
      }
    )
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.PUT_PROJECT_ERROR
      )
    })
}

export const deleteProjectMiddleware = (
  idProject: string,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.delete(`/api/project/${idProject}/delete`)
    .then((_response: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.DELETE_PROJECT_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.DELETE_PROJECT_ERROR
      )
    })
}
export const archiveProjectMiddleware = (
  idProject: string,
  isArchived: number,
  callback: (
    type: STATUS_RESPONSE,
    messenger: string,
    data?: ProjectDetail
  ) => void
) => {
  Axios.post(`/api/project/${idProject}/archive`)
    .then(
      (
        response: AxiosResponse<{
          data: ProjectDetail
        }>
      ) => {
        callback(
          STATUS_RESPONSE.SUCCESS,
          isArchived === 0
            ? MESSENGER_NOTIFICATION.ARCHIVE_PROJECT_SUCCESS
            : MESSENGER_NOTIFICATION.UN_ARCHIVE_PROJECT_SUCCESS,
          response.data.data
        )
      }
    )
    .catch((error) => {
      const newMessageError =
        isArchived === 0
          ? MESSENGER_NOTIFICATION.ARCHIVE_PROJECT_ERROR
          : MESSENGER_NOTIFICATION.UN_ARCHIVE_PROJECT_ERROR
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message
          ? error.response?.data?.message
          : newMessageError
      )
    })
}
export const favoriteProjectMiddleware = (
  idProject: string,
  callback: (
    type: STATUS_RESPONSE,
    messenger: string,
    data?: ProjectDetail
  ) => void
) => {
  Axios.post(`/api/project/${idProject}/like-or-unlike`)
    .then(
      (
        response: AxiosResponse<{
          data: ProjectDetail
        }>
      ) => {
        callback(STATUS_RESPONSE.SUCCESS, "", response.data.data)
      }
    )
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.FAVORITE_PROJECT_ERROR
      )
    })
}

export const getProjectRoleMiddleware = async (projectId: string) => {
  const response: AxiosResponse<{
    data: { role: string }
  }> = await Axios.get(`/api/project/${projectId}/role`)
  return response.data.data
}

export const putUpdateStatusMiddleware = (
  idProject: string,
  request: {
    status: number
  },
  callback: (
    type: STATUS_RESPONSE,
    messenger: string,
    data?: ProjectDetail
  ) => void
) => {
  Axios.put(`/api/project/${idProject}/update-status`, request)
    .then(
      (
        response: AxiosResponse<{
          data: ProjectDetail
        }>
      ) => {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.PUT_UPDATE_STATUS_PROJECT_SUCCESS,
          response.data.data
        )
      }
    )
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.PUT_UPDATE_STATUS_PROJECT_ERROR
      )
    })
}

export const getObjectSearchMiddleware = async (
  params: ParamsProjectsProps
) => {
  const response: AxiosResponse<{
    data: {
      projects: ProjectDetail[]
      components: ProjectComponentDetail[]
      builds: BuildConversationDetail[]
      conversations: ConversationDetail[]
    }
  }> = await Axios.get(`/api/project/search`, {
    params,
  })
  return response.data.data
}

export const deleteProjectAttachment = async (id: string) => {
  return Axios.delete(`/api/project/${id}/delete-attachment`).then(
    (res) => res.data
  )
}
