import { FC } from "react"
import { twMerge } from "tailwind-merge"
import Typography from "components/Typography"

export interface HeaderContentProps {
  onClick: () => void
}

export const HeaderContent: FC<HeaderContentProps> = ({ onClick }) => (
  <>
    <div></div>
    <Typography
      textClass="font-normal"
      textColor="black"
      style={{
        fontSize: 14,
        lineHeight: "24px",
        fontWeight: 600,
      }}
    >
      Info & Log
    </Typography>
    <Typography
      onClick={onClick}
      textClass="font-normal"
      textColor="blue"
      style={{
        fontSize: 14,
        lineHeight: "24px",
      }}
    >
      Done
    </Typography>
  </>
)

export interface HeaderBreadcrumbInfoProps {
  className?: string
}

export const HeaderBreadcrumbInfo: FC<HeaderBreadcrumbInfoProps> = ({
  children,
  className,
}) => (
  <div
    className={twMerge(
      "flex justify-end md:justify-between items-center h-full px-6",
      className
    )}
  >
    {children}
  </div>
)
