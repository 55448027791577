import { STATUS_BUILD } from "components/Status/types"
import { PATH } from "constants/path"
import { includes } from "lodash"
import { TYPE_PROJECT_COMPONENT } from "pages/project-component/project-component.constant"
import { pushTo } from "./history"

export const onRedirectProjectComponent = (
  titlePage: string,
  type: string,
  idComponent: string,
  idBuild = "history",
  state?: {}
) => {
  let newPath = PATH.projectComponentOther

  switch (type) {
    case TYPE_PROJECT_COMPONENT.PCB:
      newPath = PATH.projectComponentPCB
      break
    case TYPE_PROJECT_COMPONENT.BOM:
      newPath = PATH.projectComponentBOM
      break
  }
  if (
    includes([TYPE_PROJECT_COMPONENT.PCB, TYPE_PROJECT_COMPONENT.BOM], type)
  ) {
    pushTo(
      newPath,
      {
        idProjectComponent: idComponent,
        titlePage,
        idProjectBuildComponent: idBuild,
      },
      state
    )
    return
  }
  pushTo(
    newPath,
    {
      idProjectComponent: idComponent,
      titlePage,
      idProjectBuildComponent: idBuild,
      type,
    },
    state
  )
}

export const onRedirectConversationComponent = (
  type: string,
  idProjectComponent: string,
  idProjectBuildComponent: string,
  idProjectComponentHistory: string | null,
  idConversation: string,
  isActiveConversation?: boolean,
  state?: {},
  openToNewTab?: boolean
) => {
  let newPath = isActiveConversation
    ? PATH.inviteesBuildOther
    : PATH.inviteesOther
  switch (type) {
    case TYPE_PROJECT_COMPONENT.PCB:
      newPath = isActiveConversation ? PATH.inviteesBuildPCB : PATH.inviteesPCB
      break
    case TYPE_PROJECT_COMPONENT.BOM:
      newPath = isActiveConversation ? PATH.inviteesBuildBOM : PATH.inviteesBOM
      break
    case TYPE_PROJECT_COMPONENT.MECHANICAL:
      newPath = isActiveConversation
        ? PATH.inviteesBuildMechComponent
        : PATH.inviteesMechComponent
  }
  const params: any = {
    idProjectComponent,
    idProjectBuildComponent,
    idConversation,
  }
  if (!isActiveConversation) {
    params.idProjectComponentHistory = idProjectComponentHistory
  }

  if (
    !includes(
      [
        TYPE_PROJECT_COMPONENT.PCB,
        TYPE_PROJECT_COMPONENT.BOM,
        TYPE_PROJECT_COMPONENT.MECHANICAL,
      ],
      type
    )
  ) {
    params.type = type
  }
  if (openToNewTab) {
    window.open(
      `/invitees/component/${idProjectComponent}/${type}/${idProjectBuildComponent}/${idConversation}`
    )
  } else {
    pushTo(newPath, params, state)
  }
}

export const onRedirectProject = (idProject: string) => {
  pushTo(PATH.projectComponent, {
    idProject,
  })
}
export const onRedirectProjectUsage = () => {
  pushTo(PATH.StorageUsage)
}
export const onRedirectProjectBuild = (
  idProject: string,
  idProjectBuild: string,
  status: string,
  isOwner = false,
  archived = false
) => {
  pushTo(
    PATH.buildOverview,
    {
      status,
      idProject,
      idProjectBuild,
    },
    {
      selectArchivedFilter: archived,
    },
    { tab: isOwner ? "my-builds" : "others" }
  )
}

export const projectBuildLink = (
  idProject: string,
  idProjectBuild: string,
  status: STATUS_BUILD,
  query?: object
) => {
  let newPath = PATH.buildOverview
  return newPath
    .replace(":idProject", idProject)
    .replace(":status", status)
    .replace(":idProjectBuild", idProjectBuild)
}

export const projectComponentLink = (
  titlePage: string,
  type: string,
  idComponent: string,
  idBuild = "history",
  state?: {}
) => {
  let newPath = PATH.projectComponentOther
  return newPath
    .replace(":titlePage", titlePage)
    .replace(":idProjectComponent", idComponent)
    .replace(":type", type)
    .replace(":idProjectBuildComponent", idBuild)
}
