/* eslint-disable @typescript-eslint/no-redeclare */
import {
  UnreadBuild,
  UnreadConversation,
} from "pages/conversations/conversations.type"
import { Action } from "redux"
import { UserInfo } from "../types"

export const SET_USER_INFO_ACTION = "SET_USER_INFO_ACTION"
export const SET_UNREAD_BUILD_ACTION = "SET_UNREAD_BUILD_ACTION"
export const SET_UNREAD_CONVERSATION_ACTION = "SET_UNREAD_CONVERSATION_ACTION"

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SET_USER_INFO_ACTION = typeof SET_USER_INFO_ACTION
export type SET_UNREAD_BUILD_ACTION = typeof SET_UNREAD_BUILD_ACTION
export type SET_UNREAD_CONVERSATION_ACTION =
  typeof SET_UNREAD_CONVERSATION_ACTION
export interface SetUserInfoAction extends Action<SET_USER_INFO_ACTION> {
  payload: Partial<UserInfo>
}
export interface SetUnreadBuildAction extends Action<SET_UNREAD_BUILD_ACTION> {
  payload: Partial<UnreadBuild>
}
export interface SetUnreadConversationAction
  extends Action<SET_UNREAD_CONVERSATION_ACTION> {
  payload: Partial<UnreadConversation>
}

export const setUserInfoAction = (
  payload: Partial<UserInfo>
): SetUserInfoAction => {
  return {
    type: SET_USER_INFO_ACTION,
    payload,
  }
}
export const setUnreadBuildAction = (
  payload: Partial<UnreadBuild>
): SetUnreadBuildAction => {
  return {
    type: SET_UNREAD_BUILD_ACTION,
    payload,
  }
}

export const setUnreadConversationAction = (
  payload: Partial<UnreadConversation>
): SetUnreadConversationAction => {
  return {
    type: SET_UNREAD_CONVERSATION_ACTION,
    payload,
  }
}
