import { useContext } from "react"
import { RadioButtonProps } from "../types"
import { VersionSpecificationContext } from "../contexts/VersionSpecification.context"
import { fieldFormDataSpecification } from "helpers/utils"

const RadioButton = (props: RadioButtonProps) => {
  const { item, disabled } = props
  const context = useContext(VersionSpecificationContext)

  const handleChangeChecked = (newIndex: number) => () => {
    const isCheckedNo = newIndex === 0 ? true : false
    const isCheckedYes = newIndex === 0 ? false : true
    context?.updateDataForm(
      "formData",
      fieldFormDataSpecification(item.id, 0),
      isCheckedNo,
      Boolean(item.required)
    )
    context?.updateDataForm(
      "formData",
      fieldFormDataSpecification(item.id, 1),
      isCheckedYes,
      Boolean(item.required)
    )
  }

  return (
    <div className="flex items-center">
      {item.answers.length
        ? item.answers.map((answer, index) => (
            <div
              key={index}
              className="flex items-center"
              style={{
                marginRight: 26,
              }}
              onClick={handleChangeChecked(index)}
            >
              <input
                disabled={disabled}
                type="checkbox"
                className="checkbox-green"
                style={{
                  width: 20,
                  height: 20,
                  marginRight: 8,
                  border: context?.formData[
                    fieldFormDataSpecification(item.id, index)
                  ]
                    ? "1px solid #FFF"
                    : "1px solid #E4E4E4",
                  borderRadius: "50%",
                }}
                checked={
                  context?.formData[
                    fieldFormDataSpecification(item.id, index)
                  ] ?? false
                }
                onChange={handleChangeChecked(index)}
              />

              <p
                className="font-normal"
                style={{
                  fontSize: 14,
                  lineHeight: "24px",
                  color: "#222222",
                }}
              >
                {answer.name}
              </p>
            </div>
          ))
        : null}
      <p
        style={{
          fontSize: 14,
          lineHeight: "24px",
          color: "#222222",
        }}
        className="underline font-normal"
      >
        {item.helper_text}
      </p>
    </div>
  )
}

export default RadioButton
