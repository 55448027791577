import { MouseEventHandler } from "react"
import { twMerge } from "tailwind-merge"

interface Props {
  title: string
  onClick?: MouseEventHandler
  labelColor?: "black" | "white"
  className?: string
  icon?: React.ReactNode
}
const ActionItem = (props: Props) => {
  const { title, onClick, labelColor = "white", className = "", icon } = props
  return (
    <div
      onClick={onClick}
      className={twMerge(
        "min-h-32 flex items-center gap-3 px-3 cursor-pointer hover-text-select",
        className
      )}
    >
      {icon}
      <span
        className="font-normal text-left"
        style={{
          fontSize: 13,
          lineHeight: "22px",
          color: labelColor === "white" ? "#FFFFFF" : "#000000",
        }}
      >
        {title}
      </span>
    </div>
  )
}
export default ActionItem
