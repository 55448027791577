import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { pushTo } from "helpers/history"
import { PATH } from "constants/path"
import PageLayout from "pages/layout/PageLayout"
import {
  ProjectDetail,
  defaultProjectDetail,
  STATUS_PROJECT_ROLE,
} from "pages/projects/types"
import {
  getProjectDetailMiddleware,
  getProjectRoleMiddleware,
} from "pages/projects/services/api"
import {
  IUseDefaultValueProps,
  useBoolean,
  useString,
  useWindowSize,
} from "helpers/hooks"
import { getBuildStatusMiddleware } from "../../project-component/services/api"
import Header from "../../project-build/organisms/ProjectBuildHeader"
// import Header from " /organisms/ProjectBuildHeader"
import InfoDraftBuildCard from "../../project-build/organisms/InfoDraftBuildCard"
import {
  emptyProjectBuildDetail,
  MENU_TAB_BUILD,
  ProjectBuildAdditionalDetail,
  ProjectBuildExtraDetail,
  ProjectBuildInvitessDetail,
  ProjectComponentBuildDetail,
} from "../../project-build/project-build.type"
import {
  getProjectBuildAdditionalInfoMiddleware,
  getProjectBuildExtraInfoMiddleware,
  getProjectBuildInviteeMiddleware,
  getProjectBuildMiddleware,
  postProjectBuildComponentSyncMiddleware,
} from "../../project-build/api.services"
import { PermissionProjectBuildPage } from "../../project-build/contexts/PermissionProjectBuildPage.context"
import ComponentBuildSyncCard from "../../project-build/organisms/ComponentBuildSyncCard"
import { SelectedDefaultProp } from "components/Select/types"
import MenuTabBuildCard from "../../project-build/molecules/MenuTabBuildCard"
import AdditionalInfoCard from "../../project-build/organisms/AdditionalInfoCard"
import { cloneDeep, isUndefined, remove } from "lodash"
import ExtraInfoCard from "../../project-build/organisms/ExtraInfoCard"
import { STATUS_BUILD } from "components/Status/types"
import InviteesInfoCard from "../../project-build/organisms/InviteesInfoCard"
import { checkPermissionPage } from "helpers/utils"
import { ProjectComponentDetail } from "pages/project-component/types"
import ModalDelete from "components/ModalCustom/ModalDelete"
import { STATUS_RESPONSE } from "types"
import { toast } from "react-toastify"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { ReactComponent as ArrowLeftIcon } from "assets/images/icons/arrow-left.svg"
interface Props {
  projectBuild: ProjectComponentBuildDetail
  isArchived: boolean | undefined
  showBuildDetailLayout: IUseDefaultValueProps
  onUpdateDataEditNameBuild: (
    updatedData: Partial<ProjectComponentBuildDetail>
  ) => void
  updatedAtBuild: string
}

const BuildDetail = (props: Props) => {
  const params = useParams<{
    status: string
    idProject: string
    idProjectBuild: string
  }>()
  const {
    showBuildDetailLayout,
    isArchived,
    onUpdateDataEditNameBuild,
    projectBuild,
    updatedAtBuild,
  } = props
  const idProjectParams = params?.idProject || ""
  const idProjectBuildParams = params?.idProjectBuild || ""
  const idStatusBuildParams = params?.status || ""
  const [projectDetail, setProjectDetail] =
    useState<ProjectDetail>(defaultProjectDetail)
  const isLoading = useBoolean()
  // const [projectBuild, setProjectBuild] = useState<ProjectComponentBuildDetail>(
  //   emptyProjectBuildDetail
  // )
  const archiveProject = useBoolean(true)
  const viewOnlyShare = useBoolean(true)
  const archiveBuild = useBoolean(false)
  const [listStatus, setListStatus] = useState<SelectedDefaultProp[]>([])
  const { isMobile } = useWindowSize()
  const activeTab = useString(
    isMobile ? MENU_TAB_BUILD.COMPONENT : MENU_TAB_BUILD.INVITEES
  )
  const [additionals, setAdditionals] = useState<
    ProjectBuildAdditionalDetail[]
  >([])
  const [extras, setExtras] = useState<ProjectBuildExtraDetail[]>([])
  const [invitess, setInvitess] = useState<ProjectBuildInvitessDetail[]>([])
  const isSyncComponent = useBoolean(false)
  const projectBuildComponentSyncId = useString("")
  // const sizeWindow = useWindowSize()
  const isDeleted = projectBuild.status === STATUS_BUILD.DELETED
  useEffect(() => {
    if (!idProjectParams) {
      // pushTo(PATH.projects)
      return
    }
    getProjectDetail()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProjectParams, idProjectBuildParams, idStatusBuildParams])

  const getProjectDetail = async () => {
    isLoading.setValue(true)
    try {
      const dataProjectRoleRes = await getProjectRoleMiddleware(idProjectParams)
      const dataProjectDetailRes = await getProjectDetailMiddleware(
        idProjectParams
      )
      archiveProject.setValue(Boolean(dataProjectDetailRes.is_archived))
      viewOnlyShare.setValue(
        dataProjectRoleRes.role === STATUS_PROJECT_ROLE.VIEWER
      )
      setProjectDetail(dataProjectDetailRes)
      await getProjectBuild(idProjectBuildParams)

      isLoading.setValue(false)
    } catch (error) {
      isLoading.setValue(false)
      pushTo(PATH.projects)
    }
  }
  const getProjectBuild = async (idBuild: string, loading = false) => {
    try {
      // const dataRes = await getProjectBuildMiddleware(idBuild)
      // setProjectBuild(dataRes)
      // updatedAtBuild.setValue(dataRes.updated_at)
      archiveBuild.setValue(Boolean(projectBuild.is_archived))
      const dataBuildStatusRes = await getBuildStatusMiddleware()
      setListStatus([
        ...dataBuildStatusRes.map((i) => ({
          label: i.key,
          value: String(i.value),
        })),
      ])

      if (projectBuild.status !== STATUS_BUILD.COMMITTED) {
        await getInvitees(idBuild)
      }
      await getAdditional(idBuild)
      await getExtra(idBuild)

      if (loading) {
        isLoading.setValue(false)
      }
    } catch (error) {
      if (loading) {
        isLoading.setValue(false)
      }
      pushTo(PATH.projectComponent, {
        idProject: idProjectParams,
      })
    }
  }
  const getAdditional = async (idBuild: string, loading = false) => {
    const dataAdditionalRes = await getProjectBuildAdditionalInfoMiddleware(
      idBuild
    )
    setAdditionals(dataAdditionalRes)
    if (loading) {
      isLoading.setValue(false)
    }
  }
  const getExtra = async (idBuild: string, loading = false) => {
    const dataExtraRes = await getProjectBuildExtraInfoMiddleware(idBuild)
    setExtras(dataExtraRes)
    if (loading) {
      isLoading.setValue(false)
    }
  }
  const getInvitees = async (idBuild: string, loading = false) => {
    const dataInvitees = await getProjectBuildInviteeMiddleware(idBuild)
    setInvitess(dataInvitees)
    if (loading) {
      isLoading.setValue(false)
    }
  }
  const onChangeTab = (_newIndex: number, newLabel?: string) => () => {
    if (!newLabel) {
      return
    }
    if (activeTab.value === newLabel) {
      return
    }
    activeTab.setValue(newLabel)
    isLoading.setValue(true)
    switch (newLabel) {
      case MENU_TAB_BUILD.INVITEES:
        getInvitees(projectBuild.id, true)
        break
      case MENU_TAB_BUILD.ADDITIONAL:
        getAdditional(projectBuild.id, true)
        break
      default:
        getExtra(projectBuild.id, true)
        break
    }
  }
  const updateWhenCreateAdditional = () => {
    isLoading.setValue(true)
    getAdditional(projectBuild.id, true)
  }
  const updateWhenDeleteAdditional = (oldAdditional: string) => {
    const newAdditionals = cloneDeep(additionals)
    remove(newAdditionals, (el) => el.id === oldAdditional)
    setAdditionals(newAdditionals)
  }
  const updateWhenCreateExtra = () => {
    isLoading.setValue(true)
    getExtra(projectBuild.id, true)
  }
  const updateWhenDeleteExtra = (oldIdExtra: string) => {
    const newExtras = cloneDeep(extras)
    remove(newExtras, (el) => el.id === oldIdExtra)
    setExtras(newExtras)
  }
  const handleChangeDataVendor = () => {
    isLoading.setValue(true)
    getInvitees(projectBuild.id, true)
  }

  const renderComponentBuildSyncCard = () => (
    <div className="pr-6 md:pr-0">
      <ComponentBuildSyncCard
        projectBuild={projectBuild}
        // gridCols={sizeWindow.width > 1395 ? `grid-cols-2` : "grid-cols-1"}
        gridCols={"grid-cols-1"}
        onSyncDataComponent={onSyncDataComponent}
      />
    </div>
  )

  const renderTab = () => {
    const finalActiveTab =
      activeTab.value === MENU_TAB_BUILD.INVITEES &&
      projectBuild.status === STATUS_BUILD.COMMITTED
        ? MENU_TAB_BUILD.ADDITIONAL
        : activeTab.value
    switch (finalActiveTab) {
      case MENU_TAB_BUILD.COMPONENT:
        return renderComponentBuildSyncCard()
      case MENU_TAB_BUILD.EXTRA:
        return (
          <ExtraInfoCard
            extras={extras}
            idProjectBuild={projectBuild.id}
            updateWhenCreateExtra={updateWhenCreateExtra}
            updateWhenDeleteExtra={updateWhenDeleteExtra}
            viewOnly={isDeleted}
          />
        )
      case MENU_TAB_BUILD.INVITEES:
        return (
          <InviteesInfoCard
            idProjectBuild={projectBuild.id}
            handleChangeData={handleChangeDataVendor}
            invitess={invitess}
            sumComponent={projectBuild.components.length}
            isDeleted={isDeleted}
          />
        )
      default:
        return (
          <AdditionalInfoCard
            additionals={additionals}
            idProjectBuild={projectBuild.id}
            updateWhenCreateAdditional={updateWhenCreateAdditional}
            updateWhenDeleteAdditional={updateWhenDeleteAdditional}
            isEditorInput={checkPermissionPage({
              project: archiveProject.value,
              build: archiveBuild.value,
              viewShare: viewOnlyShare.value,
            })}
            isLabel
            isDeleteComment={checkPermissionPage({
              project: archiveProject.value,
              build: archiveBuild.value,
              viewShare: viewOnlyShare.value,
            })}
            isDeleted={isDeleted}
          />
        )
    }
  }
  const onSyncDataComponent = (newItem: ProjectComponentDetail) => (event) => {
    event.preventDefault()
    event.stopPropagation()
    isSyncComponent.setValue(true)
    projectBuildComponentSyncId.setValue(newItem.project_build_component_id)
  }
  const onSynComponent = () => {
    if (!projectBuildComponentSyncId.value) {
      return
    }
    isLoading.setValue(true)
    postProjectBuildComponentSyncMiddleware(
      projectBuildComponentSyncId.value,
      (type: STATUS_RESPONSE, messenger: string) => {
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        if (type === STATUS_RESPONSE.SUCCESS) {
          isSyncComponent.setValue(false)
          getProjectBuild(idProjectBuildParams, true)
        } else {
          isLoading.setValue(false)
        }
      }
    )
  }
  //

  return (
    <PermissionProjectBuildPage.Provider
      value={{
        archiveProject: archiveProject.value,
        archiveBuild: isUndefined(isArchived)
          ? Boolean(projectBuild.is_archived)
          : isArchived,
        viewOnlyShare: viewOnlyShare.value,
        listStatus,
        status: projectBuild.status,
        tabMenu: activeTab.value,
        projectBuild: projectBuild,
      }}
    >
      <div className=" flex flex-col w-full border-l border-[#E4E4E4] h-[calc(100_*_var(--vh)_-_52px)] md:h-[calc(100_*_var(--vh)_-_0px)] ">
        <div className="h-[48px] flex justify-between items-center">
          <div
            className="flex md:hidden p-6"
            onClick={() => {
              showBuildDetailLayout.setValue(false)
            }}
          >
            <ArrowLeftIcon />
          </div>
          <div className="md:w-full">
            <Header
              projectDetail={projectDetail}
              projectBuild={projectBuild}
              updatedAtBuild={updatedAtBuild}
            />
          </div>
        </div>
        <div className=" h-full flex flex-col overflow-auto">
          <InfoDraftBuildCard
            projectBuild={projectBuild}
            onUpdateDataEditNameBuild={onUpdateDataEditNameBuild}
          />
          <div
            className="block md:grid h-full overflow-auto"
            style={{
              borderTop: "1px solid #E4E4E4",
              // gridTemplateColumns:
              //   sizeWindow.width > 1395
              //     ? "684px calc(100% - 684px)"
              //     : "358px calc(100% - 358px)",
              gridTemplateColumns: "358px calc(100% - 358px)",
            }}
          >
            <div className="border-r border-[#E4E4E4] overflow-auto">
              <div className="hidden md:block p-6 h-full">
                {renderComponentBuildSyncCard()}
              </div>
            </div>
            <div className="flex flex-col h-full pl-6 pb-2 overflow-auto bg-grayWhite2 md:bg-white">
              <MenuTabBuildCard
                activeTab={
                  activeTab.value === MENU_TAB_BUILD.INVITEES &&
                  projectBuild.status === STATUS_BUILD.COMMITTED
                    ? MENU_TAB_BUILD.ADDITIONAL
                    : activeTab.value
                }
                onChangeTab={onChangeTab}
                additional={{
                  isRead: !projectBuild.is_read_additional_info
                    ? projectBuild.is_read_additional_info
                    : false,
                  count: additionals.length,
                }}
                extra={{
                  isRead: !projectBuild.is_read_extra_info
                    ? projectBuild.is_read_extra_info
                    : false,
                  count: extras.length,
                }}
                isInvitee={
                  projectBuild.status !== STATUS_BUILD.COMMITTED ? true : false
                }
                invitees={{
                  count: invitess.length,
                  isRead: false,
                }}
              />
              {/* tab */}
              {renderTab()}
            </div>
          </div>
        </div>
      </div>

      {isSyncComponent.value && (
        <ModalDelete
          onCloseModal={() => isSyncComponent.setValue(false)}
          title={`Update to the latest version from the master branch and cannot be undone.`}
          content=""
          label={`Confirmation`}
          onSubmit={onSynComponent}
          colorYellowButton
          titleButton="Confirm"
          styleTitle={{
            textAlign: "center",
          }}
        />
      )}
    </PermissionProjectBuildPage.Provider>
    // </PageLayout>
  )
}

export default BuildDetail
