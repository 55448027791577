import { SelectedDefaultProp } from "components/Select/types"
import { STATUS_BUILD } from "components/Status/types"
import React from "react"
import {
  MENU_TAB_BUILD,
  ProjectComponentBuildDetail,
  emptyProjectBuildDetail,
} from "../project-build.type"

export interface GlobalPermissionProjectBuildPageContext {
  archiveProject: boolean
  archiveBuild: boolean
  viewOnlyShare: boolean
  listStatus: SelectedDefaultProp[]
  status: string
  tabMenu: MENU_TAB_BUILD
  projectBuild: ProjectComponentBuildDetail
}

export const PermissionProjectBuildPage =
  React.createContext<GlobalPermissionProjectBuildPageContext>({
    archiveProject: true,
    archiveBuild: true,
    viewOnlyShare: true,
    listStatus: [],
    status: STATUS_BUILD.DRAFT,
    tabMenu: MENU_TAB_BUILD.ADDITIONAL,
    projectBuild: emptyProjectBuildDetail,
  })
