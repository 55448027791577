import Typography from "components/Typography"
import { useBoolean, useNumber } from "helpers/hooks"
import { useEffect, useRef, useState } from "react"
import { ReactComponent as CloseIcon } from "assets/images/icons/icon-close-modal.svg"
import { Avatar } from "components/Avatar"
import { formatDateForConversationMessage } from "helpers/utils"
import { cloneDeep } from "lodash"
import {
  ActivityItemDetail,
  MODEL_ACTIVITY_LOG,
  ParamsActivityLogs,
} from "components/activity-log/activity-log.type"
import { getActivitiesMiddleware } from "components/activity-log/activity-log.api"

interface Props {
  idRelation: string
  model: MODEL_ACTIVITY_LOG
  isOpen: boolean
  onCloseActivity: () => void
}
const ActivityLogCard = (props: Props) => {
  const { idRelation, model, isOpen, onCloseActivity } = props
  const [activities, setActivities] = useState<ActivityItemDetail[]>([])
  const isLoading = useBoolean(false)
  const pageHook = useNumber(1)
  const rowsPerPageHook = useNumber(20)
  const ref = useRef<HTMLDivElement>(null)
  const isMoreData = useBoolean(true)

  useEffect(() => {
    if (!isOpen || !model || !idRelation) {
      return
    }
    pageHook.setValue(1)
    setActivities([])
    getActivities()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, model, idRelation])

  const getActivities = async () => {
    isLoading.setValue(true)
    try {
      const params: ParamsActivityLogs = {
        page: pageHook.value,
        pageSize: rowsPerPageHook.value,
        id: idRelation,
        model,
      }
      const dataRes = await getActivitiesMiddleware(params)
      setActivities(dataRes)
      isMoreData.setValue(dataRes.length < rowsPerPageHook.value ? false : true)
      isLoading.setValue(false)
    } catch (error) {
      isLoading.setValue(false)
      setActivities([])
    }
  }
  const handleScroll = async () => {
    const offsetHeight = ref.current?.offsetHeight
    const scrollHeight = ref.current?.scrollHeight
    const scrollTop = ref.current?.scrollTop

    if (Number(scrollTop) + Number(offsetHeight) > Number(scrollHeight) - 10) {
      if (!isMoreData.value || !activities.length || isLoading.value) {
        return
      }
      isLoading.setValue(true)
      try {
        const params: ParamsActivityLogs = {
          page: pageHook.value + 1,
          pageSize: rowsPerPageHook.value,
          id: idRelation,
          model,
        }
        const dataRes = await getActivitiesMiddleware(params)
        isMoreData.setValue(
          dataRes.length < rowsPerPageHook.value ? false : true
        )
        pageHook.setValue(pageHook.value + 1)
        const newData = cloneDeep(activities).concat(dataRes)
        setActivities(newData)
        isLoading.setValue(false)
      } catch (error) {
        isLoading.setValue(false)
      }
    }
  }

  return (
    <div
      className="flex flex-col rounded-none md:rounded-md w-full md:w-[320px] md:bg-white"
      style={
        {
          // boxShadow: " 0px 0px 20px rgba(0, 0, 0, 0.12)",
          // height: "calc(100vh - 200px)",
        }
      }
    >
      <div className="h-52 md:h-50-custom px-6 md:px-4 flex items-center justify-between border-b-[1px] md:border-b-0 sticky top-0 bg-white">
        <Typography
          variant="h6"
          style={{
            color: " #222222",
            lineHeight: "26px",
            fontWeight: 600,
          }}
        >
          Activity log
        </Typography>
        <div
          className="flex justify-center items-center h-26-custom w-26-custom hover-button-modal"
          onClick={onCloseActivity}
        >
          <CloseIcon className="cursor-pointer" />
        </div>
      </div>

      {/* main content */}
      <div
        className="flex flex-col pl-4 md:max-h-[710px] overflow-auto mb-4 mt-[22px]"
        ref={ref}
        onScroll={handleScroll}
      >
        {activities.length
          ? activities.map((activity, index) => (
              <div key={index} className="flex flex-col items-center mb-4 mr-4">
                <div className="flex items-center w-full">
                  <div
                    style={{
                      width: 24,
                    }}
                  >
                    <Avatar
                      width="24px"
                      height="24px"
                      name={activity.user.fullname}
                      avatar={activity.user.avatar}
                      textcolor="white"
                      contentsize="11px"
                      justOneLetter={true}
                    />
                  </div>
                  <p
                    className="font-normal ml-2"
                    style={{
                      color: "#111111",
                      fontSize: 13,
                      lineHeight: "22px",
                    }}
                    dangerouslySetInnerHTML={{ __html: activity.content }}
                  ></p>
                </div>
                <div className="flex justify-start w-full pl-8">
                  <p
                    className="font-normal"
                    style={{
                      fontSize: 13,
                      lineHeight: "22px",
                      color: "#7A7A7A",
                    }}
                  >
                    {formatDateForConversationMessage(activity.created_at)}
                  </p>
                </div>
              </div>
            ))
          : null}
      </div>
    </div>
  )
}
export default ActivityLogCard
