import { STATUS_CONVERSATION } from "../conversations.type"

interface Props {
  status: string
  styleRoot?: React.CSSProperties
}
const StatusConversationCard = (props: Props) => {
  const { status, styleRoot } = props
  return (
    <div
      className="mr-3 ml-0 md:ml-6 flex items-center justify-center px-2"
      style={{
        height: 40,
        background:
          status === STATUS_CONVERSATION.CLOSED
            ? "rgba(234, 69, 69, 0.15)"
            : "rgba(86, 160, 18, 0.15)",
        borderRadius: 4,
        minWidth: 55,
        ...styleRoot,
      }}
    >
      <p
        className="font-semibold text-14 leading-24"
        style={{
          color: status === STATUS_CONVERSATION.CLOSED ? "#EA4545" : "#56A012",
        }}
      >
        {status}
      </p>
    </div>
  )
}
export default StatusConversationCard
