import { ReactComponent as IconWarning } from "assets/images/icons/icon-form-warning.svg"
import { twMerge } from "tailwind-merge"
interface Props {
  label: string
  minHeight?: number
  hiddenIcon?: boolean
  styleRoot?: React.CSSProperties
  className?: string
}
const FormLabelWarning = (props: Props) => {
  const { label, minHeight = 28, hiddenIcon, styleRoot, className } = props
  return (
    <div
      className={twMerge(
        "flex items-center px-2 bg-[#ff973826] rounded",
        className
      )}
      style={{
        minHeight,
        ...styleRoot,
      }}
    >
      <div>{!hiddenIcon ? <IconWarning className="mr-1" /> : null}</div>
      <p className="font-normal text-[13px] leading-[22px] text-[#E87A16] pl-1">
        {label}
      </p>
    </div>
  )
}
export default FormLabelWarning
