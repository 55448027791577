import { TippyCustomzie } from "components/TippyCustomzie"
import { useBoolean } from "helpers/hooks"
import ActionItem from "pages/projects/molecules/ActionItem"
import { ReactComponent as IconSupplierDropdown } from "assets/images/icons/icon-supplier-dropdown.svg"
interface Props {
  isDisable: boolean
  title: string
  value: string
  options: {
    value: string
    label: string
  }[]
}
const SelectSupplier = (props: Props) => {
  const { isDisable, title, value, options } = props
  const showSelect = useBoolean(false)

  return (
    <TippyCustomzie
      containerClass="component-card-menu"
      placement="bottom-end"
      interactive
      arrow={false}
      animation="scale"
      visible={showSelect.value}
      onClickOutside={() => showSelect.setValue(false)}
      offset={[0, 0]}
      content={
        <div
          style={{
            background: "#222222",
            borderRadius: 4,
            paddingTop: 8,
            paddingBottom: 8,
            width: 162,
          }}
        >
          <div className="min-h-32 flex items-center justify-between px-3">
            <span
              className="font-normal"
              style={{
                fontSize: 12,
                lineHeight: "18px",
                color: "#7A7A7A",
              }}
            >
              {title}
            </span>
          </div>
          {options.map((el, index) => (
            <ActionItem
              key={index}
              title={el.label}
              onClick={() => showSelect.setValue(false)}
            />
          ))}
        </div>
      }
      allowHTML
    >
      <div
        className="flex items-center w-full h-full"
        onClick={() => {
          if (isDisable) {
            return
          }
          showSelect.setValue(true)
        }}
      >
        <div
          className="pl-2 pr-4 flex items-center justify-between hover-select-item-supplier w-full"
          style={{
            height: 32,
            width: 162,
          }}
        >
          <p
            className="font-semibold"
            style={{
              fontSize: 14,
              lineHeight: "24px",
              color: "#111111",
            }}
          >
            {value}
          </p>
          <IconSupplierDropdown />
        </div>
      </div>
    </TippyCustomzie>
  )
}
export default SelectSupplier
