import React, { useEffect, useState } from "react"
import useCollapse from "react-collapsed"
import { CustomCollapseProps } from "../types"
import { toast } from "react-toastify"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { twMerge } from "tailwind-merge"

const isEqualProps = (
  prevProps: CustomCollapseProps,
  nextProps: CustomCollapseProps
) => {
  return (
    prevProps.childrenBody === nextProps.childrenBody &&
    prevProps.isToggle === nextProps.isToggle &&
    prevProps.buttonClass === nextProps.buttonClass &&
    prevProps.hideBorderRadius === nextProps.hideBorderRadius &&
    prevProps.isDisableToggle === nextProps.isDisableToggle &&
    prevProps.messageDisable === nextProps.messageDisable &&
    prevProps.header === nextProps.header
  )
}

const CustomCollapse = React.memo((props: CustomCollapseProps) => {
  const {
    header,
    containerClass = "",
    childrenBody,
    isToggle,
    buttonClass,
    isDisableToggle,
    messageDisable,
    childrenClass,
    changeHeaderBorder = true,
    handleChangeToggle,
  } = props
  const [isExpanded, setExpanded] = useState(isToggle)

  const borderBottom =
    isExpanded && changeHeaderBorder
      ? "border-[1px] border-solid border-[#E4E4E4]"
      : "border-[1px] border-solid border[#FFFFFF]"

  useEffect(() => {
    setExpanded(isToggle)
  }, [isToggle])

  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })
  const openToggle = () => {
    if (isDisableToggle) {
      toast(
        <LabelNotificationPage
          messenger={messageDisable ?? "Please upload Gerber first!"}
          type="warning"
        />
      )

      return
    }

    setExpanded(!isExpanded)
    if (handleChangeToggle) {
      handleChangeToggle(!isExpanded)
    }
  }

  return (
    <div className={twMerge("flex flex-col rounded bg-white", containerClass)}>
      <button
        {...getToggleProps({
          onClick: openToggle,
        })}
        className={twMerge(
          `min-h-12 border-b-[1px] ${borderBottom}`,
          buttonClass
        )}
      >
        {header}
      </button>
      <section className={childrenClass} {...getCollapseProps()}>
        {childrenBody}
      </section>
    </div>
  )
}, isEqualProps)

export default CustomCollapse
