import { ReactComponent as IconActiveSelect } from "assets/images/icons/icon-active-filter.svg"
import { cloneDeep } from "lodash"
import { TypeSelectProp } from "pages/project-component-detail/types"
import { BuildStatusProp } from "pages/project-component/types"
import { twMerge } from "tailwind-merge"
interface Props {
  title: string
  styleRoot?: React.CSSProperties
  dataSelects:
    | TypeSelectProp[]
    | BuildStatusProp[]
    | { key: string; value: string }[]
  objActiveSelect: any
  type?: "multi" | "single"
  setObjActiveSelect?: (newObjActiveSelect: any) => void
  containerClass?: string
}
const FormSelectFilter = (props: Props) => {
  const {
    title,
    styleRoot,
    dataSelects,
    objActiveSelect,
    type = "multi",
    containerClass,
    setObjActiveSelect,
  } = props

  const handleSelect = (newKey: number) => () => {
    let newObjActiveSelect = newKey

    if (type === "multi") {
      newObjActiveSelect = cloneDeep(objActiveSelect)
      if (newObjActiveSelect === -1) return
      if (newObjActiveSelect[newKey]) {
        delete newObjActiveSelect[newKey]
      } else {
        newObjActiveSelect[newKey] = newKey
      }
    }

    if (setObjActiveSelect) {
      setObjActiveSelect(newObjActiveSelect)
    }
  }

  return (
    <div
      className={twMerge(
        "flex flex-col w-6/12 border-left-section",
        containerClass
      )}
      style={{
        ...styleRoot,
      }}
    >
      <p
        className="font-semibold mt-5 mb-2 px-3"
        style={{
          fontSize: 13,
          lineHeight: "22px",
          color: "#7A7A7A",
        }}
      >
        {title}
      </p>
      <div className="overflow-y-auto css-26l3qy-menu">
        {dataSelects.length
          ? dataSelects.map((select, index) => (
              <div
                key={index}
                className="flex justify-between items-center px-3 cursor-pointer hover-text-select-in-production"
                style={{
                  height: 32,
                  minHeight: 32,
                }}
                onClick={handleSelect(select.value)}
              >
                <p
                  className="font-normal overflow-hidden text-ellipsis whitespace-nowrap max-w-[96px]"
                  style={{
                    fontSize: 13,
                    lineHeight: "22px",
                    color:
                      (type === "single" && objActiveSelect === select.value) ||
                      (type === "multi" &&
                        objActiveSelect[select.value] === select.value)
                        ? "#FDBE44"
                        : "#FFFFFF",
                  }}
                >
                  {select.key}
                </p>
                {(type === "single" && objActiveSelect === select.value) ||
                (type === "multi" &&
                  objActiveSelect[select.value] === select.value) ? (
                  <IconActiveSelect />
                ) : null}
              </div>
            ))
          : null}
      </div>
    </div>
  )
}
export default FormSelectFilter
