import { useState, useEffect } from "react"
import Button from "components/Button/Button"
import FormInputCard from "pages/auth/molecules/FormInputCard"
import SelectDefault from "components/Select/SelectDefault"
import { useWindowSize, useBoolean } from "helpers/hooks"
import { useAppSelector } from "hooks/useApp"
import { SettingStateProps, timezones, SettingRequestProps } from "./types"
import {
  updateNotificationEmail,
  updateSettingMiddleware,
} from "./services/api"
import { isEmpty } from "lodash"
import { toast } from "react-toastify"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import "./setting.css"
import SwitchButtonDefault from "components/SwitchButton/SwitchButtonDefault"
import { setUserInfoAction } from "pages/auth/stores/actions"
import { configureStore } from "stores/configureStore"

const Settings = () => {
  const { isMobile, width } = useWindowSize()
  const isUpdating = useBoolean(false)
  const userInfo = useAppSelector((state) => state.userInfo)
  const [settingState, setSettingState] = useState<SettingStateProps>()

  // default state
  const userTimezone = timezones.find(
    (item) => item.value === userInfo.timezone
  )
  useEffect(() => {
    if (userInfo && userTimezone) {
      setSettingState({
        ...settingState,
        timezone: userTimezone,
      })
    }
  }, [userInfo])

  const onSubmit = () => {
    isUpdating.setValue(false)
    if (settingState && settingState.timezone) {
      /// setting timezone to localStorage
      localStorage.setItem("timezone", settingState.timezone.value)
    }
    //
    const payload: SettingRequestProps = {}
    if (settingState?.timezone) {
      payload.timezone = settingState.timezone.value
    }
    //
    if (!isEmpty(payload)) {
      updateSettingMiddleware(payload).catch((error) => {
        const message = error.response?.data?.message ?? "Something went wrong!"
        toast(<LabelNotificationPage messenger={message} type="error" />)
      })
    }
  }
  const onUpdateNotificationEmail = () => {
    updateNotificationEmail()
      .then((res) => {
        configureStore.dispatch(
          setUserInfoAction({
            recived_email_from_my_own_messages:
              userInfo.recived_email_from_my_own_messages ? false : true,
          })
        )
        toast(<LabelNotificationPage messenger={res.message} type="success" />)
      })
      .catch((error) => {
        toast(
          <LabelNotificationPage
            messenger={error.response?.data?.message}
            type="error"
          />
        )
      })
  }
  const resetState = () => {
    setSettingState({
      ...settingState,
      timezone: userTimezone,
    })
    isUpdating.setValue(false)
  }

  return (
    <div className="bg-white">
      <div className="inline-block w-full">
        <div className="inline-block w-full">
          <FormInputCard title="Timezone" fontWeightText="font-semibold">
            <SelectDefault
              options={timezones}
              selectedOption={settingState?.timezone}
              handleChange={(selected) => {
                setSettingState({
                  ...settingState,
                  timezone: selected,
                })
                isUpdating.setValue(true)
              }}
              filed="name"
              rootClasses="h-10 rounded border-select flex items-center "
              controlWidth={isMobile ? width - 96 : 414}
              menuPlacement="bottom"
              menuStyle={{ marginTop: 10 }}
            />
          </FormInputCard>
        </div>
        <p className="text-brown text-[14px] mt-[-8px]">
          Changing your timezone will change the time displayed on Tracelium
        </p>
      </div>
      <div className="mt-6">
        <FormInputCard
          title="Email notification"
          fontWeightText="font-semibold"
        >
          <div className="flex items-center justify-between ">
            <SwitchButtonDefault
              checked={userInfo.recived_email_from_my_own_messages}
              containerClass="gap-3"
              labelButton="Get email notifications for messages I've sent to others."
              labelButtonClass="text-sm leading-6 text-black font-normal"
              onChange={onUpdateNotificationEmail}
            />
          </div>
        </FormInputCard>
      </div>

      <div className="flex pt-[24px] justify-between md:flex-row actions">
        <Button
          title="Cancel"
          colorBtn="white"
          widthBtn={"48%"}
          className="mb-[12px] md:mb-0"
          disabled={!isUpdating.value}
          onClick={resetState}
        />
        <Button
          disabled={!isUpdating.value || !settingState?.timezone}
          title="Save"
          onClick={onSubmit}
          widthBtn={"48%"}
        />
      </div>
    </div>
  )
}
export default Settings
