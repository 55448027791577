import PageLayout from "pages/layout/PageLayout"
import StorageUsageHeader from "pages/project-component/organisms/StorageUsageHeader"
import { useEffect, useState } from "react"
import { getStorageUsage } from "./api.service"
import { StorageUsageData } from "./StorageUsage.type"
import StorageUsageList from "./StorageUsageList"
import { ceil } from "lodash"

const StorageUsagePage = () => {
  const [data, setData] = useState<StorageUsageData>({
    projects: [],
    conversations: [],
    summary: {
      used_size: "0 Bytes",
      limited_size: "0 Bytes",
      used_percentage: 0,
    },
  })
  const getStorageUsageData = () => {
    getStorageUsage().then((response) => {
      setData(response)
    })
  }
  const handleChangeSummary = (value: boolean) => {
    if (value === true) {
      getStorageUsageData()
    }
  }
  useEffect(() => {
    getStorageUsageData()
  }, [])
  return (
    <PageLayout childrenHeader={<StorageUsageHeader />}>
      {" "}
      <div className="flex flex-col gap-2 pl-6 pr-6 pt-6">
        <p className="pb-5 text-16 font-semibold text-new-black-333333 whitespace-nowrap">
          {data.summary.used_size} ({ceil(data.summary.used_percentage)}%) of{" "}
          {data.summary.limited_size} used
        </p>
        <h4>Project Usage</h4>
        <StorageUsageList
          tableData={data.projects}
          onChangeSummary={handleChangeSummary}
          type={"Project"}
        />
        <h4>Internal Usage</h4>
        <StorageUsageList
          tableData={data.conversations}
          onChangeSummary={handleChangeSummary}
          type={"Internal"}
        />
      </div>
    </PageLayout>
  )
}

export default StorageUsagePage
