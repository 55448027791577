import { StatusProjectDetail } from "./types"

export enum TAB_PROJECT {
  MY_PROJECT = "my_project",
  SHARED = "shared",
  FAVORITE = "favorite",
}

export const PROJECT_STATUS = {
  ABANDONED: 1,
  ACTIVE: 2,
  COMPLETED: 3,
  NO_STATUS: 4,
  DELETE_ATTACHMENT: 5,
}
export const LIST_SHARING_PROJECT = [
  {
    label: "All",
    value: -1,
  },
  {
    label: "Shared projects",
    value: 1,
  },
  {
    label: "Not Shared projects",
    value: 0,
  },
]

export const LIST_STATUS_PROJECT: StatusProjectDetail[] = [
  {
    id: "",
    label: "All",
    name: "All",
    value: "0",
  },
  {
    id: "2",
    value: "2",
    label: "Active",
    name: "Active",
  },
  {
    id: "1",
    value: "1",
    label: "Abandoned",
    name: "Abandoned",
  },
  {
    id: "3",
    value: "3",
    label: "Completed",
    name: "Completed",
  },

  {
    id: "4",
    value: "4",
    label: "No status",
    name: "No status",
  },
]

export const LIST_STATUS_PROJECT_WITH_ARCHIVED = [
  {
    id: "2",
    value: "2",
    label: "Active",
    name: "Active",
  },
  {
    id: "1",
    value: "1",
    label: "Abandoned",
    name: "Abandoned",
  },
  {
    id: "3",
    value: "3",
    label: "Completed",
    name: "Completed",
  },

  {
    id: "4",
    value: "4",
    label: "No status",
    name: "No status",
  },
  {
    id: "5",
    value: "5",
    label: "Deleted",
    name: "Deleted",
  },
  {
    id: "6",
    value: "6",
    label: "Archived",
    name: "Archived",
  },
]
