import { STATUS_BUILD, STATUS_BUILD_SORTED } from "components/Status/types"
import { IUseDefaultValueProps } from "helpers/hooks"
import {
  BuildConversationDetail,
  CustomStatus,
  GROUP_BY_OPTIONS_KEY,
  MENU_TAB_CONVERSATION,
} from "../conversations/conversations.type"
import BuildConversationCard from "./molecules/BuildConversationCard"
import { useEffect, useRef, useState } from "react"
import LabelStatusBuild from "pages/project-component/molecules/LabelStatusBuild"
import { ReactComponent as SelectIcon } from "assets/images/icons/icon-arrow-down-select-tippy.svg"

interface Props {
  listBuilds: BuildConversationDetail[]
  updateDataWhenChangeFavorite: (dataRes: BuildConversationDetail) => void
  activeMenu: MENU_TAB_CONVERSATION
  onClickBuildComponent: (dataRes: BuildConversationDetail) => () => void
  activeIdBuild: IUseDefaultValueProps
  updateDataWhenArchive: (dataRes: BuildConversationDetail) => void
  updateDataWhenDeleteBuild: (dataRes: BuildConversationDetail) => void
  updateDataWhenDuplicateBuild: (dataRes: BuildConversationDetail) => void
  showLayout?: IUseDefaultValueProps
  groupOption: string
}
const BuildConversationColumn = (props: Props) => {
  const {
    listBuilds,
    activeMenu,
    activeIdBuild,
    updateDataWhenChangeFavorite,
    onClickBuildComponent,
    showLayout,
    updateDataWhenArchive,
    groupOption,
    updateDataWhenDeleteBuild,
    updateDataWhenDuplicateBuild,
  } = props
  const containerRef = useRef<any>(null)

  useEffect(() => {
    srollInToView()
  }, [])

  const srollInToView = () => {
    const index = listBuilds.findIndex(
      (item) => item.id === activeIdBuild.value
    )
    if (index === -1) return
    const itemRef = containerRef.current?.children[index]
    if (itemRef) {
      containerRef.current.scrollTop = itemRef.offsetTop - 131
    }
  }
  const [collapseBuilStatus, setCollapseBuilStatus] = useState({
    [STATUS_BUILD.COMPLETED]: false,
    [STATUS_BUILD.RFQ]: false,
    [STATUS_BUILD.CANCELLED]: false,
    [STATUS_BUILD.TEST_SETUP]: false,
    [STATUS_BUILD.IN_PRODUCTION]: false,
    [STATUS_BUILD.IN_PROGRESS]: false,
    [STATUS_BUILD.COMMITTED]: false,
  })
  const renderGroupByStatus = () => {
    const groups = listBuilds.reduce((acc, obj) => {
      const category = obj.status
      if (!acc[category]) {
        acc[category] = []
      }
      acc[category].push(obj)
      return acc
    }, {})

    return (
      <div className="pr-6 my-1" ref={containerRef}>
        {STATUS_BUILD_SORTED.map((status, index) => {
          return (
            groups[status] &&
            groups[status].length !== 0 && (
              <div key={index}>
                <div className="flex items-center mb-[7px]">
                  <div
                    className="flex p-[6px] hover-action-icon cursor-pointer"
                    onClick={() =>
                      setCollapseBuilStatus({
                        ...collapseBuilStatus,
                        [status]: !collapseBuilStatus[status],
                      })
                    }
                  >
                    <SelectIcon />
                  </div>
                  <LabelStatusBuild
                    status={status as STATUS_BUILD}
                    rootStyle={{
                      fontWeight: 600,
                      lineHeight: "22px",
                      fontSize: "13px",
                    }}
                  ></LabelStatusBuild>
                  <p
                    style={{
                      fontWeight: 600,
                      lineHeight: "22px",
                      fontSize: "13px",
                      marginLeft: 4,
                    }}
                  >{`(${groups[status].length})`}</p>
                </div>
                <div
                  className={`${collapseBuilStatus[status] ? "hidden" : ""}`}
                >
                  {groups[status].map((build, index) => (
                    <BuildConversationCard
                      item={build}
                      key={index}
                      styleRoot={{
                        marginBottom: index === listBuilds.length - 1 ? 0 : 12,
                      }}
                      isViewBuild={false}
                      activeIdBuild={activeIdBuild.value}
                      updateDataWhenChangeFavorite={
                        updateDataWhenChangeFavorite
                      }
                      updateDataWhenArchive={updateDataWhenArchive}
                      onClickBuildComponent={(data) => () => {
                        onClickBuildComponent(data)()
                        showLayout?.setValue(true)
                      }}
                      isDeleted={build.status === STATUS_BUILD.DELETED}
                      updateDataWhenDeleteBuild={updateDataWhenDeleteBuild}
                      updateDataWhenDuplicateBuild={
                        updateDataWhenDuplicateBuild
                      }
                    />
                  ))}
                </div>
              </div>
            )
          )
        })}
      </div>
    )
  }
  return (
    <>
      {GROUP_BY_OPTIONS_KEY[groupOption] ===
      GROUP_BY_OPTIONS_KEY.build_status ? (
        renderGroupByStatus()
      ) : (
        <div className="pr-6 my-1 overflow-auto" ref={containerRef}>
          {listBuilds.map((build, index) => (
            <BuildConversationCard
              item={build}
              key={index}
              styleRoot={{
                marginBottom: index === listBuilds.length - 1 ? 0 : 12,
              }}
              isViewBuild={
                // activeMenu === MENU_TAB_CONVERSATION.MY_BUILDS ? true : false
                false
              }
              activeIdBuild={activeIdBuild.value}
              updateDataWhenChangeFavorite={updateDataWhenChangeFavorite}
              updateDataWhenArchive={updateDataWhenArchive}
              updateDataWhenDeleteBuild={updateDataWhenDeleteBuild}
              onClickBuildComponent={(data) => () => {
                onClickBuildComponent(data)()
                showLayout?.setValue(true)
              }}
              isDeleted={build.status === STATUS_BUILD.DELETED}
              updateDataWhenDuplicateBuild={updateDataWhenDuplicateBuild}
            />
          ))}
        </div>
      )}
    </>
  )
}
export default BuildConversationColumn
