import ActionIconHistory from "pages/project-component-detail/molecules/ActionIconHistory"
import { FC } from "react"
import { twMerge } from "tailwind-merge"

interface FilterIconProps {
  onClick: () => void
  activeIcon?: boolean
  className?: string
  containerClass?: string
}

export const FilterIcon: FC<FilterIconProps> = ({
  onClick,
  className,
  containerClass,
  activeIcon,
}) => (
  <div className={twMerge(containerClass)}>
    <ActionIconHistory
      onClick={onClick}
      icon="filter"
      tooltip="Filter"
      styleIcon={{
        marginRight: 0,
      }}
      classActiveIcon={twMerge(
        activeIcon ? "action-icon-filter" : "",
        className
      )}
    />
  </div>
)

interface ClearAllProps {
  onClick: () => void
  active: boolean
  className?: string
  containerClass?: string
}

export const ClearAll: FC<ClearAllProps> = ({
  onClick,
  className,
  containerClass,
  active,
}) => (
  <div
    className={twMerge(
      "flex items-center justify-end h-[52px] md:h-auto pr-6 md:pr-0",
      containerClass
    )}
  >
    <p
      onClick={active ? onClick : undefined}
      className={twMerge(
        `font-semi md:font-semibold text-14 leading-24 md:hover:underline ${
          active ? "cursor-pointer" : "cursor-default"
        } ${active ? "text-blue md:text-orange-5" : "text-brown"}`,
        className
      )}
    >
      Reset all
    </p>
  </div>
)
