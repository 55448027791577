import { isUndefined } from "lodash"
import DotCard from "pages/conversations/atoms/DotCard"
import ActionIconHistory from "pages/project-component-detail/molecules/ActionIconHistory"
import React from "react"
import { InviteeBuilComponentDetail } from "../project-build.type"
import { TippyCustomzie } from "components/TippyCustomzie"
interface Props {
  isDeleted?: boolean
  component: InviteeBuilComponentDetail
  onDelete?: (oldComponent: InviteeBuilComponentDetail) => () => void
  styleRoot?: React.CSSProperties
  onClickRoot?: (oldComponent: InviteeBuilComponentDetail) => () => void
  isDisabled?: boolean
  hasDeleteIcon?: boolean
  tooltip?: React.ReactNode
}
const ComponentBuildItemCard = (props: Props) => {
  const {
    hasDeleteIcon = false,
    isDeleted = false,
    isDisabled = false,
    component,
    onDelete,
    styleRoot,
    onClickRoot,
    tooltip,
  } = props
  return (
    <TippyCustomzie
      content={tooltip}
      arrow={true}
      className="custom-tippy-menu-left component-card-menu reset-tippy"
      placement="top"
      disabled={!tooltip || component.is_read || isDisabled}
    >
      <div
        onClick={onClickRoot && !isDeleted ? onClickRoot(component) : undefined}
        className={`h-7 flex w-fit relative ${
          isDisabled
            ? "bg-grayWhite"
            : "bg-grayWhite2 hover-component-build-item"
        } ${isDeleted ? "bg-neutral3 bg-chip-deleted" : "cursor-pointer"}`}
        style={{
          border: "1px solid #E4E4E4",
          borderRadius: 4,
          ...styleRoot,
        }}
      >
        <div
          className="h-7 flex items-center px-2"
          style={{
            borderRight: "1px solid #E4E4E4",
          }}
        >
          <p
            className="font-medium"
            style={{
              fontSize: 12,
              lineHeight: "18px",
              color: isDisabled ? "#C7C7C7" : "#111111",
            }}
          >
            {component.type_key}
          </p>
        </div>
        <div className="h-7 flex items-center px-2">
          <p
            className="font-medium"
            style={{
              fontSize: 12,
              lineHeight: "18px",
              color: isDisabled ? "#C7C7C7" : "#111111",
            }}
          >
            {component.code}
          </p>
          <DotCard isDisabled={isDeleted || isDisabled} />
          <p
            className="font-medium"
            style={{
              fontSize: 12,
              lineHeight: "18px",
              color: isDisabled ? "#C7C7C7" : "#111111",
            }}
          >
            {component.version}
          </p>
          {!isDeleted && hasDeleteIcon && onDelete ? (
            <ActionIconHistory
              onClick={onDelete(component)}
              icon="close-black"
              tooltip="Delete"
              styleIcon={{
                marginRight: 0,
                marginLeft: 8,
              }}
            />
          ) : null}
        </div>
        {!isUndefined(component.is_read) && !component.is_read ? (
          <div
            className="absolute"
            style={{
              height: 11,
              width: 11,
              background: "#EA4545",
              border: "3px solid #FFFFFF",
              borderRadius: "50%",
              top: -4,
              right: -4,
            }}
          ></div>
        ) : null}
      </div>
    </TippyCustomzie>
  )
}
export default ComponentBuildItemCard
