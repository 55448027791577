import React, { useState } from "react"
import PageLayoutAuth from "./PageLayoutAuth"
import InputDefault from "components/Input/InputDefault"
import FormInputCard from "./molecules/FormInputCard"
import Header from "./atoms/Header"
import { PasswordResetRequest, defaultPasswordResetRequest } from "./types"
import { useBoolean } from "helpers/hooks"
import { pushTo } from "helpers/history"
import { STATUS_RESPONSE } from "types"
import { PATH } from "constants/path"
import { passwordResetMiddleware } from "./services/api"
import { useLocation } from "react-router-dom"
import { umamiTracking, validationPassword } from "helpers/utils"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { toast } from "react-toastify"
import { useChangeLabelStatusInputPasswordAndConfirm } from "./auth.hook"
import Button from "components/Button/Button"
import { ACTION_RECAPTCHA } from "constants/actionReCaptcha"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { EVENT } from "constants/events"

const PasswordReset = () => {
  const {
    statusInputConfirm,
    statusInputPassword,
    getStatusInputConfirm,
    getStatusInputPassword,
  } = useChangeLabelStatusInputPasswordAndConfirm()

  const [request, setRequest] = useState<PasswordResetRequest>(
    defaultPasswordResetRequest
  )
  const isLoading = useBoolean()
  const location = useLocation()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleChangeInput =
    (key: "confirmedPassword" | "password") => (event) => {
      setRequest({
        ...request,
        [key]: event.target.value,
      })
      if (key === "password") {
        if (request.confirmedPassword) {
          getStatusInputConfirm(event.target.value, request.confirmedPassword)
        }
        getStatusInputPassword(event.target.value)
      }
      if (key === "confirmedPassword") {
        getStatusInputConfirm(event.target.value, request.password)
      }
    }

  const handleDisableButton = () => {
    if (
      !validationPassword(request.password) ||
      request.password !== request.confirmedPassword
    ) {
      return true
    }
    return false
  }

  const onClickButton = async () => {
    const newResetPasswordToken = location.search.replace("?token=", "")
    if (!executeRecaptcha) {
      toast(
        <LabelNotificationPage
          messenger={MESSENGER_NOTIFICATION.RECAPTCHA_AVAILABLE}
          type="error"
        />
      )
      return
    }
    isLoading.setValue(true)
    try {
      const token = await executeRecaptcha(ACTION_RECAPTCHA.PASSWORD_RESET)
      const dataRequest: PasswordResetRequest = {
        ...request,
        captcha: token,
        resetPasswordToken: newResetPasswordToken,
      }
      umamiTracking(EVENT.FORGOT_PASSWORD)
      passwordResetMiddleware(
        dataRequest,
        (type: STATUS_RESPONSE, messenger) => {
          isLoading.setValue(false)
          if (type === STATUS_RESPONSE.SUCCESS) {
            pushTo(PATH.resetSuccess)
          } else {
            toast(
              <LabelNotificationPage messenger={messenger ?? ""} type="error" />
            )
          }
        }
      )
    } catch (error) {
      isLoading.setValue(false)
    }
  }

  const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      event.preventDefault()
      if (handleDisableButton()) {
        return
      }
      onClickButton()
    }
  }

  return (
    <PageLayoutAuth>
      <div className="h-full w-full flex flex-col p-6">
        <Header title="Set new password" />
        <div className="my-6">
          <FormInputCard
            title="New Password"
            isTippy
            label={MESSENGER_NOTIFICATION.PASSWORD_VALIDATE}
          >
            <InputDefault
              type="password"
              isPassword
              value={request.password}
              onChange={handleChangeInput("password")}
              onKeyPress={onKeyPress}
              status={statusInputPassword.status}
              labelStatus={statusInputPassword.label}
            />
          </FormInputCard>
          <FormInputCard title="Confirm New Password">
            <InputDefault
              type="password"
              isPassword
              value={request.confirmedPassword}
              onChange={handleChangeInput("confirmedPassword")}
              onKeyPress={onKeyPress}
              status={statusInputConfirm.status}
              labelStatus={statusInputConfirm.label}
            />
          </FormInputCard>
        </div>
        <Button
          title="Save"
          onClick={onClickButton}
          isDisabledBtn
          widthBtn="100%"
          disabled={handleDisableButton()}
        />
      </div>
    </PageLayoutAuth>
  )
}

export default PasswordReset
