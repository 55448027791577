import { useBoolean, useString } from "helpers/hooks"

import FormInputCard from "pages/auth/molecules/FormInputCard"
import InputDefault from "components/Input/InputDefault"
import ModalCustom from "components/ModalCustom"

import ActionFooterModalCard from "components/ModalCustom/ActionFooterModalCard"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { toast } from "react-toastify"
import { createFolderMiddleWare } from "../services/api"
import { Folder } from "../types"

interface Props {
  projectId: string
  onCloseModal: () => void
  onUpdateData: (folder: Folder) => void
}
const FormNewFolder = (props: Props) => {
  const { projectId, onCloseModal, onUpdateData } = props
  const isLoading = useBoolean()
  const name = useString("")

  const handleChangeInput = (event) => {
    name.setValue(event.target.value)
  }

  const handleDisableButton = () => {
    if (!name.value) {
      return true
    }
    return false
  }
  const onSubmit = () => {
    if (!projectId) {
      return
    }
    if (!name.value || name.value.trim().length === 0) {
      return toast(
        <LabelNotificationPage
          messenger="name of folder is required"
          type="error"
        />
      )
    }
    createFolderMiddleWare(projectId, name.value)
      .then((res) => {
        onUpdateData({ ...res, components: [] })
        toast(
          <LabelNotificationPage
            messenger={"Create folder successfully."}
            type="success"
          />
        )
      })
      .catch((_error) => {
        toast(
          <LabelNotificationPage
            messenger={"Create folder failed! Please contact to administrator."}
            type="error"
          />
        )
      })
    isLoading.setValue(true)
    onCloseModal()
  }

  return (
    <ModalCustom
      label={"Create folder"}
      handleChangeButton={() => onCloseModal}
      bodyChildren={
        <div className="flex flex-col gap-3">
          <FormInputCard
            title="Folder name"
            required
            fontWeightText="font-semibold"
          >
            <InputDefault value={name.value} onChange={handleChangeInput} />
          </FormInputCard>

          <ActionFooterModalCard
            onCloseModal={onCloseModal}
            onSubmit={onSubmit}
            handleDisableButton={handleDisableButton()}
            titleButton={"Create"}
          />
        </div>
      }
    />
  )
}

export default FormNewFolder
