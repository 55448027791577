import Breadcrumbs from "components/Breadcrumbs"
import { BreadcrumbsDetail } from "components/Breadcrumbs/types"
import { PATH } from "constants/path"
import { ProjectDetail } from "pages/projects/types"
import { ProjectComponentDetail } from "pages/project-component/types"
import React from "react"
import { TAB_URL_PROJECT_COMPONENT } from "../types"
import { ProjectComponentBuildDetail } from "pages/project-build/project-build.type"
import { STATUS_BUILD } from "components/Status/types"
import { pushTo } from "helpers/history"
import HeaderBreadcrumbCard from "components/Breadcrumbs/HeaderBreadcrumbCard"
import IDInfoProjectComponentDetailCard from "../molecules/IDInfoProjectComponentDetailCard"
import { useWindowSize } from "helpers/hooks"
import { HeaderBreadcrumbInfo } from "components/HeaderInfo/HeaderInfo"

interface Props {
  projectComponentDetail: ProjectComponentDetail
  projectDetail: ProjectDetail
  updatedAtComponent: string
  titlePage: string
  projectBuild: ProjectComponentBuildDetail
  idProjectBuildComponent: string
}
const isEqualProps = (prevProps: Props, nextProps: Props) => {
  return (
    prevProps.projectComponentDetail === nextProps.projectComponentDetail &&
    prevProps.projectDetail === nextProps.projectDetail &&
    prevProps.updatedAtComponent === nextProps.updatedAtComponent &&
    prevProps.titlePage === nextProps.titlePage &&
    prevProps.projectBuild === nextProps.projectBuild &&
    prevProps.idProjectBuildComponent === nextProps.idProjectBuildComponent
  )
}
const ProjectComponentDetailHeader = React.memo((props: Props) => {
  const {
    projectComponentDetail,
    projectDetail,
    updatedAtComponent,
    titlePage,
    projectBuild,
    idProjectBuildComponent,
  } = props
  const sizeWindow = useWindowSize()

  const onCallbackBuildPage = () => {
    if (projectBuild.status === STATUS_BUILD.DRAFT) {
      pushTo(PATH.draftBuild, {
        idProject: projectDetail.id,
        idProjectBuild: projectBuild.id,
      })
      return
    }
    if (projectBuild.status === STATUS_BUILD.COMMITTED) {
      pushTo(PATH.committedBuild, {
        idProject: projectDetail.id,
        idProjectBuild: projectBuild.id,
      })
      return
    }
    pushTo(PATH.buildOverview, {
      status: projectBuild.status,
      idProject: projectDetail.id,
      idProjectBuild: projectBuild.id,
    })
  }

  const listProjectBreadcrumbs: BreadcrumbsDetail[] = [
    {
      name: projectDetail?.name,
      field: "idProject",
      path: PATH.projectComponent,
      value: projectDetail.id,
    },
  ]
  const listComponentBreadcrumbs: BreadcrumbsDetail[] = [
    {
      name: projectComponentDetail.name,
    },
  ]

  const listBuildBreadcrumbs: BreadcrumbsDetail[] = [
    {
      name: projectBuild.name,
      onCustomRouter: onCallbackBuildPage,
    },
    {
      name: projectComponentDetail.name,
    },
  ]
  const newListBreadcrumbs =
    titlePage === TAB_URL_PROJECT_COMPONENT.COMPONENT
      ? listComponentBreadcrumbs
      : listBuildBreadcrumbs
  return (
    <HeaderBreadcrumbInfo>
      {titlePage === TAB_URL_PROJECT_COMPONENT.BUILD_COMPONENT &&
      sizeWindow.width < 1440 ? (
        <HeaderBreadcrumbCard
          renderBreadcrumb={
            <Breadcrumbs
              listBreadcrumbs={listProjectBreadcrumbs.concat(
                newListBreadcrumbs
              )}
            />
          }
          renderIDInfo={
            <IDInfoProjectComponentDetailCard
              projectBuild={projectBuild}
              projectComponentDetail={projectComponentDetail}
              projectDetail={projectDetail}
              updatedAtComponent={updatedAtComponent}
              titlePage={titlePage}
              idProjectBuildComponent={idProjectBuildComponent}
            />
          }
        />
      ) : (
        <React.Fragment>
          <Breadcrumbs
            listBreadcrumbs={listProjectBreadcrumbs.concat(newListBreadcrumbs)}
            overflow
          />
          <IDInfoProjectComponentDetailCard
            projectBuild={projectBuild}
            projectComponentDetail={projectComponentDetail}
            projectDetail={projectDetail}
            updatedAtComponent={updatedAtComponent}
            titlePage={titlePage}
            idProjectBuildComponent={idProjectBuildComponent}
          />
        </React.Fragment>
      )}
    </HeaderBreadcrumbInfo>
  )
}, isEqualProps)

export default ProjectComponentDetailHeader
