import React, { useState } from "react"
import FormInputCard from "../molecules/FormInputCard"
import InputDefault from "components/Input/InputDefault"
import { ChangePasswordRequest, defaultChangePasswordRequest } from "../types"
import { useBoolean, useWindowSize } from "helpers/hooks"
import { changePasswordMiddleware } from "../services/api"
import { STATUS_RESPONSE } from "types"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
import { STATUS_INPUT } from "components/Input/types"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { toast } from "react-toastify"
import { useAppSelector } from "hooks/useApp"
import { useChangeLabelStatusInputPasswordAndConfirm } from "../auth.hook"
import Button from "components/Button/Button"

const ChangePasswordCard = () => {
  const [request, setRequest] = useState<ChangePasswordRequest>(
    defaultChangePasswordRequest
  )
  const userInfo = useAppSelector((state) => state.userInfo)
  const isLoading = useBoolean()
  const { isMobile } = useWindowSize()
  const showToolTip = useBoolean()
  const {
    statusInputConfirm,
    statusInputPassword,
    setStatusInputConfirm,
    setStatusInputPassword,
    getStatusInputConfirm,
    getStatusInputPassword,
  } = useChangeLabelStatusInputPasswordAndConfirm()
  const handleChangeInput =
    (
      key: "currentPassword" | "newPassword" | "confirmedNewPassword" | "email"
    ) =>
    (event) => {
      setRequest({
        ...request,
        [key]: event.target.value,
      })

      if (key === "newPassword") {
        if (request.confirmedNewPassword) {
          getStatusInputConfirm(
            event.target.value,
            request.confirmedNewPassword
          )
        }
        getStatusInputPassword(event.target.value)
      }
      if (key === "confirmedNewPassword") {
        getStatusInputConfirm(event.target.value, request.newPassword)
      }
    }

  const handleDisableButton = () => {
    if (
      request.currentPassword.length < 1 ||
      request.newPassword !== request.confirmedNewPassword
    ) {
      return true
    }
    return false
  }

  const onClickButton = async () => {
    isLoading.setValue(true)
    changePasswordMiddleware(request, (type: STATUS_RESPONSE, messenger) => {
      isLoading.setValue(false)
      if (type === STATUS_RESPONSE.SUCCESS) {
        setRequest(defaultChangePasswordRequest)
        setStatusInputConfirm({
          status: STATUS_INPUT.DEFAULT,
          label: "",
        })
        setStatusInputPassword({
          status: STATUS_INPUT.DEFAULT,
          label: "",
        })
        toast(
          <LabelNotificationPage
            messenger={MESSENGER_NOTIFICATION.CHANGE_PASSWORD_SUCCESS}
            type="success"
          />
        )
      } else {
        toast(
          <LabelNotificationPage messenger={messenger ?? ""} type="error" />
        )
      }
    })
  }

  const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      event.preventDefault()
      if (handleDisableButton()) {
        return
      }
      onClickButton()
    }
  }
  const handleCancel = () => {
    setRequest(defaultChangePasswordRequest)
  }

  return (
    <div className="flex flex-col">
      <FormInputCard title="Email address" required>
        <InputDefault
          value={userInfo.email}
          onChange={handleChangeInput("email")}
          disabled
        />
      </FormInputCard>

      <div
        className="flex flex-col mt-3 pt-3"
        style={{
          borderTop: " 1px solid #E4E4E4",
        }}
      >
        <p
          className="font-semibold mb-3"
          style={{
            fontSize: 16,
            lineHeight: "26px",
            color: "#111111",
          }}
        >
          Change password
        </p>
        <FormInputCard title="Current Password">
          <InputDefault
            type="password"
            isPassword
            value={request.currentPassword}
            onChange={handleChangeInput("currentPassword")}
            onKeyPress={onKeyPress}
          />
        </FormInputCard>
        <FormInputCard
          placement="bottom"
          offset={[60, 10]}
          title="New Password"
          isTippy
          label={MESSENGER_NOTIFICATION.PASSWORD_VALIDATE}
          showToolTip={showToolTip}
          visible={isMobile ? showToolTip.value : undefined}
          onClickOutside={() =>
            isMobile ? showToolTip.setValue(false) : undefined
          }
        >
          <InputDefault
            type="password"
            isPassword
            value={request.newPassword}
            onChange={handleChangeInput("newPassword")}
            onKeyPress={onKeyPress}
            status={statusInputPassword.status}
            labelStatus={statusInputPassword.label}
          />
        </FormInputCard>
        <FormInputCard title="Confirm New Password">
          <InputDefault
            type="password"
            isPassword
            value={request.confirmedNewPassword}
            onChange={handleChangeInput("confirmedNewPassword")}
            onKeyPress={onKeyPress}
            status={statusInputConfirm.status}
            labelStatus={statusInputConfirm.label}
          />
        </FormInputCard>
      </div>

      <div className="flex items-center justify-between mt-3 w-full">
        <Button
          title="Cancel"
          colorBtn="white"
          onClick={handleCancel}
          widthBtn={"48%"}
          disabled={handleDisableButton()}
        />
        <Button
          title="Save"
          widthBtn={"48%"}
          onClick={onClickButton}
          disabled={handleDisableButton()}
          isDisabledBtn
        />
      </div>
    </div>
  )
}
export default ChangePasswordCard
