import { umamiTracking, customLocalStorageHandler } from "helpers/utils"
import { useBoolean, useString } from "helpers/hooks"
import { isUndefined } from "lodash"
import { TypeSelectProp } from "pages/project-component-detail/types"
import { BuildStatusProp } from "pages/project-component/types"
import { useState } from "react"
import {
  CustomStatus,
  definedDateRanges,
  SelectionRangeDetail,
} from "../conversations/conversations.type"
import { EVENT } from "constants/events"
import { MENU_TAB_BUILD, MENU_TAB_BUILD_KEY } from "./build.type"
import { NAME_LOCALSTORAGE } from "constants/localStorage"
import { pushTo } from "helpers/history"
import { PATH } from "constants/path"

export const useFilterBuilds = () => {
  const { storageData, handleSetLocalStorage } = customLocalStorageHandler(
    NAME_LOCALSTORAGE.BUILD
  )
  const cookiesBuild = converDataCookiesWhenOpenFilter(storageData)
  const isSearch = useBoolean(cookiesBuild.search.length ? true : false)
  const inputSearch = useString(cookiesBuild.search)
  const search = useString(cookiesBuild.search)
  const isFilter = useBoolean(false)
  const isLoading = useBoolean(false)
  const activeMenu = useString(cookiesBuild.tab)
  const [cpnTypeOptions, setCpnTypeOptions] = useState<TypeSelectProp[]>([])
  const [cpnStatusOptions, setCpnStatusOptions] = useState<BuildStatusProp[]>(
    []
  )

  const [customStatuses, setCustomStatuses] = useState<CustomStatus[]>([])
  const isShowFavorite = useBoolean(cookiesBuild.checkboxFavorite)
  const isShowDeleted = useBoolean(cookiesBuild.checkboxDelete)
  const isShowUnread = useBoolean(cookiesBuild.checkboxUnread)
  const isShowArchive = useBoolean(cookiesBuild.checkboxArchive)
  const [objSelectType, setObjSelectType] = useState(cookiesBuild.componentType)
  const [objSelectStatus, setObjSelectStatus] = useState(
    cookiesBuild.buildStatus
  )
  const [objSelectCustomStatus, setObjSelectCustomStatus] = useState({})

  const isCalendarRanges = useBoolean(false)

  const [selectionRange, setSelectionRange] = useState<SelectionRangeDetail>({
    key: "selection",
    startDate: definedDateRanges.startOfWeek,
    endDate: definedDateRanges.endOfWeek,
  })
  const [startDateCreate, setStartDateCreate] = useState<Date | null>(
    cookiesBuild.dateCreate.start
  )
  const [endDateCreate, setEndDateCreate] = useState<Date | null>(null)
  const isCalendarLastUpdate = useBoolean(false)

  const [dateLastUpdate, setDateLastUpdate] = useState<Date | null>(
    cookiesBuild.dateLastUpdate
  )
  const [selectionDate, setSelectionDate] = useState<Date | null>(
    cookiesBuild.dateCreate.end
  )
  const groupOtion = useString(cookiesBuild.groupOption)
  const isApplyFilter = useBoolean(false)
  const onChangeTab = (newTab: string) => () => {
    activeMenu.setValue(newTab)
    handleChangeCookies("tab", newTab)
    pushTo(PATH.defaultBuildOverview, undefined, undefined, {
      tab: MENU_TAB_BUILD_KEY[newTab],
    })
  }
  const onChangeInputSearch = (event) => {
    inputSearch.setValue(event.target.value)
  }
  const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      event.preventDefault()
      search.setValue(inputSearch.value)
      handleChangeCookies("search", inputSearch.value)
      if (!inputSearch.value) {
        isSearch.setValue(false)
      }
      umamiTracking(EVENT.CONVERSATION.SEARCH)
    }
  }
  const onOpenInputSearch = () => {
    isSearch.setValue(!isSearch.value)
    inputSearch.setValue("")
    search.setValue("")
    handleChangeCookies("search", "")
  }
  const onChangeCheckboxFavorite = () => {
    if (!isShowFavorite.value && isShowDeleted.value) {
      isShowDeleted.setValue(false)
    }
    isShowFavorite.setValue(!isShowFavorite.value)
  }
  const onChangeCheckboxDelete = () => {
    if (!isShowDeleted.value) {
      isShowFavorite.setValue(false)
      isShowUnread.setValue(false)
      isShowArchive.setValue(false)
      setObjSelectType({})
      setObjSelectStatus({})
    }
    isShowDeleted.setValue(!isShowDeleted.value)
  }
  const onChangeCheckBoxUnread = () => {
    if (!isShowUnread.value && isShowDeleted.value) {
      isShowDeleted.setValue(false)
    }
    isShowUnread.setValue(!isShowUnread.value)
  }
  const onChangeCheckBoxArchive = () => {
    if (!isShowArchive.value && isShowDeleted.value) {
      isShowDeleted.setValue(false)
    }
    isShowArchive.setValue(!isShowArchive.value)
  }

  // handle open create date
  const onChangeModalSelectRanges = (newValue: boolean) => () => {
    isCalendarRanges.setValue(newValue)
    isCalendarLastUpdate.setValue(false)
    setSelectionRange({
      key: "selection",
      startDate: startDateCreate
        ? new Date(startDateCreate)
        : new Date(definedDateRanges.startOfWeek),
      endDate: endDateCreate
        ? new Date(endDateCreate)
        : new Date(definedDateRanges.endOfWeek),
    })
  }

  // handle cancel create date
  const onCancelSelectionRanges = (
    startDate: Date | null,
    endDate: Date | null
  ) => {
    isCalendarRanges.setValue(false)
    setSelectionRange({
      key: "selection",
      startDate: startDate
        ? new Date(startDate)
        : new Date(definedDateRanges.startOfWeek),
      endDate: endDate
        ? new Date(endDate)
        : new Date(definedDateRanges.endOfWeek),
    })
  }

  // handle apply selection create date ranges
  const onApplySelectionRanges = () => {
    setStartDateCreate(new Date(selectionRange.startDate))
    setEndDateCreate(new Date(selectionRange.endDate))

    isCalendarRanges.setValue(false)
  }

  /// update create date selection
  const onChangeDate = (ranges) => {
    setSelectionRange(ranges.selection)
  }

  const onClearInputSelectionRanges = () => {
    setStartDateCreate(null)
    setEndDateCreate(null)
    isCalendarRanges.setValue(false)
  }

  const onChangeModalDateLastUpdate = (newValue: boolean) => () => {
    isCalendarLastUpdate.setValue(newValue)
    isCalendarRanges.setValue(false)
    setSelectionDate(dateLastUpdate ? new Date(dateLastUpdate) : new Date())
  }
  const onChangeDateLastUpdate = (date) => {
    setSelectionDate(new Date(date))
  }
  const onApplySelectionDateLastUpdate = () => {
    setDateLastUpdate(selectionDate)
    isCalendarLastUpdate.setValue(false)
  }

  const onClearInputSelectionDateLastUpdate = () => {
    setDateLastUpdate(null)
    isCalendarLastUpdate.setValue(false)
  }
  const onCancelSelectionlastUpdate = (oldDate: Date | null) => {
    if (oldDate) {
      setSelectionDate(new Date(oldDate))
    }

    isCalendarLastUpdate.setValue(false)
  }
  const handleChangeFilerAll = (newAll: boolean) => () => {
    isShowFavorite.setValue(newAll)
    isShowDeleted.setValue(newAll)
    isShowUnread.setValue(newAll)
    isShowArchive.setValue(newAll)
    const newObjType = {}
    const newObjStatus = {}
    if (newAll) {
      cpnTypeOptions.forEach((type) => {
        newObjType[type.value] = type.value
      })
      cpnStatusOptions.forEach((status) => {
        newObjStatus[status.value] = status.value
      })
    }
    setObjSelectType(newObjType)
    setObjSelectStatus(newObjStatus)
    setObjSelectCustomStatus({})
    setStartDateCreate(null)
    setEndDateCreate(null)
    setDateLastUpdate(null)
  }

  const onApplyFilter = () => {
    const newCookies = checkExitsCookies()
    newCookies.checkboxFavorite = isShowFavorite.value
    newCookies.checkboxUnread = isShowUnread.value
    newCookies.checkboxDelete = isShowDeleted.value
    newCookies.checkboxArchive = isShowArchive.value
    newCookies.dateCreate = {
      start: startDateCreate,
      end: endDateCreate,
    }
    newCookies.dateLastUpdate = dateLastUpdate
    newCookies.componentType = objSelectType
    newCookies.buildStatus = objSelectStatus
    newCookies.groupOption = groupOtion.value
    handleSetLocalStorage(newCookies)
    isFilter.setValue(false)
    isCalendarRanges.setValue(false)
    isCalendarLastUpdate.setValue(false)
    isApplyFilter.setValue(true)
  }
  const onOpenModalFiler = (newOpen: boolean) => {
    if (newOpen) {
      isShowFavorite.setValue(cookiesBuild.checkboxFavorite)
      isShowDeleted.setValue(cookiesBuild.checkboxDelete)
      setObjSelectType(cookiesBuild.componentType)
      setObjSelectStatus(cookiesBuild.buildStatus)
      setStartDateCreate(cookiesBuild.dateCreate.start)
      setEndDateCreate(cookiesBuild.dateCreate.end)
      setDateLastUpdate(cookiesBuild.dateLastUpdate)
    }
    isFilter.setValue(newOpen)
  }
  const onClickClearFilterNoResult = () => {
    isShowFavorite.setValue(false)
    isShowUnread.setValue(false)
    isShowArchive.setValue(false)
    const newObjType = {}
    const newObjStatus = {}
    const newObjCustomStatus = {}
    setObjSelectType(newObjType)
    setObjSelectStatus(newObjStatus)
    setStartDateCreate(null)
    setEndDateCreate(null)
    setDateLastUpdate(null)
    const newCookies = checkExitsCookies()
    newCookies.checkboxFavorite = false
    newCookies.checkboxDelete = false
    newCookies.checkboxUnread = false
    newCookies.checkboxArchive = false
    newCookies.componentType = newObjType
    newCookies.buildStatus = newObjStatus
    newCookies.customStatus = newObjCustomStatus
    newCookies.dateLastUpdate = null
    newCookies.dateCreate = {
      start: null,
      end: null,
    }
    handleSetLocalStorage(newCookies)
    isApplyFilter.setValue(true)
  }
  const onRemoveInputSearch = () => {
    isSearch.setValue(false)
    inputSearch.setValue("")
    search.setValue("")
    handleChangeCookies("search", "")
  }

  const handleChangeCookies = (field: string, value: any) => {
    const newCookies = checkExitsCookies()
    newCookies[field] = value
    handleSetLocalStorage(newCookies)
  }
  const checkExitsCookies = () => {
    return !isUndefined(storageData) ? storageData : {}
  }

  const rollbackFilter = () => {
    setObjSelectType(cookiesBuild.componentType)
    setObjSelectStatus(cookiesBuild.buildStatus)
    isShowFavorite.setValue(cookiesBuild.checkboxFavorite)
    isShowDeleted.setValue(cookiesBuild.checkboxDelete)
    isShowUnread.setValue(cookiesBuild.checkboxUnread)
    isShowArchive.setValue(cookiesBuild.checkboxArchive)
    isCalendarLastUpdate.setValue(false)
    isCalendarRanges.setValue(false)
  }
  const onChangeGroupOption = (option: string) => {
    if (groupOtion.value === option) return
    const newCookies = checkExitsCookies()
    newCookies.groupOption = option
    handleSetLocalStorage(newCookies)
    groupOtion.setValue(option)
    isApplyFilter.setValue(true)
  }

  return {
    isSearch,
    inputSearch,
    search,
    isFilter,
    isLoading,
    activeMenu,
    cpnTypeOptions,
    cpnStatusOptions,
    objSelectType,
    objSelectStatus,
    isShowFavorite,
    selectionRange,
    isCalendarRanges,
    startDateCreate,
    endDateCreate,
    isCalendarLastUpdate,
    selectionDate,
    dateLastUpdate,
    setCpnTypeOptions,
    setCpnStatusOptions,
    setObjSelectType,
    setObjSelectStatus,
    customStatuses,
    setCustomStatuses,
    objSelectCustomStatus,
    setObjSelectCustomStatus,
    onOpenInputSearch,
    onKeyPress,
    onChangeInputSearch,
    onChangeTab,
    onChangeCheckboxFavorite,
    onChangeDate,
    onApplySelectionRanges,
    onCancelSelectionRanges,
    onChangeModalSelectRanges,
    onClearInputSelectionRanges,
    onChangeModalDateLastUpdate,
    onChangeDateLastUpdate,
    onApplySelectionDateLastUpdate,
    onClearInputSelectionDateLastUpdate,
    onCancelSelectionlastUpdate,
    handleChangeFilerAll,
    onApplyFilter,
    isApplyFilter,
    onOpenModalFiler,
    onClickClearFilterNoResult,
    onRemoveInputSearch,
    rollbackFilter,
    onChangeCheckboxDelete,
    isShowDeleted,
    onChangeCheckBoxUnread,
    isShowUnread,
    onChangeCheckBoxArchive,
    isShowArchive,
    groupOtion,
    onChangeGroupOption,
  }
}

const converDataCookiesWhenOpenFilter = (cookies: any) => {
  const newCookies = !isUndefined(cookies) ? cookies : {}
  return {
    search: newCookies?.search ? newCookies.search : "",
    tab: newCookies?.tab ? newCookies.tab : MENU_TAB_BUILD.MY_BUILDS,
    checkboxFavorite: newCookies?.checkboxFavorite
      ? newCookies.checkboxFavorite
      : false,
    checkboxDelete: newCookies?.checkboxDelete
      ? newCookies.checkboxDelete
      : false,
    checkboxUnread: newCookies?.checkboxUnread
      ? newCookies.checkboxUnread
      : false,
    checkboxArchive: newCookies?.checkboxArchive
      ? newCookies.checkboxArchive
      : false,
    componentType: newCookies?.componentType ? newCookies.componentType : {},
    customStatus: newCookies?.customStatus || {},
    buildStatus: newCookies?.buildStatus ? newCookies.buildStatus : {},
    dateCreate: {
      start: newCookies?.dateCreate?.start
        ? new Date(newCookies.dateCreate.start)
        : null,
      end: newCookies?.dateCreate?.end
        ? new Date(newCookies.dateCreate.end)
        : null,
    },
    dateLastUpdate: newCookies?.dateLastUpdate
      ? new Date(newCookies.dateLastUpdate)
      : null,
    groupOption: newCookies?.groupOption || "latest_update",
  }
}
