import Axios from "axios"
import { UserOverview } from "./dashboard.type"

export const getUserOverview = async () => {
  return Axios.get<{ data: UserOverview }>(`/api/user/overview`).then(
    (response) => {
      return response.data.data
    }
  )
}
export const exportUserData = async () => {
  return Axios.get(`/api/user/download-all-data`)
}
