import { RootPartSingleCheckProps } from "../types"
import InfoSingleCard from "../atoms/InfoSingleCard"

const RootPartSingleCheck = (props: RootPartSingleCheckProps) => {
  const {
    name,
    tooltip,
    childrenBody,
    requiredName,
    rootClassInfo,
    styleRoot,
  } = props
  return (
    <div>
      <div
        className="flex flex-col md:flex-row items-start md:items-center mb-[24px]"
        style={{
          ...styleRoot,
        }}
      >
        <div className="min-w-[180px]">
          <InfoSingleCard
            name={name}
            tooltip={tooltip}
            requiredName={requiredName}
            rootClass={rootClassInfo}
          />
        </div>

        {childrenBody}
      </div>
    </div>
  )
}

export default RootPartSingleCheck
