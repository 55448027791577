import Button from "components/Button/Button"
import React from "react"

interface Props {
  onCloseModal: () => void
  onSubmit: () => void
  handleDisableButton: boolean
  titleButton: string
  styleRoot?: React.CSSProperties
  widthBtn?: string | number
  titleCloseButton?: string
}
const ActionFooterModalCard = (props: Props) => {
  const {
    onCloseModal,
    onSubmit,
    handleDisableButton,
    titleButton,
    styleRoot,
    titleCloseButton,
  } = props
  return (
    <div
      className="flex flex-col md:flex-row gap-y-3 md:md-gap-y-0 items-center justify-between"
      style={{
        ...styleRoot,
      }}
    >
      <Button
        widthBtn={"100%"}
        title={titleCloseButton || "Cancel"}
        colorBtn="white"
        onClick={onCloseModal}
      />
      <Button
        widthBtn={"100%"}
        title={titleButton}
        onClick={onSubmit}
        disabled={handleDisableButton}
        isDisabledBtn
      />
    </div>
  )
}
export default ActionFooterModalCard
