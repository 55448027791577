import Axios, { AxiosResponse } from "axios"
import { ActivityItemDetail, ParamsActivityLogs } from "./activity-log.type"

export const getActivitiesMiddleware = async (params: ParamsActivityLogs) => {
  const response: AxiosResponse<{
    data: ActivityItemDetail[]
  }> = await Axios.get("api/activity-log", {
    params,
  })
  return response.data.data
}
