interface Fallbackprops {
  error: Error
  componentStack: string
  resetError: () => void
}

const Fallback = ({ error, componentStack, resetError }: Fallbackprops) => {
  return (
    <article role="alert">
      <h2>Oops! Something went wrong.</h2>
      <p>{error.toString()}</p>
      <pre>{componentStack}</pre>
      <button onClick={resetError}>Try again</button>
    </article>
  )
}

export default Fallback
