import { STATUS_BUILD } from "components/Status/types"
import { includes, some } from "lodash"
import { BuildHistoryDetail } from "pages/project-component-detail/types"
import { VERSION_APPROVED_STATUS } from "types"

export const checkDisableButtonHistoryTree = (
  histories: BuildHistoryDetail[],
  historyDetail: BuildHistoryDetail
) => {
  const historiesWithoutUnapprovedVersions = histories.filter(
    (version) => version.approved_status !== VERSION_APPROVED_STATUS.UNAPPROVED
  )

  const indexMasterHistory = historiesWithoutUnapprovedVersions.findIndex(
    (el) => el.id === historyDetail.id
  )
  const disableCreateVersion =
    some(historiesWithoutUnapprovedVersions, [
      "approved_status",
      VERSION_APPROVED_STATUS.PENDING,
    ]) || historyDetail.approved_status === VERSION_APPROVED_STATUS.UNAPPROVED

  // if history have draft already or index history is not = 0 => disabled
  const isDisableEditHistory =
    (some(historiesWithoutUnapprovedVersions, ["status", STATUS_BUILD.DRAFT]) ||
      indexMasterHistory > 0 ||
      disableCreateVersion) &&
    historyDetail.status !== STATUS_BUILD.SAVE
  const isDisableRevertHistory =
    indexMasterHistory > 0 &&
    includes(
      [STATUS_BUILD.COMMITTED, STATUS_BUILD.MERGED],
      historyDetail.status
    ) &&
    !disableCreateVersion
      ? false
      : true
  return {
    isDisableEditHistory,
    isDisableRevertHistory,
  }
}
