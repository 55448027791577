import React from "react"
import IconCheckSuccess from "assets/images/icons/icon-check-success.svg"
import IconCheckError from "assets/images/icons/icon-error-notification.svg"
import IconCheckWarning from "assets/images/icons/icon-warning-notification.svg"

import { LabelNotificationPageProps } from "./types"

const LabelNotificationPage = (props: LabelNotificationPageProps) => {
  const { messenger, type } = props
  const renderIcon = () => {
    let icon = IconCheckSuccess
    let color = "#56A012"
    switch (type) {
      case "error":
        icon = IconCheckError
        color = "#EA4545"
        break
      case "warning":
        icon = IconCheckWarning
        color = "#E87A16"
        break
    }
    return {
      icon,
      color,
    }
  }
  return (
    <div className="flex items-center">
      <img src={renderIcon().icon} alt="icon" className="mr-3.5" />
      <span
        style={{
          fontWeight: 400,
          fontSize: 14,
          lineHeight: "24px",
          color: renderIcon().color,
        }}
      >
        {messenger}
      </span>
    </div>
  )
}
export default LabelNotificationPage
