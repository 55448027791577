import Tippy from "@tippyjs/react"

interface Props {
  additionalJson: any
  isBorderRadius: boolean
}
const TableInviteeAdditionalInBOMItem = (props: Props) => {
  const { additionalJson, isBorderRadius } = props
  const renderKeyHeader = (oldKey: string) => {
    if (!oldKey) {
      return ""
    }
    const sliceKey = oldKey.slice(-9)
    return oldKey.replace(sliceKey, "")
  }
  return (
    <div
      className={`flex`}
      style={{
        backgroundColor: "#FFFFFF",
        borderTop: "1px solid #E4E4E4",
        borderBottom: "1px solid #E4E4E4",
        borderRight: "1px solid #E4E4E4",
        borderTopRightRadius: isBorderRadius ? 6 : 0,
        borderBottomRightRadius: isBorderRadius ? 6 : 0,
      }}
    >
      {additionalJson.map((additionalColumn, indexColumn: number) => (
        <div key={indexColumn} className="flex flex-col cursor-pointer">
          <div
            className={`flex items-center font-semibold min-h-48-custom h-12 py-1 px-3`}
            style={{
              fontSize: 14,
              lineHeight: "24px",
              color: "#111111",
              width: 170,
              minWidth: 170,
              textAlign: "start",
              borderBottom: "1px solid #E4E4E4",
              wordBreak: "break-all",
            }}
          >
            {renderKeyHeader(additionalColumn.key)}
          </div>
          {additionalColumn.values.map((additionalValue, indexValue) => (
            <div
              className="flex items-center"
              key={indexValue}
              style={{
                backgroundColor: indexValue % 2 !== 0 ? "#F7F7F7" : "#FFFFFF",
              }}
            >
              <div
                className={`px-3 flex items-center py-1`}
                style={{
                  height: 48,
                  width: 170,
                }}
              >
                {additionalValue ? (
                  <Tippy
                    className="custom-tippy-menu-left"
                    placement="top"
                    content={
                      <span
                        className="font-normal"
                        style={{
                          fontSize: 13,
                          lineHeight: "22px",
                        }}
                      >
                        {additionalValue}
                      </span>
                    }
                  >
                    <p
                      className="font-normal"
                      style={{
                        fontSize: 14,
                        lineHeight: "24px",
                        color: "#111111",
                        display: "inline-block",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {additionalValue}
                    </p>
                  </Tippy>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}
export default TableInviteeAdditionalInBOMItem
