import Typography from "components/Typography"
import { FC } from "react"

export interface FilterHeaderProps {
  setVisible: (visible: boolean) => void
  onApply?: () => void
  onCancel?: () => void
}

export const FilterHeader: FC<FilterHeaderProps> = ({
  setVisible,
  onApply,
  onCancel,
}) => (
  <div className="flex items-center justify-between w-inherit h-inherit">
    <Typography
      textClass="font-normal"
      textColor="blue"
      style={{
        fontSize: 14,
        lineHeight: "24px",
      }}
      onClick={() => {
        ///
        onCancel?.()

        // closeLayout
        setVisible(false)
      }}
    >
      Cancel
    </Typography>
    <Typography
      textClass="font-semibold"
      textColor="black"
      style={{
        fontSize: 14,
        lineHeight: "24px",
        fontWeight: 600,
      }}
    >
      Filter
    </Typography>
    <Typography
      textClass="font-normal"
      textColor="blue"
      style={{
        fontSize: 14,
        lineHeight: "24px",
        color: "#0A5AF5",
      }}
      onClick={() => {
        ///
        onApply?.()

        // closeLayout
        setVisible(false)
      }}
    >
      Apply
    </Typography>
  </div>
)
