import { FC, ReactNode } from "react"
import IconToggleArrowRight from "assets/images/icons/icon-toggle-arrow-right.svg"
import { twMerge } from "tailwind-merge"

interface SpecificationHeaderProps {
  title: string | ReactNode
  className?: string
  isExpanded?: boolean
}

export const SpecificationHeader: FC<SpecificationHeaderProps> = ({
  title,
  className,
  isExpanded,
}) => (
  <div
    className={twMerge(
      "flex items-center px-3 justify-between min-h-[48px] border-[1px] border-solid border-[#e4e4e4]",
      className
    )}
  >
    <div className="flex items-center">
      <p
        className="font-semibold"
        style={{
          fontSize: 14,
          lineHeight: "24px",
          color: "#111111",
        }}
      >
        {title}
      </p>
    </div>
    <img
      src={IconToggleArrowRight}
      alt="icon"
      style={{
        transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
      }}
    />
  </div>
)
