import Button from "components/Button/Button"
import ButtonHasIcon from "components/Button/ButtonHasIcon"
import CheckedDefault from "components/Checked/CheckedDefault"
import { ClearAll, FilterIcon } from "components/Filter"
import { FilterCollapse } from "components/Filter/FilterCollapse"
import { FilterHeader } from "components/Filter/FilterHeader"
import { HeaderBreadcrumbInfo } from "components/HeaderInfo/HeaderInfo"
import LineHeader from "components/LineHeader"
import SelectTippy from "components/Select/SelectTippy"
import { TippyCustomzie, useTippyLayout } from "components/TippyCustomzie"
import Typography from "components/Typography"
import { EVENT } from "constants/events"
import {
  IUseDefaultValueProps,
  useBoolean,
  useString,
  useWindowSize,
} from "helpers/hooks"
import { umamiTracking, customLocalStorageHandler } from "helpers/utils"
import { isUndefined } from "lodash"
import FormSelectFilter from "pages/conversations/molecules/FormSelectFilter"
import TabItem from "../molecules/TabItem"
import {
  LIST_SHARING_PROJECT,
  LIST_STATUS_PROJECT,
  TAB_PROJECT,
} from "../project.constant"
import { CategoryDetail, ProjectCount, ProjectFilter } from "../types"
import { NAME_LOCALSTORAGE } from "constants/localStorage"
import InputDefault from "components/Input/InputDefault"
import { ReactComponent as IconClearSearch } from "assets/images/icons/icon-clear-search.svg"
import { ReactComponent as IconSearchNormal } from "assets/images/icons/icon-search-normal.svg"

interface Props {
  tab: TAB_PROJECT
  showArchive: IUseDefaultValueProps
  showDeletedAttachment: IUseDefaultValueProps
  selectSharing: IUseDefaultValueProps
  arrCategory: CategoryDetail[]
  selectCategoryId: IUseDefaultValueProps
  selectedStatusProject: object
  setSelectStatusProject: (selectedStatusProject: object) => void
  onChangeTab: (newTab: TAB_PROJECT) => () => void
  onChangeShowArchive: () => void
  onChangeShowDeleteAttachment: () => void
  onChangeSelected: (
    key: "status" | "category" | "sharing",
    newSelected: any
  ) => void
  onClickNewProject: () => void
  projectCount: ProjectCount
  isReadShareProject: boolean
  setApplyFilter: (data: boolean) => void
  applyFilter: boolean
  handleCallAPI: () => void
  search: IUseDefaultValueProps
}

const HeaderProject = (props: Props) => {
  const {
    tab,
    showArchive,
    showDeletedAttachment,
    arrCategory,
    selectCategoryId,
    selectedStatusProject,
    projectCount,
    selectSharing,
    isReadShareProject,
    setSelectStatusProject,
    onChangeTab,
    onChangeShowArchive,
    onChangeShowDeleteAttachment,
    onChangeSelected,
    onClickNewProject,
    setApplyFilter,
    handleCallAPI,
    search,
  } = props

  const { isMobile } = useWindowSize()
  const showProjectFilter = useBoolean()

  const { storageData } = customLocalStorageHandler(NAME_LOCALSTORAGE.PROJECT)
  const storageProject = !isUndefined(storageData) ? storageData : {}
  const { TippyLayout /* closeLayout, openLayout */ } = useTippyLayout()
  const preventCallApi = useBoolean(true)
  const PROJECT_LIST = [
    {
      label: `My Project (${projectCount.my_projects})`,
      value: TAB_PROJECT.MY_PROJECT,
    },
    {
      label: `Shared with me (${projectCount.shared_with_me_projects})`,
      value: TAB_PROJECT.SHARED,
    },
    {
      label: `Favorite Projects (${projectCount.favorite_projects})`,
      value: TAB_PROJECT.FAVORITE,
    },
  ]

  const onCheckClearAll = () => {
    if (
      !showArchive.value ||
      showDeletedAttachment.value ||
      selectSharing.value !== -1 ||
      selectedStatusProject[0] !== 0 ||
      selectCategoryId.value
    ) {
      return true
    }

    return false
  }

  /// set back to default value for each state
  const onClearFilter = () => {
    showArchive.setValue(true)
    showDeletedAttachment.setValue(false)
    selectSharing.setValue(-1)
    selectCategoryId.setValue("")
    setSelectStatusProject({ "0": Number(LIST_STATUS_PROJECT[0].value) })
  }

  const renderTabContent = () =>
    isMobile ? (
      <SelectTippy
        optionsSelect={PROJECT_LIST}
        activeSelect={tab}
        placementCustom="bottom-start"
        styleSelectCard={{
          minWidth: 160,
        }}
        offsetCustom={[-60, 25]}
        handleChange={(newValue) => {
          onChangeTab(newValue.value)()
        }}
      />
    ) : (
      <div className="flex items-center">
        {PROJECT_LIST.map((el, index) => (
          <TabItem
            key={el.value || index}
            title={el.label}
            style={{
              marginRight: 18,
            }}
            isIcon={el.value === "shared" ? isReadShareProject : undefined}
            isActive={tab === el.value}
            onClick={onChangeTab(el.value)}
          />
        ))}
      </div>
    )

  /// project filter content on mobile
  const renderProjectFilterDynamic = () => (
    <div>
      <ClearAll onClick={onClearFilter} active={onCheckClearAll()} />

      <CheckedDefault
        className="justify-between flex-row-reverse h-[52px] bg-white border-t-[1px] border-b-[1px] px-6"
        tilteClass="ml-0"
        textColor="black"
        checked={showArchive.value}
        onClick={onChangeShowArchive}
        title="Show Archive"
      />

      <CheckedDefault
        className="justify-between flex-row-reverse h-[52px] bg-white border-b-[1px] px-6"
        tilteClass="ml-0"
        textColor="black"
        checked={showDeletedAttachment.value}
        onClick={onChangeShowDeleteAttachment}
        title="Show deleted only"
      />

      <div className="flex flex-col">
        <FilterCollapse
          label={ProjectFilter.sharing}
          data={LIST_SHARING_PROJECT}
          chosenValue={selectSharing.value}
          onChange={(chosenValue) => {
            if (showDeletedAttachment.value) {
              return
            }
            onChangeSelected("sharing", chosenValue)
          }}
          disabled={showDeletedAttachment.value}
        />
        <FilterCollapse
          label={ProjectFilter.status}
          data={
            LIST_STATUS_PROJECT.map((el) => ({
              ...el,
              key: el.label,
              value: Number(el.value),
            })) as any
          }
          chosenValue={selectedStatusProject}
          multiActive={true}
          onChange={(chosenValue) => {
            if (showDeletedAttachment.value) {
              return
            }
            onChangeSelected("status", chosenValue)
          }}
          disabled={showDeletedAttachment.value}
        />
        <FilterCollapse
          label={ProjectFilter.category}
          data={arrCategory as any}
          chosenValue={selectCategoryId.value}
          onChange={(chosenValue) => {
            onChangeSelected("category", chosenValue)
          }}
        />
      </div>
    </div>
  )

  /// get previous value from cookies
  const onSetBackToPreviousValue = () => {
    selectSharing.setValue(storageProject.sharing)
    showDeletedAttachment.setValue(storageProject.isDeleted)
    showArchive.setValue(storageProject.isArchived)
    setSelectStatusProject(storageProject.status)
  }

  /// project filter content on desktop
  const renderProjectFilterContent = () => (
    <div
      className="pt-3.5 pb-3"
      style={{
        background: "#222222",
        borderRadius: 4,
        width: 436,
      }}
    >
      <div className="flex items-center justify-between mb-3 px-3 select-none">
        <p className="hidden md:block font-semibold text-16 leading-26 text-white">
          Filter
        </p>
        <ClearAll onClick={onClearFilter} active={onCheckClearAll()} />
      </div>

      <div className="flex items-center pl-3">
        <div className="flex flex-col">
          <CheckedDefault
            className="mb-2"
            checked={showArchive.value}
            onClick={onChangeShowArchive}
            title="Show Archive"
          />
          <CheckedDefault
            className="justify-between"
            checked={showDeletedAttachment.value}
            onClick={onChangeShowDeleteAttachment}
            title="Show deleted only"
          />
        </div>
      </div>

      <div className="flex">
        <FormSelectFilter
          title={ProjectFilter.sharing}
          type="single"
          dataSelects={arrCategory.map((el) => ({
            ...el,
            key: el.label || "",
            value: el.value || "",
          }))}
          objActiveSelect={selectCategoryId.value}
          setObjActiveSelect={(chosenValue) => {
            if (showDeletedAttachment.value) {
              return
            }
            onChangeSelected("category", chosenValue)
          }}
        />
        <FormSelectFilter
          title={ProjectFilter.sharing}
          type="single"
          dataSelects={LIST_SHARING_PROJECT.map((el) => ({
            ...el,
            key: el.label,
            value: el.value,
          }))}
          objActiveSelect={selectSharing.value}
          setObjActiveSelect={(chosenValue) => {
            if (showDeletedAttachment.value) {
              return
            }
            onChangeSelected("sharing", { value: chosenValue })
          }}
        />

        <FormSelectFilter
          title={ProjectFilter.status}
          type="multi"
          dataSelects={
            LIST_STATUS_PROJECT.map((el) => ({
              ...el,
              key: el.label,
              value: Number(el.value),
            })) as any
          }
          objActiveSelect={selectedStatusProject}
          setObjActiveSelect={(chosenValue) => {
            if (showDeletedAttachment.value) {
              return
            }
            onChangeSelected("status", chosenValue)
          }}
        />
      </div>

      <div className="flex justify-end mt-3 mr-3">
        <Button
          title="Apply"
          onClick={() => {
            setApplyFilter(true)
            handleCallAPI()
            umamiTracking(EVENT.PROJECT.FILTER)
            showProjectFilter.setValue(false)
          }}
          widthBtn={120}
          heightBtn={42}
        />
      </div>
    </div>
  )
  const onPreventBubble = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }
  const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      if (!search.value && !preventCallApi.value) {
        return
      }
      event.preventDefault()
      handleCallAPI()
      preventCallApi.setValue(false)
    }
  }
  const renderMainContent = () =>
    isMobile ? (
      <TippyLayout
        visible={showProjectFilter.value}
        headerContent={
          <FilterHeader
            setVisible={showProjectFilter.setValue}
            onCancel={onSetBackToPreviousValue}
            onApply={() => {
              setApplyFilter(true)
              handleCallAPI()
            }}
          />
        }
        mainContent={renderProjectFilterDynamic()}
      >
        <FilterIcon
          onClick={() => {
            showProjectFilter.setValue(!showProjectFilter.value)
          }}
          activeIcon={onCheckClearAll()}
        />
      </TippyLayout>
    ) : (
      <div className="flex items-center gap-4">
        {/* <InputDefault/> */}
        <div
          className=" bg-grayWhite2 p-2 rounded-full flex items-center custom-color-placeholder w-[320px]"
          onClick={onPreventBubble}
        >
          <IconSearchNormal
            values={search.value}
            onClick={onPreventBubble}
            onChange={(e) => {
              onPreventBubble(e)
            }}
          />
          <InputDefault
            value={search.value}
            placeholder="Search"
            style={{
              border: "none",
              boxShadow: "none",
              height: 30,
              fontWeight: 400,
            }}
            classCustom="h-11 border rounded bg-grayWhite2 pl-3 pr-3 text-sm font-light text-gray focus:outline-none"
            onClick={onPreventBubble}
            onChange={(e) => {
              onPreventBubble(e)
              search.setValue(e.target.value)
              if (!e.target.value && !preventCallApi.value) {
                handleCallAPI()
                preventCallApi.setValue(true)
              }
            }}
            onKeyPress={onKeyPress}
          />
          {search.value ? (
            <div
              onClick={(e) => {
                onPreventBubble(e)
                search.setValue("")
                if (!preventCallApi.value) {
                  handleCallAPI()
                  preventCallApi.setValue(true)
                }
              }}
              className="h-5 w-5 flex items-center justify-center custom-icon-clear-search cursor-pointer"
            >
              <IconClearSearch />
            </div>
          ) : null}
        </div>
        <TippyCustomzie
          containerClass="component-card-menu"
          placement="bottom-end"
          interactive
          arrow={false}
          animation="scale"
          offset={[280, 20]}
          allowHTML
          visible={showProjectFilter.value}
          content={renderProjectFilterContent()}
          onClickOutside={() => {
            onSetBackToPreviousValue()
            /// close filter popup
            showProjectFilter.setValue(false)
          }}
        >
          <FilterIcon
            onClick={() => {
              showProjectFilter.setValue(!showProjectFilter.value)
            }}
            activeIcon={onCheckClearAll()}
          />
        </TippyCustomzie>
        <ButtonHasIcon title="New project" onClick={onClickNewProject} />
      </div>
    )

  return (
    <HeaderBreadcrumbInfo className="justify-between">
      {renderTabContent()}
      {renderMainContent()}
    </HeaderBreadcrumbInfo>
  )
}
export default HeaderProject
