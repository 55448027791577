import "./button.css"
export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  widthBtn?: number | string
  heightBtn?: number | string
  isDisabledBtn?: boolean
  title: string
  sizeBtn?: "small" | "large"
  colorBtn?: "yellow" | "white" | "red" | "black"
  styleButton?: React.CSSProperties
}

const Button = (props: ButtonProps) => {
  const {
    disabled,
    title,
    isDisabledBtn,
    sizeBtn = "large",
    colorBtn = "yellow",
    widthBtn = sizeBtn === "small" ? 80 : 178,
    heightBtn = sizeBtn === "small" ? 28 : 44,
    styleButton,
    className,
    ...otherProps
  } = props
  const classes = `flex items-center justify-center rounded focus:outline-none`
  const classesColorBtn = () => {
    let newClass = "bg-btn-yellow"
    switch (colorBtn) {
      case "white":
        newClass = "bg-btn-white"
        break
      case "black":
        newClass = "bg-btn-black"
        break
      case "red":
        newClass = "bg-btn-red"
        break
    }
    return newClass
  }
  const classesSizeBtn = () => {
    if (sizeBtn === "large") {
      return `font-semibold text-[14px] leading-[24px]`
    }
    return `font-medium text-[12px] leading-[18px]`
  }

  return (
    <button
      disabled={isDisabledBtn ? disabled : false}
      className={`${classes} ${
        disabled ? "bg-btn-disable" : classesColorBtn()
      } ${classesSizeBtn()} ${className || ""} multi-button`}
      style={{
        width: widthBtn,
        height: heightBtn,
        ...styleButton,
      }}
      {...otherProps}
    >
      {title}
    </button>
  )
}

export default Button
