import { ReactComponent as IconClearSearch } from "assets/images/icons/icon-clear-search.svg"
import IconSearchNormalS from "assets/images/icons/icon-search-normal.svg"
import { ReactComponent as IconSearchNormal } from "assets/images/icons/search-normal.svg"

import InputDefault from "components/Input/InputDefault"
import { useTippyLayout } from "components/TippyCustomzie"
import { PATH } from "constants/path"
import { pushTo } from "helpers/history"
import { useString } from "helpers/hooks"
import { useEffect, useState } from "react"

const ModalSearch = () => {
  const [showSearch, setShowSearch] = useState<boolean>(false)

  const { TippyLayout } = useTippyLayout()

  const search = useString("")
  useEffect(() => {
    if (!showSearch) {
      return
    }
    search.setValue("")

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSearch])

  const handleChangeInput = (event) => {
    search.setValue(event.target.value)
  }
  const onRemoveSearch = () => {
    search.setValue("")
  }
  const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      if (!search.value) {
        return
      }
      event.preventDefault()
      setShowSearch(false)
      pushTo(
        PATH.generalSearch,
        undefined,
        {
          searchProject: search.value,
        },
        {
          query: search.value,
        }
      )
    }
  }

  const renderSearchIcon = () => (
    <div
      className="bg-new-black-333333 w-full h-[60px] md:h-10 flex justify-start md:justify-center items-center cursor-pointer pl-6 md:pl-0  "
      onClick={() => setShowSearch(!showSearch)}
    >
      <img src={IconSearchNormalS} alt="search" className="h-5 w-5" />
      <span className="block md:hidden font-semibold md:font-medium pl-3">
        Search
      </span>
    </div>
  )

  const onPreventBubble = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const renderSearchContent = () => (
    <div
      onClick={(e) => {
        onPreventBubble(e)
        setShowSearch(false)
      }}
      className="inset-0 flex fixed items-center justify-center z-[10000] bg-[#22222285]"
    >
      <div
        className="px-6 mx-6 bg-white rounded-md flex items-center custom-color-placeholder w-[648px] h-[76px]"
        onClick={onPreventBubble}
      >
        <IconSearchNormal
          values={search.value}
          onClick={onPreventBubble}
          onChange={(e) => {
            onPreventBubble(e)
            handleChangeInput(e)
          }}
        />
        <InputDefault
          placeholder="What are you looking for?"
          value={search.value}
          style={{
            border: "none",
            boxShadow: "none",
            height: 24,
            fontWeight: 600,
          }}
          onClick={onPreventBubble}
          onChange={(e) => {
            onPreventBubble(e)
            handleChangeInput(e)
          }}
          onKeyPress={onKeyPress}
        />
        {search.value ? (
          <div
            onClick={(e) => {
              onPreventBubble(e)
              onRemoveSearch()
            }}
            className="h-7	w-7 flex items-center justify-center custom-icon-clear-search cursor-pointer"
          >
            <IconClearSearch />
          </div>
        ) : null}
      </div>
    </div>
  )

  return (
    <TippyLayout
      containerClass="full-layout"
      animation="visible"
      mainContentClass="bg-transparent"
      visible={showSearch}
      mainContent={renderSearchContent()}
    >
      {renderSearchIcon()}
    </TippyLayout>
  )
}
export default ModalSearch
