import ModalDelete from "components/ModalCustom/ModalDelete"
import { GetUsersInProjectProp } from "pages/project-component/types"
import React from "react"
import { ProjectDetail } from "../types"
import FormShare from "./FormShare"
interface Props {
  isArchive: {
    value: boolean
    setValue: (newIsArchive: boolean) => void
  }
  isLoading?: {
    value: boolean
    setValue: (newIsShare: boolean) => void
  }
  isShare: {
    value: boolean
    setValue: (newIsShare: boolean) => void
  }
  projectDetail: ProjectDetail
  onArchiveProject: () => void
  onCloseFormShare: (
    newUserRole: GetUsersInProjectProp[],
    projectId: string
  ) => () => void
}
const FormModalActionProjectCard = (props: Props) => {
  const {
    isArchive,
    projectDetail,
    isShare,
    onArchiveProject,
    onCloseFormShare,
  } = props
  return (
    <React.Fragment>
      {isArchive.value && (
        <ModalDelete
          onCloseModal={() => isArchive.setValue(false)}
          title={`Are you sure to ${
            projectDetail.is_archived === 0 ? "archive" : "Unarchive"
          } this project?`}
          label="Confirmation"
          content=""
          onSubmit={onArchiveProject}
          titleButton={
            projectDetail.is_archived === 0 ? "Archive" : "Unarchive"
          }
        />
      )}
      {isShare.value && (
        <FormShare
          onCloseModal={onCloseFormShare}
          projectId={projectDetail.id}
        />
      )}
    </React.Fragment>
  )
}
export default FormModalActionProjectCard
