import {
  LIST_STATUS_PROJECT_WITH_ARCHIVED,
  PROJECT_STATUS,
} from "../project.constant"
import { ReactComponent as IconAction } from "assets/images/icons/icon-action-status.svg"
interface Props {
  status: number
  isIcon?: boolean
  minWidth?: number
}

const StatusProject = (props: Props) => {
  const { status, isIcon, minWidth } = props
  const renderStatus = () => {
    let backgroundColor = "#C7C7C7"
    let color = "#7A7A7A"
    let classColorIcon = "color-icon-no-status"
    switch (status) {
      case PROJECT_STATUS.ABANDONED:
        backgroundColor = "rgba(234, 69, 69, 0.15)"
        color = "#EA4545"
        classColorIcon = "color-icon-abandoned"
        break
      case PROJECT_STATUS.COMPLETED:
        backgroundColor = "rgba(86, 160, 18, 0.15)"
        color = "#56A012"
        classColorIcon = "color-icon-completed"
        break
      case PROJECT_STATUS.ACTIVE:
        backgroundColor = "rgba(10, 90, 245, 0.15)"
        color = "#0A5AF5"
        classColorIcon = "color-icon-active"
        break
    }
    return {
      backgroundColor,
      color,
      classColorIcon,
    }
  }
  const statusDetail = LIST_STATUS_PROJECT_WITH_ARCHIVED.find(
    (el) => Number(el.id) === status
  )
  return (
    <div
      className="mr-2 status-project-card flex items-center justify-center"
      style={{
        background: renderStatus().backgroundColor,
        borderRadius: 20,
        padding: "4px 8px",
        minWidth: minWidth ?? 70,
      }}
    >
      <p
        style={{
          fontWeight: 600,
          fontSize: 10,
          lineHeight: "14px",
          color: renderStatus().color,
        }}
      >
        {statusDetail?.name}
      </p>
      {isIcon && (
        <div className={`pl-1 ${renderStatus().classColorIcon}`}>
          <IconAction />
        </div>
      )}
    </div>
  )
}
export default StatusProject
