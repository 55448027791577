import React from "react"
import Typography from "components/Typography"
import IconArrowRight from "assets/images/icons/icon-arrow-right-breadcrumb.svg"
import { BreadcrumbsProps, BreadcrumbsDetail } from "./types"
import { pushTo } from "helpers/history"
import { twMerge } from "tailwind-merge"

const Breadcrumbs = (props: BreadcrumbsProps) => {
  const { listBreadcrumbs, className, overflow } = props
  const handleChangeRouter = (bread: BreadcrumbsDetail) => () => {
    if (bread.onCustomRouter) {
      bread.onCustomRouter()
    }
    if (!bread.path) {
      return
    }
    if (bread.query) {
      pushTo(
        bread.path,
        bread.field && bread.value
          ? {
              [bread.field]: bread.value,
            }
          : {},
        { search: bread.query }
      )
      return
    }
    pushTo(
      bread.path,
      bread.field && bread.value
        ? {
            [bread.field]: bread.value,
          }
        : {}
    )
  }

  const textOverflowStyles = overflow
    ? "w-[45%] overflow-hidden whitespace-nowrap text-ellipsis"
    : ""
  return (
    <div
      className={twMerge(
        `hidden md:flex items-center ${overflow}`,
        textOverflowStyles,
        className
      )}
    >
      {listBreadcrumbs.length
        ? listBreadcrumbs.map((el, index) => {
            const isActive = el.isActive
              ? el.isActive
              : listBreadcrumbs.length !== index + 1
            return (
              <div
                className="flex items-center"
                style={{
                  flexGrow: isActive && !el.isActive ? "" : 1,
                  maxWidth: "90%",
                }}
                key={index}
              >
                <Typography
                  onClick={handleChangeRouter(el)}
                  textClass={
                    !isActive || el.isNoHover
                      ? ""
                      : "cursor-pointer hover-breadcrumbs-text"
                  }
                  style={{
                    fontWeight: 400,
                    fontSize: 13,
                    lineHeight: "22px",
                    color: "#7A7A7A",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {el?.name}
                </Typography>
                {isActive && !el.isActive ? (
                  <img src={IconArrowRight} alt="icon" className="mx-1" />
                ) : null}
              </div>
            )
          })
        : null}
    </div>
  )
}

export default Breadcrumbs
