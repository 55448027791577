import { useEffect, useState } from "react"
import {
  CONVERSATION_ROLE,
  ConversationMessagesDetail,
  emptyConversationMessagesDetail,
} from "pages/conversations/conversations.type"
import {
  emptyProjectBuildDetail,
  ProjectComponentBuildDetail,
} from "pages/project-build/project-build.type"
import { useBoolean, useNumber, useString } from "helpers/hooks"
import { defaultProjectDetail, ProjectDetail } from "pages/projects/types"

export const useInviteeComponent = (params: {
  idProjectComponent: string
  idProjectBuildComponent: string
  idProjectComponentHistory: string | undefined
  idConversation: string
}) => {
  const idProjectComponentParam = params?.idProjectComponent || ""
  const idProjectBuildComponentParam = params?.idProjectBuildComponent || ""
  const idProjectComponentHistoryParam = params?.idProjectComponentHistory || ""
  const idConversationParam = params?.idConversation || ""
  const idComponentHistory = useString(params?.idProjectComponentHistory || "")
  const [conversationMessages, setConversationMessages] =
    useState<ConversationMessagesDetail>(emptyConversationMessagesDetail)
  const nameBOM = useString("")
  const [projectBuild, setProjectBuild] = useState<ProjectComponentBuildDetail>(
    emptyProjectBuildDetail
  )
  const [projectDetail, setProjectDetail] =
    useState<ProjectDetail>(defaultProjectDetail)
  const isReadComponent = useBoolean(true)
  const [conversationRole, setConversationRole] = useState<CONVERSATION_ROLE>(
    CONVERSATION_ROLE.VIEWER
  )

  return {
    idProjectComponentParam,
    idProjectBuildComponentParam,
    idProjectComponentHistoryParam,
    idConversationParam,
    conversationMessages,
    nameBOM,
    projectBuild,
    projectDetail,
    isReadComponent,
    setConversationMessages,
    setProjectBuild,
    setProjectDetail,
    idComponentHistory,
    conversationRole,
    setConversationRole,
  }
}
