import React from "react"

export enum ACTION_MODAL {
  YES = "yes",
  NO = "no",
}

export interface ModalProps {
  handleChangeButton?: (key: ACTION_MODAL) => () => void
  bodyChildren?: React.ReactNode
  label?: string
  denyButtonText?: string
  confirmButtonText?: string
  widthModal?: number
  styleRoot?: React.CSSProperties
  styleHeaderModal?: React.CSSProperties
  renderHeader?: React.ReactNode
}
