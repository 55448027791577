/* eslint-disable react-hooks/exhaustive-deps */
import {
  LayerAsignmentDetail,
  LayerRender,
  LayerVisibilityMap,
  PutLayerAssignmentRequest,
} from "../types"
import IconEye from "assets/images/icons/icon-eye.svg"
import IconEyeSlash from "assets/images/icons/icon-eye-slash.svg"
import LayerPCBItemCard from "./LayerPCBItemCard"
import {
  TransformComponent,
  TransformWrapper,
} from "@kokarn/react-zoom-pan-pinch"
import React, { useContext, useEffect, useState } from "react"
import LayersRenderCard from "../molecules/LayersRenderCard"
import IconZoomIn from "assets/images/icons/icon-zoom-in.svg"
import IconZoomOut from "assets/images/icons/icon-zoom-out.svg"
import IconReset from "assets/images/icons/icon-reset.svg"
import {
  useBoolean,
  useElementSize,
  useNumber,
  useWindowSize,
} from "helpers/hooks"
import { cloneDeep, cloneDeepWith, orderBy } from "lodash"
import {
  putInviteeLayerAssignmentMiddleware,
  putLayerAssignmentMiddleware,
  putSortLayers,
} from "../services"
import { STATUS_RESPONSE } from "types"
import { ProjectDetailContext } from "../contexts/ProjectDetail.context"
import ErrorGerber from "./ErrorGerber"
import { toast } from "react-toastify"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"

interface Props {
  layersPCB: LayerRender[]
  layerAssignment: LayerAsignmentDetail[]
  isToggleGerber: boolean
  viewOnly: boolean
  canViewGerber: boolean
  isInvitee?: boolean
  conversationId?: string
}
const isEqualProps = (prevProps: Props, nextProps: Props) => {
  return (
    prevProps.isToggleGerber === nextProps.isToggleGerber &&
    prevProps.viewOnly === nextProps.viewOnly &&
    JSON.stringify(prevProps.layerAssignment) ===
      JSON.stringify(nextProps.layerAssignment) &&
    JSON.stringify(prevProps.layersPCB) === JSON.stringify(nextProps.layersPCB)
  )
}
const GerberViewerCard = React.memo((props: Props) => {
  const {
    layerAssignment,
    layersPCB,
    isToggleGerber,
    viewOnly,
    canViewGerber,
    isInvitee,
    conversationId,
  } = props

  const [layerVisibility, setLayerVisibility] = useState<LayerVisibilityMap>({})
  const [layers, setLayers] = useState<LayerRender[]>([])
  const [itemList, setItemList] = useState([] as string[])
  const [squareRef, { width, height }, handleSize] = useElementSize()
  const contextProject = useContext(ProjectDetailContext)
  const scalePCB = useNumber(1)
  const isShowAllLayers = useBoolean(true)
  const { isMobile } = useWindowSize()

  const handleDrop = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return
    const updatedList = [...itemList]
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1)
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem)
    // Update State
    setItemList(updatedList)
    const newLayers = updatedList.map((id) => {
      const found = layers.find((item) => item.id === id)
      return { ...found, custom_sort: 1 }
    })
    setLayers(newLayers as any)
    // call api update sort index
    putSortLayers(
      { layer_order_ids: newLayers.map((newLayer) => newLayer?.id || "") },
      (type: STATUS_RESPONSE, messenger) => {
        if (type === STATUS_RESPONSE.ERROR) {
          toast(<LabelNotificationPage messenger={messenger} type="error" />)
        }
      }
    )
  }

  useEffect(() => {
    if (isToggleGerber) {
      setTimeout(() => {
        handleSize()
      }, 100)
    }
  }, [isToggleGerber])

  useEffect(() => {
    const newLayerVisibility = {}
    layersPCB.forEach((_layer, index) => {
      newLayerVisibility[index] = true
    })
    setLayerVisibility(newLayerVisibility)
    setLayers(layersPCB)
  }, [JSON.stringify(layersPCB)])
  useEffect(() => {
    setItemList(layers.map((item) => item.id))
  }, [JSON.stringify(layers)])

  const getNewLayerWithSequence = (
    idLayer: string,
    newSequence: number,
    sortIndex: number,
    customSort?: boolean
  ) => {
    const newBoardLayers: LayerRender[] = []
    cloneDeepWith(layers).forEach((layer) => {
      let changeSequence = layer.sequence
      let changeSortIndex = layer.sort_index
      if (
        Number(layer.sequence) === Number(newSequence) &&
        Number(newSequence) > -1
      ) {
        changeSequence = -1
      }
      if (layer.id === idLayer) {
        changeSequence = newSequence
        changeSortIndex = sortIndex
      }
      const dataToPush = {
        ...layer,

        sequence: changeSequence,
      }
      if (!customSort) {
        dataToPush.sort_index = changeSortIndex
      }
      newBoardLayers.push(dataToPush)
    })
    return newBoardLayers
  }

  const handleUpdateLayer = (
    idLayer: string,
    newSequence: number,
    oldSequence: number,
    sortIndex: number
  ) => {
    const layer = layers.find((item) => item.id === idLayer)

    setLayers(
      layer?.custom_sort
        ? getNewLayerWithSequence(
            idLayer,
            newSequence,
            sortIndex,
            layer?.custom_sort
          )
        : orderBy(
            getNewLayerWithSequence(
              idLayer,
              newSequence,
              sortIndex,
              layer?.custom_sort
            ),
            "sort_index",
            "desc"
          )
    )
    const dataRequest: PutLayerAssignmentRequest = {
      id: idLayer,
      sequence: newSequence,
    }
    if (isInvitee) {
      putInviteeLayerAssignmentMiddleware(
        dataRequest,
        conversationId,
        (type: STATUS_RESPONSE, messenger) => {
          if (type === STATUS_RESPONSE.SUCCESS) {
            contextProject?.setProjectDetail({
              ...contextProject.projectDetail,
              updated_at: new Date().toISOString(),
            })
          } else if (type === STATUS_RESPONSE.ERROR) {
            setLayers(
              orderBy(
                getNewLayerWithSequence(idLayer, oldSequence, sortIndex),
                "sort_index",
                "desc"
              )
            )
            toast(<LabelNotificationPage messenger={messenger} type="error" />)
          }
        }
      )
    } else {
      putLayerAssignmentMiddleware(
        dataRequest,
        (type: STATUS_RESPONSE, messenger) => {
          if (type === STATUS_RESPONSE.SUCCESS) {
            contextProject?.setProjectDetail({
              ...contextProject.projectDetail,
              updated_at: new Date().toISOString(),
            })
          } else if (type === STATUS_RESPONSE.ERROR) {
            setLayers(
              orderBy(
                getNewLayerWithSequence(idLayer, oldSequence, sortIndex),
                "sort_index",
                "desc"
              )
            )
            toast(<LabelNotificationPage messenger={messenger} type="error" />)
          }
        }
      )
    }
  }
  const handleChangeLayer = (index: number, oldShow: boolean) => () => {
    const newLayerVisibility = cloneDeep(layerVisibility)
    newLayerVisibility[index] = !oldShow
    setLayerVisibility(newLayerVisibility)
  }

  const handleChangeAllLayers = () => {
    isShowAllLayers.setValue(!isShowAllLayers.value)
    const newLayerVisibility = cloneDeep(layerVisibility)
    Object.keys(newLayerVisibility).forEach((key) => {
      newLayerVisibility[key] = !isShowAllLayers.value
    })
    setLayerVisibility(newLayerVisibility)
  }

  return (
    <div
      className="flex flex-col-reverse md:grid"
      style={{
        gridTemplateColumns: "35% calc(100% - 35%)",
      }}
    >
      <div
        className="flex flex-col p-3"
        style={{
          maxHeight: 600,
        }}
      >
        <div
          className={`grid ${viewOnly ? "mb-3" : ""}`}
          style={{
            gridTemplateColumns: "calc(100% - 236px) 36px 200px",
            height: 22,
          }}
        >
          <div className="flex items-center">
            <p
              className="font-semibold"
              style={{
                fontSize: 13,
                lineHeight: "22px",
                color: "#111111",
              }}
            >
              File name
            </p>
          </div>
          <div
            className="flex items-center justify-end"
            style={{
              borderRight: "1px solid #E4E4E4",
            }}
          >
            {canViewGerber ? (
              <img
                src={isShowAllLayers.value ? IconEye : IconEyeSlash}
                alt="icon"
                style={{
                  height: 20,
                }}
                className={"cursor-pointer mr-2"}
                onClick={handleChangeAllLayers}
              />
            ) : null}
          </div>

          <div className="flex items-center ml-2">
            <p
              className="font-semibold"
              style={{
                fontSize: 13,
                lineHeight: "22px",
                color: "#111111",
              }}
            >
              Assign to
            </p>
          </div>
        </div>
        {viewOnly || isMobile ? null : (
          <p
            className="font-normal"
            style={{
              fontSize: 10,
              lineHeight: "16px",
            }}
          >
            Drag to reorder.
          </p>
        )}
        <div className={`overflow-auto mt-2`}>
          <div className="flex flex-col mr-2">
            <div>
              <DragDropContext onDragEnd={handleDrop}>
                <Droppable droppableId="list-container" d>
                  {(dropProvided) => (
                    <div
                      className="list-container"
                      {...dropProvided.droppableProps}
                      ref={dropProvided.innerRef}
                    >
                      {layers.length
                        ? layers.map((layer, index) => (
                            <Draggable
                              key={layer.id}
                              draggableId={layer.id}
                              index={index}
                              isDragDisabled={viewOnly}
                            >
                              {(dragProvided) => (
                                <div
                                  className="item-container"
                                  ref={dragProvided.innerRef}
                                  {...dragProvided.dragHandleProps}
                                  {...dragProvided.draggableProps}
                                >
                                  <LayerPCBItemCard
                                    layer={layer}
                                    key={index}
                                    layerAssignment={layerAssignment}
                                    layerVisibility={layerVisibility}
                                    indexLayer={index}
                                    handleUpdateLayer={handleUpdateLayer}
                                    handleChangeLayer={handleChangeLayer}
                                    isDisabled={viewOnly}
                                    canViewGerber={canViewGerber}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))
                        : null}
                      {dropProvided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
        </div>
      </div>
      <div ref={squareRef} className="relative">
        {canViewGerber ? (
          <TransformWrapper
            wheel={{
              disabled: false,
              step: 0.5,
            }}
            initialScale={scalePCB.value}
            centerOnInit
          >
            {({ zoomIn, zoomOut, resetTransform }) => (
              <React.Fragment>
                <TransformComponent contentClass="w-full">
                  <div
                    className="relative p-2"
                    style={{ width, height: isMobile ? 300 : height }}
                  >
                    <div className="relative w-full h-full">
                      <LayersRenderCard
                        layers={layers}
                        layerVisibility={layerVisibility}
                      />
                    </div>
                  </div>
                </TransformComponent>
                <div className="hidden md:flex items-center min-h-40-custom rounded-8 absolute bg-black-04 right-5 bottom-5 px-2">
                  <img
                    src={IconZoomOut}
                    alt="icon"
                    className="mr-2 cursor-pointer"
                    onClick={() => (isMobile ? undefined : zoomOut())}
                  />
                  <img
                    src={IconZoomIn}
                    alt="icon"
                    className="mr-2 cursor-pointer"
                    onClick={() => (isMobile ? undefined : zoomIn())}
                  />
                  <img
                    src={IconReset}
                    alt="icon"
                    className="cursor-pointer"
                    onClick={() => (isMobile ? undefined : resetTransform())}
                  />
                </div>
              </React.Fragment>
            )}
          </TransformWrapper>
        ) : (
          <ErrorGerber />
        )}
      </div>
    </div>
  )
}, isEqualProps)
export default GerberViewerCard
