import React from "react"
import { showImageUrl } from "helpers/utils"

interface Props {
  file: string
  color: string
  active: boolean
}
const isEqualProps = (prevProps: Props, nextProps: Props) => {
  return (
    JSON.stringify(prevProps.file) === JSON.stringify(nextProps.file) &&
    JSON.stringify(prevProps.active) === JSON.stringify(nextProps.active)
  )
}

const SvgLayerCard = React.memo((props: Props) => {
  const { active, file } = props
  return (
    <img
      src={showImageUrl(file, "")}
      alt="icon"
      className={`w-full h-full absolute ${
        active ? "opacity-40" : "opacity-0"
      }`}
    />
  )
}, isEqualProps)

export default SvgLayerCard
