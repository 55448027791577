import { isUndefined } from "lodash"
import DotCard from "pages/conversations/atoms/DotCard"
import ActionIconHistory from "pages/project-component-detail/molecules/ActionIconHistory"
import React from "react"
import { InviteeBuilComponentDetail } from "../project-build.type"
import { formatDateForConversationMessage } from "helpers/utils"
import { useWindowSize } from "helpers/hooks"
import { TippyCustomzie } from "components/TippyCustomzie"
interface Props {
  isDeleted?: boolean
  component: InviteeBuilComponentDetail
  onDelete?: (oldComponent: InviteeBuilComponentDetail) => () => void
  styleRoot?: React.CSSProperties
  onClickRoot?: (oldComponent: InviteeBuilComponentDetail) => () => void
  isDisabled?: boolean
  hasDeleteIcon?: boolean
  tooltip?: React.ReactNode
}
const ComponentBuildItemCard2 = (props: Props) => {
  const {
    hasDeleteIcon = false,
    isDeleted = false,
    isDisabled = false,
    component,
    onDelete,
    styleRoot,
    onClickRoot,
    tooltip,
  } = props
  return (
    <TippyCustomzie
      content={tooltip}
      placement="top"
      disabled={
        !tooltip || isDeleted || isDisabled || component.is_read_invitee_commit
      }
      arrow={true}
    >
      <div
        onClick={onClickRoot && !isDeleted ? onClickRoot(component) : undefined}
        className={`flex flex-col w-[261px] py-[9px] px-3 gap-2 relative border-stroke border-[1px] border-solid rounded-[6px] ${
          isDisabled
            ? "bg-grayWhite"
            : "hover-component-build-item cursor-pointer"
        } ${isDeleted ? "bg-neutral3 bg-chip-deleted" : ""}`}
        style={{
          ...styleRoot,
        }}
      >
        <div className="flex">
          <div className="h-7 flex items-center ">
            <p
              className="font-medium px-1 py-[2px] rounded-4"
              style={{
                fontSize: 12,
                lineHeight: "18px",
                color: "#FFFFFF",
                backgroundColor: isDisabled ? "#C7C7C7" : "#333333",
              }}
            >
              {component.type_key}
            </p>
          </div>
          <div
            className="h-7 flex items-center px-2"
            style={{
              color: isDisabled ? "#C7C7C7" : "#111111",
            }}
          >
            <p
              className="font-medium"
              style={{
                fontSize: 12,
                lineHeight: "18px",
              }}
            >
              {component.code}
            </p>
            <DotCard
              isDisabled={isDeleted || isDisabled}
              styleRoot={{ margin: 4 }}
            />
            <p
              className="font-medium"
              style={{
                fontSize: 12,
                lineHeight: "18px",
              }}
            >
              {component.version}
            </p>
          </div>
        </div>

        <p
          className="font-medium text-black text-sm overflow-hidden text-ellipsis "
          style={{
            color: isDisabled ? "#C7C7C7" : "#111111",
          }}
        >
          {component.name}
        </p>
        <p
          className="font-normal "
          style={{
            fontSize: 10,
            color: isDisabled ? "#C7C7C7" : "#111111",
          }}
        >{`Updated ${formatDateForConversationMessage(
          component.updated_at
        )}`}</p>
        {(!isUndefined(component.is_read) && !component.is_read) ||
        (!isUndefined(component.is_read_invitee_commit) &&
          !component.is_read_invitee_commit) ? (
          <div
            className="absolute"
            style={{
              height: 11,
              width: 11,
              background: "#EA4545",
              border: "3px solid #FFFFFF",
              borderRadius: "50%",
              top: -4,
              right: -4,
            }}
          ></div>
        ) : null}
      </div>
    </TippyCustomzie>
  )
}
export default ComponentBuildItemCard2
