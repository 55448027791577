import IconInfoCircle from "assets/images/icons/icon-info-circle.svg"
import { InfoSingleCardProps } from "../types"
import Tippy from "@tippyjs/react"

const InfoSingleCard = (props: InfoSingleCardProps) => {
  const { name, tooltip, rootClass, requiredName, styleName } = props
  return (
    <div className={`${rootClass ?? "flex items-center mb-2 h-[30px]"}`}>
      <p
        className="font-semibold"
        style={{
          color: "#111111",
          fontSize: 14,
          lineHeight: "24px",
          ...styleName,
        }}
      >
        {name}
        <span className="text-error"> {requiredName ? "*" : ""}</span>
      </p>
      {tooltip ? (
        <Tippy
          className="custom-tippy-menu-left"
          placement="right"
          content={
            <span
              className="font-normal"
              style={{
                fontSize: 13,
                lineHeight: "22px",
              }}
            >
              {tooltip}
            </span>
          }
        >
          <img
            src={IconInfoCircle}
            alt="icon"
            className="cursor-pointer ml-2"
          />
        </Tippy>
      ) : null}
    </div>
  )
}

export default InfoSingleCard
