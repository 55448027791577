import { ReactComponent as IconButton } from "assets/images/icons/icon-button-create.svg"
import {
  BUILD_NOT_ALLOW_ADD_INVITEE_STATUSES,
  STATUS_BUILD,
} from "components/Status/types"
import { useBoolean } from "helpers/hooks"
import { checkPermissionPage } from "helpers/utils"
import { useContext, useState } from "react"
import { PermissionProjectBuildPage } from "../contexts/PermissionProjectBuildPage.context"
import FormVendor from "../molecules/FormVendor"
import InviteesItemCard from "../molecules/InviteesItemCard"
import {
  BUILD_INVITEE_STATUS,
  emptyInvitessDetail,
  ProjectBuildInvitessDetail,
} from "../project-build.type"

interface Props {
  idProjectBuild: string
  invitess: ProjectBuildInvitessDetail[]
  handleChangeData: (newInvitees?: ProjectBuildInvitessDetail) => void
  sumComponent: number
  isDeleted?: boolean
}
const InviteesInfoCard = (props: Props) => {
  const {
    idProjectBuild,
    invitess,
    sumComponent,
    handleChangeData,
    isDeleted = false,
  } = props
  const [invitessDetail, setInvitessDetail] =
    useState<ProjectBuildInvitessDetail>(emptyInvitessDetail)
  const isAddInvitee = useBoolean(false)
  const isEditInvitee = useBoolean(false)
  const { archiveProject, archiveBuild, viewOnlyShare, status } = useContext(
    PermissionProjectBuildPage
  )

  const onEditInvites = (oldInvite: ProjectBuildInvitessDetail) => () => {
    if (oldInvite.status === BUILD_INVITEE_STATUS.DEACTIVATE || isDeleted) {
      return
    }
    setInvitessDetail(oldInvite)
    isEditInvitee.setValue(true)
  }
  return (
    <div className="flex flex-col overflow-auto">
      <div className="flex flex-col">
        {invitess.length ? (
          <div className="flex flex-col pr-6 overflow-auto build-info-form">
            {invitess.map((invites, index) => (
              <InviteesItemCard
                key={index}
                originInvites={invites}
                onEditInvites={onEditInvites}
                isViewShare={
                  checkPermissionPage({
                    project: archiveProject,
                    build: archiveBuild,
                    viewShare: viewOnlyShare,
                  }) || isDeleted
                }
                sumComponent={sumComponent}
                handleChangeData={handleChangeData}
                isDeleted={isDeleted}
              />
            ))}
          </div>
        ) : null}
        {!BUILD_NOT_ALLOW_ADD_INVITEE_STATUSES.includes(
          status as STATUS_BUILD
        ) &&
        !checkPermissionPage({
          project: archiveProject,
          build: archiveBuild,
          viewShare: viewOnlyShare,
        }) &&
        !isDeleted ? (
          <div
            className={`flex items-center min-h-[48px] mb-10 md:mb-0 ${
              invitess.length ? "pt-6" : ""
            } cursor-pointer w-fit`}
            onClick={() => isAddInvitee.setValue(true)}
          >
            <IconButton />
            <p
              className="font-normal ml-1"
              style={{
                color: "#0A5AF5",
                lineHeight: "24px",
                fontSize: 14,
              }}
            >
              Add invitee
            </p>
          </div>
        ) : null}
      </div>
      {isAddInvitee.value ? (
        <FormVendor
          defaultRequest={{
            invitee: { email: "", name: "" },
            cc: [],
            is_sent: 0,
          }}
          onCloseModal={() => isAddInvitee.setValue(false)}
          handleChangeData={handleChangeData}
          openModal={isAddInvitee.value}
          idProjectBuild={idProjectBuild}
        />
      ) : null}
      {isEditInvitee.value ? (
        <FormVendor
          defaultRequest={{
            invitee: {
              email: invitessDetail.email,
              name: invitessDetail.name,
            },
            id: invitessDetail.id,
            is_sent: invitessDetail.is_sent,
            cc: invitessDetail.cc,
          }}
          onCloseModal={() => isEditInvitee.setValue(false)}
          handleChangeData={handleChangeData}
          openModal={isEditInvitee.value}
          idProjectBuild={idProjectBuild}
        />
      ) : null}
    </div>
  )
}
export default InviteesInfoCard
