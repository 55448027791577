import ComponentBuildItemCard from "./ComponentBuildItemCard"
import { SelectUserBuild } from "./FormInProduction"
import { ReactComponent as IconSupplierDropdown } from "assets/images/icons/icon-supplier-dropdown.svg"
import FormLabelWarning from "components/FormLabel/FormLabelWarning"
import { TippyCustomzie } from "components/TippyCustomzie"
import { useBoolean } from "helpers/hooks"
import { cloneDeep } from "lodash"
import { emptyInviteeBuildComponentDetail } from "../project-build.type"

interface Props {
  dataSelect: SelectUserBuild
  objSelect: {}
  setObjSelect: (newObjSelect: {}) => void
}
const FromSelectInviteeItem = (props: Props) => {
  const { dataSelect, objSelect, setObjSelect } = props
  const showTippy = useBoolean(false)
  const onChangeSelect =
    (
      newInvitee: {
        id: string
        name: string
        email: string
      },
      oldProjectBuildComponentId: string
    ) =>
    () => {
      const newObjSelect = cloneDeep(objSelect)
      const value = `${newInvitee.id}_${newInvitee.name}_${newInvitee.email}`
      if (newObjSelect[oldProjectBuildComponentId] === value) {
        showTippy.setValue(false)
        return
      }
      newObjSelect[oldProjectBuildComponentId] = value
      setObjSelect(newObjSelect)
      showTippy.setValue(false)
    }
  const renderValueSelect = (projectBuildComponentId: string) => {
    if (objSelect[projectBuildComponentId]) {
      const newValue = objSelect[projectBuildComponentId].split("_")
      return (
        <div className="flex items-center max-w-[232px] md:max-w-[370px] ">
          <p
            className="font-semibold overflow-hidden text-ellipsis whitespace-nowrap"
            style={{
              fontSize: 13,
              lineHeight: "22px",
              color: "#111111",
            }}
          >
            {newValue[1]}
          </p>
          <div
            className="h-1 w-1 mx-2"
            style={{
              background: "#7A7A7A",
              borderRadius: "50%",
            }}
          ></div>
          <p
            className="font-normal overflow-hidden text-ellipsis whitespace-nowrap"
            style={{
              fontSize: 13,
              lineHeight: "22px",
              color: "#7A7A7A",
            }}
          >
            {newValue[2]}
          </p>
        </div>
      )
    }
    return (
      <p
        className="font-normal"
        style={{
          fontSize: 13,
          lineHeight: "22px",
          color: "#7A7A7A",
        }}
      >
        Select an invitee
      </p>
    )
  }
  return (
    <div
      className="flex py-3 md:py-0 flex-col md:flex-row px-3 gap-3 md:gap-0 md:items-center justify-between mb-3"
      style={{
        // height: 52,
        minHeight: 52,
        background: " #FFFFFF",
        border: "1px solid #E4E4E4",
        borderRadius: 6,
      }}
    >
      <div className="pr-3 border-b-[1px] pb-3 border-b-[#E4E4E4] md:border-b-0 md:border-r-[1px] md:border-r-[#E4E4E4] md:pb-0">
        <ComponentBuildItemCard
          component={{
            ...emptyInviteeBuildComponentDetail,
            code: dataSelect.code,
            type_key: dataSelect.typeCode,
            project_build_component_id: dataSelect.projectBuildComponentId,
            version: dataSelect.version,
          }}
          styleRoot={{
            marginBottom: 0,
            marginRight: 0,
          }}
        />
      </div>
      {/* <div className="flex items-center justify-around w-[282px] md:w-auto h-6"> */}
      {dataSelect.invitees.length ? (
        <TippyCustomzie
          containerClass="component-card-menu reset-tippy"
          placement="bottom-end"
          interactive
          arrow={false}
          animation="scale"
          visible={showTippy.value}
          onClickOutside={() => showTippy.setValue(false)}
          content={
            <div
              style={{
                background: "#222222",
                borderRadius: 4,
                paddingTop: 8,
                paddingBottom: 8,
                maxWidth: 268,
              }}
            >
              {dataSelect.invitees.map((invitee, index) => (
                <div
                  key={index}
                  onClick={onChangeSelect(
                    invitee,
                    dataSelect.projectBuildComponentId
                  )}
                  className="min-h-32 flex items-center px-3 cursor-pointer hover-text-select-in-production overflow-hidden"
                >
                  <p
                    className="font-normal text-white"
                    style={{
                      fontSize: 13,
                      lineHeight: "22px",
                    }}
                  >
                    {`${invitee.name} · ${invitee.email}`}
                  </p>
                </div>
              ))}
            </div>
          }
          allowHTML
        >
          <div
            onClick={() => showTippy.setValue(!showTippy.value)}
            className="flex items-center justify-between md:justify-normal cursor-pointer"
          >
            <div>{renderValueSelect(dataSelect.projectBuildComponentId)}</div>

            <IconSupplierDropdown className="ml-2 icon-select-in-production" />
          </div>
        </TippyCustomzie>
      ) : (
        <FormLabelWarning
          label="No invitee to select"
          minHeight={22}
          className="justify-center"
          styleRoot={{
            backgroundColor: "transparent",
            paddingRight: 0,
          }}
        />
      )}
      {/* </div> */}
    </div>
  )
}
export default FromSelectInviteeItem
