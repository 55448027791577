import IconLoading from "assets/images/loading-history.gif"
interface Props {
  label: string
}

const FormLoading = (props: Props) => {
  const { label } = props

  return (
    <div
      className="inset-0 flex fixed items-center justify-center"
      style={{
        zIndex: 10000,
        backgroundColor: "#22222285",
      }}
    >
      <div
        className="p-3 bg-white rounded-md flex flex-col"
        style={{
          width: 400,
        }}
      >
        <div className="flex flex-col justify-center items-center relative">
          <img
            src={IconLoading}
            alt="icon"
            style={{
              width: 216,
            }}
          />
          <p
            className="font-normal absolute"
            style={{
              fontSize: 14,
              lineHeight: "24px",
              color: "#222222",
              bottom: 30,
            }}
          >
            {label}
          </p>
        </div>
      </div>
    </div>
  )
}

export default FormLoading
