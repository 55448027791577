import { twMerge } from "tailwind-merge"
import "./switch-button.css"
export interface SwitchButtonDefaultProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  labelButton?: string
  containerClass?: string
  labelButtonClass?: string
}

const SwitchButtonDefault = (props: SwitchButtonDefaultProps) => {
  const { labelButton, containerClass, labelButtonClass, ...otherProps } = props
  const defaultLableButtonStyle =
    "font-normal text-[10px] leading-[12px] text-[#7A7A7A] ml-2 whitespace-nowrap"
  return (
    <div className={twMerge("flex items-center", containerClass)}>
      <label className="switch">
        <input type="checkbox" {...otherProps} />
        <span className="slider round"></span>
      </label>
      {labelButton ? (
        <p
          className={twMerge(
            labelButtonClass ? labelButtonClass : defaultLableButtonStyle
          )}
        >
          {labelButton}
        </p>
      ) : null}
    </div>
  )
}
export default SwitchButtonDefault
