import { TypeSelectProp } from "pages/project-component-detail/types"
import { BuildStatusProp } from "pages/project-component/types"
import React from "react"
import { ConversationDetail, CustomStatus } from "../conversations.type"

export interface GlobalConversationsPageContext {
  cpnTypeOptions: TypeSelectProp[]
  cpnStatusOptions: BuildStatusProp[]
  customStatuses: CustomStatus[]
  setCustomStatuses: (newObj: any) => void
  objSelectCustomStatus: any
  setObjSelectCustomStatus?: (newObj: any) => void
  objSelectType: any
  setObjSelectType?: (newObj: any) => void
  objSelectStatus: any
  setObjSelectStatus?: (newObj: any) => void
  updateCurrentConvoInConvoList: (
    conversationId: string,
    data: Partial<ConversationDetail>
  ) => void
}

export const ConversationsPageContext =
  React.createContext<GlobalConversationsPageContext>({
    cpnStatusOptions: [],
    cpnTypeOptions: [],
    customStatuses: [],
    objSelectCustomStatus: {},
    objSelectType: {},
    objSelectStatus: {},
    setCustomStatuses: () => {},
    updateCurrentConvoInConvoList: () => {},
  })
