import { TabItemProps } from "./types"
import { twMerge } from "tailwind-merge"

const TabItem = (props: TabItemProps) => {
  const {
    active,
    label,
    index,
    styleTabRoot,
    className,
    isRead,
    count,
    handleClickTabItem,
  } = props
  const renderCount = () => {
    if (!count) {
      return ""
    }
    return ` (${count})`
  }

  return (
    <div
      onClick={handleClickTabItem(index, label)}
      className={twMerge(
        `cursor-pointer mr-[18px] min-h-32 border-solid border-b-2 ${
          active ? "border-[#F7AC1B]" : "border-[#fff]"
        }`,
        className
      )}
      style={styleTabRoot}
    >
      <p
        className="font-semibold text-sm leading-6"
        style={{
          color: active ? "#111111" : "#7A7A7A",
        }}
      >
        {`${label}${renderCount()}`}
      </p>
      <div className="w-[6px] h-[6px]">
        {isRead ? (
          <div
            style={{
              width: 6,
              height: 6,
              backgroundColor: "#EA4545",
              borderRadius: "50%",
            }}
          />
        ) : null}
      </div>
    </div>
  )
}
export default TabItem
