import SelectMutiTippy from "components/Select/SelectMutiTippy"
import { SelectedDefaultProp } from "components/Select/types"
import { useBoolean } from "helpers/hooks"

interface Props {
  cpnTypeOptions: SelectedDefaultProp[]
  cpnTypeSelected: SelectedDefaultProp[]
  folderOptions: SelectedDefaultProp[]
  folderSelected: SelectedDefaultProp[]
  onChangeTypeSelected: (newSelect: SelectedDefaultProp[]) => void
  onChangeFolderSelected: (newSelect: SelectedDefaultProp[]) => void
}

const ListTypeBuildCard = (props: Props) => {
  const {
    cpnTypeOptions,
    cpnTypeSelected,
    onChangeTypeSelected,
    folderOptions,
    folderSelected,
    onChangeFolderSelected,
  } = props
  const selectTypeTippy = useBoolean()
  const selectFolderTippy = useBoolean()

  return (
    <div className="flex items-center justify-between pr-4 mt-[12px]">
      <div className="flex items-center h-7 pr-4">
        <p
          className="font-semibold text-center "
          style={{
            color: "#222222",
            fontSize: 14,
            lineHeight: "24px",
          }}
        >
          Available
        </p>
      </div>
      <div className="flex justify-between gap-3">
        <SelectMutiTippy
          key={"selectedType"}
          optionsSelect={cpnTypeOptions}
          activeSelect={cpnTypeSelected}
          handleChange={onChangeTypeSelected}
          title="Type"
          styleSelectCard={{ width: 155 }}
          setTippy={selectTypeTippy}
        />
        <SelectMutiTippy
          key={"selectedFolder"}
          optionsSelect={folderOptions}
          activeSelect={folderSelected}
          title="Folder"
          handleChange={onChangeFolderSelected}
          styleSelectCard={{ width: 220 }}
          setTippy={selectFolderTippy}
        />
      </div>
    </div>
  )
}
export default ListTypeBuildCard
