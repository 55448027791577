import { STATUS_BUILD } from "components/Status/types"
import { addAlphaColor, findWithRegex } from "helpers/utils"
import {
  BuildHistoryDetail,
  TAB_URL_PROJECT_COMPONENT,
} from "pages/project-component-detail/types"
import React from "react"
import { isEmpty } from "lodash"

export const spacingAndLineWidthTree = (lengthHistory: number) => {
  let spacing = 15
  let lineWidth = 2
  if (lengthHistory > 20) {
    spacing = 7
    lineWidth = 1.5
  } else if (lengthHistory > 10) {
    spacing = 10
    lineWidth = 1.5
  }
  return {
    spacing,
    lineWidth,
  }
}
export const converHistoryTreeGitGraph = (
  reverseHistoryTrees: BuildHistoryDetail[],
  objBranchTree: any,
  gitgraph: any
) => {
  let orgiginParentId = ""
  reverseHistoryTrees.forEach((history, index) => {
    if (!objBranchTree[history.id]) {
      if (!history.parent_id) {
        if (!history.project_build_component_id) {
          if (index === 0) {
            objBranchTree[history.id] = gitgraph
              .branch({
                name: `${history.branch}-${history.code}`,
              })
              .commit({
                subject: history.commit,
                hash: history.id,
                renderDot: (commit) => renderDotHistoryTree(commit, history),
              })
            orgiginParentId = history.id
          } else {
            converDataHistoryTreeNoParentIdAndNoProjectBuildComponentId(
              history,
              objBranchTree,
              orgiginParentId
            )
          }
        } else {
          converDataHistoryTreeNoParentIdAndExsitProjectBuildComponentId(
            history,
            objBranchTree,
            gitgraph
          )
        }
      } else {
        converDataHistoryTreeParentId(
          history,
          objBranchTree,
          gitgraph,
          orgiginParentId
        )
      }
    } else {
      objBranchTree[history.id].commit({
        subject: history.commit,
        renderDot: (commit) => renderDotHistoryTree(commit, history),
      })
    }
  })
}
export const converDataHistoryTreeNoParentIdAndNoProjectBuildComponentId = (
  history: BuildHistoryDetail,
  objBranchTree: any,
  orgiginParentId: string
) => {
  if (Boolean(history.is_merged) && history.from_build_component_id) {
    objBranchTree[orgiginParentId].merge({
      branch: objBranchTree[history.from_build_component_id],
      commitOptions: {
        hash: history.id,
        renderDot: (commit) => renderDotHistoryTree(commit, history),
      },
    })
  } else {
    objBranchTree[orgiginParentId].commit({
      subject: history.commit,
      hash: history.id,
      renderDot: (commit) => renderDotHistoryTree(commit, history),
    })
  }
}
export const converDataHistoryTreeNoParentIdAndExsitProjectBuildComponentId = (
  history: BuildHistoryDetail,
  objBranchTree: any,
  gitgraph: any
) => {
  if (objBranchTree[history.project_build_component_id]) {
    objBranchTree[history.project_build_component_id].commit({
      subject: history.commit,
      renderDot: (commit) => renderDotHistoryTree(commit, history),
    })
  } else {
    objBranchTree[history.project_build_component_id] = gitgraph
      .branch({
        name: `${history.branch}-${history.code}`,
      })
      .commit({
        subject: history.commit,
        hash: history.id,
        renderDot: (commit) => renderDotHistoryTree(commit, history),
      })
  }
}
export const converDataHistoryTreeParentId = (
  history: BuildHistoryDetail,
  objBranchTree: any,
  gitgraph: any,
  orgiginParentId: string
) => {
  if (history.project_build_component_id) {
    if (objBranchTree[history.project_build_component_id]) {
      objBranchTree[history.project_build_component_id].commit({
        subject: history.commit,
        renderDot: (commit) => renderDotHistoryTree(commit, history),
      })
    } else {
      objBranchTree[history.project_build_component_id] = gitgraph
        .branch({
          name: `${history.branch}-${history.code}`,
          from: history.parent_id,
        })
        .commit({
          subject: history.commit,
          hash: history.id,
          renderDot: (commit) => renderDotHistoryTree(commit, history),
        })
    }
  } else {
    if (Boolean(history.is_merged) && history.from_build_component_id) {
      objBranchTree[orgiginParentId].merge({
        branch: objBranchTree[history.from_build_component_id],
        commitOptions: {
          hash: history.id,
          renderDot: (commit) => renderDotHistoryTree(commit, history),
        },
      })
    } else {
      if (!orgiginParentId) {
        orgiginParentId = history.id
        objBranchTree[orgiginParentId] = gitgraph
          .branch({
            name: `${history.branch}-${history.code}`,
          })
          .commit({
            subject: history.commit,
            hash: history.id,
            renderDot: (commit) => renderDotHistoryTree(commit, history),
          })
      } else {
        objBranchTree[orgiginParentId].commit({
          subject: history.commit,
          renderDot: (commit) => renderDotHistoryTree(commit, history),
        })
      }
    }
  }
}
export const renderDotHistoryTree = (
  commit: any,
  history: BuildHistoryDetail
) => {
  const idRow = `row-${
    Boolean(history.is_merged) && history.from_build_component_id
      ? history.parent_id
      : history.project_build_component_id
  }`
  document.querySelectorAll(`[id=${idRow}]`).forEach((row) => {
    const newCardTree: any = row?.querySelector(".card-tree")
    if (newCardTree) {
      newCardTree.style.backgroundColor = addAlphaColor(
        commit.style.dot.color,
        0.15
      )
      const newCardTreeChildren: any = newCardTree?.querySelector(
        ".card-tree-children"
      )
      if (newCardTreeChildren) {
        newCardTreeChildren.style.backgroundColor = commit.style.dot.color
      }
    }
  })
  const svg = document.getElementById("gitgrah")
  return (
    <>
      {React.createElement(
        "svg",
        {
          xmlns: "http://www.w3.org/2000/svg",
          viewBox: "0 0 10 10",
          height: "10",
          width: "10",
          fill: "none",
        },
        React.createElement("circle", {
          fill: commit.style.dot.color,
          fillOpacity: "0.15",
          cx: 5,
          cy: 5,
          r: 5,
        }),
        React.createElement("circle", {
          fill: commit.style.dot.color,
          cx: 5,
          cy: 5,
          r: 3,
        })
      )}
      {React.createElement("line", {
        stroke: "#E4E4E4",
        strokeWidth: 1,
        x1:
          Number(svg?.clientWidth) > 0
            ? Number(svg?.clientWidth) - commit.x - 15 - 2
            : 0,
        transform: "translate(8, 5)",
      })}
    </>
  )
}

export const converCommitHistoryTree = (
  originHistory: BuildHistoryDetail,
  titlePage: string
) => {
  if (!originHistory.commit) {
    return {
      newCommit: "",
      newForm: "",
    }
  }
  if (titlePage === TAB_URL_PROJECT_COMPONENT.BUILD_COMPONENT) {
    let newCommitHasBuildComponent = originHistory.commit
    let buildSliceCommit = originHistory.commit.slice(-6)
    if (findWithRegex(buildSliceCommit, /V-[0-9]{4}/g) === "") {
      buildSliceCommit = ""
    }
    if (buildSliceCommit || originHistory.reverted_from) {
      newCommitHasBuildComponent = originHistory.commit.replace(
        buildSliceCommit,
        ""
      )
    }
    return {
      newCommit: newCommitHasBuildComponent,
      newForm: buildSliceCommit,
    }
  }
  let newCommitComponent = originHistory.commit
  let newFormBuild = ""
  let subForm = "- from build"
  let sliceCommit = originHistory.commit.slice(-6)
  if (findWithRegex(sliceCommit, /V-[0-9]{4}/g) === "") {
    sliceCommit = ""
  }
  if (
    sliceCommit ||
    originHistory.reverted_from ||
    !originHistory.project_build_component_id
  ) {
    subForm = ""
  }

  if (
    (Boolean(originHistory.is_merged) &&
      originHistory.status === STATUS_BUILD.COMMITTED) ||
    (originHistory.reverted_from && sliceCommit)
  ) {
    newCommitComponent = originHistory.commit.replace(sliceCommit, "")
    newFormBuild = sliceCommit
  }
  if (
    originHistory.transcended_from &&
    !isEmpty(originHistory.transcended_from)
  ) {
    const index = originHistory.commit.lastIndexOf(" from ") + 6
    sliceCommit = originHistory.commit.substring(index)

    newCommitComponent = originHistory.commit.substring(0, index)
    newFormBuild = sliceCommit
  }
  return {
    newCommit: `${newCommitComponent} ${subForm}`,
    newForm: newFormBuild,
  }
}
