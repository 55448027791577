import React from "react"
export interface GlobalPermissionProjectPageContext {
  viewOnlyShare: boolean
  archiveProject: boolean
}

export const PermissionProjectPage =
  React.createContext<GlobalPermissionProjectPageContext>({
    archiveProject: true,
    viewOnlyShare: true,
  })
