import SimpleTable, { SimpleTableProps } from "components/Table/SimpleTable"
import { UserOverviewDownloadHistory } from "../dashboard.type"
import moment from "moment"
import { downloadFile, umamiTracking } from "helpers/utils"
import { API_URL } from "config/environments"
import { EVENT } from "constants/events"

const columns: SimpleTableProps["columns"] = [
  {
    title: "Generated date",
    dataIndex: "date",
    render: (data: UserOverviewDownloadHistory) =>
      moment(data.created_at).format("DD/MM/YYYY"),
  },
  {
    title: "Link Download",
    dataIndex: "link",
    render: (data: UserOverviewDownloadHistory) => {
      if (moment().diff(moment(data.expired_at), "seconds") > 0) {
        return <p className="text-brown">The link has been expired</p>
      }
      return (
        <a
          className="text-blue cursor-pointer"
          onClick={() => {
            downloadFile(
              `${API_URL}/api/user/user-all-data/${data.id}`,
              `data_export_${new Date().toISOString()}.zip`,
              true
            )
            umamiTracking(EVENT.DOWNLOAD_QUOTA)
          }}
        >
          {API_URL}/api/user/user-all-data/{data.id}
        </a>
      )
    },
    width: "60%",
  },
  {
    title: "Expiration date",
    dataIndex: "expired_date",
    render: (data: UserOverviewDownloadHistory) => {
      if (moment().diff(moment(data.expired_at), "seconds") > 0) {
        return ""
      }
      return moment(data.expired_at).format("DD/MM/YYYY")
    },
  },
]

interface DownloadHistoryProps {
  histories?: UserOverviewDownloadHistory[]
}

const DownloadHistory = (props: DownloadHistoryProps) => {
  return (
    <SimpleTable
      displayOD
      columns={columns}
      dataSource={props.histories || []}
    />
  )
}
export default DownloadHistory
