import { ReactComponent as IconFavorite } from "assets/images/icons/icon-favorite-project.svg"
import { ReactComponent as IconFavoriteActive } from "assets/images/icons/icon-favorite.svg"
import LabelStatusBuild from "pages/project-component/molecules/LabelStatusBuild"
import { STATUS_BUILD } from "components/Status/types"
import {
  CONVERSATION_ROLE,
  ConversationDetail,
  CustomStatus,
  MENU_TAB_CONVERSATION,
} from "../conversations.type"
import { formatDateForConversationMessage } from "helpers/utils"
import {
  archiveConversationMiddleware,
  createCustomStatusMiddleware,
  deleteCustomstatusMiddleware,
  likeConversationMiddleware,
  updateCustomStatusMiddleware,
  updateCustomStatusesMiddleware,
} from "../conversations.api"
import {
  IUseDefaultValueProps,
  useBoolean,
  useNumber,
  useWindowSize,
} from "helpers/hooks"
import { useAppSelector } from "hooks/useApp"
import { toast } from "react-toastify"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import DotCard from "../atoms/DotCard"
import { TippyCustomzie } from "components/TippyCustomzie"
import ActionIconHistory from "pages/project-component-detail/molecules/ActionIconHistory"
import ActionItem from "pages/projects/molecules/ActionItem"
import ModalDelete from "components/ModalCustom/ModalDelete"
import ModalCustom from "components/ModalCustom"
import Button from "components/Button/Button"
import { ReactComponent as LeftArrowIcon } from "assets/images/icons/arrow-left.svg"
import { useContext, useState } from "react"
import FormInputCard from "pages/auth/molecules/FormInputCard"
import InputDefault from "components/Input/InputDefault"
import { isEmpty, remove } from "lodash"
import { ConversationsPageContext } from "../contexts/ConversationsPage.context"
import { Avatar } from "components/Avatar"

interface Props {
  item: ConversationDetail
  isViewBuild?: boolean
  styleRoot?: React.CSSProperties
  activeConversationId: string
  updateDataWhenChangeFavorite: (dataRes: ConversationDetail) => void
  updateDataWhenArchive: (dataRes: ConversationDetail) => void
  onClickConversation: (dataRes: ConversationDetail) => () => void
  updateDataWhenChangeCustomStatus: (
    type: "edit" | "delete",
    customStatus: CustomStatus
  ) => void
  updateDataWhenChangeConvoCustomStatus: (
    buildId: string,
    customStatus?: CustomStatus
  ) => void
  isDeleted?: boolean
  openNotes?: IUseDefaultValueProps
  activeMenu: MENU_TAB_CONVERSATION
  isAction?: boolean
}
export const renderCustomStatusColor = (color: string) => {
  if (color === "green")
    return `bg-green_opacity_015 hover:bg-green_opacity_03 text-green`
  if (color === "blue") {
    return `bg-blue_opacity_015 hover:bg-blue_opacity_03 text-blue`
  }
  if (color === "red") {
    return `bg-red_opacity_015 hover:bg-red_opacity_03 text-red`
  }
  if (color === "yellow") {
    return `bg-yellow_opacity_015 hover:bg-yellow_opacity_03 text-yellow`
  }
  if (color === "stroke") {
    return `bg-stroke_opacity_015 hover:bg-stroke_opacity_03 text-stroke`
  }
  if (color === "purple") {
    return `bg-purple_opacity_015 hover:bg-purple_opacity_03 text-purple`
  }
  if (color === "orange") {
    return `bg-orange_opacity_015 hover:bg-orange_opacity_03 text-orange`
  }
  if (color === "pink") {
    return `bg-pink_opacity_015 hover:bg-pink_opacity_03 text-pink`
  }
  return `bg-yellow_opacity_015 hover:bg-yellow_opacity_03 text-yellow`
}
const colorList = [
  { name: "red", value: "rgba(234, 69, 69, 1)" },
  { name: "orange", value: "rgba(232, 122, 22, 1)" },
  { name: "yellow", value: "rgba(247, 172, 27, 1)" },
  { name: "green", value: "rgba(86, 160, 18, 1)" },
  { name: "blue", value: "rgba(10, 90, 245, 1)" },
  { name: "purple", value: "rgba(143, 0, 255, 1)" },
  { name: "pink", value: "rgba(243, 62, 182, 1)" },
  { name: "stroke", value: "rgba(122, 122, 122, 1)" },
]
const ConversationCard = (props: Props) => {
  const {
    item,
    styleRoot,
    activeConversationId,
    updateDataWhenChangeFavorite,
    updateDataWhenArchive,
    onClickConversation,
    updateDataWhenChangeCustomStatus,
    updateDataWhenChangeConvoCustomStatus,
    isDeleted,
    openNotes,
    activeMenu,
    isAction = true,
  } = props

  const userInfo = useAppSelector((state) => state.userInfo)
  const { customStatuses, setCustomStatuses } = useContext(
    ConversationsPageContext
  )
  const isLoading = useBoolean(false)
  const showTippy = useBoolean()
  const editStatuses = useBoolean()
  const editStatus = useBoolean()
  const createStatus = useBoolean()
  const deleteStatus = useBoolean()
  const selectedStatus = useNumber(-1)
  const [status, setStatus] = useState<CustomStatus>({
    id: "",
    name: "",
    color: "",
  })
  const onChangeStatusName = (event: any) => {
    setStatus({ ...status, name: event.target.value })
  }
  const onChangeStatusColor = (color: string) => {
    setStatus({ ...status, color })
  }
  const archive =
    item.is_archived_by_me || item.is_archived_by_owner || isDeleted
  const { isMobile } = useWindowSize()
  const onChangeFavorite = (event: any) => {
    if (!isAction) return
    event.preventDefault()
    event.stopPropagation()
    if (!item.id) {
      return
    }
    isLoading.setValue(true)
    likeConversationMiddleware(item.id)
      .then((res) => {
        updateDataWhenChangeFavorite(item)
        toast(<LabelNotificationPage messenger={res.message} type="success" />)
      })
      .catch((error) => {
        toast(
          <LabelNotificationPage
            messenger={error.res?.data?.message}
            type="success"
          />
        )
      })
  }
  const onArchive = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    isLoading.setValue(true)
    archiveConversationMiddleware(item.id)
      .then((res) => {
        updateDataWhenArchive({
          ...item,
          is_archived_by_me: !item.is_archived_by_me,
        })
        toast(<LabelNotificationPage messenger={res.message} type="success" />)
      })
      .catch((error) => {
        toast(
          <LabelNotificationPage
            messenger={error.res?.data?.message}
            type="success"
          />
        )
      })
    showTippy.setValue(!showTippy.value)
    isLoading.setValue(false)
  }

  const onOpenNotes = () => {
    openNotes?.setValue(true)
    showTippy.setValue(false)
  }
  const handleClickTippy = (event: any) => {
    event.stopPropagation()
    showTippy.setValue(!showTippy.value)
  }
  const onEditStatuses = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    isLoading.setValue(true)
    editStatuses.setValue(true)
    showTippy.setValue(false)
  }
  const onSelectEditStatus = (index: number) => {
    editStatus.setValue(true)
    setStatus(customStatuses[index])
    selectedStatus.setValue(index)
  }
  const onResetState = () => {
    editStatus.setValue(false)
    setStatus({ id: "", name: "", color: "" })
    selectedStatus.setValue(-1)
    createStatus.setValue(false)
  }
  const onCloseModal = () => {
    editStatus.setValue(false)
    selectedStatus.setValue(-1)
    setStatus({ id: "", name: "", color: "" })
    createStatus.setValue(false)
    editStatuses.setValue(false)
  }
  const onUpdateStatus = (type: "create" | "edit" | "delete") => {
    if (!status.color || !status.name) {
      return
    }
    if (type === "create") {
      isLoading.setValue(true)
      createCustomStatusMiddleware(status)
        .then((res) => {
          setCustomStatuses([...customStatuses, res])
          toast(<LabelNotificationPage messenger={"success"} type="success" />)
        })
        .catch((error) => {
          toast(
            <LabelNotificationPage
              messenger={error.response?.data?.message}
              type="error"
            />
          )
        })
      isLoading.setValue(false)
      onResetState()
    }
    if (type === "edit") {
      customStatuses[selectedStatus.value] = status
      updateCustomStatusesMiddleware(status)
        .then((res) => {
          setCustomStatuses([...customStatuses])
          updateDataWhenChangeCustomStatus("edit", status)
          toast(
            <LabelNotificationPage messenger={res.message} type="success" />
          )
        })
        .catch((error) => {
          toast(
            <LabelNotificationPage
              messenger={error.response?.data?.message}
              type="error"
            />
          )
        })
      isLoading.setValue(false)
      onResetState()
    }
    if (type === "delete") {
      isLoading.setValue(true)
      deleteCustomstatusMiddleware(status)
        .then((res) => {
          remove(customStatuses, (el) => el.id === status.id)
          setCustomStatuses([...customStatuses])
          updateDataWhenChangeCustomStatus("delete", status)
          toast(
            <LabelNotificationPage messenger={res.message} type="success" />
          )
        })
        .catch((error) => {
          toast(
            <LabelNotificationPage
              messenger={error.response?.data?.message}
              type="error"
            />
          )
        })
      isLoading.setValue(false)
      onResetState()
    }
  }
  const onUpdateStatusForConversation = (customStatus?: CustomStatus) => {
    isLoading.setValue(true)
    updateCustomStatusMiddleware(item.id, customStatus)
      .then((res) => {
        updateDataWhenChangeConvoCustomStatus(item.id, customStatus)
        toast(<LabelNotificationPage messenger={res.message} type="success" />)
      })
      .catch((error) => {
        toast(
          <LabelNotificationPage
            messenger={error.response?.data?.message}
            type="error"
          />
        )
      })
    isLoading.setValue(false)
  }
  const renderNextModal = (type: "edit" | "create") => {
    return (
      <ModalCustom
        handleChangeButton={() => onCloseModal}
        renderHeader={
          <div className="flex gap-2 items-center">
            <div
              className="flex justify-center items-center h-26-custom w-26-custom hover-button-modal cursor-pointer"
              onClick={onResetState}
            >
              <LeftArrowIcon />
            </div>
            <p className="text-base font-semibold text-black">
              {type === "edit" ? "Edit" : "Create status"}
            </p>
          </div>
        }
        bodyChildren={
          <div className="flex flex-col items-center">
            <div className="">
              <FormInputCard
                title="Status"
                required
                fontWeightText="font-semibold"
              >
                <InputDefault
                  value={status.name}
                  onChange={onChangeStatusName}
                />
              </FormInputCard>
              <p className="text-sm font-semibold text-black">Colors</p>
              <div className="grid grid-cols-4  grid-flow-row gap-2">
                {colorList.map((color) => {
                  return (
                    <div
                      className={`m-1 h-[56px] w-[83px] p-1 rounded-4 ${
                        color.name === status.color
                          ? "active-project-build"
                          : "hover-project-card"
                      }`}
                      onClick={() => {
                        onChangeStatusColor(color.name)
                      }}
                    >
                      <div
                        className="h-full w-full rounded-4 "
                        style={{ backgroundColor: color.value }}
                      ></div>
                    </div>
                  )
                })}
              </div>
            </div>

            <div className="flex items-center mt-6 content-center">
              {type === "edit" && (
                <Button
                  title="Delete"
                  colorBtn="white"
                  onClick={() => {
                    deleteStatus.setValue(true)
                  }}
                />
              )}
              <Button
                title={`${type === "edit" ? "Save" : "Create"}`}
                onClick={() => {
                  onUpdateStatus(type)
                }}
              />
            </div>
          </div>
        }
      />
    )
  }

  return (
    <div
      onClick={onClickConversation(item)}
      className={`cursor-pointer flex flex-col gap-[10px] p-3 mb-3 relative ${
        activeConversationId === item.id
          ? "active-project-build"
          : "hover-project-card"
      } ${isDeleted ? "bg-delete-element" : ""} ${
        archive ? "bg-neutral3" : "bg-white"
      } ${item.is_hidden ? "hidden" : ""}`}
      style={{
        border: "1px solid #E4E4E4",
        borderRadius: 6,
        ...styleRoot,
      }}
    >
      {!item.is_read && (
        <div className="absolute top-[6px] left-0 ">
          <DotCard
            styleRoot={{
              height: 6,
              width: 6,
              backgroundColor: "#EA4545",
            }}
          />
        </div>
      )}

      <div
        className="flex items-center justify-between"
        style={{
          height: 24,
        }}
      >
        <div className="flex items-center overflow-auto">
          {item.is_favorite ? (
            <IconFavoriteActive onClick={onChangeFavorite} />
          ) : (
            <IconFavorite onClick={onChangeFavorite} />
          )}
          <div className="flex items-center">
            <p
              className="text-14 leading-24 color-black-111111 font-semibold ml-2"
              style={{
                whiteSpace: "nowrap",
                color: archive ? " #C7C7C7" : "#111111",
              }}
            >
              {item.code}
            </p>
            <DotCard
              isDisabled={archive}
              styleRoot={{ marginLeft: 4, marginRight: 4 }}
            />
            <LabelStatusBuild
              status={item.project_build_status || STATUS_BUILD.DRAFT}
              rootStyle={{
                fontSize: 12,
                lineHeight: "18px",
              }}
              isArchive={archive}
            />
          </div>
        </div>

        <div className="flex items-center justify-between">
          {isDeleted ||
          !isAction ||
          activeMenu === MENU_TAB_CONVERSATION.FROM_ME ||
          activeMenu === MENU_TAB_CONVERSATION.OTHERS ? null : (
            <TippyCustomzie
              containerClass="component-card-menu reset-tippy"
              placement="bottom-end"
              interactive
              arrow={false}
              animation="scale"
              visible={showTippy.value}
              onClickOutside={() => showTippy.setValue(false)}
              content={
                <div
                  style={{
                    background: "#222222",
                    borderRadius: 4,
                    paddingTop: 8,
                    paddingBottom: 8,
                    width: 165,
                  }}
                >
                  {!item.is_archived_by_owner && (
                    <ActionItem
                      title={!archive ? "Archive" : "Unarchive"}
                      onClick={onArchive}
                    />
                  )}
                  <>
                    <ActionItem
                      title={"Edit status"}
                      onClick={onEditStatuses}
                    />
                    <ActionItem title={"View note"} onClick={onOpenNotes} />
                  </>
                </div>
              }
              allowHTML
            >
              <div
                className="flex items-center w-[30px] h-[30px] cursor-pointer"
                onClick={handleClickTippy}
              >
                <ActionIconHistory
                  icon="three-dots"
                  tooltip="Action"
                  styleIcon={{
                    marginRight: 0,
                  }}
                />
              </div>
            </TippyCustomzie>
          )}
        </div>
      </div>
      {/*  user start*/}
      <div className="flex items-center gap-2">
        <Avatar
          name={item.owner.name}
          avatar={item.owner.avatar}
          width="24px"
          height="24px"
        />
        <div className="flex flex-col w-full gap-1 overflow-hidden whitespace-nowrap">
          <p
            className="font-semibold text-14 overflow-hidden text-ellipsis"
            style={{
              color: archive ? " #C7C7C7" : "black",
            }}
          >
            {activeMenu === MENU_TAB_CONVERSATION.FROM_ME
              ? item.invitee.name
              : item.owner.name}
          </p>
          <p
            className="text-12 leading-18 color-gray-7a font-medium"
            style={{
              color: archive ? " #C7C7C7" : "#7a7a7a",
            }}
          >
            {activeMenu === MENU_TAB_CONVERSATION.FROM_ME
              ? item.invitee.email
              : item.owner.email}
          </p>
        </div>
      </div>
      {/* last message */}
      {item.draftMessage || item.draftAttachments ? (
        <p
          className="flex gap-1 text-12 leading-18 max-h-4 font-normal overflow-hidden text-ellipsis whitespace-nowrap pointer-events-none"
          style={{
            color: archive ? " #C7C7C7" : "#7a7a7a",
          }}
        >
          <span className="text-red font-medium">Draft : </span>{" "}
          {item.draftMessage ? (
            <p
              className="overflow-hidden text-ellipsis"
              dangerouslySetInnerHTML={{
                __html: item.draftMessage,
              }}
            ></p>
          ) : (
            <span>{`${item.draftAttachments} attached ${
              item.draftAttachments > 1 ? "files" : "file"
            }`}</span>
          )}
        </p>
      ) : (
        <p
          className="text-12 leading-18 max-h-4 font-normal overflow-hidden text-ellipsis whitespace-nowrap pointer-events-none"
          style={{
            color: archive ? " #C7C7C7" : "#7a7a7a",
          }}
          dangerouslySetInnerHTML={{
            __html: item.message.content,
          }}
        ></p>
      )}

      {/* list components */}
      {item.components && item.components.length !== 0 && (
        <div className="flex items-center flex-wrap">
          {[...new Set(item.components.map((child) => child.type_key))].map(
            (component, index) => (
              <div
                key={index}
                className="px-1 flex items-center mr-1 mb-1"
                style={{
                  background: archive ? "#F1F1F1" : "#F7F7F7",
                  border: "1px solid #E4E4E4",
                  borderRadius: 4,
                  height: 20,
                }}
              >
                <p
                  className="font-semibold"
                  style={{
                    fontSize: 10,
                    lineHeight: "14px",
                    color: archive ? " #C7C7C7" : "#7a7a7a",
                  }}
                >
                  {component}
                </p>
              </div>
            )
          )}
        </div>
      )}
      <div
        className="flex items-center justify-between"
        style={{
          height: 24,
          minHeight: 24,
        }}
      >
        <p
          className="text-12 leading-18 font-medium"
          style={{
            color: archive ? " #C7C7C7" : "#7a7a7a",
          }}
        >
          {isDeleted
            ? "No update"
            : `${formatDateForConversationMessage(item.updated_at)}`}
        </p>

        {/* custom status */}
        {item.custom_status && item.custom_status.id && (
          <div className="flex items-center">
            <div
              className={`px-2 py-1 rounded-[20px] min-w-[70px] max-h-6 flex items-center justify-center text-xs ${renderCustomStatusColor(
                item.custom_status.color
              )}`}
            >
              <p className=" flex justify-center w-full h-full overflow-x-hidden overflow-ellipsis whitespace-nowrap ">
                {item.custom_status.name}
              </p>
            </div>
          </div>
        )}
      </div>
      {/* custom status */}

      {/* modal status */}
      {editStatuses.value && (
        <ModalCustom
          label="Conversation status"
          handleChangeButton={() => () => {
            editStatuses.setValue(false)
          }}
          bodyChildren={
            <div>
              <div className="flex flex-col gap-3 max-h-[218px] overflow-y-auto">
                {customStatuses && customStatuses.length === 0 ? (
                  <div className=" flex items-center justify-center h-[82px] font-normal text-sm text-black">
                    No status to select
                  </div>
                ) : (
                  customStatuses.map((customStatus, index) => {
                    return (
                      <div className="flex justify-between items-center  ">
                        <div
                          className={`w-[300px] rounded-[20px] ${renderCustomStatusColor(
                            customStatus.color
                          )} `}
                          onClick={() => {
                            onUpdateStatusForConversation(customStatus)
                            onCloseModal()
                          }}
                        >
                          <p className="my-2 ml-3 overflow-x-hidden overflow-ellipsis whitespace-nowrap">
                            {" "}
                            {customStatus.name}
                          </p>
                        </div>
                        <ActionIconHistory
                          icon="edit"
                          onClick={() => onSelectEditStatus(index)}
                        />
                      </div>
                    )
                  })
                )}
              </div>

              <div className="flex items-center justify-between mt-6">
                <Button
                  title="Remove status"
                  colorBtn="white"
                  onClick={() => {
                    onUpdateStatusForConversation()
                    onCloseModal()
                  }}
                />
                <Button
                  title="Create new status"
                  onClick={() => {
                    createStatus.setValue(true)
                  }}
                />
              </div>
            </div>
          }
        />
      )}
      {createStatus.value && renderNextModal("create")}
      {selectedStatus.value >= 0 && renderNextModal("edit")}
      {deleteStatus.value && (
        <ModalDelete
          onCloseModal={() => deleteStatus.setValue(false)}
          title={""}
          content={`When action is confirmed, this status will be removed from all Build cards. This action can not undo.
          Press "Delete" to process.`}
          label={`Warning`}
          onSubmit={() => {
            onUpdateStatus("delete")
            deleteStatus.setValue(false)
          }}
          titleButton="Delete"
          contentWrapperStyles={{
            paddingTop: 10,
            paddingBottom: 36,
            textAlign: "center",
          }}
        />
      )}
    </div>
  )
}
export default ConversationCard
