import { useEffect } from "react"
import { useBoolean } from "helpers/hooks"
import { postComponentHistoryCreateAndCopyMiddleware } from "pages/project-component-detail/services"
import { STATUS_RESPONSE } from "types"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { toast } from "react-toastify"
import { DataErrorWhenEditHistoryTree } from "pages/project-component-detail/types"
import FormLoading from "components/FormLoading/FormLoading"
import { inviteeCreateDraft } from "pages/conversations/organisms/invitees-component/services/invitee.api"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
interface Props {
  onCloseModal: () => void
  openModal: boolean
  idBuildComponent: string
  conversationId: string
  handleUpdateDataWhenCreate: () => void
  label: string
  isChangeVersionToDraft?: boolean
}

const FormCreateForInvitee = (props: Props) => {
  const {
    onCloseModal,
    idBuildComponent,
    handleUpdateDataWhenCreate,
    openModal,
    conversationId,
    label,
    isChangeVersionToDraft,
  } = props
  const isLoading = useBoolean()
  useEffect(() => {
    if (!openModal) {
      return
    }
    onSubmit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal])

  const onSubmit = () => {
    if (!idBuildComponent) {
      return
    }
    isLoading.setValue(true)
    inviteeCreateDraft(idBuildComponent, conversationId)
      .then((res) => {
        toast(
          <LabelNotificationPage
            messenger={
              isChangeVersionToDraft
                ? MESSENGER_NOTIFICATION.CHANGE_VERSION_TO_DRAFT_SUCCESS
                : MESSENGER_NOTIFICATION.POST_BUILD_HISTORY_SUCCESS
            }
            type={"success"}
          />
        )
        handleUpdateDataWhenCreate()
      })
      .catch((error) => {
        toast(
          <LabelNotificationPage
            messenger={
              error.response.data?.message ||
              (isChangeVersionToDraft
                ? MESSENGER_NOTIFICATION.CHANGE_VERSION_TO_DRAFT_SUCCESS
                : MESSENGER_NOTIFICATION.POST_BUILD_HISTORY_ERROR)
            }
            type={"error"}
          />
        )
      })
      .finally(() => {
        onCloseModal()
      })
    isLoading.setValue(false)
  }

  return <FormLoading label={label} />
}

export default FormCreateForInvitee
