import React, { useContext } from "react"
import SelectDefault from "components/Select/SelectDefault"
import { SelectSpecificationProps } from "../types"
import { VersionSpecificationContext } from "../contexts/VersionSpecification.context"
import { fieldFormDataSpecification } from "helpers/utils"

const SelectSpecification = (props: SelectSpecificationProps) => {
  const context = useContext(VersionSpecificationContext)

  const { item, disabled = false } = props
  const newAnswers =
    item.answers && item.answers.length
      ? item.answers.map((el) => {
          return {
            ...el,
            label: el.name,
            value: el.id,
          }
        })
      : []

  const handleChangeSelect = (valueSelect) => {
    context?.updateDataForm(
      "formData",
      fieldFormDataSpecification(item.id, item.sequence),
      valueSelect.id,
      Boolean(item.required)
    )
  }
  return (
    <div className="flex mb-4">
      <SelectDefault
        isDisabled={disabled}
        options={newAnswers}
        selectedOption={newAnswers.find(
          (el) =>
            el.id ===
            context?.formData[
              fieldFormDataSpecification(item.id, item.sequence)
            ]
        )}
        handleChange={handleChangeSelect}
        filed="name"
        rootClasses="h-10 rounded border-select flex items-center "
        controlWidth={300}
      />
    </div>
  )
}

export default SelectSpecification
