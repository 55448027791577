import { ReactComponent as IconEdit } from "assets/images/icons/icon-edit-history.svg"
import { ReactComponent as IconRevert } from "assets/images/icons/icon-revert-history.svg"
import { ReactComponent as IconTrascend } from "assets/images/icons/icon-trascend-history.svg"
import { ReactComponent as IconDownload } from "assets/images/icons/icon-download-history.svg"
import { ReactComponent as IconMessage } from "assets/images/icons/icon-message-history.svg"
import { ReactComponent as IconCommit } from "assets/images/icons/icon-commit-history.svg"
import { ReactComponent as IconSystemData } from "assets/images/icons/icon-system-data.svg"
import { ReactComponent as IconActionCloseModal } from "assets/images/icons/icon-action-close-modal.svg"
import { ReactComponent as IconActionCopyLink } from "assets/images/icons/icon-copy-link.svg"
import { ReactComponent as IconActionThreeDot } from "assets/images/icons/icon-three-dots.svg"
import { ReactComponent as IconActionConversation } from "assets/images/icons/icon-action-conversation.svg"
import { ReactComponent as IconActionCloseBlack } from "assets/images/icons/icon-close-black.svg"
import { ReactComponent as IconSearch } from "assets/images/icons/icon-search-conversation.svg"
import { ReactComponent as IconFilter } from "assets/images/icons/icon-filter-conversation.svg"
import { ReactComponent as IconMerge } from "assets/images/icons/icon-merge-history.svg"
import { ReactComponent as IconNotes } from "assets/images/icons/notes-icon.svg"
import { twMerge } from "tailwind-merge"
import { ReactComponent as IconResolve } from "assets/images/icons/icon-resolve-comment.svg"
import { ReactComponent as IconUnapprove } from "assets/images/icons/unapprove-icon.svg"
import Tippy from "@tippyjs/react"
import { MouseEventHandler } from "react"
import { ReactComponent as IconSetting } from "assets/images/icons/icon-settings.svg"

interface Props {
  icon:
    | "commit"
    | "edit"
    | "revert"
    | "transcend"
    | "download"
    | "message"
    | "system-data"
    | "close-modal"
    | "copy-link"
    | "three-dots"
    | "conversation"
    | "close-black"
    | "search"
    | "filter"
    | "merge"
    | "note"
    | "approve"
    | "setting"
    | "unapprove"

  tooltip?: string
  styleIcon?: React.CSSProperties
  customStyleIcon?: React.CSSProperties
  disabled?: boolean
  classIcon?: string
  onClick?: MouseEventHandler
  classActiveIcon?: string
}
const ActionIconHistory = (props: Props) => {
  const {
    icon,
    tooltip,
    styleIcon,
    customStyleIcon,
    disabled,
    onClick,
    classIcon,
    classActiveIcon,
  } = props

  const iconStyles = twMerge("cursor-pointer", classIcon)

  const renderIcon = () => {
    switch (icon) {
      case "commit":
        return (
          <IconCommit
            style={{
              ...customStyleIcon,
            }}
            className={iconStyles}
            onClick={onClick}
          />
        )
      case "edit":
        return (
          <IconEdit
            style={{
              ...customStyleIcon,
            }}
            className={iconStyles}
            onClick={onClick}
          />
        )
      case "revert":
        return (
          <IconRevert
            style={{
              ...customStyleIcon,
            }}
            className={iconStyles}
            onClick={onClick}
          />
        )
      case "transcend":
        return (
          <IconTrascend
            style={{
              ...customStyleIcon,
            }}
            className={iconStyles}
            onClick={onClick}
          />
        )
      case "download":
        return (
          <IconDownload
            style={{
              ...customStyleIcon,
            }}
            className={iconStyles}
            onClick={onClick}
          />
        )
      case "system-data":
        return (
          <IconSystemData
            style={{
              ...customStyleIcon,
            }}
            className={iconStyles}
            onClick={onClick}
          />
        )
      case "close-modal":
        return (
          <IconActionCloseModal
            style={{
              ...customStyleIcon,
            }}
            className={iconStyles}
            onClick={onClick}
          />
        )
      case "copy-link":
        return (
          <IconActionCopyLink
            style={{
              ...customStyleIcon,
            }}
            className={iconStyles}
            onClick={onClick}
          />
        )
      case "three-dots":
        return (
          <IconActionThreeDot
            style={{
              ...customStyleIcon,
            }}
            className={iconStyles}
            onClick={onClick}
          />
        )
      case "conversation":
        return (
          <IconActionConversation
            style={{
              ...customStyleIcon,
            }}
            className={iconStyles}
            onClick={onClick}
          />
        )
      case "close-black":
        return (
          <IconActionCloseBlack
            style={{
              ...customStyleIcon,
            }}
            className={iconStyles}
            onClick={onClick}
          />
        )
      case "search":
        return (
          <IconSearch
            style={{
              ...customStyleIcon,
            }}
            className={iconStyles}
            onClick={onClick}
          />
        )
      case "filter":
        return (
          <IconFilter
            style={{
              ...customStyleIcon,
            }}
            className={iconStyles}
            onClick={onClick}
          />
        )
      case "merge":
        return (
          <IconMerge
            style={{
              ...customStyleIcon,
            }}
            className={iconStyles}
            onClick={onClick}
          />
        )
      case "note":
        return (
          <IconNotes
            style={{
              ...customStyleIcon,
            }}
            className={iconStyles}
            onClick={onClick}
          />
        )
      case "approve":
        return (
          <IconResolve
            style={{
              ...customStyleIcon,
            }}
            className={iconStyles}
            onClick={onClick}
          />
        )
      case "setting":
        return (
          <IconSetting
            style={{
              ...customStyleIcon,
            }}
            className={iconStyles}
            onClick={onClick}
          />
        )
      case "unapprove":
        return (
          <IconUnapprove
            style={{
              ...customStyleIcon,
            }}
            className={iconStyles}
            onClick={onClick}
          />
        )

      default:
        return (
          <IconMessage
            style={{
              ...customStyleIcon,
            }}
            onClick={onClick}
            className={iconStyles}
          />
        )
    }
  }
  return (
    <Tippy
      className="custom-tippy-menu-left"
      placement="top"
      content={
        tooltip && (
          <span
            className="hidden md:block font-normal"
            style={{
              fontSize: 13,
              lineHeight: "22px",
            }}
          >
            {tooltip}
          </span>
        )
      }
    >
      <div
        style={{
          marginRight: 14,
          ...styleIcon,
        }}
        className={twMerge(
          `${disabled ? "disabled-icon-history" : "hover-action-icon"}`,
          classActiveIcon
        )}
      >
        {renderIcon()}
      </div>
    </Tippy>
  )
}
export default ActionIconHistory
