import TabItem from "components/Tab/TabItem"
import { MENU_TAB_BUILD } from "pages/project-build/project-build.type"
import { SEARCH_TAB } from "../types"
interface Props {
  onChangeTab: (index: number, label?: string) => () => void
  activeTab: string
  count?: {
    projects: number
    components: number
    builds: number
    conversations: number
  }
}
const ProjectSearchTab = (props: Props) => {
  const {
    activeTab,
    onChangeTab,
    count = {
      projects: 2,
      builds: 3,
      components: 4,
      conversations: 1,
    },
  } = props
  const styleTab = () => {
    return {
      height: "auto",
      borderBottom: "none",
      display: "flex",
      alignItems: "center",
    }
  }
  return (
    <div className="h-6 md:h-12 my-5 md:my-0 w-full flex items-center justify-normal overflow-x-auto overflow-y-hidden whitespace-nowrap">
      <TabItem
        index={0}
        label={SEARCH_TAB.PROJECT}
        count={count.projects}
        active={activeTab === SEARCH_TAB.PROJECT}
        handleClickTabItem={onChangeTab}
        styleTabRoot={styleTab()}
      />
      <TabItem
        index={1}
        label={SEARCH_TAB.COMPONENT}
        count={count.components}
        active={activeTab === SEARCH_TAB.COMPONENT}
        handleClickTabItem={onChangeTab}
        styleTabRoot={styleTab()}
      />
      <TabItem
        index={2}
        label={SEARCH_TAB.BUILD}
        count={count.builds}
        active={activeTab === SEARCH_TAB.BUILD}
        handleClickTabItem={onChangeTab}
        styleTabRoot={styleTab()}
      />
      <TabItem
        index={3}
        label={SEARCH_TAB.CONVERSATION}
        active={activeTab === SEARCH_TAB.CONVERSATION}
        handleClickTabItem={onChangeTab}
        count={count.conversations}
        styleTabRoot={styleTab()}
        // isRead={conversation.isRead}
      />
    </div>
  )
}
export default ProjectSearchTab
