import IconCheckCircleSuccess from "assets/images/icons/icon-check-circle-success.svg"
import { pushTo } from "helpers/history"
import { PATH } from "constants/path"
import PageLayoutAuth from "./PageLayoutAuth"
import Button from "components/Button/Button"

const PasswordResetSuccess = () => {
  return (
    <PageLayoutAuth>
      <div className="h-full w-full justify-center items-center flex flex-col p-6">
        <img
          src={IconCheckCircleSuccess}
          alt="icon"
          style={{
            height: 96,
          }}
        />
        <p
          className="font-bold mt-6 mb-1"
          style={{
            fontSize: 20,
            lineHeight: "32px",
            color: "#111111",
          }}
        >
          Password updated!
        </p>
        <p
          className="font-normal mb-12"
          style={{
            fontSize: 14,
            lineHeight: "24px",
            color: "#111111",
          }}
        >
          Your password have been updated!
        </p>
        <Button
          title="Login"
          onClick={() => pushTo(PATH.login)}
          widthBtn="100%"
        />
      </div>
    </PageLayoutAuth>
  )
}

export default PasswordResetSuccess
