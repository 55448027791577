import { useCallback, useEffect, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import { pushTo } from "helpers/history"
import { PATH } from "constants/path"
import { useBoolean, useString } from "helpers/hooks"
import {
  getBuildComponentDetailMiddleware,
  getProjectComponentDetailMiddleware,
} from "pages/project-component/services/api"
import {
  ProjectComponentDetail,
  defaultProjectComponentDetail,
} from "pages/project-component/types"
import {
  dataEditorDefault,
  FileUrlProp,
  BuildHistoryDetail,
  defaultBuildHistoryDetail,
} from "pages/project-component-detail/types"
import {
  getHistoriesBuildComponentConvoMiddleware,
  getIsReadBuildComponentMiddleware,
  getProjectComponentHistoryMiddleware,
  getProjectComponentOtherMiddleware,
} from "pages/project-component-detail/services"
import AttachmentOtherCard from "pages/project-component-detail/organisms/AttachmentOtherCard"
import { TYPE_PROJECT_COMPONENT } from "pages/project-component/project-component.constant"
import { cloneDeep, debounce, includes, isEmpty, remove } from "lodash"
import { findHistoryTreeDefault, parseDraftToHtml } from "helpers/utils"
import { useAppSelector } from "hooks/useApp"
import {
  getConversationMessagesMiddleware,
  getConversationRoleMiddleWare,
} from "pages/conversations/conversations.api"
import { useInviteeComponent } from "./invitees-component.hook"
import { getProjectDetailMiddleware } from "pages/projects/services/api"
import PageInviteeComponent from "./PageInviteeComponent"
import {
  STATUS_BUILD,
  ProjectComponentStatus,
  BuildStatusNumber,
} from "components/Status/types"
import HistoryTreeCard from "components/HistoryTree/HistoryTreeCard"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { STATUS_RESPONSE } from "types"
import { toast } from "react-toastify"
import { postInviteeTabContentMiddleware } from "./services/invitee.api"
import { InputEditorDefault } from "components/Input/InputEditorDefault"
import { CONVERSATION_ROLE } from "pages/conversations/conversations.type"
import InputEditorDefault2 from "components/Input/InputEditorDefault2"

const InviteeOtherComponent = () => {
  const params = useParams<{
    idProjectComponent: string
    idProjectBuildComponent: string
    idProjectComponentHistory: string
    idConversation: string
  }>()

  const locationInviteeComponentOther = useLocation<any>()

  const userInfo = useAppSelector((state) => state.userInfo)
  const isLoading = useBoolean()
  const [componentDetail, setComponentDetail] =
    useState<ProjectComponentDetail>(defaultProjectComponentDetail)
  const [dataEditor, setDataEditor] = useState<string>("")
  const updatedAtProjectComponent = useString("")
  const [listFileUrl, setListFileUrl] = useState<FileUrlProp[]>([])
  const versionHistory = useString("")
  const [historyDetail, setHistoryDetail] = useState<BuildHistoryDetail>(
    defaultBuildHistoryDetail
  )
  const [histories, setHistories] = useState<BuildHistoryDetail[]>([])
  const maxFile = 50
  const {
    conversationMessages,
    idConversationParam,
    idProjectBuildComponentParam,
    idProjectComponentHistoryParam,
    idProjectComponentParam,
    isReadComponent,
    nameBOM,
    projectBuild,
    projectDetail,
    setProjectDetail,
    setConversationMessages,
    setProjectBuild,
    idComponentHistory,
    conversationRole,
    setConversationRole,
  } = useInviteeComponent(params)

  const viewOnly =
    includes(
      [STATUS_BUILD.COMMITTED, STATUS_BUILD.MERGED, STATUS_BUILD.SAVE],
      historyDetail.status
    ) || conversationRole !== CONVERSATION_ROLE.INVITEE
  const isDeleted =
    projectBuild.status === STATUS_BUILD.DELETED ||
    componentDetail.status === ProjectComponentStatus.DeletedAttachment ||
    historyDetail?.build_status === BuildStatusNumber.DELETED_ATTACHMENT

  useEffect(() => {
    const isCallAPI =
      !idProjectComponentParam ||
      !idProjectBuildComponentParam ||
      !idConversationParam

    if (isCallAPI) {
      pushTo(PATH.conversations)
      return
    }
    getProjectComponent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    idProjectComponentParam,
    idProjectBuildComponentParam,
    idConversationParam,
  ])

  const getProjectComponent = async () => {
    isLoading.setValue(true)
    try {
      const dataComponentRes = await getProjectComponentDetailMiddleware(
        idProjectComponentParam,
        idConversationParam
      )
      setComponentDetail(dataComponentRes)
      if (
        !includes(
          [
            TYPE_PROJECT_COMPONENT.FIRMWARE,
            TYPE_PROJECT_COMPONENT.MECHANICAL,
            TYPE_PROJECT_COMPONENT.MISCELLANEOUS,
            TYPE_PROJECT_COMPONENT.SOFTWARE,
          ],
          dataComponentRes.type.key
        )
      ) {
        pushTo(PATH.conversations)
        return
      }
      await getConversationRole()
      await getHistories(dataComponentRes.type.key)
      await getConversationMessages(idConversationParam)
      await getProjectBuildComponent(idProjectBuildComponentParam)
      await getIsReadProjectBuildComponent(idProjectBuildComponentParam)
      updatedAtProjectComponent.setValue(dataComponentRes.updated_at)
      nameBOM.setValue(dataComponentRes.name.replace(" ", "_"))

      isLoading.setValue(false)
    } catch (error) {
      isLoading.setValue(false)
      pushTo(PATH.conversations)
    }
  }
  const getConversationRole = async () => {
    const role = await getConversationRoleMiddleWare(
      idProjectBuildComponentParam,
      idConversationParam
    )
    setConversationRole(role.role)
  }
  const getComponentHistory = async (originIdHistory: string) => {
    const dataComponentHistoryRes = await getProjectComponentHistoryMiddleware(
      originIdHistory,
      idConversationParam
    )
    if (dataComponentHistoryRes.code) {
      versionHistory.setValue(dataComponentHistoryRes.code)
    }
    setHistoryDetail(dataComponentHistoryRes)
  }

  const getProjectBuildComponent = async (idProjectBuildComponent: string) => {
    const dataRes = await getBuildComponentDetailMiddleware(
      idProjectBuildComponent,
      idConversationParam
    )
    setProjectBuild(dataRes)
    if (
      locationInviteeComponentOther.state &&
      Boolean(locationInviteeComponentOther?.state?.breadcrumpProject)
    ) {
      const dataProjectDetailRes = await getProjectDetailMiddleware(
        dataRes.project_id,
        idConversationParam
      )
      setProjectDetail(dataProjectDetailRes)
    }
  }
  const getHistories = async (
    oldType: string,
    loading = false,
    findItemCommit = true
  ) => {
    try {
      const dataRes = await getHistoriesBuildComponentConvoMiddleware(
        idProjectBuildComponentParam,
        idConversationParam
      )
      setHistories(dataRes)

      if (dataRes.length) {
        await saveDataHistoryDetail(dataRes, oldType, findItemCommit)
      }
      if (loading) {
        isLoading.setValue(false)
      }
    } catch (error) {
      if (loading) {
        isLoading.setValue(false)
      }
    }
  }
  const saveDataHistoryDetail = async (
    newDataRes: BuildHistoryDetail[],
    oldType: string,
    findItemCommit = true
  ) => {
    const { newHistoryDetail } = findHistoryTreeDefault(
      newDataRes,
      findItemCommit,
      locationInviteeComponentOther
    )
    if (newHistoryDetail.code) {
      versionHistory.setValue(newHistoryDetail.code)
    }
    idComponentHistory.setValue(newHistoryDetail.id)
    setHistoryDetail(newHistoryDetail)
    await getDataInType(newHistoryDetail.id, oldType)
  }
  const getIsReadProjectBuildComponent = async (
    oldIdProjectBuildComponent: string
  ) => {
    const dataRes = await getIsReadBuildComponentMiddleware(
      oldIdProjectBuildComponent,
      idConversationParam
    )
    isReadComponent.setValue(dataRes.is_read_invitee_comment)
  }

  const getDataInType = useCallback(
    async (oldIdHistory: string, oldType: string, loading = false) => {
      try {
        const dataRes = await getProjectComponentOtherMiddleware(
          oldIdHistory,
          oldType.toLocaleLowerCase(),
          idConversationParam
        )
          setDataEditor(dataRes.description || "")
        setListFileUrl(dataRes.files)
        if (loading) {
          isLoading.setValue(false)
        }
      } catch (error) {
        if (loading) {
          setDataEditor("")
          setListFileUrl([])
          isLoading.setValue(false)
        }
      }
    },
    [isLoading]
  )
  const handleChangeHistoryDetail = useCallback(
    (newHistoryDetail: BuildHistoryDetail) => () => {
      if (newHistoryDetail.id === historyDetail.id) {
        return
      }
      isLoading.setValue(true)
      setHistoryDetail(newHistoryDetail)
      versionHistory.setValue(newHistoryDetail.code)
      idComponentHistory.setValue(newHistoryDetail.id)
      getDataInType(
        newHistoryDetail.id,
        componentDetail.type.key.toLocaleLowerCase(),
        true
      )
    },
    [idComponentHistory.value, isLoading]
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // [idComponentHistory, isLoading, getBOM, isViewLinkedBuild, historyDetail]
  )
  const getConversationMessages = async (
    conversationId: string,
    loading = false
  ) => {
    const dataRes = await getConversationMessagesMiddleware(conversationId)
    setConversationMessages(dataRes)
    if (loading) {
      isLoading.setValue(false)
    }
  }

  const onSubmitAdditional = async (description: string, type: string) => {
    if (historyDetail.status !== "Draft" || !historyDetail.id) {
      return
    }
    isLoading.setValue(true)
    try {
      const formData = new FormData()
      formData.append("description", description)
      await postInviteeTabContentMiddleware(
        historyDetail.id,
        idConversationParam,
        type,
        formData
      )
    } catch (error: any) {
      handleUpdateDataTypeErrorWhenCallAPI(error)
    }
  }
  const debouceOnSubmitAdditionalMessage = useCallback(
    debounce(onSubmitAdditional, 500),
    [historyDetail.id]
  )

  const handleUpdateDataWhenDeleteFile = (oldIdFile: string) => {
    const newFileURls = cloneDeep(listFileUrl)
    remove(newFileURls, (el) => el.id === oldIdFile)
    setListFileUrl(newFileURls)
    // updatedAtComponentDetail(new Date().toISOString())
  }
  const handleUpdateDataWhenAddFile = async (
    fileRequest: FormData,
    fileErrors: File[]
  ) => {
    if (!idComponentHistory.value) {
      return
    }
    isLoading.setValue(true)
    try {
      fileErrors.forEach((fileError) => {
        toast(
          <LabelNotificationPage
            messenger={`File ${fileError.name} wrong format! Files allowed: rar,zip,7z`}
            type="warning"
          />
        )
      })
      await postInviteeTabContentMiddleware(
        idComponentHistory.value,
        idConversationParam,
        componentDetail.type.key.toLocaleLowerCase(),
        fileRequest
      )
      await handleUpdateDataTypeSuccessWhenCallAPI()
    } catch (error: any) {
      handleUpdateDataTypeErrorWhenCallAPI(error)
    }
  }
  const handleUpdateDataTypeSuccessWhenCallAPI = async () => {
    await getDataInType(
      idComponentHistory.value,
      componentDetail.type.key.toLocaleLowerCase(),
      true
    )
    toast(
      <LabelNotificationPage
        messenger={`Update ${componentDetail.type.key} successfully!`}
        type={STATUS_RESPONSE.SUCCESS}
      />
    )
    // updatedAtComponentDetail(new Date().toISOString())
  }
  const handleUpdateDataTypeErrorWhenCallAPI = async (error: any) => {
    let message = error?.response?.data?.message
    if (error.response.status === 413 && !message) {
      message = `The upload of file larger than ${maxFile}MB is not allowed`
    }
    toast(
      <LabelNotificationPage
        messenger={message || `Update ${componentDetail.type.key} failed!`}
        type={STATUS_RESPONSE.ERROR}
      />
    )
    isLoading.setValue(false)
  }
  const onUpdateDataEdit = (item: ProjectComponentDetail) => {
    setComponentDetail(item)
  }

  const handleUpdateDataWhenChangeHistory = (getFirst = false) => {
    isLoading.setValue(true)
    getHistories(componentDetail.type.key, true, getFirst)
    // updatedAtComponentDetail(new Date().toISOString())
  }
  return (
    <PageInviteeComponent
      componentDetail={componentDetail}
      updatedAtProjectComponent={updatedAtProjectComponent.value}
      projectBuild={projectBuild}
      idConversationParam={idConversationParam}
      idProjectBuildComponentParam={idProjectBuildComponentParam}
      conversationMessages={conversationMessages}
      projectDetail={projectDetail}
      idProjectComponentHistoryParam={historyDetail.id}
      nameBOM={nameBOM.value}
      userInfo={userInfo}
      isReadComponent={isReadComponent}
      isLoading={isLoading.value}
      histories={histories}
      versionHistory={historyDetail}
      isDeleted={isDeleted}
      handleUpdateDataWhenChangeHistory={handleUpdateDataWhenChangeHistory}
      setHistoryDetail={setHistoryDetail}
      conversationRole={conversationRole}
      commitButtonStage={{
        isActive:
          listFileUrl.length > 0 && historyDetail.status === STATUS_BUILD.DRAFT
            ? true
            : false,
        tooltipHelper: `Please upload ${componentDetail.type.key.toLocaleLowerCase()} attachment`,
      }}
    >
      <div className="flex flex-col pl-6 py-6 grow overflow-auto">
        {histories.length !== 0 && (
          <HistoryTreeCard
            histories={histories}
            historyDetail={historyDetail}
            handleChangeHistoryDetail={handleChangeHistoryDetail}
            typeComponent={componentDetail.type.key}
            titlePage={"build-component"}
          />
        )}
        <div className="flex flex-col	h-full">
          <AttachmentOtherCard
            listFileUrl={listFileUrl}
            viewOnly={viewOnly}
            typeOther={componentDetail.type.key.toLocaleLowerCase()}
            styleRoot={{
              marginTop: histories.length === 0 ? "0" : "1.5rem",
            }}
            isDeleted={isDeleted}
            conversationId={params.idConversation}
            handleUpdateDataWhenDeleteFile={handleUpdateDataWhenDeleteFile}
            handleUpdateDataWhenAddFile={handleUpdateDataWhenAddFile}
          />
          <div
            className="flex flex-col mr-6 mt-6 p-3"
            style={{
              border: "1px solid #E4E4E4",
              borderRadius: 4,
              background: "#FFFFFF",
            }}
          >
            <p
              className="font-semibold mb-2"
              style={{
                fontSize: 14,
                lineHeight: "24px",
                color: "#111111",
              }}
            >
              Additional info
            </p>
            <InputEditorDefault2
              readOnly={viewOnly}
              setValue={(description) => {
                if (description !== dataEditor) {
                  debouceOnSubmitAdditionalMessage(
                    description,
                    componentDetail.type.key.toLocaleLowerCase()
                  )
                }
              }}
              value={dataEditor}
              versionId={historyDetail.id}
            />
          </div>
        </div>
      </div>
    </PageInviteeComponent>
  )
}

export default InviteeOtherComponent
