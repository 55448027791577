import Tippy, { TippyProps } from "@tippyjs/react"
import { FC, useCallback } from "react"
import { twMerge } from "tailwind-merge"
import "tippy.js/animations/scale.css"
import "tippy.js/dist/tippy.css" // optional
import "tippy.js/themes/light.css"
import "./index.css"

import { useBoolean } from "helpers/hooks"
import { Omit } from "lodash"

export interface TippyCustomzieProps extends TippyProps {
  containerClass?: string
  content?: any
}

export const TippyCustomzie = (props: TippyCustomzieProps) => {
  const { children, containerClass = "", ...otherProps } = props

  return (
    <div className={containerClass}>
      <Tippy {...otherProps}>
        <div>{children}</div>
      </Tippy>
    </div>
  )
}

export interface TippyLayoutProps
  extends Omit<TippyProps, "content" | "onClickOutside"> {
  containerClass?: string
  headerContent?: any
  headerClass?: string
  mainContent: any
  mainContentClass?: string
  type?: "full-screen" | "content-screen"
}

export const useTippyLayout = () => {
  const showLayout = useBoolean()

  const closeLayout = () => showLayout.setValue(false)
  const openLayout = () => showLayout.setValue(true)

  const TippyLayout: FC<TippyLayoutProps> = useCallback(
    ({
      children,
      headerContent,
      mainContent,
      headerClass,
      mainContentClass,
      containerClass,
      visible,
      placement,
      type = "full-screen",
      animation = "shift-left",
      offset = [0, 0],
      allowHTML = true,
      interactive = true,
      arrow = false,
      ...props
    }) => {
      const contentHeight =
        type === "full-screen"
          ? "h-[calc(100_*_var(--vh)_-_52px_-_24px)]"
          : "h-[calc(100_*_var(--vh)_-_52px)]"

      return (
        <div className={twMerge(`${type}`, containerClass)}>
          <Tippy
            {...props}
            visible={visible ?? showLayout.value}
            placement={
              placement ?? type === "full-screen" ? "top-end" : "top-start"
            }
            animation={animation}
            interactive={interactive}
            allowHTML={allowHTML}
            offset={offset}
            arrow={arrow}
            content={
              <div className="flex flex-col height-screen">
                {!headerContent ? null : (
                  <div
                    className={twMerge(
                      "h-[52px] px-6  bg-[#ffffff] border-b-[1px] w-full",
                      headerClass
                    )}
                  >
                    <div className="flex items-center justify-between w-inherit h-inherit">
                      {headerContent}
                    </div>
                  </div>
                )}
                <div
                  className={twMerge(
                    `flex-1 ${
                      headerContent ? "my-6" : "my-0"
                    } bg-[#ffffff] overflow-auto ${contentHeight}`,
                    mainContentClass
                  )}
                >
                  {mainContent}
                </div>
              </div>
            }
          >
            <div>{children}</div>
          </Tippy>
        </div>
      )
    },
    [showLayout.value]
  )

  return { TippyLayout, closeLayout, openLayout }
}
