import ModalCustom from "components/ModalCustom"
import { useBoolean } from "helpers/hooks"
import { isArray } from "lodash"
import { useEffect, useState } from "react"
import TableBOMItem from "../molecules/TableBOMItem"
import { getPCBBOMMiddleware } from "../services"

interface Props {
  onCloseModal: () => void
  openModal: boolean
  idHitory: string
  nameBOM: string
}
const FormSubComponentBOM = (props: Props) => {
  const { onCloseModal, openModal, idHitory, nameBOM } = props
  const isLoading = useBoolean()
  const [BOMJson, setBOMJson] = useState<any>([])
  const [headers, setHeaders] = useState<string[]>([])

  useEffect(() => {
    if (!openModal || !idHitory) {
      return
    }
    getPCBBOM(idHitory)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal, idHitory])
  const getPCBBOM = async (oldIdHistory: string) => {
    try {
      isLoading.setValue(true)
      const dataRes = await getPCBBOMMiddleware(oldIdHistory)
      const newTables = isArray(dataRes.bom_json)
        ? dataRes.bom_json
        : Object.values(dataRes.bom_json)[0]
      if (newTables.length) {
        const newHeaders: string[] = Object.keys(newTables[0])
        setHeaders(newHeaders)
        setBOMJson(newTables)
      } else {
        setHeaders([])
        setBOMJson([newTables])
      }
      isLoading.setValue(false)
    } catch (error) {
      isLoading.setValue(false)
    }
  }
  return (
    <ModalCustom
      label={nameBOM}
      handleChangeButton={() => onCloseModal}
      styleRoot={{
        width: "calc(100vw - 100px)",
        paddingRight: 0,
      }}
      styleHeaderModal={{
        paddingRight: 16,
      }}
      bodyChildren={
        <div
          className="flex flex-col"
          style={{
            overflow: "auto",
            maxHeight: "calc(100vh - 100px)",
          }}
        >
          <TableBOMItem
            headers={headers}
            dataBody={BOMJson}
            isAddColumn={false}
            lengthSupplier={0}
            lengthAdditinal={0}
            lengthInviteeBOM={0}
            styleRoot={{
              width: "fit-content",
              marginRight: 16,
              marginBottom: 6,
            }}
          />
        </div>
      }
    />
  )
}
export default FormSubComponentBOM
