import React from "react"
import { ProjectDetail } from "pages/projects/types"

export interface GlobalProjectDetailContext {
  projectDetail: ProjectDetail
  setProjectDetail: (projectDetail: ProjectDetail) => void
  getBuildHistoriesComponents?: (currentVersionId?: string) => void
}

export const ProjectDetailContext =
  React.createContext<GlobalProjectDetailContext | null>(null)
