import Tippy from "@tippyjs/react"

interface Props {
  headers: string[]
  dataBody: any
  isAddColumn: boolean
  lengthSupplier: number
  lengthAdditinal: number
  lengthInviteeBOM: number
  styleRoot?: React.CSSProperties
}
const TableBOMItem = (props: Props) => {
  const {
    headers,
    lengthSupplier,
    dataBody,
    isAddColumn,
    lengthAdditinal,
    lengthInviteeBOM,
    styleRoot,
  } = props
  const renderTableBody = (header: string, value: any) => {
    if (value[header] && value[header].length > 20) {
      return (
        <Tippy
          className="custom-tippy-menu-left"
          placement="top"
          content={
            <span
              className="font-normal"
              style={{
                fontSize: 13,
                lineHeight: "22px",
              }}
            >
              {value[header]}
            </span>
          }
        >
          <p
            className="font-normal text-ellipsis-2"
            style={{
              fontSize: 14,
              lineHeight: "24px",
              color: "#111111",
            }}
          >
            {value[header]}
          </p>
        </Tippy>
      )
    }
    return (
      <p
        className="font-normal text-ellipsis-2"
        style={{
          fontSize: 14,
          lineHeight: "24px",
          color: "#111111",
        }}
      >
        {value[header]}
      </p>
    )
  }
  const renderBorderRadius = () => {
    if (
      !isAddColumn &&
      !lengthSupplier &&
      !lengthAdditinal &&
      !lengthInviteeBOM
    ) {
      return 6
    }
    return 0
  }
  return (
    <div
      className="flex flex-col"
      style={{
        borderLeft: "1px solid #E4E4E4",
        borderTop: "1px solid #E4E4E4",
        borderBottom: "1px solid #E4E4E4",
        borderRight: "1px solid #E4E4E4",
        borderTopLeftRadius: 6,
        borderBottomLeftRadius: 6,
        borderTopRightRadius: renderBorderRadius(),
        borderBottomRightRadius: renderBorderRadius(),
        marginTop: 10,
        backgroundColor: "#FFFFFF",
        ...styleRoot,
      }}
    >
      <div className="flex items-end">
        <div
          className={`px-3 flex items-center font-semibold min-h-48-custom h-12`}
          style={{
            fontSize: 14,
            lineHeight: "24px",
            color: "#111111",
            width: 65,
            textAlign: "start",
            borderBottom: "1px solid #E4E4E4",
            wordBreak: "break-word",
          }}
        >
          #
        </div>
        {headers.map((header: string, idx: number) => (
          <div
            className={`px-3 flex items-center font-semibold min-h-48-custom h-12`}
            style={{
              fontSize: 14,
              lineHeight: "24px",
              color: "#111111",
              width: 170,
              minWidth: 170,
              textAlign: "start",
              borderBottom: "1px solid #E4E4E4",
              wordBreak: "break-word",
            }}
            key={idx}
          >
            {header}
          </div>
        ))}
      </div>
      {dataBody.length
        ? dataBody.map((el: any, index: number) => (
            <div
              className="flex items-center"
              key={index}
              style={{
                backgroundColor: index % 2 !== 0 ? "#F7F7F7" : "#FFFFFF",
                width: "min-content",
              }}
            >
              <div
                className="px-3 flex items-center py-1 "
                style={{
                  height: 48,
                  width: 65,
                }}
              >
                {index + 1}
              </div>
              {headers.map((header: string, idx: number) => (
                <div
                  className="px-3 flex items-center py-1 "
                  style={{
                    height: 48,
                    width: 170,
                  }}
                  key={idx}
                >
                  {renderTableBody(header, el)}
                </div>
              ))}
            </div>
          ))
        : null}
    </div>
  )
}
export default TableBOMItem
