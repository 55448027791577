import { BuildConversationDetail } from "../conversations.type"
import {
  SetConversationBuildAction,
  SET_CONVERSATION_BUILD_ACTION,
} from "./actions"

export const conversationBuildInfo = (
  state: BuildConversationDetail[],
  action: SetConversationBuildAction
) => {
  if (action.type === SET_CONVERSATION_BUILD_ACTION) {
    return action.payload
  }
  return state || []
}
