import {
  SetUnreadBuildAction,
  SetUserInfoAction,
  SET_UNREAD_BUILD_ACTION,
  SET_USER_INFO_ACTION,
  SetUnreadConversationAction,
  SET_UNREAD_CONVERSATION_ACTION,
} from "./actions"
import { UserInfo, emptyUserInfo } from "../types"
import {
  emptyUnreadBuild,
  emptyUnreadConversation,
  UnreadBuild,
  UnreadConversation,
} from "pages/conversations/conversations.type"

export const userInfo = (state: UserInfo, action: SetUserInfoAction) => {
  if (action.type === SET_USER_INFO_ACTION) {
    return { ...state, ...action.payload }
  }
  return state || emptyUserInfo
}
export const unreadBuild = (
  state: UnreadBuild,
  action: SetUnreadBuildAction
) => {
  if (action.type === SET_UNREAD_BUILD_ACTION) {
    return {
      ...state,
      ...action.payload,
    }
  }

  return state || emptyUnreadBuild
}
export const unreadConversation = (
  state: UnreadConversation,
  action: SetUnreadConversationAction
) => {
  if (action.type === SET_UNREAD_CONVERSATION_ACTION) {
    return {
      ...state,
      ...action.payload,
    }
  }

  return state || emptyUnreadConversation
}
