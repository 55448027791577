import Typography from "components/Typography"
import { generateQueryString } from "helpers/history"
import { MouseEventHandler } from "react"
import { Link, NavLink } from "react-router-dom"
import { twMerge } from "tailwind-merge"

interface Props {
  title: any
  label?: any
  isBorderRight?: boolean
  styleRoot?: React.CSSProperties
  isClick?: boolean
  onClickLabel?: MouseEventHandler
  onClickTitle?: MouseEventHandler
  containerClass?: string
  titleClass?: string
  labelClass?: string
  path?: string
  state?: object
  search?: object
}

const LabelHeader = (props: Props) => {
  const {
    title,
    isBorderRight,
    label,
    styleRoot,
    onClickLabel,
    onClickTitle,
    isClick,
    containerClass,
    titleClass,
    labelClass,
    path,
    state,
    search,
  } = props

  return (
    <div
      className={twMerge("flex items-center", containerClass)}
      style={{
        ...styleRoot,
      }}
    >
      <div className="flex items-end md:items-center justify-between flex-1">
        {title ? (
          <Typography
            className={twMerge(
              "font-normal text-sm md:text-[13px] leading-[22px] text-brown whitespace-nowrap overflow-hidden ",
              titleClass
            )}
            onClick={onClickTitle}
          >
            {title}
          </Typography>
        ) : null}

        {label ? (
          isClick ? (
            <NavLink
              to={{
                pathname: path,
                // state: state,
                search: generateQueryString(search),
              }}
              className={twMerge(
                `pl-1 hover-label-header
                font-semibold text-new-black-333333 whitespace-nowrap`,
                labelClass
              )}
            >
              {label}
            </NavLink>
          ) : (
            <span
              onClick={onClickLabel}
              className={twMerge(
                `pl-1 font-semibold text-new-black-333333 whitespace-nowrap`,
                labelClass
              )}
            >
              {label}
            </span>
          )
        ) : null}
      </div>

      {isBorderRight ? (
        <div className="mx-3 h-[13px] bg-[#E4E4E4]  w-[1px]"></div>
      ) : null}
    </div>
  )
}
export default LabelHeader

export const getContentLabelStyleProps = (
  isBorderRight?: boolean,
  containerClass?: string,
  titleClass?: string,
  labelClass?: string
) => ({
  containerClass: twMerge(
    "h-[52px] md:h-[13px] w-full border-b-[1px] first-item-border-top md:border-none",
    containerClass
  ),
  titleClass: twMerge("px-6 md:px-0 text-new-black-333333", titleClass),
  labelClass: twMerge(
    "px-6 md:pl-1 md:pr-0 text-brown md:text-new-black-333333 font-medium md:font-semibold",
    labelClass
  ),
  isBorderRight,
})
