import { useBoolean, useString, useWindowSize } from "helpers/hooks"
import { ReactComponent as IconButton } from "assets/images/icons/icon-button-create.svg"
import { STATUS_TYPE_FILE_BUILD } from "../build-history.constant"
import {
  downloadPrivateAsset,
  downloadPrivateAssetForInvitee,
} from "helpers/utils"
import { ReactComponent as IconRemoveFile } from "assets/images/icons/icon-remove-file.svg"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { toast } from "react-toastify"
import { FileUrlProp } from "../types"
import ModalDelete from "components/ModalCustom/ModalDelete"
import { useState } from "react"
import { deleteFileOtherMiddleware } from "../services"
import { STATUS_RESPONSE } from "types"
import ActionIconHistory from "../molecules/ActionIconHistory"
import AttachmentFile from "../molecules/AttachmentFile"

interface Props {
  typeOther: string
  listFileUrl: FileUrlProp[]
  viewOnly: boolean
  handleUpdateDataWhenDeleteFile?: (oldIdFile: string) => void
  handleUpdateDataWhenAddFile?: (
    fileRequest: FormData,
    fileErrors: File[]
  ) => void
  styleRoot?: React.CSSProperties
  isDeleted?: boolean
  conversationId?: string
  versionId?: string
  maxFile?: number
  isInvitee?: boolean
}
const AttachmentOtherCard = (props: Props) => {
  const {
    listFileUrl,
    viewOnly,
    typeOther,
    styleRoot,
    handleUpdateDataWhenDeleteFile,
    handleUpdateDataWhenAddFile,
    isDeleted,
    conversationId,
    versionId,
    maxFile = 50,
    isInvitee = false,
  } = props
  const { isMobile } = useWindowSize()
  let attachmentContainerStyle: React.CSSProperties = {
    flexFlow: "wrap",
  }
  if (isMobile) {
    attachmentContainerStyle = {
      overflow: "auto",
    }
  }

  const keyInputFile = useString()
  const isDelete = useBoolean(false)
  const [fileDelete, setFileDelete] = useState<FileUrlProp>({
    id: "",
    name: "",
    file: "",
  })
  const isLoading = useBoolean()
  const [dragActive, setDragActive] = useState(false)

  const fileSelectedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      handleUploadFiles(event.target.files)
    }
  }
  const handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true)
    } else if (e.type === "dragleave") {
      setDragActive(false)
    }
  }
  const handleUploadFiles = (fileList: FileList) => {
    const fileErrors: File[] = []
    const formData = new FormData()
    for (const [, newFile] of Object.entries(fileList)) {
      if (newFile.size > maxFile * 1000 * 1000) {
        return toast(
          <LabelNotificationPage
            messenger={`There are some files those are larger than ${maxFile}MB is not allowed`}
            type="error"
          />
        )
      }
      formData.append("files", newFile)
    }
    keyInputFile.setValue(Math.random().toString(36))
    if (handleUpdateDataWhenAddFile) {
      handleUpdateDataWhenAddFile(formData, fileErrors)
    }
  }
  const handleDrop = (event: React.DragEvent<HTMLInputElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setDragActive(false)
    if (event.dataTransfer.files) {
      handleUploadFiles(event.dataTransfer.files)
    }
  }

  const handleDeleteFileDownload = (oldFile: FileUrlProp) => () => {
    setFileDelete(oldFile)
    isDelete.setValue(true)
  }

  const onCloseModal = () => {
    setFileDelete({
      file: "",
      id: "",
      name: "",
    })
    isDelete.setValue(false)
  }
  const onSubmitDeleteFile = () => {
    if (!fileDelete.id) {
      return
    }
    isLoading.setValue(true)
    deleteFileOtherMiddleware(
      fileDelete.id,
      typeOther,
      (type: STATUS_RESPONSE, messenger: string) => {
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        isLoading.setValue(false)
        if (type === STATUS_RESPONSE.SUCCESS) {
          if (handleUpdateDataWhenDeleteFile) {
            handleUpdateDataWhenDeleteFile(fileDelete.id)
          }
          onCloseModal()
        }
      },
      conversationId
    )
  }

  return (
    <div
      className="flex flex-col px-3 pt-3 mr-6 mt-6 bg-white"
      style={{
        border: "1px solid #E4E4E4",
        borderRadius: 4,
        ...styleRoot,
      }}
    >
      <p
        className="font-semibold mb-2"
        style={{
          fontSize: 14,
          lineHeight: "24px",
          color: "#111111",
        }}
      >
        Attachment
      </p>
      <div className="flex items-center" style={attachmentContainerStyle}>
        {listFileUrl.length ? (
          listFileUrl.map((file, index) => (
            <div key={index} className="flex items-center mb-3">
              <div
                className="h-10 grid"
                style={{
                  gridTemplateColumns: viewOnly
                    ? "48px calc(100% - 71px) 23px"
                    : "48px calc(100% - 94px) 23px 23px",
                  width: 256,
                }}
              >
                <AttachmentFile
                  file={file.file}
                  filename={file.name}
                  conversation_id={conversationId}
                  project_component_history_id={versionId}
                />
                {!isDeleted ? (
                  <div className="hidden md:flex items-center cursor-pointer">
                    <a
                      onClick={() => {
                        downloadPrivateAsset(
                          file.file,
                          conversationId
                            ? { conversation_id: conversationId }
                            : { project_component_history_id: versionId }
                        )
                      }}
                    >
                      <ActionIconHistory
                        icon="download"
                        tooltip="Download"
                        styleIcon={{
                          marginRight: 0,
                        }}
                      />
                    </a>
                  </div>
                ) : null}

                {!isDeleted && !viewOnly ? (
                  <div
                    className="hidden md:flex justify-end items-center cursor-pointer"
                    onClick={handleDeleteFileDownload(file)}
                  >
                    <IconRemoveFile />
                  </div>
                ) : null}
              </div>
              <div
                className="mx-3"
                style={{
                  width: 1,
                  height: 40,
                  background: "#F7F7F7",
                }}
              />
            </div>
          ))
        ) : viewOnly ? (
          <div className="flex items-center justify-center w-full h-[100px] pb-3">
            <p className="font-semibold text-14 leading-24 color-gray-7a">
              There is no attachment
            </p>
          </div>
        ) : null}

        {!isDeleted && !viewOnly ? (
          <form
            className="hidden md:block mb-3 relative"
            onDragEnter={handleDrag}
            style={{
              width: 256,
            }}
          >
            <input
              id={`container-files-${STATUS_TYPE_FILE_BUILD.ATTACHMENT}`}
              onChange={fileSelectedHandler}
              type="file"
              multiple
              style={{ display: "none" }}
              key={keyInputFile.value || ""}
            />
            <label
              htmlFor={`container-files-${STATUS_TYPE_FILE_BUILD.ATTACHMENT}`}
            >
              <div
                className={`h-10 flex items-center justify-center cursor-pointer`}
                style={{
                  background: `${
                    dragActive
                      ? "rgba(10, 90, 245, 0.15)"
                      : "rgba(10, 90, 245, 0.05)"
                  }`,
                  border: `1px dashed #0A5AF5`,
                  borderRadius: 4,
                }}
              >
                <IconButton
                  style={{
                    marginRight: 4,
                    width: 24,
                  }}
                />
                <p
                  className={`font-medium text-[12px] leading-[18px] text-blue`}
                >
                  Drag files here or click upload
                </p>
              </div>
            </label>
            {dragActive && !viewOnly && (
              <div
                className="absolute w-full h-full rounded-4 "
                style={{
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                }}
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
              ></div>
            )}
          </form>
        ) : null}
      </div>
      {!isDeleted && isDelete.value && (
        <ModalDelete
          onCloseModal={onCloseModal}
          title={`Are you sure to delete file ${fileDelete.name}?`}
          label={`Confirmation`}
          onSubmit={onSubmitDeleteFile}
          titleButton="Delete"
          styleTitle={{ textAlign: "center" }}
        />
      )}
    </div>
  )
}
export default AttachmentOtherCard
