import { BuildHistoryDetail } from "pages/project-component-detail/types"
interface Props {
  history: BuildHistoryDetail
}
const InfoBranchCard = (props: Props) => {
  const { history } = props
  return (
    <div className="flex items-center flex-nowrap">
      <div
        style={{
          height: 1,
          width: history.project_build_component_id ? 30 : 0,
          backgroundColor: "#E4E4E4",
        }}
      ></div>
      <div
        className="h-2.5 w-2.5 flex items-center justify-center mx-1 card-tree"
        style={{
          background: "rgba(86, 160, 18, 0.15)",
          borderRadius: "50%",
        }}
      >
        <div
          className="h-1.5 w-1.5 card-tree-children"
          style={{
            background: "#56A012",
            borderRadius: "50%",
          }}
        ></div>
      </div>
      <p
        className="font-semibold capitalize ml-1 whitespace-nowrap"
        style={{
          fontSize: 13,
          lineHeight: "22px",
          color: "#111111",
        }}
      >
        {history.branch}
      </p>
      <p
        className="font-normal ml-1"
        style={{
          fontSize: 13,
          lineHeight: "22px",
          color: "#111111",
        }}
      >
        -
      </p>
      <p
        className="font-normal whitespace-nowrap"
        style={{
          fontSize: 13,
          lineHeight: "22px",
          color: "#111111",
        }}
      >
        {history.code}
      </p>
    </div>
  )
}
export default InfoBranchCard
