import React from "react"
import Typography from "components/Typography"
import { ReactComponent as CloseIcon } from "assets/images/icons/icon-close-modal.svg"
import { ModalProps, ACTION_MODAL } from "./types"

const ModalCustom = (props: ModalProps) => {
  const {
    handleChangeButton,
    label,
    bodyChildren,
    widthModal,
    styleRoot,
    styleHeaderModal,
    renderHeader,
  } = props
  return (
    <div
      className="inset-0 flex fixed items-center justify-center"
      style={{
        zIndex: 10000,
        backgroundColor: "#22222285",
      }}
      onClick={(event) => {
        event.preventDefault()
        event.stopPropagation()
      }}
    >
      <div
        className="pb-3	px-4 bg-white rounded-md flex flex-col mx-6"
        style={{
          width: widthModal || 400,
          ...styleRoot,
        }}
      >
        <div
          className="flex justify-between items-center h-50-custom"
          style={{
            ...styleHeaderModal,
          }}
        >
          {renderHeader ? (
            renderHeader
          ) : (
            <Typography
              className="text-base font-semibold"
              style={{
                color: "#222222",
                lineHeight: "26px",
              }}
            >
              {label}
            </Typography>
          )}
          {handleChangeButton ? (
            <div
              className="flex justify-center items-center h-26-custom w-26-custom hover-button-modal cursor-pointer"
              onClick={
                handleChangeButton && handleChangeButton(ACTION_MODAL.NO)
              }
            >
              <CloseIcon />
            </div>
          ) : null}
        </div>
        <div className="pt-3">{bodyChildren}</div>
      </div>
    </div>
  )
}
export default ModalCustom
