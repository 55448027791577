import { ReactComponent as IconButton } from "assets/images/icons/icon-button-create.svg"
import "./button.css"

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  widthButton?: number
  heightButton?: number
  isDisabledBtn?: boolean
  textSize?: number
  textLineHeight?: number
  textWeight?: number
  title: string
  showIcon?: boolean
}

const ButtonHasIcon = (props: Props) => {
  const {
    disabled,
    widthButton = 128,
    heightButton = 40,
    textSize = 14,
    textLineHeight = 24,
    textWeight = 400,
    title,
    isDisabledBtn,
    showIcon = true,
    ...otherProps
  } = props
  const backgroundBtn = disabled
    ? `disabled-btn-has-icon`
    : `bg-[#0a5af526] hover-btn-has-icon`
  const classesBtn = `flex items-center justify-center rounded-[4px] ${backgroundBtn} focus:outline-none`
  const classesTitle = `ml-1 text-[#0A5AF5]`
  return (
    <button
      disabled={isDisabledBtn ? disabled : false}
      className={classesBtn}
      style={{
        width: widthButton,
        height: heightButton,
      }}
      {...otherProps}
    >
      {showIcon ? <IconButton /> : null}
      <p
        className={classesTitle}
        style={{
          fontSize: textSize,
          fontWeight: textWeight,
          lineHeight: `${textLineHeight}px`,
        }}
      >
        {title}
      </p>
    </button>
  )
}
export default ButtonHasIcon
