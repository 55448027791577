import { Route, Redirect } from "react-router-dom"
import { isLogin } from "config/environments"
import { PATH } from "constants/path"

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { pathname, search } = rest.location
  return (
    <Route
      {...rest}
      render={() =>
        isLogin() ? (
          Component
        ) : (
          <Redirect
            to={{
              pathname: PATH.login,
              state: { redirectUrl: pathname, search },
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
