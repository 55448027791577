import React, { useState } from "react"
import { InputDefaultProps, STATUS_INPUT } from "./types"
import IconEye from "assets/images/icons/icon-eye.svg"
import IconEyeSlash from "assets/images/icons/icon-eye-slash.svg"

const InputDefault = (props: InputDefaultProps) => {
  const {
    status,
    labelStatus,
    type,
    value,
    isPassword,
    customIcon,
    classCustom,
    styleRootInput,
    ...otherProps
  } = props
  const [typeInput, setTypeInput] = useState("text")
  const [showIcon, setShowIcon] = useState(false)

  React.useEffect(() => {
    if (!type) {
      return
    }
    setTypeInput(type)
  }, [type])

  const renderStatus = () => {
    switch (status) {
      case STATUS_INPUT.ERROR:
        return `border-red-500 focus:border-red-500`
      case STATUS_INPUT.VALID:
        return `border-green-500 focus:border-green-500`
      default:
        return `border-gray box-shadow-input`
    }
  }

  const showIconStatus = () => {
    if (status === STATUS_INPUT.ERROR || status === STATUS_INPUT.VALID) {
      return (
        <div className="flex mt-1">
          <p
            className="font-semibold"
            style={{
              color: status === STATUS_INPUT.VALID ? "#56A012" : "#EA4545",
              fontSize: 10,
              lineHeight: "14px",
            }}
          >
            {labelStatus}
          </p>
        </div>
      )
    }
    return null
  }

  const handleChangeTypeIcon = () => {
    const newType = typeInput === "password" ? "text" : "password"
    setTypeInput(newType)
  }

  const classes = `h-11 border rounded pl-3 ${
    isPassword ? "pr-12" : "pr-3"
  } text-sm font-normal text-black focus:outline-none ${renderStatus()}`

  const handleOnFocus = () => {
    if (!isPassword) {
      return
    }
    setShowIcon(true)
  }

  const handleOnBlur = () => {
    if (!isPassword) {
      return
    }
    if (!value) {
      setShowIcon(false)
    }
  }

  const renderIcon = () => {
    if (customIcon) {
      return (
        <img
          src={customIcon}
          alt="icon"
          className="w-6 cursor-pointer absolute right-6 top-4"
        />
      )
    }
    if (showIcon) {
      return (
        <img
          src={customIcon ?? typeInput === "password" ? IconEye : IconEyeSlash}
          alt="icon"
          className="cursor-pointer absolute"
          style={{
            right: 12,
            top: 10,
          }}
          onClick={handleChangeTypeIcon}
        />
      )
    }
    return null
  }

  return (
    <div
      className="flex flex-col relative w-full input-auto-fill"
      style={{
        ...styleRootInput,
      }}
    >
      <input
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        type={typeInput}
        value={value}
        className={classCustom ?? classes}
        {...otherProps}
      />
      {renderIcon()}
      {showIconStatus()}
    </div>
  )
}

export default InputDefault
