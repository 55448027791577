import {
  UnreadConversation,
  emptyUnreadBuild,
  emptyUnreadConversation,
} from "./../../conversations/conversations.type"
import Axios, { AxiosResponse, AxiosError } from "axios"
import {
  SignUpRequest,
  LoginRequest,
  ForgetPasswordRequest,
  PasswordResetRequest,
  UserInfo,
  ChangePasswordRequest,
  InformationUserRequest,
  PrivacyDetail,
  CountryDataProp,
  InviteSentSignUpRequest,
  LoginResponse,
  CompanyRole,
} from "../types"
import { STATUS_RESPONSE } from "types"
import {
  setUnreadBuildAction,
  setUnreadConversationAction,
  setUserInfoAction,
} from "../stores/actions"
import { configureStore } from "stores/configureStore"
import { UnreadBuild } from "pages/conversations/conversations.type"
import { setUser } from '@sentry/react';

export const signUpMiddleware = (
  request: SignUpRequest,
  callback: (type: STATUS_RESPONSE, messenger?: string) => void
) => {
  Axios.post(`/api/auth/register`, request)
    .then((_response: AxiosResponse) => {
      callback(STATUS_RESPONSE.SUCCESS)
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ?? "Sign up failed!"
      )
    })
}

export const loginMiddleware = async (request: LoginRequest) => {
  return Axios.post<LoginResponse>(`/api/auth/login`, request).then(
    (response) => {
      return response.data
    }
  )
}

export const forgetPasswordMiddleware = (
  request: ForgetPasswordRequest,
  callback: (type: STATUS_RESPONSE, messenger?: string) => void
) => {
  Axios.post(`/api/auth/forgot-password`, request)
    .then((_response: AxiosResponse) => {
      callback(STATUS_RESPONSE.SUCCESS)
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ?? "Forgot password failed!"
      )
    })
}
export const passwordResetMiddleware = (
  request: PasswordResetRequest,
  callback: (type: STATUS_RESPONSE, messenger?: string) => void
) => {
  Axios.post(`/api/auth/reset-password`, request)
    .then((_response: AxiosResponse) => {
      callback(STATUS_RESPONSE.SUCCESS)
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ?? "Password Reset failed!"
      )
    })
}

export const verifyEmailMiddleware = (
  verificationToken: string,
  callback: (type: STATUS_RESPONSE, messenger?: string) => void
) => {
  Axios.post(`/api/auth/verify/${verificationToken}`)
    .then((_response: AxiosResponse) => {
      callback(STATUS_RESPONSE.SUCCESS)
    })
    .catch((errorVerify) => {
      callback(
        STATUS_RESPONSE.ERROR,
        errorVerify.response?.data?.message ?? "Email verification failed!"
      )
    })
}
export const reSendEmailMiddleware = (
  type: string,
  email: string,
  callback: (type: STATUS_RESPONSE, messenger?: string) => void
) => {
  Axios.post(`/api/auth/resend-email/${type}/${email}`)
    .then((_response: AxiosResponse) => {
      callback(STATUS_RESPONSE.SUCCESS)
    })
    .catch((errorResend) => {
      callback(
        STATUS_RESPONSE.ERROR,
        errorResend.response.data?.message ?? "Email verification failed!"
      )
    })
}

export const getUserMiddleware = (
  callback?: (type: STATUS_RESPONSE) => void
) => {
  Axios.get(`/api/user/me`)
    .then((response: AxiosResponse<{ data: UserInfo }>) => {
      configureStore.dispatch(setUserInfoAction(response.data.data))
      localStorage.setItem("user_id", response.data.data.id)
      // setting timezone
      if (response.data.data.timezone) {
        localStorage.setItem("timezone", response.data.data.timezone)
      }
      // capture user for SENTRY event
      setUser(response.data.data);
      if (callback) {
        callback(STATUS_RESPONSE.SUCCESS)
      }
    })
    .catch((_error: AxiosError) => {
      if (callback) {
        callback(STATUS_RESPONSE.ERROR)
      }
    })
}
export const getUnreadBuildMiddleware = () => {
  Axios.get("/api/conversation/unread-build")
    .then((response: AxiosResponse<{ data: UnreadBuild }>) => {
      configureStore.dispatch(setUnreadBuildAction(response.data.data))
    })
    .catch((_error: AxiosError) => {
      configureStore.dispatch(setUnreadBuildAction(emptyUnreadBuild))
    })
}

export const getUnreadConversationMiddleware = () => {
  Axios.get("/api/conversation/unread")
    .then((response: AxiosResponse<{ data: UnreadConversation }>) => {
      configureStore.dispatch(setUnreadConversationAction(response.data.data))
    })
    .catch((_error: AxiosError) => {
      configureStore.dispatch(
        setUnreadConversationAction(emptyUnreadConversation)
      )
    })
}

export const changePasswordMiddleware = (
  request: ChangePasswordRequest,
  callback: (type: STATUS_RESPONSE, messenger?: string) => void
) => {
  Axios.post(`/api/user/change-password`, request)
    .then((_response: AxiosResponse) => {
      callback(STATUS_RESPONSE.SUCCESS)
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response.data?.message ?? "Change password failed!"
      )
    })
}

export const updateInformationMiddleware = (
  request: InformationUserRequest,
  callback: (type: STATUS_RESPONSE, messenger?: string) => void
) => {
  Axios.put(`/api/user/update`, request)
    .then((_response: AxiosResponse) => {
      callback(STATUS_RESPONSE.SUCCESS)
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response.data?.message ?? "Update Information failed!"
      )
    })
}

export const getFAQMiddleware = async () => {
  const response: AxiosResponse<{
    data: PrivacyDetail
  }> = await Axios.get(`/api/about/faq`)
  return response.data.data
}

export const getPrivacyPolicyMiddleware = async () => {
  const response: AxiosResponse<{
    data: PrivacyDetail
  }> = await Axios.get(`/api/about/privacy-policy`)
  return response.data.data
}

export const getTermOfUseMiddleware = async () => {
  const response: AxiosResponse<{
    data: PrivacyDetail
  }> = await Axios.get(`/api/about/term-of-use`)
  return response.data.data
}

// Order API
export const getCountryMiddleware = async (params?: {
  filter: { country: string }
}) => {
  const response: AxiosResponse<{
    data: CountryDataProp[]
  }> = await Axios.get(`/api/order/country`, { params })
  return response.data.data
}

export const getStatesMiddleware = async (params: {
  filter?: { state: string }
  country: string
  countryCode: string
}) => {
  const response: AxiosResponse<{
    data: string[]
  }> = await Axios.get(`/api/order/state`, { params })
  return response.data.data
}

export const getCitiesMiddleware = async (params: {
  filter?: { city: string }
  state?: string
  country: string
  countryCode: string
}) => {
  const response: AxiosResponse<{
    data: { postalCode: string; city: string }[]
  }> = await Axios.get(`/api/order/city`, { params })
  return response.data.data
}
export const inviteSentigSnUpMiddleware = (
  verificationToken: string,
  request: InviteSentSignUpRequest,
  callback: (type: STATUS_RESPONSE, messenger?: string) => void
) => {
  Axios.post(
    `/api/auth/create-password-and-verify/${verificationToken}`,
    request
  )
    .then(() => {
      callback(STATUS_RESPONSE.SUCCESS)
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ?? " Invite Sent Sign up failed!"
      )
    })
}

export const settingTwoFactor = async (type: "enable" | "disable") => {
  return Axios.post(`/api/auth/two-factor/${type}`).then((response) => {
    getUserMiddleware()
    return response.data
  })
}
export const resendCodeTwoFactor = async (token: string) => {
  return Axios.post<LoginResponse>(
    `/api/auth/two-factor/resend-code?token=${token}`
  ).then((response) => {
    return response.data
  })
}
export const verifyTwoFactor = async (code: string, token: string) => {
  return Axios.post<LoginResponse>(
    `/api/auth/two-factor/verify?token=${token}`,
    { code }
  ).then((response) => {
    return response.data
  })
}

export const getCompanyRoles = async () => {
  const res = await Axios.get<{ data: CompanyRole[] }>(
    "/api/auth/get-company-roles"
  )
  return res.data.data
}
