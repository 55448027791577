import { ReactComponent as ChosenIcon } from "assets/images/icons/icon-active-select.svg"
import { ReactComponent as IconArrow } from "assets/images/icons/up-arrow-20.svg"
import Typography from "components/Typography"
import { useBoolean } from "helpers/hooks"
import { cloneDeep } from "lodash"
import CustomCollapse from "pages/project-component-detail/molecules/CustomCollapse"
import { FC } from "react"
import "./index.css"

interface FilterCollapseProps {
  data: { value: any; label: any }[]
  chosenValue: any
  onChange?: (chosenValue: any) => void
  label?: string
  multiActive?: boolean
  disabled?: false
}

export const FilterCollapse: FC<FilterCollapseProps> = ({
  data,
  chosenValue,
  onChange,
  multiActive,
  label = "",
  disabled = false,
}) => {
  const isExpanded = useBoolean()

  const handleSelect = (selected: any) => () => {
    if (!onChange) {
      return
    }
    let newSelected: any
    if (multiActive) {
      newSelected = cloneDeep(chosenValue)
      if (newSelected[selected.value] !== undefined) {
        delete newSelected[selected.value]
      } else {
        newSelected[selected.value] = selected.value
      }
    } else {
      newSelected = selected
    }

    onChange(newSelected)
  }

  const getExpectedValue = (
    dataItem: any,
    defaultValue: any,
    resultValue: any
  ) => {
    if (multiActive) {
      if (chosenValue[dataItem.value] !== undefined) {
        return resultValue
      } else {
        return defaultValue
      }
    }

    if (chosenValue === dataItem.value) {
      return resultValue
    }

    return defaultValue
  }

  return (
    <CustomCollapse
      handleChangeToggle={(collapse) => isExpanded.setValue(collapse)}
      changeHeaderBorder={isExpanded.value && data.length}
      containerClass={`project-filter-list ${disabled ? "disabled" : ""}`}
      isDisableToggle={disabled}
      buttonClass="border-0"
      header={
        <div className="flex items-center justify-between cursor-pointer h-[52px] w-full">
          <Typography
            textClass="font-normal"
            style={{
              fontSize: 14,
              lineHeight: "24px",
              color: "#000000",
            }}
          >
            {label}
          </Typography>
          <div>
            <IconArrow
              className={`${data.length ? "" : "arrow-icon-inactive"}`}
              style={{
                transform:
                  isExpanded.value && data.length
                    ? "rotate(0deg)"
                    : "rotate(180deg)",
              }}
            />
          </div>
        </div>
      }
      childrenClass="flex flex-col"
      childrenBody={data.map((item, index) => (
        <div
          key={`${item.value}-${item.label}-${index}`}
          className="section-item-border flex items-center justify-between px-6"
          style={{
            backgroundColor: getExpectedValue(item, "transparent", "#FDBE44"),
          }}
          onClick={handleSelect(item)}
        >
          <Typography
            textClass="font-normal h-[52px]"
            style={{
              fontSize: 14,
              lineHeight: "24px",
              color: getExpectedValue(item, "#666666", "#000000"),
            }}
          >
            {item.label}
          </Typography>

          {getExpectedValue(
            item,
            null,
            <div>
              <ChosenIcon />
            </div>
          )}
        </div>
      ))}
    />
  )
}
