import Tippy from "@tippyjs/react"
import IconInfoCircle from "assets/images/icons/icon-info-circle.svg"
import Typography from "components/Typography"
import { FormInputCardProps } from "../types"

const FormInputCard = (props: FormInputCardProps) => {
  const {
    children,
    title,
    customClass,
    required,
    isTippy,
    label,
    showToolTip,
    ...tippyProps
  } = props

  return (
    <div className={`${customClass ?? "mb-3"}`}>
      <div className="flex items-center mb-1">
        <Typography
          style={{
            color: "#111111",
            fontSize: 14,
            fontWeight: 600,
          }}
        >
          {title}
          {required ? <span className="text-error">*</span> : null}
          {label && !isTippy ? (
            <span className="font-normal mr-1 text-brown">{label}</span>
          ) : null}
        </Typography>
        {isTippy && label && (
          <Tippy
            {...tippyProps}
            content={
              <span className="font-normal text-[13px] leading-[22px]">
                {label}
              </span>
            }
          >
            <img
              onClick={() => {
                showToolTip?.setValue(!showToolTip.value)
              }}
              src={IconInfoCircle}
              alt="icon"
              className="cursor-pointer pl-2"
            />
          </Tippy>
        )}
      </div>
      {children}
    </div>
  )
}

export default FormInputCard
