export enum TAB_PROJECT_COMPONENT {
  COMPONENT = "component",
  BUILD = "build",
  FOLDER = "folder",
}
export enum TYPE_PROJECT_COMPONENT {
  PCB = "PCB",
  BOM = "BOM",
  MECHANICAL = "Mechanical",
  SOFTWARE = "Software",
  FIRMWARE = "Firmware",
  MISCELLANEOUS = "Miscellaneous",
}
