/* eslint-disable react-hooks/exhaustive-deps */
import { IUseDefaultValueProps, useBoolean, useString } from "helpers/hooks"
import React, { useEffect, useRef, useState } from "react"
import {
  cloneDeep,
  compact,
  isArray,
  isEmpty,
  isEqual,
  remove,
  slice,
} from "lodash"
import TableBOMItem from "pages/project-component-detail/molecules/TableBOMItem"
import {
  converDataTableColumnBOM,
  converNameHeaderTableAdditional,
  downloadFile,
  formatDateForConversationMessage,
} from "helpers/utils"
import InviteeTableBOMAddColumn from "./InviteeTableBOMAddColumn"
import {
  CONVERSATION_ROLE,
  InviteeAddColumnDetail,
} from "pages/conversations/conversations.type"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { toast } from "react-toastify"
import { postAddInviteeColumnMiddleware } from "pages/conversations/conversations.api"
import { STATUS_RESPONSE, VERSION_APPROVED_STATUS } from "types"
import { API_URL } from "config/environments"
import TableAdditionalJSON from "pages/project-component-detail/molecules/TableAdditionalJSON"
import ActionIconHistory from "pages/project-component-detail/molecules/ActionIconHistory"
import Button from "components/Button/Button"
import {
  BuildHistoryDetail,
  ComponentType,
  TableColumnBOMDetail,
} from "pages/project-component-detail/types"
import { STATUS_BUILD } from "components/Status/types"
import ButtonHasIcon from "components/Button/ButtonHasIcon"
import TableBOMAddColumn from "pages/project-component-detail/molecules/TableBOMAddColumn"
import { postBOMAddColumnMiddleware } from "pages/project-component-detail/services"
import { inviteeBomAddAdditionalColMiddleware } from "./services/invitee.api"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
import ModalDelete from "components/ModalCustom/ModalDelete"
interface Props {
  BOMJson: any
  additionalJson: any
  inviteeBoms: any
  nameBOM: string
  updatedAtBOM: string
  conversationId: string
  projectComponentHistory: BuildHistoryDetail
  componentType: ComponentType
  isInvitee: boolean
  isCloseConversation: boolean
  isAddColumn: IUseDefaultValueProps
  updateDataWhenChangeColumn: (callback: () => void) => void
  isDeleted?: boolean
  conversationRole: CONVERSATION_ROLE
}
const InviteeBOMDetailCard = (props: Props) => {
  const {
    BOMJson,
    additionalJson,
    nameBOM,
    updatedAtBOM,
    conversationId,
    projectComponentHistory,
    inviteeBoms,
    isInvitee,
    isCloseConversation,
    updateDataWhenChangeColumn,
    conversationRole,
    isAddColumn,
    componentType,
  } = props
  const refTable = useRef<HTMLDivElement>(null)
  const isLoading = useBoolean(false)
  const [headers, setHeaders] = useState<string[]>([])
  const [dataAddColumns, setDataAddColumns] = useState<
    InviteeAddColumnDetail[]
  >([])
  const [originDataAddColumns, setOriginDataAddColumns] = useState<
    InviteeAddColumnDetail[]
  >([])
  const recommendAltPart = useBoolean(false)
  const [dataAdditionalJson, setDataAdditionalJson] = useState<
    TableColumnBOMDetail[]
  >([])
  const [originAdditionalJson, setOriginAdditionalJson] = useState<
    TableColumnBOMDetail[]
  >([])
  const [extraJson, setExtraJson] = useState<TableColumnBOMDetail[]>([])
  const isHasColumn = useBoolean(false)
  const defaultBomValues = Array.from(new Array(BOMJson?.length || 0)).map(
    () => ""
  )
  const [tableAddColumn, setDataAddColumn] = useState<string[]>([])
  const isUpdatingRecomendingParts = useBoolean()
  const isUpdatingAdditionalColumn = useBoolean()
  const isDeleteColumn = useBoolean(false)
  const keyDeleteColumn = useString("")
  useEffect(() => {
    if (!isEmpty(extraJson)) {
      console.log("this being run")

      isAddColumn.setValue(true)
    }
  }, [JSON.stringify(extraJson)])

  useEffect(() => {
    if (isArray(BOMJson) && BOMJson.length) {
      const newHeaders: string[] = Object.keys(BOMJson[0])
      setHeaders(newHeaders)
      setDataAddColumn(Array.from(new Array(BOMJson.length)).map(() => ""))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(BOMJson)])

  useEffect(() => {
    if (isArray(additionalJson) && additionalJson.length) {
      const newDataAdditional: TableColumnBOMDetail[] =
        converDataAdditionals(additionalJson)
      setDataAdditionalJson(newDataAdditional)
      setOriginAdditionalJson(newDataAdditional)
    } else {
      setDataAdditionalJson([])
      setOriginAdditionalJson([])
    }
    setExtraJson([])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(additionalJson)])

  useEffect(() => {
    if (isArray(inviteeBoms) && inviteeBoms.length) {
      if (!inviteeBoms[0].additional_json.length) {
        setDataAddColumns([
          {
            key: "",
            values: defaultBomValues,
            idColumn: "",
          },
        ])
        setOriginDataAddColumns([
          {
            key: "",
            values: defaultBomValues,
            idColumn: "",
          },
        ])

        isHasColumn.setValue(false)
        isUpdatingRecomendingParts.setValue(false)
        return
      }
      const newDataBodyAddition: any[] = inviteeBoms[0].additional_json.length
        ? converDataAdditionals(inviteeBoms[0].additional_json)
        : []
      setDataAddColumns(newDataBodyAddition)
      setOriginDataAddColumns(newDataBodyAddition)
      isUpdatingRecomendingParts.setValue(
        newDataBodyAddition.length ? true : false
      )
      isHasColumn.setValue(true)
    } else {
      setDataAddColumns([
        {
          key: "",
          values: defaultBomValues,
          idColumn: "",
        },
      ])
      setOriginDataAddColumns([
        {
          key: "",
          values: defaultBomValues,
          idColumn: "",
        },
      ])

      isHasColumn.setValue(false)
      isUpdatingRecomendingParts.setValue(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(inviteeBoms)])
  const converDataAdditionals = (oldData: any) => {
    return oldData.map((elAdditionalJson) => {
      const { newKey, sliceKey } = converNameHeaderTableAdditional(
        elAdditionalJson.key
      )
      return {
        ...elAdditionalJson,
        idColumn: sliceKey,
        key: newKey,
      }
    })
  }

  const onRecommendAltPart = () => {
    recommendAltPart.setValue(true)
    isHasColumn.setValue(true)
    scrollRight()
  }
  const onCancelColumn = () => {
    recommendAltPart.setValue(false)
    setDataAddColumns(originDataAddColumns)
    if (!originDataAddColumns[0].key) {
      isHasColumn.setValue(false)
    }
  }
  const onAddMoreColumn = () => {
    const newDataAddColumns = cloneDeep(dataAddColumns)
    newDataAddColumns.push({
      key: "",
      values: defaultBomValues,
      idColumn: "",
    })
    setDataAddColumns(newDataAddColumns)
    scrollRight()
  }
  const scrollRight = () => {
    setTimeout(() => {
      if (refTable.current) {
        refTable.current.scrollLeft = refTable.current.scrollWidth
        refTable.current.scrollIntoView({ behavior: "smooth" })
      }
    }, 0)
  }
  const onSendColumn = () => {
    if (!projectComponentHistory.id || !conversationId) {
      return
    }
    const newDataSend = cloneDeep(dataAddColumns)
    const newHeaders = newDataSend.map((el) => el.key)
    const newHeadersCompact = compact(newHeaders)
    if (newHeadersCompact.length !== newHeaders.length) {
      toast(
        <LabelNotificationPage
          messenger="Required to enter header table!"
          type="warning"
        />
      )
      return
    }
    isLoading.setValue(true)
    if (isEqual(newDataSend, originDataAddColumns)) {
      setTimeout(() => {
        isLoading.setValue(false)
        recommendAltPart.setValue(false)
      }, 1000)
      return
    }
    postAddInviteeColumnMiddleware(
      conversationId,
      projectComponentHistory.id,
      newDataSend.map((el) => {
        return {
          ...el,
          key: el.key.concat(el.idColumn),
        }
      }),
      isUpdatingRecomendingParts.value,
      (type: STATUS_RESPONSE, messenger: string) => {
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        isLoading.setValue(false)
        if (type === STATUS_RESPONSE.SUCCESS) {
          recommendAltPart.setValue(false)
          isHasColumn.setValue(false)
          updateDataWhenChangeColumn(() => {
            scrollRight()
          })
        }
      }
    )
  }
  const onExportFile = () => {
    if (!projectComponentHistory.id || !conversationId) {
      return
    }
    isLoading.setValue(true)
    const uri =
      componentType === ComponentType.BOM
        ? `${API_URL}/api/${componentType}/${projectComponentHistory.id}/${conversationId}/export`
        : `${API_URL}/api/${componentType}/${projectComponentHistory.id}/${conversationId}/export-bom`
    downloadFile(uri, nameBOM ? nameBOM : new Date().toISOString(), true)
    isLoading.setValue(false)
  }

  const handleUpdateAdditionalJson = (newData: TableColumnBOMDetail[]) => {
    /// just update because no create any new column
    isAddColumn?.setValue(true)
    if (newData.length === originAdditionalJson.length) {
      setDataAdditionalJson(newData)
      return
    }
    if (newData.length > originAdditionalJson.length) {
      const extraColumns = slice(
        newData,
        originAdditionalJson.length,
        newData.length
      )
      const newExtraJson = cloneDeep(extraJson)
      extraColumns.forEach((column, index) => {
        if (newExtraJson[index]) {
          newExtraJson[index] = {
            idColumn: column.idColumn || newExtraJson[index].idColumn,
            key: column.key || newExtraJson[index].key,
            values: newExtraJson[index].values.map(
              (v, i) => column.values[i] || v
            ),
          }
        } else {
          newExtraJson.push(column)
        }
      })
      setExtraJson(newExtraJson)
      const remainAdditionalColumns = slice(
        newData,
        0,
        originAdditionalJson.length
      )
      setDataAdditionalJson(remainAdditionalColumns)
    }
  }
  const handleClickAddAdditionalCol = (event) => {
    event.stopPropagation()
    if (projectComponentHistory.status === STATUS_BUILD.COMMITTED) return
    isAddColumn.setValue(true)
    setExtraJson(
      extraJson.concat({
        idColumn: "",
        key: "",
        values: tableAddColumn,
      })
    )
    scrollRight()
  }
  const handleCancelAddColumn = (successAPI?: boolean) => {
    if (!isAddColumn) {
      return false
    }
    isAddColumn.setValue(false)
    isUpdatingAdditionalColumn.setValue(false)

    if (!successAPI) {
      setExtraJson([])
      setDataAdditionalJson(originAdditionalJson)
    }
  }
  const handleOpenModelDelete = (type: string) => {
    isDeleteColumn.setValue(true)
    keyDeleteColumn.setValue(type)
  }
  const onCloseModalDelete = () => {
    isDeleteColumn.setValue(false)
    keyDeleteColumn.setValue("")
  }
  const deleteAdditional = () => {
    isLoading.setValue(true)
    const newDataTableAdditional = cloneDeep(originAdditionalJson)
    remove(
      newDataTableAdditional,
      (el) => `${el.key}${el.idColumn}` === keyDeleteColumn.value
    )
    inviteeBomAddAdditionalColMiddleware(
      projectComponentHistory.id,
      conversationId,
      converDataTableColumnBOM(newDataTableAdditional),
      componentType
    )
      .then((_res) => {
        isDeleteColumn.setValue(false)
        keyDeleteColumn.setValue("")
        setDataAdditionalJson(newDataTableAdditional)
        setOriginAdditionalJson(newDataTableAdditional)
        toast(
          <LabelNotificationPage
            messenger={"Delete column successfully!"}
            type={"success"}
          />
        )
      })
      .catch((error) => {
        toast(
          <LabelNotificationPage
            messenger={error.response?.data?.message}
            type={"error"}
          />
        )
      })
  }
  const onDeleteColumn = () => {
    if (!projectComponentHistory || !keyDeleteColumn.value) {
      console.log("in this?")
      return
    }
    deleteAdditional()
  }
  const onSaveAddAdditionalColumn = () => {
    if (!projectComponentHistory) {
      return
    }
    const newDataTableBOM = dataAdditionalJson.concat(extraJson)
    // check if user leave header empty
    let isEmptyHeader = false
    newDataTableBOM.forEach((i) => {
      if (i.key === "") {
        isEmptyHeader = true
      }
    })
    //
    if (isEmptyHeader) {
      toast(
        <LabelNotificationPage
          messenger="Required to enter header table!"
          type="warning"
        />
      )
      return
    }
    //
    isLoading.setValue(true)
    inviteeBomAddAdditionalColMiddleware(
      projectComponentHistory.id,
      conversationId,
      converDataTableColumnBOM(newDataTableBOM),
      componentType
    )
      .then((res) => {
        toast(
          <LabelNotificationPage
            messenger={"Add column successfully"}
            type={"success"}
          />
        )
        handleCancelAddColumn(true)
        updateDataWhenChangeColumn(() => {
          scrollRight()
        })
      })
      .catch((error) => {
        toast(
          <LabelNotificationPage
            messenger={error.response?.data?.message || "not ok"}
            type={"error"}
          />
        )
      })
  }
  const renderAddAdditionalColBtn = () => {
    if (
      conversationRole !== CONVERSATION_ROLE.INVITEE ||
      projectComponentHistory.status === STATUS_BUILD.COMMITTED ||
      projectComponentHistory.status === STATUS_BUILD.SAVE
    )
      return null
    if (isAddColumn.value || isUpdatingAdditionalColumn.value) {
      return (
        <div className="flex">
          <Button
            title="Cancel"
            colorBtn="white"
            sizeBtn="small"
            onClick={() => handleCancelAddColumn()}
            styleButton={{
              color: "#EA4545",
              marginRight: 12,
            }}
          />
          <Button
            title="Save"
            sizeBtn="small"
            onClick={onSaveAddAdditionalColumn}
          />
        </div>
      )
    }
    return (
      <ButtonHasIcon
        title="Add"
        widthButton={80}
        heightButton={28}
        textSize={12}
        textLineHeight={18}
        textWeight={500}
        onClick={handleClickAddAdditionalCol}
      />
    )
  }

  const renderActionButton = () => {
    if (!isInvitee) {
      return (
        <ActionIconHistory
          icon="download"
          tooltip="Download"
          onClick={onExportFile}
          styleIcon={{
            marginRight: 0,
          }}
        />
      )
    }
    const titleButton = isHasColumn.value ? "Edit" : "Recommend Alt Part"
    const widthButtonTitle = isHasColumn.value ? 47 : 142
    return (
      <React.Fragment>
        <Button
          title="Export"
          sizeBtn="small"
          colorBtn="white"
          onClick={onExportFile}
          styleButton={{
            color: "#EA4545",
          }}
        />

        {renderAddAdditionalColBtn()}
        {!isCloseConversation &&
        projectComponentHistory.approved_status ===
          VERSION_APPROVED_STATUS.APPROVED &&
        conversationRole === CONVERSATION_ROLE.INVITEE &&
        componentType === ComponentType.BOM ? (
          <Button
            title={titleButton}
            sizeBtn="small"
            colorBtn="white"
            widthBtn={widthButtonTitle}
            onClick={onRecommendAltPart}
            styleButton={{
              marginLeft: 12,
            }}
          />
        ) : null}
      </React.Fragment>
    )
  }

  const renderModalDelete = () => {
    if (!isDeleteColumn.value) {
      return null
    }

    const { newKey } = converNameHeaderTableAdditional(keyDeleteColumn.value)

    return (
      <ModalDelete
        onCloseModal={onCloseModalDelete}
        title={`Are you sure to delete ${newKey}`}
        label={`Confirmation`}
        onSubmit={onDeleteColumn}
        titleButton="Delete"
      />
    )
  }
  return (
    <div className="flex flex-col mr-6 mt-6">
      <div className="flex justify-between items-end h-7">
        <p
          className="font-semibold"
          style={{
            fontSize: 16,
            lineHeight: "26px",
            color: "#111111",
          }}
        >
          BOM Detail
        </p>
        <div className="flex items-center">
          <p
            className="font-normal"
            style={{
              fontSize: 12,
              lineHeight: "18px",
              color: "#7A7A7A",
            }}
          >
            Last update:
            {updatedAtBOM
              ? ` ${formatDateForConversationMessage(updatedAtBOM)}`
              : " No update"}
          </p>

          <div className="hidden md:flex md:items-center">
            <div
              className="mx-3"
              style={{
                width: 1,
                height: 13,
                backgroundColor: "#E4E4E4",
              }}
            ></div>
            {recommendAltPart.value ? (
              <React.Fragment>
                <Button
                  title="Cancel"
                  sizeBtn="small"
                  colorBtn="white"
                  onClick={onCancelColumn}
                  styleButton={{
                    color: "#EA4545",
                  }}
                />
                <Button
                  title="Add column"
                  sizeBtn="small"
                  colorBtn="white"
                  onClick={onAddMoreColumn}
                  styleButton={{
                    marginRight: 12,
                    marginLeft: 12,
                  }}
                />
                <Button title="Send" sizeBtn="small" onClick={onSendColumn} />
              </React.Fragment>
            ) : (
              renderActionButton()
            )}
          </div>
        </div>
      </div>

      <div>
        <div
          className="flex items-center"
          ref={refTable}
          style={{
            overflow: "auto",
            transform: "rotateX(180deg)",
            marginTop: 5,
          }}
        >
          <div
            className="flex items-center"
            style={{
              transform: "rotateX(180deg)",
            }}
          >
            <TableBOMItem
              headers={headers}
              dataBody={BOMJson}
              isAddColumn={isHasColumn.value}
              lengthSupplier={0}
              lengthAdditinal={additionalJson.length}
              lengthInviteeBOM={0}
            />
            {dataAdditionalJson.length ? (
              <TableAdditionalJSON
                dataAdditionalJson={dataAdditionalJson}
                defaultBomValues={tableAddColumn}
                isDisable={
                  projectComponentHistory.status === STATUS_BUILD.COMMITTED
                }
                isAddColumn={isAddColumn.value}
                lengthInviteeBOM={isHasColumn.value}
                setDataAdditionalJson={handleUpdateAdditionalJson}
                isUpdateColumn={isUpdatingAdditionalColumn}
                handleOpenModelDelete={handleOpenModelDelete}
              />
            ) : null}
            {isHasColumn.value ? (
              <InviteeTableBOMAddColumn
                isDisable={!recommendAltPart.value}
                dataAddColumns={dataAddColumns}
                setDataAddColumns={setDataAddColumns}
                defaultBomValues={defaultBomValues}
              />
            ) : null}
            {!isEmpty(extraJson)
              ? extraJson.map((_item, index) => (
                  <TableBOMAddColumn
                    key={index}
                    jsonIndex={index}
                    extraJson={extraJson}
                    setExtraJson={setExtraJson}
                  />
                ))
              : null}
          </div>
        </div>
      </div>
      {renderModalDelete()}
    </div>
  )
}
export default InviteeBOMDetailCard
