import { settingTwoFactor } from "../services/api"
import { useAppSelector } from "hooks/useApp"
import Button from "components/Button/Button"
import { configureStore } from "stores/configureStore"
import { openModal, closeModal } from "reducers/modal"
import { umamiTracking } from "helpers/utils"
import { EVENT } from "constants/events"

interface TwoFactorFormProps {
  handleSubmit: (type: "enable" | "disable") => void
}

const EnableTwoFactorForm = (props: TwoFactorFormProps) => {
  const { handleSubmit } = props
  return (
    <>
      <p className="text-sm text-black font-semibold">Secure your account</p>
      <p className="mt-2 mb-6">
        Two-factor authentication adds an extra layer of security to your
        account. To log in, you'll need to provide a code along with your
        username and password. This let us know it's actually you.
      </p>
      <div className="flex items-center justify-between mt-3">
        <Button
          title="Enable Two-Factor Authentication"
          widthBtn={"100%"}
          onClick={() => handleSubmit("enable")}
        />
      </div>
    </>
  )
}
const DisableTwoFactorForm = (props: TwoFactorFormProps) => {
  const { handleSubmit } = props
  const onSubmit = () => {
    configureStore.dispatch(
      openModal({
        type: "Delete",
        props: {
          deleteModal: {
            title: `This reduces the security level of your account and is not recommended.`,
            label: `Disable two-factor authentication`,
            content: `Are you sure you want to continue?`,
            onSubmit: onDisableTwoFactor,
            titleButton: `Yes, Disable 2FA`,
            styleTitle: {
              textAlign: "center",
            },
          },
        },
      })
    )
  }

  const onDisableTwoFactor = () => {
    handleSubmit("disable")
    closeModal()
  }

  return (
    <div>
      <p className="text-sm text-black font-semibold">
        Two-Factor Authentication
      </p>
      <p className="mt-2 mb-6">
        Along with your email and password, you will be asked to verify your
        identity.
      </p>
      <div className="flex items-center justify-between mt-3">
        <Button
          title="Disable Two-Factor Authentication"
          widthBtn={"100%"}
          onClick={onSubmit}
        />
      </div>
    </div>
  )
}

const TwoFactorSetting = () => {
  const userInfo = useAppSelector((state) => state.userInfo)
  const onUpdateTwoFactor = (type: "enable" | "disable") => {
    if (type === "enable") {
      umamiTracking(EVENT.ENABLE_2FA)
    }
    settingTwoFactor(type)
  }
  return (
    <div className="flex flex-col">
      {userInfo.enable_2fa ? (
        <DisableTwoFactorForm handleSubmit={onUpdateTwoFactor} />
      ) : (
        <EnableTwoFactorForm handleSubmit={onUpdateTwoFactor} />
      )}
    </div>
  )
}
export default TwoFactorSetting
