import { useString } from "helpers/hooks"
import InputDefault from "./InputDefault"
import { ReactComponent as CloseIcon } from "assets/images/icons/close-icon.svg"
import { isValidEmail } from "helpers/utils"
interface Props {
  emails: string[]
  setEmails: (emails: string[]) => void
}
const EmailChipInput = (props: Props) => {
  const { emails, setEmails } = props
  const input = useString()
  const handleChange = (evt) => {
    input.setValue(evt.target.value)
  }

  const handleKeyDown = (event) => {
    if (["Enter", "Tab"].includes(event.key)) {
      event.preventDefault()
      const value = input.value.trim()
      if (value && isValid(value)) {
        setEmails([...emails, value.toLowerCase()])
        input.setValue("")
      }
    }
  }
  const handlePaste = (event) => {
    event.preventDefault()
    const paste = event.clipboardData.getData("text")
    const addedEmails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g)

    if (addedEmails) {
      const toBeAdded = addedEmails
        .filter((email: string) => !emails.includes(email.toLowerCase()))
        .map((email) => email.toLowerCase())
      setEmails([...emails, ...toBeAdded])
      input.setValue("")
    }
  }
  const handleDelete = (deletedEmail: string) => {
    setEmails(emails.filter((email) => email !== deletedEmail))
  }
  const isValid = (email: string) => {
    return isValidEmail(email) && !emails.includes(email.toLowerCase())
  }
  return (
    <>
      <InputDefault
        onPaste={handlePaste}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        value={input.value}
      ></InputDefault>
      {emails.length !== 0 && (
        <div className="flex flex-wrap gap-2 mt-3">
          {emails.map((email) => (
            <div
              className="flex items-center bg-neutral3 gap-2 py-1 px-2 rounded-4 border-[1px] border-stroke "
              key={email}
            >
              <p className="text-[12px] text-black leading-[18px] font-medium">
                {email}
              </p>
              <CloseIcon
                className="w-4 h-4 cursor-pointer hover-action-icon"
                onClick={() => handleDelete(email)}
              />
            </div>
          ))}
        </div>
      )}
    </>
  )
}
export default EmailChipInput
