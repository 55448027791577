import { LabelChangeRouterProps } from "../types"
import { pushTo } from "helpers/history"

const LabelChangeRouter = (props: LabelChangeRouterProps) => {
  const { label, path, title } = props
  const handleChangeRouter = () => {
    pushTo(path)
  }
  return (
    <div className="flex items-center mt-6">
      <p
        className="font-normal mr-2"
        style={{
          lineHeight: "24px",
          color: "#111111",
          fontSize: 14,
        }}
      >
        {title}
      </p>
      <p
        className="font-normal cursor-pointer hover:underline"
        onClick={handleChangeRouter}
        style={{
          lineHeight: "22px",
          color: "#0A5AF5",
          fontSize: 13,
        }}
      >
        {label}
      </p>
    </div>
  )
}

export default LabelChangeRouter
