import React, { useContext } from "react"
import { SpecificationSingleCheckCardProps } from "../types"
import { showImageUrl, fieldFormDataSpecification } from "helpers/utils"
import SingleCheckCardItem from "../atoms/SingleCheckCardItem"
import InputDefault from "components/Input/InputDefault"
import { VersionSpecificationContext } from "../contexts/VersionSpecification.context"
import { API_URL } from "config/environments"

const SpecificationSingleCheckCard = (
  props: SpecificationSingleCheckCardProps
) => {
  const { item, isInput, disabled = false } = props
  const context = useContext(VersionSpecificationContext)
  const handleChangeInput = (event) => {
    const newValue = event.target?.value
    context?.updateDataForm(
      "formData",
      fieldFormDataSpecification(item.id, item.sequence),
      newValue,
      Boolean(item.required)
    )
  }
  const renderValueInput = () => {
    const newValue = item.answers.find(
      (el) =>
        el.name ===
        context?.formData[
          `${fieldFormDataSpecification(item.id, item.sequence)}`
        ]
    )
      ? ""
      : context?.formData[
          `${fieldFormDataSpecification(item.id, item.sequence)}`
        ] ?? ""
    if (item.has_see_stackup && newValue.trim() === "See stackup") {
      return {
        oldValue: "",
        styleClass: "",
      }
    }
    return {
      oldValue: newValue,
      styleClass: newValue ? "active-input-specification" : "",
    }
  }

  return (
    <div
      className="flex items-center"
      style={{
        flexFlow: "row wrap",
      }}
    >
      {item.answers.map((answer, index) => (
        <SingleCheckCardItem
          key={index}
          answer={answer}
          idPart={item.id}
          sequencePart={item.sequence}
          disabled={disabled}
        />
      ))}
      {isInput ? (
        <InputDefault
          disabled={disabled}
          value={renderValueInput().oldValue}
          placeholder="Other"
          onChange={handleChangeInput}
          styleRootInput={{
            width: "auto",
          }}
          classCustom={`h-[32px] w-80-custom border-input bg-white rounded px-2 focus:outline-none mr-2 mb-2 text-center ${
            renderValueInput().styleClass
          } `}
        />
      ) : null}
      {item.has_see_stackup ? (
        <SingleCheckCardItem
          answer={{
            name: "See stackup",
            created_at: "",
            helper_text: "",
            id: "",
            icon: "",
            placeholder: "",
            sequence: 1000,
            specification_part_id: "",
          }}
          idPart={item.id}
          sequencePart={item.sequence}
          disabled={disabled}
        />
      ) : null}

      {item.helper_text ? (
        <p
          style={{
            fontSize: 14,
            lineHeight: "24px",
            color: "#7A7A7A",
          }}
          className="font-normal mr-2 mb-2"
        >
          {item.helper_text}
        </p>
      ) : null}
      {item.image ? (
        <img
          src={showImageUrl(item.image, API_URL)}
          alt="icon"
          className="mb-2"
          style={{
            height: 32,
          }}
        />
      ) : null}
    </div>
  )
}

export default SpecificationSingleCheckCard
