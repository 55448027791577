import { ReactComponent as Logo } from "assets/images/logo-tracelium-terms-policy.svg"
import { ReactComponent as IconGetStarted } from "assets/images/icon-get-started-term-policy.svg"

import { useBoolean, useString, useWindowSize } from "helpers/hooks"
import {
  FIELD_MENU_TERM_AND_POLICY,
  menuTermAndPolicy,
} from "./terms-policy.type"
import { pushTo } from "helpers/history"
import { PATH } from "constants/path"
import { useEffect, useRef, useState } from "react"
import { PrivacyDetail } from "pages/auth/types"
import {
  getPrivacyPolicyMiddleware,
  getTermOfUseMiddleware,
} from "pages/auth/services/api"
import PrivacyPolicyCard from "pages/auth/molecules/PrivacyPolicyCard"
import { useLocation } from "react-router-dom"
import Button from "components/Button/Button"
import { umamiTracking } from "helpers/utils"
import { EVENT } from "constants/events"

const PATHNAME = {
  [FIELD_MENU_TERM_AND_POLICY.TERM]: PATH.termsAndServices,
  [FIELD_MENU_TERM_AND_POLICY.POLICY]: PATH.policies,
}
const TermsAndPolicyPage = () => {
  const location = useLocation<any>()
  const activeMenu = useString(FIELD_MENU_TERM_AND_POLICY.TERM)
  const isLoading = useBoolean(false)
  const refPage = useRef<HTMLDivElement>(null)

  const { isMobile } = useWindowSize()

  const [privacyPolicy, setPrivacyPolicy] = useState<PrivacyDetail>({
    parts: [],
    updated_at: "",
  })
  const [termOfUse, setTermOfUse] = useState<PrivacyDetail>({
    parts: [],
    updated_at: "",
  })
  useEffect(() => {
    if (location.pathname === PATH.termsAndServices) {
      activeMenu.setValue(FIELD_MENU_TERM_AND_POLICY.TERM)
    }
    if (location.pathname === PATH.policies) {
      activeMenu.setValue(FIELD_MENU_TERM_AND_POLICY.POLICY)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getDataPrivacy()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getDataPrivacy = async () => {
    isLoading.setValue(true)
    const calls: Promise<PrivacyDetail>[] = []
    calls.push(getPrivacyPolicyMiddleware())
    calls.push(getTermOfUseMiddleware())

    const [dataResPrivacy, dataResTermOfUser] = await Promise.all(calls)
    setPrivacyPolicy(dataResPrivacy)
    setTermOfUse(dataResTermOfUser)
    isLoading.setValue(false)
  }

  const onChangeMenu = (newField: FIELD_MENU_TERM_AND_POLICY) => () => {
    if (newField === activeMenu.value) {
      return
    }
    pushTo(PATHNAME[newField])
    umamiTracking(EVENT[newField])
    activeMenu.setValue(newField)
    if (refPage.current && !isMobile) {
      refPage.current.scroll(0, 0)
      refPage.current.scrollIntoView({ behavior: "smooth" })
    }
  }

  const renderBody = () => {
    switch (activeMenu.value) {
      case FIELD_MENU_TERM_AND_POLICY.POLICY:
        return (
          <PrivacyPolicyCard
            name="Privacy Policy"
            update={privacyPolicy.updated_at}
            listPrivacy={privacyPolicy.parts}
          />
        )
      default:
        return (
          <PrivacyPolicyCard
            name="Terms and Conditions"
            update={termOfUse.updated_at}
            listPrivacy={termOfUse.parts}
          />
        )
    }
  }

  const renderTabContent = () => (
    <div className="md:pt-0 flex justify-between md:flex-col sm:justify-start pt-6 md:bg-transparent bg-grayWhite2 ">
      {menuTermAndPolicy.map((el, index) => (
        <div
          onClick={onChangeMenu(el.field)}
          className="cursor-pointer md:mb-4"
          key={index}
        >
          <p
            className="font-semibold text-14 leading-24 m-0"
            style={{
              color: activeMenu.value === el.field ? "#111111" : "#7A7A7A",
            }}
          >
            {el.label}
          </p>
        </div>
      ))}
    </div>
  )

  return (
    <div className="flex flex-col md:w-full height-screen ">
      <div className="md:bg-transparent flex items-center px-6 min-h-[56px] bg-[#222222] md">
        <Logo
          onClick={() => pushTo(PATH.login)}
          className="cursor-pointer min-h-6"
        />
      </div>

      <div
        className=" flex justify-center h-full bg-grayWhite2 overflow-auto scrollbar-stable"
        ref={refPage}
      >
        <div className="w-full md:grid relative md:max-w-[984px] md:grid-cols-[216px_calc(100%_-_216px)] flex flex-col ">
          {/* config to scroll fixed  */}
          <div className="md:flex md:flex-col ">
            {/* tab content */}
            <div className="w-full md:ml-6 fixed md:top-[80px] md:w-[216px] md:flex md:flex-col">
              {renderTabContent()}
              <div
                className="md:flex md:flex-col  bg-white p-4 hidden"
                style={{
                  border: "1px solid #E4E4E4",
                  borderRadius: 4,
                }}
              >
                <div className="items-center justify-center mb-4">
                  <IconGetStarted />
                </div>
                <p className="font-semibold text-16 leading-26 color-black-111111">
                  Ready to start
                </p>
                <p className="font-semibold text-16 leading-26 color-black-111111 mb-4">
                  your first project?
                </p>
                <Button
                  widthBtn="100%"
                  title="Get Started"
                  onClick={() => pushTo(PATH.login)}
                />
              </div>
            </div>
          </div>

          {/* main content */}
          <div className="px-6 mt-[72px] pb-6  md:ml-6 md:my-6 ">
            <div
              className="p-6 bg-white w-full"
              style={{
                border: " 1px solid #E4E4E4",
                borderRadius: 4,
              }}
            >
              {renderBody()}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default TermsAndPolicyPage
