import { TippyCustomzie } from "components/TippyCustomzie"
import { useBoolean } from "helpers/hooks"
import { ProjectComponentDetail } from "../types"
import { ProjectComponentStatus } from "components/Status/types"

interface Props {
  components: ProjectComponentDetail[]
  isArchive: boolean
  isDeleted?: boolean
}
const LabelComponentBuildCard = (props: Props) => {
  const { components, isArchive, isDeleted } = props
  const showMoreComponent = useBoolean(false)
  return (
    <div className="flex items-center flex-wrap">
      {components.map((component, index) => {
        if (index > 2) {
          return null
        }
        return (
          <ComponentItem
            key={index}
            isArchive={isArchive}
            component={component}
            isDeleted={isDeleted}
          />
        )
      })}
      {components.length > 3 ? (
        <TippyCustomzie
          containerClass="component-card-menu"
          placement="bottom-end"
          interactive
          arrow={false}
          animation="scale"
          visible={showMoreComponent.value}
          onClickOutside={() => showMoreComponent.setValue(false)}
          content={
            <div
              className="bg-white p-2 flex flex-col"
              style={{
                width: 240,
                boxShadow: "0px 2px 10px rgba(29, 29, 29, 0.12)",
                borderRadius: 6,
              }}
            >
              {components.map((component, index) => {
                if (index < 3) {
                  return null
                }
                return (
                  <ComponentItem
                    key={index}
                    isArchive={isArchive}
                    component={component}
                    isDeleted={isDeleted}
                  />
                )
              })}
            </div>
          }
          allowHTML
        >
          <div
            onClick={() => showMoreComponent.setValue(!showMoreComponent.value)}
            className="flex items-center justify-center show-more-list-build-component"
            style={{
              width: 20,
              height: 20,
              borderRadius: 6,
            }}
          >
            <p
              className="font-semibold cursor-pointer"
              style={{
                fontSize: 10,
                lineHeight: "14px",
                color: isArchive ? "#C7C7C7" : "#0A5AF5",
              }}
            >{`+${components.length - 3}`}</p>
          </div>
        </TippyCustomzie>
      ) : null}
    </div>
  )
}
export default LabelComponentBuildCard

const ComponentItem = (props: {
  isArchive: boolean
  component: ProjectComponentDetail
  isDeleted?: boolean
}) => {
  const { component, isArchive, isDeleted } = props
  const chipDeleted =
    component.status === ProjectComponentStatus.DeletedAttachment || isDeleted
  return (
    <div
      className={`flex items-center w-fit ${
        chipDeleted
          ? "bg-neutral3 bg-chip-deleted"
          : isArchive
          ? "bg-neutral3"
          : "bg-grayWhite2"
      }`}
      style={{
        border: "1px solid #E4E4E4",
        borderRadius: 4,
        height: 20,
        marginRight: 4,
        marginBottom: 4,
      }}
    >
      <div
        className="flex items-center h-full"
        style={{
          borderRight: "1px solid #E4E4E4",
          paddingLeft: 4,
          paddingRight: 4,
        }}
      >
        <p
          className="font-semibold"
          style={{
            fontSize: 10,
            lineHeight: "14px",
            color: isArchive ? "#C7C7C7" : "#7A7A7A",
            whiteSpace: "nowrap",
          }}
        >
          {component.type?.key || ""}
        </p>
      </div>
      <p
        className="font-semibold"
        style={{
          fontSize: 10,
          lineHeight: "14px",
          paddingLeft: 4,
          paddingRight: 4,
          color: isArchive ? "#C7C7C7" : "#7A7A7A",
          whiteSpace: "nowrap",
        }}
      >
        {component.code || ""}
      </p>
    </div>
  )
}
