import { onRedirectProjectComponent } from "helpers/redirect"
import {
  BuildHistoryDetail,
  TAB_URL_PROJECT_COMPONENT,
} from "pages/project-component-detail/types"
// import { COMMIT_DEFAULT } from "../history-tree.constant"
import { converCommitHistoryTree } from "../history-tree.utils"
import { findWithRegex } from "helpers/utils"
import { isEmpty } from "lodash"
import { VERSION_APPROVED_STATUS } from "types"

interface Props {
  titlePage: string
  history: BuildHistoryDetail
  histories: BuildHistoryDetail[]
  typeComponent: string
}
const InfoCommitCard = (props: Props) => {
  const { titlePage, history, typeComponent, histories } = props
  const { newCommit, newForm } = converCommitHistoryTree(history, titlePage)
  const componentCode =
    findWithRegex(newForm, /CM-[0-9]{4}/g) ||
    findWithRegex(newForm, /PR[0-9]{4}-CM[0-9]/g)
  const versionCode =
    findWithRegex(newForm, /V[0-9]{4}/g) ||
    findWithRegex(newForm, /V-[0-9]{4}/g)

  const onRedirectToMainComponent = (event) => {
    event.preventDefault()
    event.stopPropagation()
    onRedirectProjectComponent(
      TAB_URL_PROJECT_COMPONENT.COMPONENT,
      typeComponent,
      history.transcended_from?.project_component_id || "",
      "history"
    )
  }
  const onRedirect = (event) => {
    event.preventDefault()
    event.stopPropagation()
    if (titlePage === TAB_URL_PROJECT_COMPONENT.BUILD_COMPONENT) {
      if (history.reverted_from) {
        onRedirectProjectComponent(
          TAB_URL_PROJECT_COMPONENT.BUILD_COMPONENT,
          typeComponent,
          history.project_component_id,
          history.project_build_component_id,
          {
            idHistoryTreeState: history.reverted_from,
          }
        )
        return
      }

      onRedirectProjectComponent(
        TAB_URL_PROJECT_COMPONENT.COMPONENT,
        typeComponent,
        history.project_component_id,
        "history",
        {
          idHistoryTreeState: history.id,
        }
      )
      return
    }
    if (history.transcended_from && !isEmpty(history.transcended_from)) {
      onRedirectProjectComponent(
        TAB_URL_PROJECT_COMPONENT.COMPONENT,
        typeComponent,
        history.transcended_from.project_component_id,
        "history",
        {
          idHistoryTreeState:
            history.transcended_from.project_component_history_id,
        }
      )
      return
    }

    //
    const isViewingSyncFromMain =
      history.commit.indexOf("Version has been synced from master") > -1 &&
      titlePage === TAB_URL_PROJECT_COMPONENT.COMPONENT
    if (isViewingSyncFromMain) {
      const syncedHistory = histories.find(
        (h) => h.code === versionCode && h.branch.toLowerCase() === "master"
      )
      if (syncedHistory) {
        onRedirectProjectComponent(
          TAB_URL_PROJECT_COMPONENT.COMPONENT,
          typeComponent,
          history.project_component_id,
          "history",
          {
            idHistoryTreeState: syncedHistory.id,
          }
        )
        return
      }
    }

    if (!history.project_build_component_id && history.reverted_from) {
      onRedirectProjectComponent(
        TAB_URL_PROJECT_COMPONENT.COMPONENT,
        typeComponent,
        history.project_component_id,
        "history",
        {
          idHistoryTreeState: history.reverted_from,
        }
      )
      return
    }
    onRedirectProjectComponent(
      TAB_URL_PROJECT_COMPONENT.BUILD_COMPONENT,
      typeComponent,
      history.project_component_id,
      history.from_build_component_id
        ? history.from_build_component_id
        : history.project_build_component_id,
      {
        idHistoryTreeState: history.reverted_from
          ? history.reverted_from
          : history.id,
      }
    )
  }
  const renderTextForm = () => {
    if (!newCommit) {
      return ""
    }
    if (newForm) {
      return newForm
    }
    if (
      history.project_build_component_id &&
      titlePage === TAB_URL_PROJECT_COMPONENT.COMPONENT
    ) {
      return history.branch
    }
    return ""
  }

  return (
    <div className="flex items-center flex-nowrap">
      <p
        className={`font-normal text-13 leading-22 ${
          history.approved_status === VERSION_APPROVED_STATUS.UNAPPROVED
            ? "text-gray"
            : "text-[#111111]"
        } whitespace-nowrap`}
      >
        {newCommit}
      </p>
      {!isEmpty(history.transcended_from) && history.transcended_from ? (
        <p className="ml-1">
          {componentCode ? (
            <>
              {" "}
              <span
                onClick={onRedirectToMainComponent}
                className="whitespace-nowrap font-nornal text-13 leading-22 text-[#0A5AF5] hover:underline"
              >
                {componentCode}
              </span>
              {" -"}
            </>
          ) : null}
          {versionCode ? (
            <>
              {" "}
              <span
                onClick={onRedirect}
                className="whitespace-nowrap font-nornal text-13 leading-22 text-[#0A5AF5] hover:underline"
              >
                {" "}
                {versionCode}
              </span>
            </>
          ) : null}
        </p>
      ) : (
        <p
          onClick={onRedirect}
          className="whitespace-nowrap font-nornal text-13 leading-22 ml-1 text-[#0A5AF5] hover:underline"
        >
          {renderTextForm()}
        </p>
      )}
    </div>
  )
}
export default InfoCommitCard
