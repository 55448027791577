import Axios from "axios"
import { StorageUsage, StorageUsageResponse } from "./StorageUsage.type"

export const getStorageUsage = async () => {
  return Axios.get<{ data: StorageUsageResponse }>(`/api/project/usage`).then(
    (response) => {
      const temp = response.data.data
      return {
        ...temp,
        projects: temp.projects.reduce((pre, cur) => {
          pre = pre
            .concat([
              {
                id: cur.id,
                name: cur.name,
                code: cur.code,
                size: cur.size,
                type: cur.type,
              },
            ])
            .concat(
              cur.components.map((item) => ({
                id: item.id,
                name: item.name,
                code: item.code,
                size: item.size,
                type: item.type,
                project_id: cur.id,
                sub_type: item.sub_type,
              }))
            )
            .concat(
              cur.builds.map((item) => ({
                id: item.id,
                name: item.name,
                code: item.code,
                size: item.size,
                type: item.type,
                project_id: cur.id,
                sub_status: item.sub_status,
                owner: item.owner,
                is_archived: item.is_archived,
              }))
            )
          return pre
        }, [] as StorageUsage[]),
      }
    }
  )
}
