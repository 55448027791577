import PageLayout from "pages/layout/PageLayout"
import HeaderInviteeComponent from "./HeaderInviteeComponent"
import InfoInviteeComponentDetail from "./InfoInviteeComponentDetail"
import { ProjectComponentDetail } from "pages/project-component/types"
import { ProjectComponentBuildDetail } from "pages/project-build/project-build.type"
import { ProjectDetail } from "pages/projects/types"
import {
  BUILD_INVITEE_DETAIL_STATUS,
  CONVERSATION_ROLE,
  ConversationMessagesDetail,
} from "pages/conversations/conversations.type"
import { UserInfo } from "pages/auth/types"
import { STATUS_BUILD } from "components/Status/types"
import { BuildHistoryDetail } from "pages/project-component-detail/types"
import { checkDisableButtonHistoryTree } from "./services/invitee-component-detail.helpers"
interface Props {
  children: React.ReactNode
  componentDetail: ProjectComponentDetail
  updatedAtProjectComponent: string
  projectBuild: ProjectComponentBuildDetail
  idConversationParam: string
  idProjectBuildComponentParam: string
  conversationMessages: ConversationMessagesDetail
  projectDetail: ProjectDetail
  idProjectComponentHistoryParam: string
  nameBOM: string
  userInfo: UserInfo
  isReadComponent: {
    value: boolean
    setValue: (newValue: boolean) => void
  }
  isLoading: boolean
  histories: BuildHistoryDetail[]
  versionHistory: BuildHistoryDetail
  isDeleted?: boolean
  handleUpdateDataWhenChangeHistory?: () => void
  conversationRole: CONVERSATION_ROLE
  commitButtonStage?: {
    isActive: boolean
    tooltipHelper: string
  }
  specificationRequired?: string[]
  isUpdatingBom?: boolean
  setHistoryDetail: React.Dispatch<React.SetStateAction<BuildHistoryDetail>>
}

const PageInviteeComponent = (props: Props) => {
  const {
    children,
    componentDetail,
    updatedAtProjectComponent,
    projectBuild,
    idConversationParam,
    idProjectBuildComponentParam,
    conversationMessages,
    projectDetail,
    idProjectComponentHistoryParam,
    nameBOM,
    userInfo,
    isReadComponent,
    isDeleted,
    versionHistory,
    setHistoryDetail,
    histories,
    handleUpdateDataWhenChangeHistory,
    conversationRole,
    commitButtonStage,
    specificationRequired = [],
    isUpdatingBom = false,
  } = props

  const { isDisableEditHistory, isDisableRevertHistory } =
    checkDisableButtonHistoryTree(histories, versionHistory)
  return (
    <PageLayout
      childrenHeader={
        <HeaderInviteeComponent
          projectComponentDetail={componentDetail}
          updatedAtProjectComponent={updatedAtProjectComponent}
          projectBuild={projectBuild}
          idConversation={idConversationParam}
          idProjectBuildComponentParam={idProjectBuildComponentParam}
          convoID={conversationMessages.conversation_code}
          projectDetail={projectDetail}
          conversationRole={conversationRole}
        />
      }
      heightHeader={48}
      minWidth="min-w-[1100px]"
    >
      <div className="flex flex-col h-full justify-between">
        <InfoInviteeComponentDetail
          projectComponentDetail={componentDetail}
          idProjectBuildComponentParam={idProjectBuildComponentParam}
          idConversationParam={idConversationParam}
          isSentInvite={Boolean(conversationMessages.invitee.is_sent)}
          isInvitee={userInfo.id === conversationMessages.invitee.user_id}
          idProjectComponentHistoryParam={idProjectComponentHistoryParam}
          isDownload={true}
          nameBOM={nameBOM}
          isCloseConversation={
            BUILD_INVITEE_DETAIL_STATUS[
              conversationMessages.invitee.in_production_status
            ] === BUILD_INVITEE_DETAIL_STATUS[3] ||
            projectBuild.status === STATUS_BUILD.CANCELLED ||
            Boolean(projectBuild.is_archived)
          }
          setIsReadComponent={isReadComponent.setValue}
          isReadComponent={isReadComponent.value}
          historyDetail={versionHistory}
          isDeleted={isDeleted}
          handleUpdateDataWhenChangeHistory={
            handleUpdateDataWhenChangeHistory
              ? handleUpdateDataWhenChangeHistory
              : () => {}
          }
          conversationRole={conversationRole}
          commitButtonStage={commitButtonStage}
          isDisableEditHistory={isDisableEditHistory}
          isDisableRevertHistory={isDisableRevertHistory}
          extraConditionsToDisableCommitBtn={{
            isUpdatingBom,
            specificationRequired,
          }}
          setHistoryDetail={setHistoryDetail}
        />
        {children}
      </div>
    </PageLayout>
  )
}
export default PageInviteeComponent
