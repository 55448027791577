import { useState, useEffect } from "react"
import { TableColumnBOMDetail } from "../types"
import { cloneDeep } from "lodash"
import { handlePasteFromCell } from "../../../helpers/bom"

interface Props {
  jsonIndex: number
  extraJson: TableColumnBOMDetail[]
  setExtraJson: (newValue: TableColumnBOMDetail[]) => void
}

const TableBOMAddColumn = (props: Props) => {
  const { jsonIndex, extraJson, setExtraJson } = props

  const [extraData, setExtraData] = useState<TableColumnBOMDetail>(
    extraJson[jsonIndex]
  )

  useEffect(() => {
    const newExtraJson = cloneDeep(extraJson)
    newExtraJson[jsonIndex] = extraData
    setExtraJson(newExtraJson)
  }, [extraData.key, JSON.stringify(extraData.values)])

  useEffect(() => {
    setExtraData(extraJson[jsonIndex])
  }, [JSON.stringify(extraJson[jsonIndex])])

  const handleChangeInput =
    (oldIndex: number, type: "header" | "body") => (event: any) => {
      const inputValue = event.target.value
      if (type === "header") {
        setExtraData({ ...extraData, key: inputValue })
        return
      }
      const newData = cloneDeep(extraData.values)
      newData[oldIndex] = inputValue
      setExtraData({ ...extraData, values: newData })
    }

  const onPaste =
    (rowIndex: number, type: "header" | "body") => (event: any) => {
      const copiedData = event.clipboardData.getData("text/plain")
      if (copiedData === "") return
      const newData = handlePasteFromCell(
        extraJson,
        extraData.values.map(() => ""),
        copiedData,
        type,
        jsonIndex,
        rowIndex,
        event
      )
      if (newData) {
        setExtraJson(newData)
      }
    }

  return (
    <div
      className="flex flex-col cursor-pointer "
      style={{
        borderRight: "1px solid #E4E4E4",
        borderTop: "1px solid #E4E4E4",
        borderBottom: "1px solid #E4E4E4",
        marginTop: 10,
        backgroundColor: "#FFFFFF",
        paddingBottom: 0,
        borderTopRightRadius: 6,
        borderBottomRightRadius: 6,
      }}
    >
      <div
        className="flex"
        style={{
          height: 48,
          width: 170,
          borderBottom: "1px solid #E4E4E4",
        }}
      >
        <input
          className="focus:outline-none font-semibold px-3 focus-input-table"
          placeholder="Empty"
          value={extraData.key}
          onChange={handleChangeInput(0, "header")}
          onPaste={onPaste(0, "header")}
          style={{
            height: 47,
            width: 170,
            fontSize: 14,
            lineHeight: "24px",
            color: "#111111",
            backgroundColor: "transparent",
          }}
        />
      </div>
      <div>
        {extraData.values.map((el, index) => (
          <div
            className={`flex items-center`}
            style={{
              height: 48,
              width: 170,
              backgroundColor: index % 2 !== 0 ? "#F7F7F7" : "#FFFFFF",
            }}
            key={index}
          >
            <input
              className="focus:outline-none font-normal px-3 focus-input-table"
              value={el}
              placeholder="Empty"
              onChange={handleChangeInput(index, "body")}
              onPaste={onPaste(index, "body")}
              style={{
                height: 48,
                width: 170,
                fontSize: 14,
                lineHeight: "24px",
                color: "#111111",
                backgroundColor: "transparent",
              }}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
export default TableBOMAddColumn
