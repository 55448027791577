export enum ACTION_LOGOUT {
  YES = "yes",
  NO = "no",
}
export interface LogoutCardProps {
  handleChangeButton: (key: ACTION_LOGOUT) => () => void
}

export interface MenuLeftProps {
  name: string
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >
  path: string
}
export interface NoteInfo {
  log: string
  created_at: string
}
export interface ReleaseNoteProps {
  onCloseNotes: () => void
  noteInfo: { notes: string; created_at: string }
}
