import "./dashboard.css"
import { useState, useEffect } from "react"
import { getUserOverview } from "./api.services"
import { UserOverview } from "./dashboard.type"
import PageLayout from "pages/layout/PageLayout"
import UserPlan from "./molecules/UserPlan"
import TotalSpaceUsage from "./molecules/TotalSpaceUsage"
import Quotation from "./molecules/Quotation"
import DownloadHistory from "./molecules/DownloadHistory"

const Dashboard = () => {
  const [data, setData] = useState<UserOverview>({
    tier: "Free user",
    limited_size: "0 GB",
    used_size: "0 GB",
    used_percentage: 0,
    project_count: 0,
    quota_limit: 0,
    quota_number: 0,
    renew_at: "N/A",
    description: "N/A",
    download_histories: [],
    project_limit: 0,
    component_per_project_limit: 0,
    live_project_count: 0,
  })

  const getDashboardOverview = () => {
    getUserOverview().then((response) => setData(response))
  }

  useEffect(() => {
    getDashboardOverview()
  }, [])

  return (
    <PageLayout>
      <div className="p-6">
        <h2 className="text-[16px] font-semibold lh-26 text-black mb-[8px]">
          Dashboard
        </h2>
        <div className="flex flex-col md:flex-row gap-6 group-flex-col">
          <div className="flex flex-col sm:flex-row gap-6">
            <UserPlan data={data} />
            <TotalSpaceUsage data={data} />
          </div>
          <Quotation data={data} getDashboardOverview={getDashboardOverview} />
        </div>

        <h2 className="text-[16px] font-semibold lh-26 text-black mb-[8px] mt-[24px]">
          History all-data download
        </h2>
        <div className="text-black bg-white border border-border-gray rounded-md overflow-auto">
          <DownloadHistory histories={data.download_histories} />
        </div>
      </div>
    </PageLayout>
  )
}
export default Dashboard
