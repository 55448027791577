import { useRef, useEffect, useState } from "react"
import { isNull } from "lodash"
import * as THREE from "three"
import { TDSLoader } from "three/examples/jsm/loaders/TDSLoader"
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import { TrackballControls } from "three/examples/jsm/controls/TrackballControls"
import TreeSTLLoader from "three-stl-loader"
import Button from "components/Button/Button"
import { convertStepToGlBObject } from "components/ThreeDModels/CustomLoaders/StepLoader"
import { toast } from "react-toastify"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"

interface ThreeDModelProps {
  url: string
  fileName?: string
}

type LoaderProps = (
  renderer: THREE.WebGLRenderer,
  fileUrl: string,
  aspect: number
) => Promise<{
  camera: THREE.PerspectiveCamera
  reqAnimation: number
  resetCamera: () => void
}>

const ViewerMaxHeight = 500

const getFileExtension = (filename: string) => {
  const pattern = "^.+\\.([^.]+)$"
  const ext = new RegExp(pattern).exec(filename)
  return ext == null ? "" : ext[1]
}

const fitCameraToSelection = (
  camera: any,
  controls: any,
  object: any,
  fitOffset = 2.2
) => {
  const size = new THREE.Vector3()
  const center = new THREE.Vector3()
  const box = new THREE.Box3()
  box.makeEmpty()
  box.expandByObject(object)

  box.getSize(size)
  box.getCenter(center)

  const maxSize = Math.max(size.x, size.y, size.z)
  const fitHeightDistance =
    maxSize / (2 * Math.atan((Math.PI * camera.fov) / 360))
  const fitWidthDistance = fitHeightDistance / camera.aspect
  const distance = fitOffset * Math.max(fitHeightDistance, fitWidthDistance)

  const direction = controls.target
    .clone()
    .sub(camera.position)
    .normalize()
    .multiplyScalar(distance)

  controls.maxDistance = distance * 10
  controls.target.copy(center)

  camera.near = distance / 100
  camera.far = distance * 100
  camera.updateProjectionMatrix()

  camera.position.copy(controls.target).sub(direction)

  controls.update()
}

const loadSTLModel: LoaderProps = (renderer, fileUrl, aspect) => {
  return new Promise((resolve, reject) => {
    const STLLoader = TreeSTLLoader(THREE)
    const loader = new STLLoader()
    const scene = new THREE.Scene()
    loader.load(
      fileUrl,
      (geometry: any) => {
        const material = new THREE.MeshMatcapMaterial({
          color: 0xffeedd,
        })
        const object = new THREE.Mesh(geometry, material)

        object.geometry.computeVertexNormals()
        object.geometry.center()
        scene.add(object)
        /// camera
        const camera = new THREE.PerspectiveCamera(60, aspect, 0.1, 1000)
        camera.position.z = 2
        /// light
        const ambientLight = new THREE.AmbientLight(0xcccccc, 1)
        scene.add(ambientLight)
        const controls = new OrbitControls(camera, renderer.domElement)
        ////
        const animate = () => {
          const req = requestAnimationFrame(animate)
          controls.update()
          renderer.render(scene, camera)
          return req
        }
        const resetCamera = () => {
          return fitCameraToSelection(camera, controls, object)
        }
        //
        const reqAnimation = animate()
        // init camera
        resetCamera()
        //
        return resolve({ camera, reqAnimation, resetCamera })
      },
      undefined,
      (error: Error) => {
        console.log(error)
        reject(error)
      }
    )
  })
}

const load3DSModel: LoaderProps = (renderer, fileUrl, aspect) => {
  return new Promise((resolve, reject) => {
    const loader = new TDSLoader()
    const scene = new THREE.Scene()
    loader.load(
      fileUrl,
      (object) => {
        scene.add(object)

        /// camera
        const camera = new THREE.PerspectiveCamera(60, aspect, 0.1, 1000)
        camera.position.z = 2

        /// light
        scene.add(new THREE.HemisphereLight())
        const directionalLight = new THREE.DirectionalLight(0xffeedd)
        directionalLight.position.set(0, 0, 2)
        scene.add(directionalLight)

        /// controls
        const controls = new TrackballControls(camera, renderer.domElement)
        object.traverse((child) => {
          if (child instanceof THREE.Mesh) {
            child.material.specular.setScalar(0.1)
          }
        })

        const animate = () => {
          const req = requestAnimationFrame(animate)
          controls.update()
          renderer.render(scene, camera)
          return req
        }

        const resetCamera = () => {
          fitCameraToSelection(camera, controls, object)
        }
        //
        const reqAnimation = animate()
        // init camera
        resetCamera()
        //
        return resolve({ camera, reqAnimation, resetCamera })
      },
      undefined,
      (error) => {
        console.log(error)
        reject(error)
      }
    )
  })
}

const loadObjModel: LoaderProps = (renderer, fileUrl, aspect) => {
  return new Promise((resolve, reject) => {
    const loader = new OBJLoader()
    const scene = new THREE.Scene()
    loader.load(
      fileUrl,
      (object) => {
        scene.add(object)

        /// camera
        const camera = new THREE.PerspectiveCamera(60, aspect, 0.1, 1000)
        camera.position.z = 2

        /// light
        const ambientLight = new THREE.AmbientLight(0xffeedd, 0.2)
        scene.add(ambientLight)

        const pointLight = new THREE.PointLight(0xffffff, 0.6)
        camera.add(pointLight)
        scene.add(camera)

        /// controls
        const controls = new TrackballControls(camera, renderer.domElement)
        //

        //
        const animate = () => {
          const req = requestAnimationFrame(animate)
          controls.update()
          renderer.render(scene, camera)
          return req
        }
        const resetCamera = () => {
          fitCameraToSelection(camera, controls, object)
        }
        //
        const reqAnimation = animate()
        // init camera
        resetCamera()
        //
        return resolve({ camera, reqAnimation, resetCamera })
      },
      undefined,
      (error) => {
        console.log(error)
        reject(error)
      }
    )
  })
}

const loadStepModel: LoaderProps = async (renderer, fileUrl, aspect) => {
  const glbObject = (await convertStepToGlBObject(fileUrl)) as string | null
  if (!glbObject) {
    return Promise.reject("Error")
  }
  const loader = new GLTFLoader()
  const scene = new THREE.Scene()
  renderer.toneMapping = THREE.ACESFilmicToneMapping
  renderer.toneMappingExposure = 1
  return new Promise((resolve, reject) => {
    loader.load(
      glbObject,
      (gltf) => {
        scene.add(gltf.scene)
        gltf.scene.traverse((child) => {
          if (child instanceof THREE.Mesh) {
            child.castShadow = true
            child.receiveShadow = true
          }
        })

        /// camera
        const camera = new THREE.PerspectiveCamera(60, aspect, 0.1, 1000)
        camera.position.z = 2

        // /// light
        // /// light
        const ambientLight = new THREE.AmbientLight(0xffeedd, 1)
        scene.add(ambientLight)
        //
        const pointLight = new THREE.PointLight(0xffffff, 0.6)
        camera.add(pointLight)
        scene.add(camera)

        const controls = new OrbitControls(camera, renderer.domElement)
        ////
        const animate = () => {
          const req = requestAnimationFrame(animate)
          controls.update()
          renderer.render(scene, camera)
          return req
        }
        const resetCamera = () => {
          return fitCameraToSelection(camera, controls, gltf.scene)
        }
        //
        const reqAnimation = animate()
        // init camera
        resetCamera()
        //
        return resolve({ camera, reqAnimation, resetCamera })
      },
      undefined,
      (error) => reject(error)
    )
  })
}

export const getObjectLoader = (url: string) => {
  const fileExtention = getFileExtension(url).toLocaleLowerCase()
  let loader: LoaderProps | null = null
  if (fileExtention === "stl") {
    loader = loadSTLModel
  }
  if (fileExtention === "3ds") {
    loader = load3DSModel
  }
  if (fileExtention === "obj") {
    loader = loadObjModel
  }
  if (fileExtention === "stp" || fileExtention === "step") {
    loader = loadStepModel
  }
  return loader
}

export const Mech3DModel = ({ url, fileName }: ThreeDModelProps) => {
  const loader = getObjectLoader(fileName || url)
  const refContainer = useRef<any>()
  const [loading, setLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState("")
  const [renderer, setRenderer] = useState<any>()
  const [camera, setCamera] = useState<THREE.PerspectiveCamera>()
  const [reqAnimation, setReqAnimation] = useState<number>()
  const [resetCamera, setResetCamera] = useState<any>(undefined)

  useEffect(() => {
    if (!camera || !renderer) {
      return
    }

    const resize = () => {
      const { current: container } = refContainer
      // console.log('container', container)
      if (!camera || !container) return
      const newScW = container.clientWidth
      const newScH = newScW > ViewerMaxHeight ? ViewerMaxHeight : newScW
      camera.aspect = newScW / newScH
      camera.updateProjectionMatrix()
      renderer.setSize(newScW, newScH)
    }
    window.addEventListener("resize", resize)
    return () => {
      window.removeEventListener("resize", resize)
    }
  }, [camera, renderer])

  useEffect(() => {
    const { current: container } = refContainer
    if (container && !renderer) {
      let containerDOM = container
      let scW = containerDOM.clientWidth
      let scH = ViewerMaxHeight
      while (scW === 0) {
        containerDOM = containerDOM.parentElement
        scW = containerDOM.clientWidth
      }

      if (scW <= ViewerMaxHeight) {
        scH = scW
      }
      //
      const aspect = scW / scH
      const newRenderer = new THREE.WebGLRenderer({
        antialias: true,
        alpha: true,
      })
      newRenderer.setPixelRatio(window.devicePixelRatio)
      newRenderer.setSize(scW, scH)
      newRenderer.outputEncoding = THREE.sRGBEncoding
      container.appendChild(newRenderer.domElement)
      setRenderer(newRenderer)

      if (loader) {
        toast(
          <LabelNotificationPage
            messenger="System busy. Please wait.."
            type="warning"
          />
        )
        loader(newRenderer, url, aspect)
          .then((result) => {
            setCamera(() => result.camera)
            setReqAnimation(() => result.reqAnimation)
            setResetCamera(() => result.resetCamera)
            setLoading(false)
          })
          .catch((error) => {
            console.log(error)
            setLoading(false)
            setErrorMessage(
              "There is something wrong when load the file. Please contact the administrator!"
            )
          })
      }
      //// remove event listener
      return () => {
        if (reqAnimation) {
          cancelAnimationFrame(reqAnimation)
        }
        newRenderer.dispose()
      }
    }
  }, [])

  return (
    <div
      className="w-full h-[0] relative"
      style={{ paddingTop: `min(${ViewerMaxHeight}px, 100%)` }}
    >
      <div className="w-full h-full absolute left-3 top-0" ref={refContainer}>
        {isNull(loader) ? (
          <span className="font-semibold text-14 leading-24 color-gray-7a flex w-full h-full justify-center items-center">
            The 3D viewer currently supports .stl, .3ds, .obj, .stp, .step only
          </span>
        ) : loading ? (
          <span className="font-semibold text-14 leading-24 color-gray-7a flex w-full h-full justify-center items-center">
            Loading...
          </span>
        ) : errorMessage.length > 0 ? (
          <span className="absolute top-0 font-semibold text-14 leading-24 color-gray-7a flex w-full h-full justify-center items-center">
            {errorMessage}
          </span>
        ) : null}
      </div>
      <div className="3d-controls absolute right-0 top-0">
        <Button
          title="Reset View"
          colorBtn="white"
          sizeBtn="small"
          onClick={resetCamera}
        />
      </div>
    </div>
  )
}
