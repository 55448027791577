import { Action } from "redux"
import { BuildConversationDetail } from "../conversations.type"

export const SET_CONVERSATION_BUILD_ACTION = "SET_CONVERSATION_BUILD_ACTION"
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SET_CONVERSATION_BUILD_ACTION = typeof SET_CONVERSATION_BUILD_ACTION

export interface SetConversationBuildAction
  extends Action<SET_CONVERSATION_BUILD_ACTION> {
  payload: BuildConversationDetail[]
}

export const setConversationBuildAction = (
  payload: BuildConversationDetail[]
): SetConversationBuildAction => {
  return {
    type: SET_CONVERSATION_BUILD_ACTION,
    payload,
  }
}
