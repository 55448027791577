import { ReactComponent as IconButton } from "assets/images/icons/icon-button-create.svg"
import { ReactComponent as IconRemoveFile } from "assets/images/icons/icon-remove-file.svg"
import { ReactComponent as DownloadBlueIcon } from "assets/images/icons/download-blue-icon.svg"
import { useString, useWindowSize } from "helpers/hooks"
import React from "react"
import { STATUS_TYPE_FILE_BUILD } from "../build-history.constant"
import { downloadPrivateAsset } from "helpers/utils"
import ActionIconHistory from "./ActionIconHistory"
import { ExtendedViewType } from "pages/project-component-detail/types"
import { TemplateLink } from "constants/index"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { toast } from "react-toastify"
import AttachmentFile from "../molecules/AttachmentFile"

interface Props {
  title: STATUS_TYPE_FILE_BUILD
  fileName: string
  file: string
  maxFile?: number
  handleUpdateFile?: (formData: any) => void
  containerFiles: string
  onRemoveFile?: () => void
  isAction: boolean
  acceptFile?: string
  isSyncData?: boolean
  onClickSyncData?: () => void
  detailViewType?: ExtendedViewType
  setDetailViewType?: (type: ExtendedViewType) => void
  isDeleted?: boolean
  versionId?: string
}
const UpdateFileItem = (props: Props) => {
  const {
    title,
    file,
    fileName,
    maxFile = 50,
    handleUpdateFile,
    containerFiles,
    onRemoveFile,
    isAction,
    acceptFile,
    isSyncData = false,
    onClickSyncData,
    detailViewType,
    setDetailViewType,
    isDeleted,
    versionId,
  } = props
  const keyInputFile = useString(Math.random().toString(36))
  const { isMobile } = useWindowSize()
  const isActive = isMobile || isDeleted || isAction
  const [dragActive, setDragActive] = React.useState(false)

  // handle drag events
  const handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
    // console.log("drag over: ", e)

    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true)
    } else if (e.type === "dragleave") {
      console.log("leave")

      setDragActive(false)
    }
  }

  const handleDrop = (event: React.DragEvent<HTMLInputElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setDragActive(false)
    if (isDeleted) {
      return null
    }

    if (event.dataTransfer.files && event.dataTransfer.files.length) {
      if (event.dataTransfer.files.length > 1) {
        return toast(
          <LabelNotificationPage
            messenger={`Allow only one file`}
            type="warning"
          />
        )
      }
      if (event.dataTransfer.files[0].size > maxFile * 1000 * 1000)
        return toast(
          <LabelNotificationPage
            messenger={`The upload of file larger than ${maxFile}MB is not allowed`}
            type="error"
          />
        )
    }
    if (event.dataTransfer.files) {
      handleUploadFile(event.dataTransfer.files[0])
    }
  }
  const fileSelectedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isDeleted) {
      return null
    }

    if (
      event.target.files &&
      event.target.files.length &&
      event.target.files[0].size > maxFile * 1000 * 1000
    ) {
      toast(
        <LabelNotificationPage
          messenger={`The upload of file larger than ${maxFile}MB is not allowed`}
          type="error"
        />
      )
      return
    }
    if (event.target.files) {
      handleUploadFile(event.target.files[0])
    }
  }
  const handleUploadFile = (fileUpload: File) => {
    const formData = new FormData()
    formData.append("file", fileUpload)
    keyInputFile.setValue(Math.random().toString(36))
    if (handleUpdateFile) {
      handleUpdateFile(formData)
    }
  }
  const onClickRemoveFile = (event) => {
    if (isDeleted) {
      return null
    }
    event.stopPropagation()
    event.preventDefault()
    if (onRemoveFile) {
      onRemoveFile()
    }
  }
  const renderFileName = () => {
    if (!fileName) {
      return (
        <form onDragEnter={handleDrag} className="relative">
          <input
            id={containerFiles}
            onChange={fileSelectedHandler}
            accept={acceptFile}
            type="file"
            disabled={isActive}
            style={{ display: "none" }}
            key={keyInputFile.value || ""}
          />
          <label htmlFor={containerFiles}>
            <div
              className={`h-12 flex items-center justify-center cursor-pointer ${
                isActive ? "disable-icon-add-file" : ""
              }`}
              style={{
                background: isActive
                  ? "#F1F1F1"
                  : dragActive
                  ? "rgba(10, 90, 245, 0.15)"
                  : "rgba(10, 90, 245, 0.05)",
                border: isActive ? "1px solid #FFFFFF" : `1px dashed blue`,
                borderRadius: 4,
              }}
            >
              {isActive ? (
                <p
                  className="font-medium"
                  style={{
                    fontSize: 12,
                    lineHeight: "18px",
                    color: "#7A7A7A",
                  }}
                >
                  No file chosen
                </p>
              ) : (
                <React.Fragment>
                  <IconButton
                    style={{
                      marginRight: 4,
                      width: 24,
                    }}
                  />
                  <div className="font-medium text-[12px] leading-[18px] text-blue">
                    <p>Drag file here or click to upload</p>
                  </div>
                </React.Fragment>
              )}
            </div>
          </label>
          {dragActive && !isActive && (
            <div
              className="absolute w-full h-full rounded-4 "
              style={{
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              }}
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            ></div>
          )}
        </form>
      )
    }
    return (
      <div
        className="h-10 grid"
        style={{
          gridTemplateColumns: "48px calc(100% - 76px) 28px",
        }}
      >
        <AttachmentFile
          file={file}
          filename={fileName}
          project_component_history_id={versionId}
        />

        {!isDeleted ? (
          <div className="hidden md:flex justify-end items-center cursor-pointer">
            {!isAction ? <IconRemoveFile onClick={onClickRemoveFile} /> : null}
            {isSyncData ? (
              <ActionIconHistory
                onClick={onClickSyncData}
                icon="system-data"
                tooltip="Sync BOM detail table"
                styleIcon={{
                  marginRight: 0,
                }}
              />
            ) : null}
          </div>
        ) : null}
      </div>
    )
  }
  return (
    <div
      className="flex flex-col px-3 pb-3 pt-2 bg-white relative hover-project-card"
      style={{
        border: "1px solid #E4E4E4",
        borderRadius: 4,
      }}
    >
      <div className="flex justify-between items-center mb-3">
        <div>
          <p
            className="font-semibold overflow-hidden text-ellipsis whitespace-nowrap"
            style={{
              fontSize: 14,
              lineHeight: "24px",
              color: "#111111",
            }}
          >
            {title}
          </p>
          {title === STATUS_TYPE_FILE_BUILD.GERBER ? (
            <p className="font-light italic text-xs">
              {" "}
              Please provide your Gerber and NC Drill files in a zip format.
            </p>
          ) : (
            ""
          )}
        </div>

        {!isDeleted && fileName && file ? (
          <a
            onClick={() =>
              downloadPrivateAsset(file, {
                project_component_history_id: versionId,
              })
            }
          >
            <ActionIconHistory
              classIcon="hidden md:block"
              icon="download"
              tooltip="Download"
              styleIcon={{
                marginRight: 0,
              }}
            />
          </a>
        ) : null}
      </div>

      {renderFileName()}
      {!isDeleted ? (
        <p
          className="text-[10px] text-blue cursor-pointer mt-[12px]"
          onClick={() => {
            if (setDetailViewType && fileName && file) {
              if (title === STATUS_TYPE_FILE_BUILD.GERBER) {
                setDetailViewType(ExtendedViewType.Gerber)
              }
              if (title === STATUS_TYPE_FILE_BUILD.BOM) {
                setDetailViewType(ExtendedViewType.Bom)
              }
              if (title === STATUS_TYPE_FILE_BUILD.MECH_3D) {
                setDetailViewType(ExtendedViewType.Mech3D)
              }
            }
          }}
        >
          {fileName &&
          file &&
          setDetailViewType &&
          ((title === STATUS_TYPE_FILE_BUILD.GERBER &&
            detailViewType !== ExtendedViewType.Gerber) ||
            (title === STATUS_TYPE_FILE_BUILD.BOM &&
              detailViewType !== ExtendedViewType.Bom) ||
            (title === STATUS_TYPE_FILE_BUILD.MECH_3D &&
              detailViewType !== ExtendedViewType.Mech3D))
            ? `Click to show detail`
            : null}
        </p>
      ) : null}

      {title === STATUS_TYPE_FILE_BUILD.BOM &&
      !fileName &&
      !file &&
      !isDeleted &&
      !isAction &&
      !isMobile ? (
        <a
          className="text-[10px] text-blue cursor-pointer flex items-center"
          href={TemplateLink.Bom}
          target="_blank"
        >
          <DownloadBlueIcon /> Download a BOM template
        </a>
      ) : null}
    </div>
  )
}
export default UpdateFileItem
