import { Orientation } from "@gitgraph/react"
import Button from "components/Button/Button"
import { isUndefined } from "lodash"
interface Props {
  orientationTree: Orientation | undefined
  handleChangeOrderByHistory: (
    newOrientation: Orientation | undefined
  ) => () => void
}
const ActionSoftCard = (props: Props) => {
  const { orientationTree, handleChangeOrderByHistory } = props
  return (
    <div className="flex justify-end items-center px-3 pb-3">
      {/* <p
        className="font-semibold"
        style={{
          fontSize: 14,
          lineHeight: "24px",
          color: "#111111",
        }}
      >
        History
      </p> */}
      <div className="flex items-center">
        <Button
          className="mx-1 md:mx-3"
          title="Newest version on top"
          sizeBtn="small"
          colorBtn="white"
          widthBtn={153}
          onClick={handleChangeOrderByHistory(undefined)}
          styleButton={{
            background: isUndefined(orientationTree) ? "#F1F1F1" : "#FFFFFF",
            color: isUndefined(orientationTree) ? "#111111" : "#7A7A7A",
          }}
        />
        <Button
          title="Root on top"
          sizeBtn="small"
          colorBtn="white"
          widthBtn={91}
          onClick={handleChangeOrderByHistory(Orientation.VerticalReverse)}
          styleButton={{
            background: !isUndefined(orientationTree) ? "#F1F1F1" : "#FFFFFF",
            color: !isUndefined(orientationTree) ? "#111111" : "#7A7A7A",
          }}
        />
      </div>
    </div>
  )
}
export default ActionSoftCard
