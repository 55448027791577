import Axios, { AxiosResponse } from "axios"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
import {
  CreateBuildRequest,
  ProjectBuildDetail,
} from "pages/project-component/types"
import { STATUS_RESPONSE } from "types"
import {
  CreateAdditionalRequest,
  FormVendorRequest,
  InviteeBuilComponentDetail,
  ProjectBuildAdditionalDetail,
  ProjectBuildExtraDetail,
  ProjectBuildInvitessDetail,
  ProjectComponentBuildDetail,
  WorkedVendor,
} from "./project-build.type"
import {
  BuildConversationDetail,
  BuildInviteesConversationDetail,
  CONVERSATION_ROLE,
  emptyBuildConversationDetail,
} from "pages/conversations/conversations.type"
import { STATUS_PROJECT_ROLE } from "pages/projects/types"

export const getProjectBuildMiddleware = async (
  idBuild: string,
  params?: {}
) => {
  const response = await Axios.get<{
    data: ProjectComponentBuildDetail
  }>(`/api/project-build/${idBuild}`, {
    params,
  })
  return response.data.data
}

export const postAddBuildMiddleware = (
  request: CreateBuildRequest,
  callback: (
    type: STATUS_RESPONSE,
    messenger: string,
    dataRes?: ProjectComponentBuildDetail
  ) => void
) => {
  Axios.post(`/api/project-build/create`, request)
    .then(
      (
        response: AxiosResponse<{
          data: ProjectComponentBuildDetail
        }>
      ) => {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.POST_BUILD_SUCCESS,
          response.data.data
        )
      }
    )
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ?? MESSENGER_NOTIFICATION.POST_BUILD_ERROR
      )
    })
}
export const putProjectBuildMiddleware = (
  idBuild: string,
  request: {
    project_component_ids: string[]
  },
  callback: (
    type: STATUS_RESPONSE,
    messenger: string,
    dataRes?: ProjectComponentBuildDetail
  ) => void
) => {
  Axios.put(`/api/project-build/${idBuild}/update`, request)
    .then(
      (
        response: AxiosResponse<{
          data: ProjectComponentBuildDetail
        }>
      ) => {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.UPDATE_BUILD_SUCCESS,
          response.data.data
        )
      }
    )
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.UPDATE_BUILD_ERROR
      )
    })
}

export const deleteComponentInBuildMiddleware = (
  idProjectBuildComponent: string,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.delete(`/api/project-build/delete-component/${idProjectBuildComponent}`)
    .then((_response: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.DELETE_COMPONENT_IN_BUILD_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.DELETE_COMPONENT_IN_BUILD_ERROR
      )
    })
}
export const duplicateBuildMiddleware = (
  idBuild: string,
  callback: (
    type: STATUS_RESPONSE,
    messenger: string,
    data?: ProjectBuildDetail
  ) => void
) => {
  Axios.post(`/api/project-build/${idBuild}/duplicate`)
    .then(
      (
        response: AxiosResponse<{
          data: ProjectBuildDetail
        }>
      ) => {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.DUPLICATE_BUILD_SUCCESS,
          response.data.data
        )
      }
    )
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.DUPLICATE_BUILD_ERROR
      )
    })
}
export const archiveBuildMiddleware = (
  idBuild: string,
  isArchive: boolean,
  callback: (
    type: STATUS_RESPONSE,
    messenger: string,
    data?: ProjectBuildDetail
  ) => void
) => {
  Axios.post(`/api/project-build/${idBuild}/archive`)
    .then(
      (
        response: AxiosResponse<{
          data: ProjectBuildDetail
        }>
      ) => {
        callback(
          STATUS_RESPONSE.SUCCESS,
          isArchive
            ? MESSENGER_NOTIFICATION.UN_ARCHIVE_BUILD_SUCCESS
            : MESSENGER_NOTIFICATION.ARCHIVE_BUILD_SUCCESS,
          response.data.data
        )
      }
    )
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ||
          (isArchive
            ? MESSENGER_NOTIFICATION.UN_ARCHIVE_BUILD_ERROR
            : MESSENGER_NOTIFICATION.ARCHIVE_BUILD_ERROR)
      )
    })
}
export const postCopyLinkProjectBuild = (
  idBuild: string,
  callback: (
    type: STATUS_RESPONSE,
    messenger: string,
    data?: {
      url: string
    }
  ) => void
) => {
  Axios.post(`/api/project-build/${idBuild}/share`)
    .then(
      (
        response: AxiosResponse<{
          data: {
            url: string
          }
        }>
      ) => {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.POST_COPY_LINK_SUCCESS,
          response.data.data
        )
      }
    )
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.POST_COPY_LINK_ERROR
      )
    })
}

export const postProjectBuildStatusMiddleware = (
  idBuild: string,
  data: {
    status: string
    reason: string
  },
  labelStatus: string,
  callback: (
    type: STATUS_RESPONSE,
    messenger: string,
    dataRes?: ProjectComponentBuildDetail
  ) => void
) => {
  Axios.post(`/api/project-build/${idBuild}/status`, data)
    .then(
      (
        response: AxiosResponse<{
          data: ProjectComponentBuildDetail
        }>
      ) => {
        callback(
          STATUS_RESPONSE.SUCCESS,
          `${labelStatus} successfully!`,
          response.data.data
        )
      }
    )
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ?? `${labelStatus} failed!`
      )
    })
}

export const getProjectBuildAdditionalInfoMiddleware = async (
  idProjectBuild: string,
  idConversation?: string
) => {
  let url = `/api/project-build/${idProjectBuild}/get-list-additional-info`
  if (idConversation) {
    url += `?conversation_id=${idConversation}`
  }
  const response: AxiosResponse<{
    data: ProjectBuildAdditionalDetail[]
  }> = await Axios.get(url)
  return response.data.data
}
export const getProjectBuildExtraInfoMiddleware = async (
  idProjectBuild: string
) => {
  const response: AxiosResponse<{
    data: ProjectBuildExtraDetail[]
  }> = await Axios.get(
    `/api/project-build/${idProjectBuild}/get-list-extra-info`
  )
  return response.data.data
}

export const postProjectBuildCreateAdditionalInfoMiddleware = (
  idBuild: string,
  data: CreateAdditionalRequest,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.post(`/api/project-build/${idBuild}/create-additional-info`, data)
    .then((_response: AxiosResponse) => {
      callback(STATUS_RESPONSE.SUCCESS, "")
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.CREATE_ADDITIONAL_INFO_ERROR
      )
    })
}
export const deleteProjectBuildCreateAdditionalInfoMiddleware = (
  idAdditional: string,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.delete(`/api/project-build/additional-info/${idAdditional}/delete`)
    .then((_response: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.DELETE_ADDITIONAL_INFO_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.DELETE_ADDITIONAL_INFO_ERROR
      )
    })
}
export const postProjectBuildCreateExtraInfoMiddleware = (
  idBuild: string,
  data: CreateAdditionalRequest,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.post(`/api/project-build/${idBuild}/create-extra-info`, data)
    .then((_response: AxiosResponse) => {
      callback(STATUS_RESPONSE.SUCCESS, "")
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.CREATE_EXTRA_INFO_ERROR
      )
    })
}
export const deleteProjectBuildCreateExtraInfoMiddleware = (
  idExtra: string,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.delete(`/api/project-build/extra-info/${idExtra}/delete`)
    .then((_response: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.DELETE_EXTRA_INFO_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.DELETE_EXTRA_INFO_ERROR
      )
    })
}

export const getProjectBuildInviteeMiddleware = async (
  idProjectBuild: string
) => {
  const response: AxiosResponse<{
    data: ProjectBuildInvitessDetail[]
  }> = await Axios.get(`/api/project-build/${idProjectBuild}/get-list-invitee`)
  return response.data.data
}

export const postProjectBuildCreateInviteeMiddleware = async (
  idBuild: string,
  data: FormVendorRequest,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.post(`/api/project-build/${idBuild}/create-invitee`, data)
    .then((_response: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.CREATE_INVITEE_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.CREATE_INVITEE_SUCCESS
      )
    })
}
export const putProjectBuildUpdateInviteeMiddleware = (
  idInvitee: string,
  data: FormVendorRequest,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.put(`/api/project-build/update-invitee/${idInvitee}`, data)
    .then((_response: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.UPDATE_INVITEE_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.UPDATE_INVITEE_ERROR
      )
    })
}
export const getProjectBuildInviteeComponentMiddleware = async (
  idInvitee: string
) => {
  const response: AxiosResponse<{
    data: InviteeBuilComponentDetail[]
  }> = await Axios.get(
    `/api/project-build/invitee/${idInvitee}/get-list-rest-invitee-build-component`
  )
  return response.data.data
}
export const deleteProjectBuildMiddleware = (
  idProjectBuild: string,
  nameBuild: string,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.delete(`/api/project-build/${idProjectBuild}/delete`)
    .then((_response: AxiosResponse) => {
      callback(STATUS_RESPONSE.SUCCESS, `Delete ${nameBuild} successfully!`)
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ?? `Delete ${nameBuild} failed!`
      )
    })
}
export const postProjectBuildInviteeAddComponentMiddleware = (
  idInvitee: string,
  projectBuildComponentId: string,
  callback: (
    type: STATUS_RESPONSE,
    messenger: string,
    data?: InviteeBuilComponentDetail
  ) => void
) => {
  Axios.post(
    `/api/project-build/invitee/${idInvitee}/component/${projectBuildComponentId}/add`
  )
    .then(
      (
        response: AxiosResponse<{
          data: InviteeBuilComponentDetail
        }>
      ) => {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.ADD_COMPONENT_INVITEE_SUCCESS,
          response.data.data
        )
      }
    )
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.ADD_COMPONENT_INVITEE_ERROR
      )
    })
}
export const delteProjectBuildInviteeAddComponentMiddleware = (
  buildInviteComponentId: string,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.delete(`/api/project-build/invitee/component/${buildInviteComponentId}`)
    .then((_response: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.DELETE_COMPONENT_INVITEE_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.DELETE_COMPONENT_INVITEE_ERROR
      )
    })
}
export const postProjectBuildSendInviteeMiddleware = (
  idInvitee: string,
  type: string,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.post(`/api/project-build/invitee/${idInvitee}/send`)
    .then((_response: AxiosResponse) => {
      callback(STATUS_RESPONSE.SUCCESS, `${type} invite successfully!`)
    })
    .catch((errorSend) => {
      callback(
        STATUS_RESPONSE.ERROR,
        errorSend.response?.data?.message ?? `${type} invite failed!`
      )
    })
}
export const postProjectBuildInviteeActivateMiddleware = (
  idInvitee: string,
  type: string,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.post(`/api/project-build/invitee/${idInvitee}/activate`)
    .then((_response: AxiosResponse) => {
      callback(STATUS_RESPONSE.SUCCESS, `${type} invitee successfully!`)
    })
    .catch((errorActivate) => {
      callback(
        STATUS_RESPONSE.ERROR,
        errorActivate.response?.data?.message ?? `${type} invite failed!`
      )
    })
}

export const getProjectBuildListInviteeByBuildComponentMiddleware = async (
  projectBuildComponentId: string
) => {
  const response: AxiosResponse<{
    data: {
      id: string
      name: string
      email: string
    }[]
  }> = await Axios.get(
    `/api/project-build/get-list-invitee-by-build-component/${projectBuildComponentId}`
  )
  return response.data.data
}

export const putProjectBuildToInProductionMiddleware = (
  idProductBuild: string,
  dataRequest: {
    data: {
      project_build_component_id: string
      invitee_id: string
    }[]
    status: string | number
  },
  callback: (
    type: STATUS_RESPONSE,
    messenger: string,
    dataRes?: ProjectComponentBuildDetail
  ) => void
) => {
  Axios.put(
    `/api/project-build/${idProductBuild}/to-in-production`,
    dataRequest
  )
    .then(
      (
        response: AxiosResponse<{
          data: ProjectComponentBuildDetail
        }>
      ) => {
        callback(
          STATUS_RESPONSE.SUCCESS,
          "Build status to in production successfully!",
          response.data.data
        )
      }
    )
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ?? "Build status to in production failed!"
      )
    })
}

export const postProjectBuildInviteeSyncMiddleware = (
  idInvitee: string,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.post(`/api/project-build/invitee/${idInvitee}/sync`)
    .then((_response: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.SYNC_DATA_INVITEE_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.SYNC_DATA_INVITEE_ERROR
      )
    })
}

export const postProjectBuildComponentSyncMiddleware = (
  idProjectBuildComponent: string,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.post(`/api/project-build/component/${idProjectBuildComponent}/sync`)
    .then((_response: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        "Update to the latest version from the master branch successfully!"
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          "Update to the latest version from the master branch failed!"
      )
    })
}

export const putProjectBuildNameMiddleware = (
  idProjectBuild: string,
  request: {
    name: string
  },
  callback: (
    type: STATUS_RESPONSE,
    messenger: string,
    dataRes?: ProjectComponentBuildDetail
  ) => void
) => {
  Axios.put(`/api/project-build/${idProjectBuild}/update-name`, request)
    .then(
      (
        response: AxiosResponse<{
          data: ProjectComponentBuildDetail
        }>
      ) => {
        callback(
          STATUS_RESPONSE.SUCCESS,
          "Updated project build successfully!",
          response.data.data
        )
      }
    )
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ?? "Updated project build failed!"
      )
    })
}

export const getWorkedVendors = async () => {
  return Axios.get<{ data: WorkedVendor[] }>(
    "/api/project-build/worked-vendors"
  ).then((res) => res.data)
}

export const deleteBuildAttachment = async (id: string) => {
  return Axios.delete(`/api/project-build/${id}/delete-attachment`).then(
    (res) => res.data
  )
}

export const duplicateBuilInBuildOverviewdMiddleware = async (
  idBuild: string
) => {
  const res = await Axios.post<{ data: ProjectBuildDetail }>(
    `/api/project-build/${idBuild}/duplicate`
  )
  const data = res.data.data
  const finalData: BuildConversationDetail = {
    ...emptyBuildConversationDetail,
    id: data.id,
    project_id: data.project_id,
    code: data.code,
    components: JSON.parse(data.component_types),
    project_code: data.project_code,
    is_read: false,
    is_archived: 0,
    updated_by: "by <b>me</b>",
    project_role: STATUS_PROJECT_ROLE.EDITOR,
    project_name: data.project_name,
    users: [],
    status: data.status,
    updated_at: Date().toString(),
  }
  return finalData
}

export const updateInviteeModifyComponentPermissionMiddleWare = async (
  invitee_id: string,
  can_modify_component: 0 | 1
) => {
  const res = await Axios.put(
    `api/project-build/invitee/${invitee_id}/update-permission`,
    { can_modify_component }
  )
  return res.data
}
