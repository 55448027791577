import Typography from "components/Typography"
import ImageNoData from "assets/images/icons/icon-no-data-project.svg"
import ButtonHasIcon from "components/Button/ButtonHasIcon"
interface Props {
  onClick?: () => void
  context: string
  showButton?: boolean
}
const NoData = (props: Props) => {
  const { onClick, context, showButton } = props
  return (
    <div className="flex flex-col items-center justify-center h-full">
      <img
        src={ImageNoData}
        alt="no data"
        style={{
          height: 178,
        }}
      />
      <Typography
        textClass="font-base text-sm py-6"
        style={{
          color: "#111111",
        }}
      >
        {context}
      </Typography>
      {showButton && (
        <ButtonHasIcon
          className="hidden md:flex md:items-center"
          title="New project"
          onClick={onClick}
        />
      )}
    </div>
  )
}
export default NoData
