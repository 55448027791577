import InputDefault from "components/Input/InputDefault"
import ModalCustom from "components/ModalCustom"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { useBoolean, useString, useWindowSize } from "helpers/hooks"
import { ItemUserInvited } from "pages/project-component/organisms/ItemUserInvited"
import {
  getRoleOptions,
  getUsersInProject,
  setRoleInProjectMiddleware,
} from "pages/project-component/services/api"
import {
  defaultSelectedRole,
  GetUsersInProjectProp,
  SelectedRole,
} from "pages/project-component/types"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { STATUS_RESPONSE } from "types"
import { isValidEmail } from "helpers/utils"
import SelectTippy from "components/Select/SelectTippy"
import { cloneDeep, remove } from "lodash"
import Button from "components/Button/Button"
interface Props {
  onCloseModal: (
    newUserRole: GetUsersInProjectProp[],
    projectId: string
  ) => () => void
  projectId: string
}
const FormShare = (props: Props) => {
  const { onCloseModal, projectId } = props
  const userEmail = useString("")
  const [roleOptions, setRoleOptions] = useState<SelectedRole[]>([])
  const { isMobile } = useWindowSize()
  const [selectedRole, setSelectedRole] =
    useState<SelectedRole>(defaultSelectedRole)
  const [usersInProject, setUsersInProject] = useState<GetUsersInProjectProp[]>(
    []
  )
  const isLoadingUser = useBoolean(false)
  useEffect(() => {
    if (!projectId) {
      return
    }
    handleGetRoleOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId])

  const handleChangeInputSearch = (event) => {
    userEmail.setValue(event.target.value)
  }
  const handleGetRoleOptions = async () => {
    isLoadingUser.setValue(true)
    try {
      const options = await getRoleOptions()
      await handleGetUsersInProject()
      const newOptions = options.map((option) => ({
        label: option.key,
        value: String(option.value),
      }))
      setRoleOptions(newOptions)
      if (newOptions.length) {
        setSelectedRole(newOptions[0])
      }
    } catch {
      isLoadingUser.setValue(false)
      setRoleOptions([])
      setSelectedRole(defaultSelectedRole)
    }
  }
  const handleGetUsersInProject = async () => {
    try {
      const dataRes = await getUsersInProject(projectId)
      setUsersInProject(dataRes)
      isLoadingUser.setValue(false)
    } catch {
      isLoadingUser.setValue(false)
    }
  }

  const handleOnClickInvite = async () => {
    if (isLoadingUser.value) {
      return
    }
    const userExist = usersInProject.findIndex(
      (el) => el.email === userEmail.value.trim()
    )
    if (userExist > -1) {
      toast(
        <LabelNotificationPage
          messenger="Shared account already exists!"
          type="warning"
        />
      )
      return
    }
    isLoadingUser.setValue(true)
    const bodyData = {
      email: userEmail.value.trim(),
      role: Number(selectedRole.value),
    }
    setRoleInProjectMiddleware(
      projectId,
      bodyData,
      false,
      (type: STATUS_RESPONSE, messenger: string) => {
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        if (type === STATUS_RESPONSE.SUCCESS) {
          userEmail.setValue("")
          handleGetUsersInProject()
        } else {
          isLoadingUser.setValue(false)
        }
      }
    )
  }

  const handleChangeUserInvited = (newDataRes: {
    email: string
    role: number
  }) => {
    const newUsersInProject = cloneDeep(usersInProject)
    if (newDataRes.role === 4) {
      remove(newUsersInProject, (el) => el.email === newDataRes.email)
      setUsersInProject(newUsersInProject)
      return
    }
    const index = newUsersInProject.findIndex(
      (el) => el.email === newDataRes.email
    )
    if (index > -1) {
      newUsersInProject[index].role = newDataRes.role
      setUsersInProject(newUsersInProject)
    }
  }
  return (
    <ModalCustom
      label="Invite member"
      handleChangeButton={() => onCloseModal(usersInProject, projectId)}
      widthModal={640}
      bodyChildren={
        <div>
          <div className="flex items-center flex-col md:flex-row gap-y-3 md:gap-y-0">
            <div
              className="min-h-[44px] flex items-center justify-between px-3 w-full flex-1 mr-0 md:mr-[10px]"
              style={{
                border: "1px solid #E4E4E4",
                borderRadius: 4,
              }}
            >
              <InputDefault
                classCustom="w-full h-24-custom focus:outline-none pr-2"
                placeholder="Email address"
                style={{
                  fontWeight: 400,
                  fontSize: 14,
                  lineHeight: "24px",
                  color: "#111111",
                }}
                value={userEmail.value}
                onChange={(e) => handleChangeInputSearch(e)}
              />
              <SelectTippy
                optionsSelect={roleOptions}
                activeSelect={selectedRole.value}
                handleChange={(value) => setSelectedRole(value)}
              />
            </div>
            <Button
              title="Send Invite"
              widthBtn={isMobile ? "100%" : 120}
              onClick={handleOnClickInvite}
              disabled={!isValidEmail(userEmail.value)}
              isDisabledBtn
            />
          </div>
          <div className="overflow-auto max-h-[500px]">
            <div className="flex flex-col mt-4 pr-1">
              {usersInProject.map((item, index) => (
                <div className="mb-4" key={index}>
                  <ItemUserInvited
                    data={item}
                    originRoleOptions={roleOptions}
                    projectId={projectId}
                    getUsersInProject={handleChangeUserInvited}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      }
    />
  )
}
export default FormShare
