import Tippy from "@tippyjs/react"
import { API_URL } from "config/environments"
import { getFirstLetterInName, showImageUrl } from "helpers/utils"
import { GetUsersInProjectProp } from "pages/project-component/types"

interface Props {
  data: GetUsersInProjectProp[]
  owner?: string
  max: number
}
const RoleUsersCard = (props: Props) => {
  const { data, owner, max } = props
  const urlAvatar = (newUrl: string) => {
    if (!newUrl.includes("public/")) {
      return undefined
    }
    return API_URL
  }
  return (
    <div className="flex items-center">
      {data.length
        ? data.map((user, index) => {
            if (index + 1 > max) {
              return null
            }
            return (
              <Tippy
                key={index}
                className="custom-tippy-menu-left"
                placement="bottom"
                content={
                  <div className="text-center">
                    <p>
                      {owner && owner === user.user_id ? "Owner: " : null}
                      {user.fullname}
                    </p>
                    <p>{user.email}</p>
                  </div>
                }
              >
                <div
                  key={index}
                  className="flex items-center justify-center"
                  style={{
                    width: 24,
                    height: 24,
                    border: "1px solid #FFFFFF",
                    backgroundColor: !user.avatar ? "#F7AC1B" : "",
                    borderRadius: "50%",
                    marginLeft: index > 0 ? -8 : 0,
                  }}
                >
                  {user.avatar ? (
                    <img
                      src={showImageUrl(user.avatar, urlAvatar(user.avatar))}
                      alt="avatar"
                      style={{
                        borderRadius: "50%",
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <p
                      style={{
                        fontWeight: 500,
                        fontSize: 11,
                        lineHeight: "18px",
                        color: "#FFF",
                      }}
                    >
                      {getFirstLetterInName(user.fullname || "N/A", true)}
                    </p>
                  )}
                </div>
              </Tippy>
            )
          })
        : null}
      {data.length > max ? (
        <div
          className="flex items-center justify-center"
          style={{
            width: 24,
            height: 24,
            border: "1px solid #FFFFFF",
            backgroundColor: "#7A7A7A",
            borderRadius: "50%",
            marginLeft: -8,
          }}
        >
          <p
            style={{
              fontWeight: 500,
              fontSize: 11,
              lineHeight: "18px",
              color: "#FFF",
            }}
          >
            {`+${data.length - max}`}
          </p>
        </div>
      ) : null}
    </div>
  )
}
export default RoleUsersCard
