import { ReactComponent as IconSearchNormal } from "assets/images/icons/search-normal.svg"
import { ReactComponent as IconClearSearch } from "assets/images/icons/icon-clear-search.svg"
import InputDefault from "components/Input/InputDefault"
interface Props {
  search: string
  handleChangeInputSearch: (event) => void
  onKeyPress: (event: React.KeyboardEvent<HTMLDivElement>) => void
  onRemoveSearch: () => void
  onBlurInput: () => void
}
const HeaderProjectSearch = (props: Props) => {
  const {
    search,
    handleChangeInputSearch,
    onKeyPress,
    onRemoveSearch,
    onBlurInput,
  } = props
  return (
    <div className="flex h-full items-center px-6">
      <IconSearchNormal values={search} onChange={handleChangeInputSearch} />
      <div className="relative">
        <p
          className="font-semibold text-14 leading-24 pl-3 pr-2 color-black-111111"
          style={{
            width: !search ? 196 : "auto",
            height: 24,
          }}
        >
          {search}
        </p>
        <div className="absolute top-0 right-0 left-0 bottom-0 custom-color-placeholder">
          <InputDefault
            placeholder="What are you looking for?"
            value={search}
            style={{
              border: "none",
              boxShadow: "none",
              height: 24,
              fontWeight: 600,
              paddingRight: 8,
            }}
            onChange={handleChangeInputSearch}
            onKeyPress={onKeyPress}
            onBlur={onBlurInput}
          />
        </div>
      </div>
      <div
        onClick={onRemoveSearch}
        className="h-7	w-7 flex items-center justify-center custom-icon-clear-search cursor-pointer"
      >
        <IconClearSearch />
      </div>
    </div>
  )
}
export default HeaderProjectSearch
