import Axios from "axios"
import { NoteInfo } from "../types"

export const createAgreementWithLastestDocument = async () => {
  return Axios.post(`/api/about/create-agreement`).then((res) => res.data)
}
export const getReleaseNotesMiddleware = async (): Promise<NoteInfo> => {
  return Axios.get(`api/change-log/get`).then((res) => res.data.data)
}
export const updateSeenNotesMiddleware = async () => {
  return Axios.put(`api/change-log/update-viewed-user`).then((res) => res.data)
}
