import { STATUS_BUILD } from "components/Status/types"
import { BuildConversationDetail } from "pages/conversations/conversations.type"
import { TypeSelectProp } from "pages/project-component-detail/types"
import { BuildStatusProp } from "pages/project-component/types"
import React from "react"

export interface GlobalBuildOverviewPageContext {
  cpnTypeOptions: TypeSelectProp[]
  cpnStatusOptions: BuildStatusProp[]
  objSelectType: any
  setObjSelectType?: (newObj: any) => void
  objSelectStatus: any
  setObjSelectStatus?: (newObj: any) => void
  setBuild: (buildId: string, data: Partial<BuildConversationDetail>) => void
  setSelectedBuildStatus: (status: STATUS_BUILD) => void
}

export const BuildOverviewPageContext =
  React.createContext<GlobalBuildOverviewPageContext>({
    cpnStatusOptions: [],
    cpnTypeOptions: [],
    objSelectType: {},
    objSelectStatus: {},
    setBuild: () => {},
    setSelectedBuildStatus: () => {},
  })
