import StatusProject from "pages/projects/molecules/StatusProject"
import Typography from "components/Typography"
import { ProjectDetail, ProjectStatus } from "pages/projects/types"
import { TippyCustomzie } from "components/TippyCustomzie"
import { useBoolean, useString, useWindowSize } from "helpers/hooks"
import ActionItem from "pages/projects/molecules/ActionItem"
import { LIST_STATUS_PROJECT } from "pages/projects/project.constant"
import RoleUsersCard from "pages/projects/molecules/RoleUsersCard"
import { Avatar } from "components/Avatar"
import { useContext, useEffect } from "react"
import { PermissionProjectPage } from "../contexts/PermissionProjectPage.context"
import { checkPermissionPage } from "helpers/utils"
import Tippy from "@tippyjs/react"
import { useAppSelector } from "hooks/useApp"
import Button from "components/Button/Button"
import { ReactComponent as IconSetting } from "assets/images/icons/icon-settings.svg"
import DotCard from "pages/conversations/atoms/DotCard"
interface Props {
  projectDetail: ProjectDetail
  handleChangeStatusProject: (
    idStatus: string,
    callback: () => void
  ) => () => void
  onClickAction: (
    key: "edit" | "archive" | "delete",
    callback: () => void
  ) => () => void
  handleShare: () => void
  handleChangeData?: (newProject?: ProjectDetail) => void
  isDeleted?: boolean
}
const InfoProjectCard = (props: Props) => {
  const {
    projectDetail,
    handleShare,
    handleChangeStatusProject,
    onClickAction,
    isDeleted,
  } = props
  const { isMobile } = useWindowSize()
  const showTippy = useBoolean(false)
  const showTippyName = useBoolean(false)
  const editDescription = useString("")
  const { archiveProject, viewOnlyShare } = useContext(PermissionProjectPage)
  const userInfo = useAppSelector((state) => state.userInfo)

  useEffect(() => {
    editDescription.setValue(projectDetail.description)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetail.description])
  const handleClickTippy = (event) => {
    event.stopPropagation()
    event.preventDefault()
    if (
      checkPermissionPage({
        project: archiveProject,
        viewShare: viewOnlyShare,
      }) ||
      isDeleted
    ) {
      return
    }
    showTippy.setValue(!showTippy.value)
  }
  const handleClickTippyName = (event) => {
    event.stopPropagation()
    event.preventDefault()
    if (
      checkPermissionPage({
        viewShare: viewOnlyShare,
      }) ||
      isDeleted
    ) {
      return
    }
    showTippyName.setValue(!showTippyName.value)
  }

  const renderRoleUser = () => {
    if (!projectDetail.role_users || !projectDetail) {
      return null
    }

    const owner = projectDetail.role_users.find(
      (i) => i.user_id === projectDetail.created_by
    )
    const contributors =
      projectDetail.role_users.filter(
        (i) => i.user_id !== projectDetail.created_by
      ) || []

    if (userInfo.id === owner?.user_id && !contributors.length) {
      return null
    }

    return (
      <div className="flex items-center mr-1">
        {owner && userInfo.id !== owner.user_id ? (
          <div className="flex items-center mr-[12px]">
            <span
              className="font-medium"
              style={{
                color: "#7A7A7A",
                fontSize: 12,
                lineHeight: "18px",
                marginRight: 4,
              }}
            >
              Owner:
            </span>
            <Tippy
              className="custom-tippy-menu-left"
              placement="bottom"
              content={
                <div className="text-center">
                  <p>{owner.fullname}</p>
                  <p>{owner.email}</p>
                </div>
              }
            >
              <div className="cursor-pointer">
                <Avatar
                  width="24px"
                  height="24px"
                  name={owner.fullname}
                  avatar={owner.avatar}
                  textcolor="white"
                  contentsize="11px"
                  justOneLetter={true}
                />
              </div>
            </Tippy>
          </div>
        ) : null}
        {contributors.length > 0 ? (
          <>
            <div>
              <span
                className="font-medium"
                style={{
                  color: "#7A7A7A",
                  fontSize: 12,
                  lineHeight: "18px",
                  marginRight: 4,
                }}
              >
                Contributors:
              </span>
            </div>
            <RoleUsersCard
              data={contributors}
              max={projectDetail.role_users.length}
            />
          </>
        ) : null}
      </div>
    )
  }

  const renderDesciption = () => (
    <p className="font-sm cursor-pointer color-[#111111] leading-6 custom-break-words  mt-0 md:mt-[31px]">
      {projectDetail.description}
    </p>
  )

  return (
    <div className="flex flex-col md:flex-row justify-between px-6 py-2 gap-y-3 md:gap-y-0 bg-white border-b border-[#E4E4E4]">
      <div className="flex flex-grow flex-col md:justify-between  ">
        <div className="flex flex-col md:flex-row-reverse items-start md:items-center md:justify-end gap-2 md:gap-0 ">
          <div className="flex items-center">
            <Typography
              variant="h6"
              style={{
                color: "#111111",
                lineHeight: "26px",
                fontWeight: 600,
              }}
            >
              {projectDetail.name}
            </Typography>
          </div>

          {!isDeleted && userInfo.id === projectDetail.created_by ? (
            <TippyCustomzie
              containerClass="component-card-menu"
              placement="bottom-end"
              interactive
              arrow={false}
              animation="scale"
              visible={showTippy.value}
              offset={[20, 7]}
              onClickOutside={() => showTippy.setValue(false)}
              content={
                <div
                  style={{
                    background: "#222222",
                    borderRadius: 4,
                    paddingTop: 8,
                    paddingBottom: 8,
                    width: 100,
                  }}
                >
                  {LIST_STATUS_PROJECT.filter((el) => el.id).map(
                    (status, index) => (
                      <ActionItem
                        key={index}
                        title={status.label}
                        onClick={handleChangeStatusProject(status.id, () =>
                          showTippy.setValue(false)
                        )}
                      />
                    )
                  )}
                </div>
              }
              allowHTML
            >
              <div className="flex items-center">
                <p
                  className=" md:hidden font-semibold"
                  style={{
                    fontSize: 13,
                    lineHeight: "22px",
                    color: "#333333",
                  }}
                >
                  {projectDetail.code}
                </p>
                {isMobile && <DotCard />}

                <div onClick={handleClickTippy} className="cursor-pointer">
                  <StatusProject
                    status={
                      projectDetail.is_archived
                        ? ProjectStatus.Archive
                        : projectDetail.status || 4
                    }
                    isIcon={
                      !checkPermissionPage({
                        project: archiveProject,
                        viewShare: viewOnlyShare,
                      })
                    }
                    minWidth={85}
                  />
                </div>
              </div>
            </TippyCustomzie>
          ) : null}
        </div>

        {isMobile ? null : renderDesciption()}
      </div>

      <div className="flex justify-between flex-col ml-0 md:ml-2 gap-y-3 md:gap-y-0">
        <div className="flex justify-start md:justify-end">
          {projectDetail.categories && projectDetail.categories.length
            ? projectDetail.categories.map((category, index) => (
                <div
                  key={index}
                  className="flex items-center md:px-2 h-26-custom mr-1 last:mr-0"
                  style={{
                    background: "#F7F7F7",
                    borderRadius: 4,
                  }}
                >
                  <p
                    className="font-medium"
                    style={{
                      color: "#7A7A7A",
                      fontSize: 12,
                      lineHeight: "18px",
                    }}
                  >
                    {category.name}
                  </p>
                </div>
              ))
            : null}
        </div>

        <div className="flex justify-between md:justify-end">
          {renderRoleUser()}
          {!isDeleted ? (
            <div className="flex justify-between gap-3 md:gap-0 ">
              <Button
                title="Share"
                colorBtn="white"
                onClick={handleShare}
                disabled={checkPermissionPage({
                  project: archiveProject,
                  viewShare: userInfo.id !== projectDetail.created_by,
                })}
                sizeBtn="small"
              />
              {userInfo.id === projectDetail.created_by && (
                <TippyCustomzie
                  containerClass="component-card-menu"
                  placement="bottom-end"
                  interactive
                  arrow={false}
                  animation="scale"
                  visible={showTippyName.value}
                  offset={[0, 7]}
                  allowHTML
                  onClickOutside={() => showTippyName.setValue(false)}
                  content={
                    <div
                      onClick={() =>
                        showTippyName.setValue(!showTippyName.value)
                      }
                      style={{
                        background: "#222222",
                        borderRadius: 4,
                        paddingTop: 8,
                        paddingBottom: 8,
                        width: 100,
                      }}
                    >
                      {projectDetail.is_archived === 0 ? (
                        <ActionItem
                          className="hidden md:flex"
                          title="Edit"
                          onClick={onClickAction("edit", () =>
                            showTippyName.setValue(false)
                          )}
                        />
                      ) : null}
                      <ActionItem
                        title={
                          projectDetail.is_archived === 0
                            ? "Archive"
                            : "Unarchive"
                        }
                        onClick={onClickAction("archive", () =>
                          showTippyName.setValue(false)
                        )}
                      />
                      <ActionItem
                        title={"Delete"}
                        onClick={onClickAction("delete", () =>
                          showTippyName.setValue(false)
                        )}
                      />
                    </div>
                  }
                >
                  <Tippy
                    offset={[0, 10]}
                    className="custom-tippy-menu-left"
                    placement="top"
                    content={<p>Setting</p>}
                  >
                    <div
                      onClick={handleClickTippyName}
                      className="flex items-center mt-1 cursor-pointer "
                    >
                      <IconSetting className="w-[20px] h-[20px]" />
                    </div>
                  </Tippy>
                </TippyCustomzie>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}
export default InfoProjectCard
