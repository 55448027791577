import { useState, useEffect, useCallback } from "react"
import { useBoolean } from "helpers/hooks"
import {
  FormProjectRequest,
  defaultFormProjectRequest,
  FormProjectProps,
  CategoryDetail,
  ProjectDetail,
} from "../types"
import { toast } from "react-toastify"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
import FormInputCard from "pages/auth/molecules/FormInputCard"
import InputDefault from "components/Input/InputDefault"
import MultipleInput from "components/Input/MultipleInput"
import ModalCustom from "components/ModalCustom"
import SelectMulti from "components/Select/SelectMulti"
import {
  getCategoriesMiddleware,
  postCategoryMiddleware,
  postProjectMiddleware,
  putProjectMiddleware,
} from "../services/api"
import { STATUS_RESPONSE } from "types"
import { cloneDeep, debounce } from "lodash"
import ActionFooterModalCard from "components/ModalCustom/ActionFooterModalCard"
import { umamiTracking } from "helpers/utils"
import { EVENT } from "constants/events"

const FormProject = (props: FormProjectProps) => {
  const { defaultRequest, openModal, handleChangeData, onCloseModal } = props
  const isLoading = useBoolean()
  const [request, setRequest] = useState<FormProjectRequest>(
    defaultFormProjectRequest
  )
  const [selectedCategory, setSelectedCategory] = useState<CategoryDetail[]>([])
  const [listCategories, setListCategories] = useState<CategoryDetail[]>([])
  const isLoadingSearch = useBoolean(false)

  useEffect(() => {
    if (!openModal) {
      return
    }
    setRequest({
      ...request,
      name: defaultRequest.name,
      description: defaultRequest.description,
    })
    setSelectedCategory(defaultRequest.selectCategories ?? [])
    getCategories()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultRequest, openModal])

  const getCategories = async (newInputName = "", loading = true) => {
    isLoading.setValue(loading)
    const params = {
      page: 1,
      pageSize: 7,
      filter: {
        name: newInputName,
      },
    }

    try {
      const dataRes = await getCategoriesMiddleware(
        `/api/category/get-user-categories`,
        params
      )
      const newDataRes: CategoryDetail[] = dataRes.map((el) => {
        return {
          ...el,
          label: el.name,
          value: el.id,
        }
      })
      setListCategories(newDataRes)
      isLoadingSearch.setValue(false)
      isLoading.setValue(false)
    } catch (error) {
      setListCategories([])
      isLoading.setValue(false)
    }
  }

  const handleChangeInput = (key: "name" | "description") => (event) => {
    if (key === "description" && event.target.value.length > 150) {
      return
    }
    setRequest({
      ...request,
      [key]: event.target.value,
    })
  }

  const handleDisableButton = () => {
    if (request.description.length > 150) {
      return true
    }
    if (!request.name) {
      return true
    }
    return false
  }
  const onSubmit = () => {
    if (request.description.length > 150) {
      toast(
        <LabelNotificationPage
          messenger={MESSENGER_NOTIFICATION.DESCRIPTION_ERROR}
          type="error"
        />
      )
      return
    }
    if (selectedCategory.length === 0) {
      toast(
        <LabelNotificationPage
          messenger={MESSENGER_NOTIFICATION.REQUICE_CREATE_CATEGORY_ERROR}
          type="error"
        />
      )
      return
    }
    const dataRequest = {
      name: request.name,
      description: request.description,
      category_ids: selectedCategory.map((el) => el.id),
    }
    isLoading.setValue(true)
    if (defaultRequest.id) {
      putProjectMiddleware(
        defaultRequest.id,
        dataRequest,
        (type: STATUS_RESPONSE, messenger: string, dataRes?: ProjectDetail) => {
          toast(<LabelNotificationPage messenger={messenger} type={type} />)
          isLoading.setValue(false)
          if (type === STATUS_RESPONSE.SUCCESS && dataRes) {
            onCloseModal()
            handleChangeData(dataRes)
          }
        }
      )
      return
    }
    postProjectMiddleware(
      dataRequest,
      (type: STATUS_RESPONSE, messenger: string) => {
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        isLoading.setValue(false)
        if (type === STATUS_RESPONSE.SUCCESS) {
          onCloseModal()
          handleChangeData()
        }
      }
    )
    umamiTracking(EVENT.PROJECT.CREATE)
  }

  const handleChange = (newValue) => {
    setSelectedCategory(newValue)
  }
  const handleCreateSelect = (newSelect) => async () => {
    if (!newSelect) {
      return
    }
    postCategoryMiddleware(
      {
        name: newSelect,
      },
      (type: STATUS_RESPONSE, messenger: string, data?: CategoryDetail) => {
        if (type === STATUS_RESPONSE.ERROR) {
          toast(<LabelNotificationPage messenger={messenger} type={type} />)
          return
        }
        if (data) {
          const newlistCategories = cloneDeep(listCategories)
          newlistCategories.push({
            id: data.id,
            name: data.name,
            value: data.id,
            label: data.name,
          })
          setListCategories(newlistCategories)
        }
      }
    )
  }
  const handleInputChange = (newValueInput: string) => {
    isLoadingSearch.setValue(true)
    debounceSearch(newValueInput)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    debounce((newSearch: string) => {
      getCategories(newSearch, false)
    }, 200),
    []
  )

  return (
    <ModalCustom
      label={`${defaultRequest.id ? "Edit project" : "Create new Project"}`}
      handleChangeButton={() => onCloseModal}
      bodyChildren={
        <div className="flex flex-col">
          <FormInputCard
            title="Project Name"
            required
            fontWeightText="font-semibold"
          >
            <InputDefault
              value={request.name}
              onChange={handleChangeInput("name")}
            />
          </FormInputCard>
          <FormInputCard title="Description" fontWeightText="font-semibold">
            <MultipleInput
              value={request.description}
              onChange={handleChangeInput("description")}
              rows={4}
              maxLength={150}
            />
          </FormInputCard>
          <FormInputCard
            title="Category"
            required
            fontWeightText="font-semibold"
          >
            <SelectMulti
              options={listCategories}
              selectedOption={selectedCategory}
              handleChange={handleChange}
              handleCreateSelect={handleCreateSelect}
              handleInputChange={handleInputChange}
              isLoadingSearch={isLoadingSearch.value}
            />
          </FormInputCard>
          <ActionFooterModalCard
            onCloseModal={onCloseModal}
            onSubmit={onSubmit}
            handleDisableButton={handleDisableButton()}
            titleButton={defaultRequest.id ? "Save" : "Create"}
          />
        </div>
      }
    />
  )
}

export default FormProject
