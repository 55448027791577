import { TextareaProps } from "./types"

const MultipleInput = (props: TextareaProps) => {
  const { value, hiddenText, maxLength, ...otherProps } = props

  const classes = `border box-shadow-input rounded px-3 pt-2 pb-4 text-sm font-normal focus:outline-none `

  return (
    <div className="flex flex-col relative">
      <textarea
        style={{
          color: "#111111",
        }}
        value={value}
        className={classes}
        maxLength={maxLength}
        {...otherProps}
      />
      {!hiddenText && String(value).length ? (
        <div className="absolute left-[2px] bottom-[1px] w-[95%] mr-2 bg-white flex justify-end">
          <span className="text-gray text-xs italic">
            {String(value).length}/{maxLength}
          </span>
        </div>
      ) : null}
    </div>
  )
}

export default MultipleInput
