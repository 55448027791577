import { PARAM_ORDER } from "constants/index"
import { GetUsersInProjectProp } from "pages/project-component/types"
import { CSSProperties } from "react"

export enum ProjectFilter {
  sharing = "Sharing",
  status = "Status",
  category = "Category",
}

export enum ProjectStatus {
  Abandoned = 1,
  Active = 2,
  Completed = 3,
  NoStatus = 4,
  DeletedAttachment = 5,
  Archive = 6,
}
export enum SEARCH_TAB {
  PROJECT = "Projects",
  COMPONENT = "Components",
  BUILD = "Builds",
  CONVERSATION = "Conversation",
}
export interface PageLayoutProjectProps {
  children: React.ReactNode
  childrenHeader?: React.ReactNode
  heightHeader?: number
  minWidth?: string
  dynamicContent?: boolean
  contentStyles?: CSSProperties
  contentClass?: string
  headerClass?: string
  headerStyles?: CSSProperties
  containerClass?: string
  containerStyles?: CSSProperties
}

export interface FormProjectRequest {
  id: string
  selectCategories: CategoryDetail[]
  name: string
  description: string
}

export const defaultFormProjectRequest: FormProjectRequest = {
  id: "",
  selectCategories: [],
  description: "",
  name: "",
}

export interface AutoSelectItemProps {
  listItem: any[]
  filedShow?: string
  handleChangeItem: (item: any) => (event) => void
  handleMoreData?: () => void
}

export interface FormProjectProps {
  handleChangeData: (newProject?: ProjectDetail) => void
  onCloseModal: () => void
  defaultRequest: FormProjectRequest
  openModal: boolean
}

export interface ProjectDetail {
  id: string
  category_id: string
  categories: CategoriesProp[]
  name: string
  description: string
  created_at: string
  created_by: string
  updated_at: string
  code: string
  status: ProjectStatus
  is_archived: number
  is_favorite: number
  role_users: GetUsersInProjectProp[]
  user?: GetUsersInProjectProp
  live_component_count: number
  component_per_project_limit: number
  full_code: string
}
export interface CategoriesProp {
  id: string
  name: string
  created_at: string
  created_by: string
}

export const defaultProjectDetail: ProjectDetail = {
  category_id: "",
  created_at: "",
  created_by: "",
  description: "",
  id: "",
  name: "",
  updated_at: "",
  categories: [],
  code: "",
  is_archived: 0,
  is_favorite: 0,
  live_component_count: 0,
  component_per_project_limit: 0,
  status: ProjectStatus.NoStatus,
  role_users: [],
  full_code: "",
}

export interface ProjectCardProps {
  item: ProjectDetail
  isDiable: boolean
  handleChangeButton: (
    key: "share" | "edit" | "archive" | "favorite" | "delete_attachment",
    data: ProjectDetail,
    callback: () => void
  ) => void
  loggedUserId?: string
  openToNewTab?: boolean
}

export interface CategoryDetail {
  id: string
  name: string
  label?: string
  value?: string
}

export const defaultCategoryDetail: CategoryDetail = {
  id: "",
  name: "",
  label: "All",
  value: "",
}

export interface CategoryRequest {
  page: number
  pageSize: number
  filter?: {
    name: string
  }
}

export enum StatusProps {
  all = 0,
  abondoned = 1,
  active = 2,
  completed = 3,
  noStatus = 4,
}

export enum ShareProps {
  all = -1,
  noShared = 0,
  shared = 1,
}

export interface ParamsProjectsProps {
  page?: number
  pageSize?: number
  category_ids?: string
  status?: string
  is_shared?: ShareProps
  is_archived?: 0 | 1
  is_deleted?: 0 | 1
  sort?: string
  filter?: {
    name?: string
  }
  order?: PARAM_ORDER
  search?: string
}

export interface HeaderProjectProps {
  categories: CategoryDetail[]
  selectCategory: CategoryDetail
  handleChangeSelect: (selectCategory: CategoryDetail) => void
  totalSearchProject: number
}

export const defaultCpnTypeSelected = { label: "All", value: "0" }
export const defaultStatusSelected = { label: "All", value: "" }

export const STATUS_PROJECT_ROLE = {
  VIEWER: "Viewer",
  OWNER: "Owner",
  EDITOR: "Editor",
}

export interface StatusProjectDetail {
  id: string
  name: string
  label: string
  value: string
}

export const defaultStatusProjectDetail: StatusProjectDetail = {
  id: "",
  name: "",
  label: "All",
  value: "",
}

export interface ProjectCount {
  favorite_projects: number
  my_projects: number
  shared_with_me_projects: number
}

export interface ProjectActivityDetail {
  id: string
  project_id: string
  content: string
  created_at: string
  created_by: string
  user: GetUsersInProjectProp
}
