import DotCard from "./DotCard"

interface Props {
  name: string
  email: string
  label: string
}
const InfoNameAndEmailConversationCard = (props: Props) => {
  const { email, name, label } = props
  return (
    <div className="flex items-center">
      <p
        className="font-normal text-13 leading-22 color-gray-7a"
        style={{
          width: 48,
        }}
      >
        {label}
      </p>
      <p className="font-semibold text-13 leading-22 color-black-111111 max-w-150 md:max-w-461 overflow-hidden text-ellipsis">
        {name}
      </p>
      <DotCard />
      <p className="font-normal text-13 leading-22 color-gray-7a ">{email}</p>
    </div>
  )
}
export default InfoNameAndEmailConversationCard
