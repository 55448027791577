import ActionIconHistory from "pages/project-component-detail/molecules/ActionIconHistory"

interface Props {
  handleClickTippyComment: (event) => void
  isReadComponent: boolean
}
const ActionIconComment = (props: Props) => {
  const { handleClickTippyComment, isReadComponent } = props
  return (
    <div
      className="flex items-center relative"
      onClick={handleClickTippyComment}
    >
      <ActionIconHistory
        icon="message"
        tooltip="Comment"
        styleIcon={{
          marginRight: 0,
        }}
      />
      {!isReadComponent ? (
        <div
          className="absolute"
          style={{
            height: 11,
            width: 11,
            background: "#EA4545",
            border: "3px solid #FFFFFF",
            borderRadius: "50%",
            top: 1,
            right: 1,
          }}
        ></div>
      ) : null}
    </div>
  )
}
export default ActionIconComment
