export interface BreadcrumbsDetail {
  name: string
  field?: string
  value?: string
  path?: string
  query?: string
  isNoHover?: boolean
  isActive?: boolean
  onCustomRouter?: () => void
}

export interface BreadcrumbsProps {
  listBreadcrumbs: BreadcrumbsDetail[]
  className?: string
  overflow?: boolean
}

export enum TAB_HEADER_BREADCRUMB {
  BREADCRUMB = "Breadcrumb",
  PROJECT_INFO = "Project-info",
}
