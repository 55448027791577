import { RootState, configureStore } from "stores/configureStore"
import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit"
import { DeleteModalProps } from "components/ModalCustom/ModalDelete"

export type ModalType =
  | "none"
  // General
  | "Delete"
  | "Custom"

export interface ModalState {
  type: ModalType
  props: {
    customModal?: any
    deleteModal?: Omit<DeleteModalProps, "onCloseModal">
  }
}

const initialState: ModalState = {
  props: {},
  type: "none",
}

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal: (
      _state,
      action: PayloadAction<
        Omit<ModalState, "props"> & { props?: Partial<ModalState["props"]> }
      >
    ) => {
      return {
        ...action.payload,
        props: { ...initialState.props, ...action.payload.props },
      }
    },
    closeModalAction: (state) => {
      state.type = "none"
    },
    resetModalState: () => initialState,
  },
})

export const { openModal, closeModalAction } = modalSlice.actions

export const modalReducer = modalSlice.reducer

export const closeModal = () => {
  configureStore.dispatch(closeModalAction())
  setTimeout(
    () => configureStore.dispatch(modalSlice.actions.resetModalState()),
    300
  )
}

const modalSeletor = (state: RootState) => state.modal

export const modalPropsSelector = createSelector(
  modalSeletor,
  ({ props = {} }) => {
    return props as Required<ModalState["props"]>
  }
)
