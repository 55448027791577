import React, { useState } from "react"
import PageLayoutAuth from "./PageLayoutAuth"
import InputDefault from "components/Input/InputDefault"
import FormInputCard from "./molecules/FormInputCard"
import Header from "./atoms/Header"
import LabelChangeRouter from "./molecules/LabelChangeRouter"
import { STATUS_INPUT } from "components/Input/types"
import {
  ForgetPasswordRequest,
  defaultForgetPasswordRequest,
  TYPE_RESEND_EMAIL,
} from "./types"
import { useBoolean } from "helpers/hooks"
import { isValidEmail, changeLabelStatusEmail } from "helpers/utils"
import { cloneDeep } from "lodash"
import { STATUS_RESPONSE } from "types"
import { forgetPasswordMiddleware } from "./services/api"
import { pushTo } from "helpers/history"
import { PATH } from "constants/path"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { toast } from "react-toastify"
import Button from "components/Button/Button"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { ACTION_RECAPTCHA } from "constants/actionReCaptcha"

const ForgetPassword = () => {
  const [request, setRequest] = useState<ForgetPasswordRequest>(
    defaultForgetPasswordRequest
  )
  const [statusInputEmail, setStatusInputEmail] = useState({
    status: STATUS_INPUT.DEFAULT,
    label: "",
  })
  const isLoading = useBoolean()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleChangeInput = (key: "email") => (event) => {
    setRequest({
      ...request,
      [key]: event.target.value,
    })
    setStatusInputEmail(changeLabelStatusEmail(event.target.value))
  }

  const handleDisableButton = () => {
    const newRequest = cloneDeep(request)
    let isDisable = false
    Object.values(newRequest).forEach((el, index) => {
      if (!el) {
        isDisable = true
      } else {
        if (Object.keys(newRequest)[index] === "email" && !isValidEmail(el)) {
          isDisable = true
        }
      }
    })

    return isDisable
  }

  const onClickButton = async () => {
    isLoading.setValue(true)
    if (!executeRecaptcha) {
      toast(
        <LabelNotificationPage
          messenger={MESSENGER_NOTIFICATION.RECAPTCHA_AVAILABLE}
          type="error"
        />
      )
      return
    }
    try {
      const token = await executeRecaptcha(ACTION_RECAPTCHA.FORGET_PASSWORD)
      const dataRequest: ForgetPasswordRequest = {
        ...request,
        captcha: token,
      }
      forgetPasswordMiddleware(
        dataRequest,
        (type: STATUS_RESPONSE, messenger) => {
          isLoading.setValue(false)
          if (type === STATUS_RESPONSE.SUCCESS) {
            toast(
              <LabelNotificationPage
                messenger={MESSENGER_NOTIFICATION.FORGOT_PASSWORD_SUCCESS ?? ""}
                type={STATUS_RESPONSE.SUCCESS}
              />
            )
            pushTo(PATH.confirmEmail, "", {
              type: TYPE_RESEND_EMAIL.FORGOT_PASSWORD,
              email: request.email,
              title: "Reset account password",
              label: "We have sent a link to reset password via email:",
            })
          } else {
            toast(
              <LabelNotificationPage messenger={messenger ?? ""} type="error" />
            )
          }
        }
      )
    } catch (error) {
      isLoading.setValue(false)
    }
  }

  const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      event.preventDefault()
      if (handleDisableButton()) {
        return
      }
      onClickButton()
    }
  }

  return (
    <PageLayoutAuth>
      <div className="h-full w-full flex flex-col p-6">
        <Header title="Forgot Password?" />
        <LabelChangeRouter
          title="Enter the email address you used when you joined and we’ll send you instructions to reset your password."
          label=""
          path=""
        />
        <div className="my-6">
          <FormInputCard title="Email">
            <InputDefault
              value={request.email}
              onChange={handleChangeInput("email")}
              status={statusInputEmail.status}
              labelStatus={statusInputEmail.label}
              onKeyPress={onKeyPress}
            />
          </FormInputCard>
        </div>
        <Button
          title="Send reset instructions"
          onClick={onClickButton}
          isDisabledBtn
          widthBtn="100%"
          disabled={handleDisableButton()}
        />
        <div className="mt-6 flex items-center justify-center cursor-pointer">
          <p
            className="font-normal hover:underline"
            style={{
              fontSize: 14,
              lineHeight: "24px",
              color: "#7A7A7A",
            }}
            onClick={() => pushTo(PATH.login)}
          >
            Back to login
          </p>
        </div>
      </div>
    </PageLayoutAuth>
  )
}
export default ForgetPassword
