import GerberError from "assets/images/error-gerber.png"
const ErrorGerber = () => {
  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <img className="my-6" src={GerberError} alt="icon"></img>
      <p className=" font-normal text-center text-black mb-3">
        The gerber viewer does not support this gerber
      </p>
    </div>
  )
}
export default ErrorGerber
