export enum FIELD_MENU_TERM_AND_POLICY {
  TERM = "TERM",
  POLICY = "POLICY",
}
export interface MenuTermAndPolicyDetail {
  label: string
  field: FIELD_MENU_TERM_AND_POLICY
}

export const menuTermAndPolicy: MenuTermAndPolicyDetail[] = [
  {
    label: "Terms and Conditions",
    field: FIELD_MENU_TERM_AND_POLICY.TERM,
  },
  {
    label: "Privacy Policy",
    field: FIELD_MENU_TERM_AND_POLICY.POLICY,
  },
]
