import ButtonHasIcon from "components/Button/ButtonHasIcon"
import CheckedDefault from "components/Checked/CheckedDefault"

interface Props {
  availableComponents: number
  selectAll: boolean
  onChangeSelectedAll: (oldSelect: boolean) => () => void
  onSubmitAddSelected: () => void
  disabledButtonAdd: boolean
}
const FooterSelectComponentCard = (props: Props) => {
  const {
    availableComponents,
    selectAll,
    disabledButtonAdd,
    onChangeSelectedAll,
    onSubmitAddSelected,
  } = props
  return (
    <div className="min-h-[64px] flex items-center justify-between pr-6 pt-[24px]">
      <CheckedDefault
        textColor="black"
        checked={selectAll}
        title="Select all"
        onClick={onChangeSelectedAll(selectAll)}
      />
      <div className="hidden md:flex md:items-center">
        <p
          className="font-normal"
          style={{
            fontSize: 14,
            color: "#7A7A7A",
            lineHeight: "24px",
            marginRight: 24,
          }}
        >
          Selected:
          <span
            style={{
              fontWeight: 600,
              color: "#222222",
            }}
          >
            {` ${availableComponents} component${
              availableComponents > 1 ? "s" : ""
            }`}
          </span>
        </p>
        <ButtonHasIcon
          title="Add selected"
          disabled={disabledButtonAdd}
          isDisabledBtn
          widthButton={104}
          heightButton={40}
          showIcon={false}
          onClick={onSubmitAddSelected}
        />
      </div>
    </div>
  )
}

export default FooterSelectComponentCard
