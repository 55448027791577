import { STATUS_BUILD } from "components/Status/types"
import React from "react"
import { twMerge } from "tailwind-merge"
import { ReactComponent as IconArrow } from "assets/images/icons/arrow-down-status-build.svg"

interface Props {
  status: STATUS_BUILD
  rootStyle?: React.CSSProperties
  isArchive?: boolean
  isSelect?: boolean
  className?: string
}
const LabelStatusBuild = (props: Props) => {
  const { status, rootStyle, isArchive, isSelect, className } = props
  const renderColor = () => {
    if (isArchive) {
      return "#C7C7C7"
    }
    let newColor = "#E87A16"
    switch (status) {
      case STATUS_BUILD.COMMITTED:
        newColor = "#56A012"
        break
      case STATUS_BUILD.RFQ:
        newColor = "#8F00FF "
        break
      case STATUS_BUILD.SAVE:
      case STATUS_BUILD.MERGED:
      case STATUS_BUILD.IN_PRODUCTION:
        newColor = "#0A5AF5 "
        break
      case STATUS_BUILD.COMPLETED:
        newColor = "#56A012"
        break
      case STATUS_BUILD.UNAPPROVE:
      case STATUS_BUILD.CANCELLED:
        newColor = "#EA4545"
        break
      case STATUS_BUILD.DELETED:
        newColor = "#e74c3c"
        break
    }
    return newColor
  }
  return (
    <div
      className={twMerge(
        `flex items-center ${isSelect ? "cursor-pointer" : ""}`,
        className
      )}
    >
      <p
        className="font-semibold"
        style={{
          fontSize: 13,
          lineHeight: "22px",
          color: renderColor(),
          ...rootStyle,
        }}
      >
        {status === STATUS_BUILD.DELETED ? "Deleted" : status}
      </p>
      {isSelect ? <IconArrow className="ml-2" /> : null}
    </div>
  )
}
export default LabelStatusBuild
