import { LogoutCardProps, ACTION_LOGOUT } from "../types"
import Button from "components/Button/Button"
const LogoutCard = (props: LogoutCardProps) => {
  const { handleChangeButton } = props
  return (
    <div
      className="inset-0 flex fixed items-center justify-center"
      style={{
        zIndex: 10000,
        backgroundColor: "#22222285",
      }}
    >
      <div className="px-6 py-6 bg-white rounded-md flex flex-col items-center justify-center mx-6 w-webkit-fill md:w-[420px]">
        <p
          className="font-semibold"
          style={{
            fontSize: 20,
            lineHeight: "32px",
            color: "#111111",
          }}
        >
          Are you sure you want to logout?
        </p>
        <div className="flex flex-col md:flex-row items-center justify-between w-full mt-10 gap-3">
          <Button
            widthBtn={"100%"}
            title="Yes"
            colorBtn="white"
            onClick={handleChangeButton(ACTION_LOGOUT.YES)}
          />
          <Button
            widthBtn={"100%"}
            title="No"
            onClick={handleChangeButton(ACTION_LOGOUT.NO)}
          />
        </div>
      </div>
    </div>
  )
}
export default LogoutCard
