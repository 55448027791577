import React from "react"

export enum STATUS_INPUT {
  DEFAULT = "default",
  ERROR = "error",
  VALID = "valid",
}

export interface InputDefaultProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  status?: STATUS_INPUT
  labelStatus?: string
  isPassword?: boolean
  customIcon?: string
  classCustom?: string
  styleRootInput?: React.CSSProperties
}

export interface InputSearchProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  handleClearInputSearch?: () => void
}

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  hiddenText?: boolean
}
