export const EVENT = {
  TERM: "terms-and-conditions",
  POLICY: "policies",
  LOGIN: "login",
  SIGNUP: "sign-up",
  FORGOT_PASSWORD: "forgot-password",
  UPDATE_PROFILE: "update-profile",
  ENABLE_2FA: "enable-2fa",
  FAQ: "faq",
  EXPORT_QUOTA: "export-quota",
  DOWNLOAD_QUOTA: "download_quota",
  PROJECT: {
    CREATE: "create-project",
    FILTER: "filter-project",
    SHARE: "share-project",
    SEARCH: "search-project",
  },
  COMPONENT: {
    CREATE: "create-component",
    FILTER: "filter-component",
  },
  BUILD: {
    CREATE: "create-build",
    FILTER: "filter-build",
    DOWNLOAD: "download-build",
  },
  COMMENT: {
    VIEW: "view-comments",
    CREATE: "comment",
  },
  LOG: {
    VIEW: "view-logs",
  },
  BOM: {
    ADD_SUPPLIER: "add-supplier-bom",
    ADD_COLUMN: "add-column-bom",
    UPDATE_COLUMN: "update-column-bom",
    SYNC_SUPPLIER_STOCKS: "sync-supplier-stocks",
  },
  CONVERSATION: {
    FILTER: "filter-conversation",
    SEARCH: "search-conversation",
    CREATE: "create-conversation",
    ENABLE: "enable-conversation-notification",
    DISABLE: "disable-conversation-notification",
  },
  INVITEE: {
    CREATE: "create-invitee",
    SEND_INVITATION: "send-invitation-invitee",
    ADD_COMPONENT: "add-components",
    REMOVE: "remove-invitee",
  },
}
