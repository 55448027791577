import TabItem from "components/Tab/TabItem"
import { MENU_TAB_BUILD } from "pages/project-build/project-build.type"
interface Props {
  onChangeTab: (index: number, label?: string) => () => void
  activeTab: string
  additional: {
    isRead: boolean
    count: number
  }
  conversation: {
    isRead: boolean
    count: number
  }
  isDeactivated?: boolean
}
const MenuTabConversationCard = (props: Props) => {
  const { activeTab, additional, conversation, onChangeTab, isDeactivated } =
    props
  const styleTab = () => {
    return {
      height: "auto",
      borderBottom: "none",
      display: "flex",
      alignItems: "center",
    }
  }
  return (
    <div className="h-6 md:h-12 my-5 md:my-0 w-full flex items-center justify-normal overflow-x-auto overflow-y-hidden whitespace-nowrap">
      {/* <TabItem
        index={0}
        label={MENU_TAB_BUILD.COMPONENT}
        active={activeTab === MENU_TAB_BUILD.COMPONENT}
        handleClickTabItem={onChangeTab}
        className="flex items-center md:hidden h-auto border-0"
      /> */}
      <TabItem
        index={2}
        label={MENU_TAB_BUILD.CONVERSATION}
        active={activeTab === MENU_TAB_BUILD.CONVERSATION}
        handleClickTabItem={onChangeTab}
        count={conversation.count}
        styleTabRoot={styleTab()}
        isRead={conversation.isRead}
      />
      {!isDeactivated && (
        <TabItem
          index={1}
          label={MENU_TAB_BUILD.ADDITIONAL}
          count={additional.count}
          active={activeTab === MENU_TAB_BUILD.ADDITIONAL}
          handleClickTabItem={onChangeTab}
          styleTabRoot={styleTab()}
          isRead={additional.isRead}
        />
      )}
    </div>
  )
}
export default MenuTabConversationCard
