interface Props {
  styleRoot?: React.CSSProperties
  isDisabled?: boolean
}
const DotCard = (props: Props) => {
  const { styleRoot, isDisabled } = props
  return (
    <div
      className="h-1 w-1 mx-2"
      style={{
        backgroundColor: isDisabled ? "#C7C7C7" : "#7A7A7A",
        borderRadius: "50%",
        ...styleRoot,
      }}
    ></div>
  )
}
export default DotCard
