import { FC } from "react"
import { twMerge } from "tailwind-merge"

interface ItemWrapperProps {
  className?: string
}

const ItemWrapper: FC<ItemWrapperProps> = ({ children, className }) => (
  <div
    className={twMerge(
      "text-black overflow-hidden p-[12px] min-h-[192px] md:min-h-[262px] bg-white border border-border-gray rounded-md",
      className
    )}
  >
    {children}
  </div>
)

export default ItemWrapper
