import React, { useContext, useState, useEffect } from "react"
import { SpecificationEditorProps, FileEditorDetail } from "../types"
import InputEditor from "components/Input/InputEditor"
import { VersionSpecificationContext } from "../contexts/VersionSpecification.context"
import { remove, cloneDeep, differenceBy } from "lodash"
import {
  getBase64,
  convertUrlBase64,
  fieldFormDataSpecification,
} from "helpers/utils"
import { useString } from "helpers/hooks"
import InputEditor2 from "components/Input/InputEditor2"

const SpecificationEditor = (props: SpecificationEditorProps) => {
  const { item, readOnly = false, conversationId, versionId } = props

  const [listFileUrl, setListFileUrl] = useState<FileEditorDetail[]>([])
  const [originListFileUrl, setOriginListFileUrl] = useState<
    FileEditorDetail[]
  >([])
  const keyInputFile = useString()

  useEffect(() => {
    setListFileUrl(item.files || [])
    setOriginListFileUrl(item.files || [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(item.files)])

  const context = useContext(VersionSpecificationContext)

  const handleChangeEditor = (description: string) => {
    context?.updateDataForm(
      "formEditor",
      `${item.id}_editor_${item.sequence}`,
      description,
      Boolean(item.required),
      true
    )
  }

  const handleDeleteFileDownload =
    (oldIndex: number, idFile?: string) => () => {
      if (!idFile) {
        return
      }
      const newFiles = cloneDeep(listFileUrl)
      remove(newFiles, (_el, index) => index === oldIndex)
      const idOriginFiles = cloneDeep(originListFileUrl).map((el) => el.id)
      const idNewFiles = cloneDeep(newFiles).map((el) => el.id)
      setListFileUrl(newFiles)
      context?.updateDataForm(
        "formDeleteFile",
        item.id,
        differenceBy(idOriginFiles, idNewFiles),
        Boolean(item.required)
      )
    }

  const fileSelectedHandler = async (
    files: FileList,
    newIdPart: string,
    newSequence: number
  ) => {
    const currentFiles: any = []
    if (files) {
      for (const [, file] of Object.entries(files)) {
        currentFiles.push(file)
      }
    }

    const fileRequest: {
      name: string
      file: string
    }[] = []

    const calls: Promise<any>[] = []
    currentFiles.forEach((element) => {
      calls.push(
        getBase64(element).then((data) => {
          fileRequest.push({
            file: convertUrlBase64(String(data)),
            name: element.name,
          })
        })
      )
    })

    await Promise.all(calls)
    keyInputFile.setValue(Math.random().toString(36))
    let listFileOld = []
    if (
      context?.formDataEditorFile[
        fieldFormDataSpecification(newIdPart, newSequence)
      ] &&
      context?.formDataEditorFile[
        fieldFormDataSpecification(newIdPart, newSequence)
      ].length
    ) {
      listFileOld =
        context?.formDataEditorFile[
          fieldFormDataSpecification(newIdPart, newSequence)
        ]
    }
    context?.updateDataForm(
      "formAddFile",
      fieldFormDataSpecification(newIdPart, newSequence),
      fileRequest.concat(listFileOld),
      Boolean(item.required)
    )
  }

  const handleDeleteFileNoDownload = (oldIndex: number) => () => {
    const newFiles = cloneDeep(
      context?.formDataEditorFile[
        fieldFormDataSpecification(item.id, item.sequence)
      ]
    )
    remove(newFiles, (_el, index) => index === oldIndex)
    context?.updateDataForm(
      "formAddFile",
      fieldFormDataSpecification(item.id, item.sequence),
      newFiles,
      Boolean(item.required)
    )
  }

  return (
    <div className="hidden md:flex flex-col">
      <InputEditor2
        readOnly={readOnly}
        setValue={handleChangeEditor}
        listFileUrl={listFileUrl}
        value={
          context?.formDataEditor[item.id + "_editor_" + item.sequence] ?? ""
        }
        handleDeleteFileDownload={handleDeleteFileDownload}
        handleAddFiles={(files) => {
          fileSelectedHandler(files, item.id, item.sequence)
        }}
        listFileAdd={
          context?.formDataEditorFile[
            fieldFormDataSpecification(item.id, item.sequence)
          ] ?? []
        }
        keyInputFile={keyInputFile.value}
        idPart={item.id}
        handleDeleteFileNoDownload={handleDeleteFileNoDownload}
        isBlur
        conversationId={conversationId}
        versionId={versionId}
      />
    </div>
  )
}

export default SpecificationEditor
