import "./index.css"
import { isEmpty } from "lodash"
export interface SimpleTableProps {
  columns: {
    title: string
    dataIndex: string
    render?: (data: any) => any
    align?: "left" | "center" | "right"
    width?: string
  }[]
  dataSource: any
  displayOD?: boolean // ordinal numbers
  customClass?: string
  isRequiredTableHeader?: boolean
}

const Table = ({
  columns,
  dataSource,
  displayOD,
  customClass,
  isRequiredTableHeader = true,
}: SimpleTableProps) => {
  return (
    <>
      <table
        className={`${
          customClass ? customClass : "simple-table"
        } w-full text-sm text-left text-gray-500 dark:text-gray-400`}
      >
        {isRequiredTableHeader && (
          <thead>
            <tr>
              {displayOD ? (
                <th className="text-center pr-[12px]">#</th>
              ) : undefined}
              {columns.map((column, index) => (
                <th
                  key={index}
                  className={`text-${column.align || "left"}`}
                  style={{
                    width: column.width || "auto",
                  }}
                >
                  {column.title}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {dataSource.map((data: any, index: number) =>
            data.isShow === false ? (
              <></>
            ) : (
              <tr
                className={data.project_id ? "tr-secondary" : "tr-primary"}
                key={index}
              >
                {displayOD ? (
                  <td className="text-center pr-[12px]">{index + 1}</td>
                ) : undefined}
                {columns.map((column, index2) => (
                  <td
                    key={index2}
                    className={`text-${column.align || "left"}`}
                    style={{
                      width: column.width || "auto",
                    }}
                  >
                    {column.render
                      ? column.render(data)
                      : data[column.dataIndex || ""]}
                  </td>
                ))}
              </tr>
            )
          )}
        </tbody>
      </table>
      {isEmpty(dataSource) ? (
        <p className="text-center font-medium text-brown py-[10px]">
          No records found.
        </p>
      ) : null}
    </>
  )
}
export default Table
