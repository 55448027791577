import { useBoolean, useString } from "helpers/hooks"
import ModalCustom from "components/ModalCustom"
import FormInputCard from "pages/auth/molecules/FormInputCard"
import MultipleInput from "components/Input/MultipleInput"
import Button from "components/Button/Button"

interface Props {
  onCloseModal: () => void
  idHistory: string
  message: string
  label: string
  onSubmitButton: (newMessageInput: string, callback: () => void) => void
  messageUndo?: string
  extraTopForm?: JSX.Element
}

const FormConfirm = (props: Props) => {
  const {
    idHistory,
    message,
    label,
    messageUndo = " This action cannot be undone.",
    onCloseModal,
    onSubmitButton,
    extraTopForm,
  } = props
  const isLoading = useBoolean()
  const messageInput = useString("")
  const onSubmit = () => {
    if (!idHistory) {
      return
    }
    isLoading.setValue(true)
    onSubmitButton(messageInput.value, () => {
      isLoading.setValue(false)
      messageInput.setValue("")
    })
  }

  return (
    <ModalCustom
      label={label}
      handleChangeButton={() => onCloseModal}
      bodyChildren={
        <div className="flex flex-col">
          {extraTopForm}
          <FormInputCard title="Message" fontWeightText="font-semibold">
            <MultipleInput
              value={messageInput.value}
              onChange={(event) => {
                if (event.target.value.length > 256) {
                  return
                }
                messageInput.setValue(event.target.value)
              }}
              rows={3}
              maxLength={256}
            />
          </FormInputCard>
          <p
            className="font-medium"
            style={{
              fontSize: 12,
              lineHeight: "18px",
              color: "#222222",
            }}
          >
            {message}
          </p>
          <p
            className="font-medium"
            style={{
              fontSize: 12,
              lineHeight: "18px",
              color: "#222222",
            }}
          >
            {messageUndo}
          </p>
          <div className="flex items-center justify-between mt-8">
            <Button title="Cancel" colorBtn="white" onClick={onCloseModal} />
            <Button title="Confirm" onClick={onSubmit} />
          </div>
        </div>
      }
    />
  )
}

export default FormConfirm
