import React from "react"
import Select, { components } from "react-select"
import { ReactComponent as DownIcon } from "assets/images/icons/icon-select.svg"
import { SelectDefaultProps } from "./types"
import { ReactComponent as IconActiveSelect } from "assets/images/icons/icon-active-select.svg"
import { ReactComponent as IconSelectSupplier } from "assets/images/icons/icon-select-supplier.svg"
import { ReactComponent as IconSupplierDropdown } from "assets/images/icons/icon-supplier-dropdown.svg"

const SelectDefault = (props: SelectDefaultProps) => {
  const {
    options,
    selectedOption,
    handleChange,
    rootClasses,
    isDisabled,
    controlWidth,
    menuStyle,
    singleValueStyle,
    isSearchable = false,
    fontWeight,
    downIconClass,
    controlStyle,
    menuPlacement,
    selectSupplier,
  } = props
  const customStyles = {
    control: () => {
      return {
        ...{
          border: "none",
          height: 24,
          overflow: "hidden",
          display: "flex",
          minWidth: controlWidth || 150,
          cursor: "pointer",
          paddingLeft: "4px",
        },
        ...controlStyle,
      }
    },
    valueContainer: () => ({
      height: "100%",
      width: "100%",
      padding: 0,
    }),
    singleValue: (provided, state) => {
      const changeStyles = {
        maxWidth: "calc(100% - 32px)",
        color: "#111111",
        fontSize: "14px",
        fontWeight: fontWeight || "400",
        lineHeight: "24px",
        marginLeft: "0px",
        marginRight: "0px",
        transition: "opacity 300ms",
        opacity: state.isDisabled ? 0.5 : 1,
      }
      return {
        ...provided,
        ...changeStyles,
        ...singleValueStyle,
      }
    },
    indicatorSeparator: () => ({
      display: "none",
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: "24px",
      boxShadow: "none",
      backgroundColor: "#222222",
      ...menuStyle,
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      marginTop: 8,
      marginBottom: 8,
    }),
  }

  const DropdownIndicator = (dropProps) => {
    return (
      <components.DropdownIndicator {...dropProps}>
        {selectSupplier ? (
          <IconSupplierDropdown />
        ) : (
          <DownIcon className={downIconClass} />
        )}
      </components.DropdownIndicator>
    )
  }

  const renderColorSelected = (newIsSelected: boolean) => {
    if (selectSupplier && newIsSelected) {
      return "#FDBE44"
    }
    return newIsSelected ? "#111111" : "#FFFFFF"
  }
  const renderIconSelected = (newIsSelected: boolean) => {
    if (selectSupplier && newIsSelected) {
      return <IconSelectSupplier />
    }
    if (newIsSelected) {
      return <IconActiveSelect />
    }
    return null
  }
  return (
    <div className={rootClasses}>
      <Select
        value={selectedOption}
        styles={customStyles}
        isSearchable={isSearchable}
        onChange={handleChange}
        options={options}
        menuPosition="fixed"
        className="scrollbar-select"
        menuPlacement={menuPlacement || "auto"}
        isDisabled={isDisabled}
        components={{
          Option: (optionProps) => {
            const data = optionProps.data
            return (
              <div
                className={`min-h-32 flex items-center justify-between px-3 cursor-pointer ${
                  optionProps.isSelected ? "" : "hover-text-select"
                }`}
                style={{
                  backgroundColor:
                    optionProps.isSelected && !selectSupplier ? "#FDBE44" : "",
                }}
                {...optionProps.innerProps}
              >
                <span
                  className="font-normal"
                  style={{
                    fontSize: 13,
                    lineHeight: "22px",
                    color: renderColorSelected(optionProps.isSelected),
                  }}
                >
                  {data.label}
                </span>
                {renderIconSelected(optionProps.isSelected)}
              </div>
            )
          },
          DropdownIndicator,
        }}
      />
    </div>
  )
}
export default SelectDefault
