import { TippyCustomzie, useTippyLayout } from "components/TippyCustomzie"
import { useBoolean, useWindowSize } from "helpers/hooks"
import LabelHeader from "pages/project-component/molecules/LabelHeader"
import ActivityLogCard from "pages/project-component/organisms/ActivityLogCard"
import { MODEL_ACTIVITY_LOG } from "./activity-log.type"
interface Props {
  idRelation: string
  model: MODEL_ACTIVITY_LOG
}
const ActivityLogDefault = (props: Props) => {
  const { idRelation, model } = props
  const showTippy = useBoolean(false)
  const { isMobile } = useWindowSize()

  const { TippyLayout } = useTippyLayout()

  const handleClickTippy = (event) => {
    event.stopPropagation()
    showTippy.setValue(true)
  }

  const renderActivityContent = () => (
    <ActivityLogCard
      isOpen={showTippy.value}
      onCloseActivity={() => {
        showTippy.setValue(false)
      }}
      idRelation={idRelation}
      model={model}
    />
  )

  const renderActivityLabel = () => (
    <div
      className="flex items-center"
      style={{
        height: 19,
      }}
      onClick={handleClickTippy}
    >
      <LabelHeader
        title="Activity log"
        styleRoot={{
          cursor: "pointer",
        }}
      />
    </div>
  )

  return isMobile ? (
    <TippyLayout
      visible={showTippy.value}
      mainContent={renderActivityContent()}
    >
      {renderActivityLabel()}
    </TippyLayout>
  ) : (
    <TippyCustomzie
      containerClass="component-card-menu"
      placement="bottom-end"
      interactive
      arrow={false}
      animation="scale"
      visible={showTippy.value}
      onClickOutside={() => showTippy.setValue(false)}
      content={renderActivityContent()}
      allowHTML
    >
      {renderActivityLabel()}
    </TippyCustomzie>
  )
}
export default ActivityLogDefault
