import Button from "components/Button/Button"
import FormLabelWarning from "components/FormLabel/FormLabelWarning"
import ModalCustom from "components/ModalCustom"
import {
  DataErrorWhenEditHistoryTree,
  TAB_URL_PROJECT_COMPONENT,
} from "pages/project-component-detail/types"
interface Props {
  onCloseModal: () => void
  titlePage: string
  errorEditTree: DataErrorWhenEditHistoryTree
  onSubmitError: () => void
}
const FormRedirectHistoryTree = (props: Props) => {
  const { onCloseModal, titlePage, errorEditTree, onSubmitError } = props
  return (
    <ModalCustom
      label="Warning"
      handleChangeButton={() => onCloseModal}
      bodyChildren={
        <div className="flex flex-col">
          <FormLabelWarning label={errorEditTree.message} />
          <div className="flex items-center justify-between mt-8">
            <Button
              widthBtn="100%"
              title={
                titlePage === TAB_URL_PROJECT_COMPONENT.COMPONENT
                  ? `Go to Build ${errorEditTree.build_code}`
                  : "Go to main branch"
              }
              onClick={onSubmitError}
            />
          </div>
        </div>
      }
    />
  )
}
export default FormRedirectHistoryTree
