import Tippy from "@tippyjs/react"
import { Avatar } from "components/Avatar"
import { TippyCustomzie } from "components/TippyCustomzie"
import TableInviteeAdditionalInBOMItem from "./TableInviteeAdditionalInBOMItem"
import { ReactComponent as IconActionTable } from "assets/images/icons/icon-action-table-bom.svg"
import { useEffect, useState } from "react"
import { cloneDeep } from "lodash"
import { useNumber } from "helpers/hooks"

interface Props {
  originInviteeBoms: any[]
  isDisable: boolean
  lengthBOMJSON: number
  onChangeScroll: () => void
}

const TableInviteeBOMCard = (props: Props) => {
  const { originInviteeBoms, lengthBOMJSON, onChangeScroll } = props
  const [listExpand, setListExpand] = useState<boolean[]>([])
  const [listActive, setListActive] = useState<boolean[]>([])
  const fieldActive = useNumber(-1)

  useEffect(() => {
    setListExpand(originInviteeBoms.map(() => true))
    setListActive(originInviteeBoms.map(() => false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(originInviteeBoms)])
  const handleOpenModal = (newIdx: number) => () => {
    const newListActive = cloneDeep(listActive)
    newListActive[newIdx] = !newListActive[newIdx]
    setListActive(newListActive)
    fieldActive.setValue(newIdx)
  }

  const handleOnClickOutside = () => {
    const newListActive = cloneDeep(listActive)
    setListActive(newListActive.map((_el) => false))
    fieldActive.setValue(-1)
  }
  const onChangeCollapse = (newIdx: number) => () => {
    const newListExpand = cloneDeep(listExpand)
    newListExpand[newIdx] = !newListExpand[newIdx]
    setListExpand(newListExpand)
    const newListActive = cloneDeep(listActive)
    newListActive[newIdx] = !newListActive[newIdx]
    setListActive(newListActive)
    fieldActive.setValue(-1)
    onChangeScroll()
  }
  const renderStyle = (oldEl: any, oldIndex: number) => {
    return {
      borderRight:
        !oldEl.additional_json.length || !listExpand[oldIndex]
          ? "1px solid #E4E4E4"
          : "none",

      borderTopRightRadius:
        oldIndex === originInviteeBoms.length - 1 &&
        (!oldEl.additional_json.length || !listExpand[oldIndex])
          ? 6
          : 0,
      borderBottomRightRadius:
        oldIndex === originInviteeBoms.length - 1 &&
        (!oldEl.additional_json.length || !listExpand[oldIndex])
          ? 6
          : 0,
    }
  }

  return (
    <div
      className="flex"
      style={{
        marginTop: 10,
      }}
    >
      {originInviteeBoms.map((el, index) => {
        return (
          <div
            className={`flex cursor-pointer relative ${
              el.additional_json.length
                ? "hover-disabled-button-action-table"
                : ""
            }`}
            style={{
              border:
                fieldActive.value === index ? "1px solid #FDBE44" : "none",
              willChange: "width",
              transition: "width 0.5s cubic-bezier(0.2, 0, 0, 1) 0s",
            }}
            key={index}
          >
            <div
              key={index}
              className={`flex flex-col`}
              style={{
                backgroundColor: "#FFFFFF",
                borderTop: "1px solid #E4E4E4",
                borderBottom: "1px solid #E4E4E4",
                ...renderStyle(el, index),
              }}
            >
              <div
                className={`flex items-center justify-center font-semibold min-h-48-custom h-12`}
                style={{
                  fontSize: 14,
                  lineHeight: "24px",
                  color: "#111111",
                  width: 48,
                  minWidth: 48,
                  borderBottom: "1px solid #E4E4E4",
                  wordBreak: "break-all",
                }}
              >
                {el?.user ? (
                  <Tippy
                    className="custom-tippy-menu-left"
                    placement="top"
                    content={
                      <span
                        className="font-normal"
                        style={{
                          fontSize: 13,
                          lineHeight: "22px",
                        }}
                      >
                        {el.user.name}
                      </span>
                    }
                  >
                    <div>
                      <Avatar
                        width="24px"
                        height="24px"
                        name={el.user.name}
                        avatar={el.user.avatar}
                        textcolor="white"
                        contentsize="11px"
                        justOneLetter={true}
                      />
                    </div>
                  </Tippy>
                ) : null}
              </div>
              {Array.from(new Array(lengthBOMJSON)).map(
                (_elUser, indexUser) => (
                  <div
                    key={indexUser}
                    className={`flex items-center justify-center py-1 px-3`}
                    style={{
                      height: 48,
                      width: 48,
                      backgroundColor:
                        indexUser % 2 !== 0 ? "#F7F7F7" : "#FFFFFF",
                    }}
                  >
                    <p
                      className="font-normal"
                      style={{
                        fontSize: 14,
                        lineHeight: "24px",
                        color: "#111111",
                        display: "inline-block",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {!listExpand[index] ? "..." : ""}
                    </p>
                  </div>
                )
              )}
            </div>
            {el.additional_json &&
            el.additional_json.length &&
            listExpand[index] ? (
              <TableInviteeAdditionalInBOMItem
                additionalJson={el.additional_json}
                isBorderRadius={index === originInviteeBoms.length - 1}
              />
            ) : null}
            <TippyCustomzie
              containerClass="component-card-menu absolute top-0 left-0 w-full h-[1px]"
              placement="top"
              interactive
              arrow={false}
              animation="scale"
              visible={listActive[index]}
              onClickOutside={handleOnClickOutside}
              offset={[0, 10]}
              content={
                <div
                  className="flex items-center"
                  onClick={onChangeCollapse(index)}
                  style={{
                    background: "#222222",
                    borderRadius: 4,
                    padding: "0px 12px",
                    width: 140,
                    height: 48,
                  }}
                >
                  <p
                    className="font-normal"
                    style={{
                      fontSize: 13,
                      lineHeight: "22px",
                      color: "#FFFFFF",
                    }}
                  >
                    {listExpand[index] ? "Collapse" : "Expand "}
                  </p>
                </div>
              }
              allowHTML
            >
              <div
                className={`absolute flex items-center justify-center cursor-pointer ${
                  fieldActive.value === index
                    ? "active-button-action-table"
                    : "disabled-button-action-table"
                }`}
                onClick={handleOpenModal(index)}
                style={{
                  top: -10,
                  left:
                    (20 +
                      Object.keys(
                        el.additional_json.length && listExpand[index]
                          ? el.additional_json[0]
                          : ""
                      ).length *
                        170) /
                    2,
                  height: 20,
                  width: 28,
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #E4E4E4",
                  borderRadius: 4,
                }}
              >
                <IconActionTable />
              </div>
            </TippyCustomzie>
          </div>
        )
      })}
    </div>
  )
}
export default TableInviteeBOMCard
