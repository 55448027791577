export interface TimezoneProps {
  value: string
  label: string
}
export interface SettingStateProps {
  timezone?: TimezoneProps
}

export interface SettingRequestProps {
  timezone?: string
}

export const timezones: TimezoneProps[] = [
  {
    value: "+0000",
    label: "(UTC+00:00) Dublin, Edinburgh, Lisbon, London",
  },
  {
    value: "+0100",
    label: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
  },
  {
    value: "+0200",
    label: "(UTC+02:00) Athens, Bucharest, Istanbul",
  },
  {
    value: "+0300",
    label: "(UTC+03:00) Kuwait, Riyadh",
  },
  {
    value: "+0330",
    label: "(UTC+03:30) Tehran",
  },
  {
    value: "+0400",
    label: "(UTC+04:00) Moscow, St. Petersburg, Volgograd",
  },
  {
    value: "+0430",
    label: "(UTC+04:30) Kabul",
  },
  {
    value: "+0500",
    label: "(UTC+05:00) Tashkent",
  },
  {
    value: "+0530",
    label: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
  },
  {
    value: "+0545",
    label: "(UTC+05:45) Kathmandu",
  },
  {
    value: "+0600",
    label: "(UTC+06:00) Dhaka, Yekaterinburg",
  },
  {
    value: "+0700",
    label: "(UTC+07:00) Bangkok, Hanoi, Jakarta",
  },
  {
    value: "+0800",
    label: "(UTC+08:00) Kuala Lumpur, Singapore, Beijing",
  },
  {
    value: "+0900",
    label: "(UTC+09:00) Osaka, Sapporo, Tokyo",
  },
  {
    value: "+0930",
    label: "(UTC+09:30) Darwin, Adelaide",
  },
  {
    value: "+1000",
    label: "(UTC+10:00) Canberra, Melbourne, Sydney",
  },
  {
    value: "+1100",
    label: "(UTC+11:00) Solomon Islands, New Caledonia",
  },
  {
    value: "+1200",
    label: "(UTC+12:00) Fiji, Marshall Islands",
  },
  {
    value: "+1300",
    label: "(UTC+13:00) Nuku'alofa, Samoa",
  },
  {
    value: "-1200",
    label: "(UTC-12:00) International Date Line West",
  },
  {
    value: "-1100",
    label: "(UTC-11:00) Coordinated Universal Time-11",
  },
  {
    value: "-1000",
    label: "(UTC-10:00) Hawaii",
  },
  {
    value: "-0900",
    label: "(UTC-09:00) Alaska",
  },
  {
    value: "-0800",
    label: "(UTC-08:00) Baja California",
  },
  {
    value: "-0700",
    label: "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
  },
  {
    value: "-0600",
    label: "(UTC-06:00) Central America",
  },
  {
    value: "-0500",
    label: "(UTC-05:00) Bogota, Lima, Quito",
  },
  {
    value: "-0430",
    label: "(UTC-04:30) Caracas",
  },
  {
    value: "-0400",
    label: "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
  },
  {
    value: "-0330",
    label: "(UTC-03:30) Newfoundland",
  },
  {
    value: "-0300",
    label: "(UTC-03:00) Cayenne, Fortaleza",
  },
  {
    value: "-0200",
    label: "(UTC-02:00) Coordinated Universal Time-2",
  },
  {
    value: "-0100",
    label: "(UTC-01:00) Cape Verde",
  },
]
