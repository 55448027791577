import React, { useContext, useEffect, useRef, useState } from "react"
import {
  useBoolean,
  useString,
  useNumber,
  useWindowSize,
  IUseDefaultValueProps,
} from "helpers/hooks"
import { TippyCustomzie } from "components/TippyCustomzie"
import {
  Folder,
  ProjectComponentDetail,
  UpdateProjectComponentRequest,
} from "../types"
import TypeComponent from "../molecules/TypeComponent"
import {
  checkPermissionPage,
  formatDate,
  formatDateForConversationMessage,
} from "helpers/utils"
import ActionItem from "pages/projects/molecules/ActionItem"
import { toast } from "react-toastify"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { STATUS_RESPONSE } from "types"
import ModalDelete from "components/ModalCustom/ModalDelete"
import {
  postArchiveProjectComponentMiddleware,
  postDuplicateProjectComponentMiddleware,
  putProjectComponentMiddleware,
  deleteProjectComponentAttachment,
} from "../services/api"
import { deleteComponentOrVersionMiddleware } from "pages/project-component-detail/services"
import { pushTo } from "helpers/history"
import { PATH } from "constants/path"
import { TYPE_PROJECT_COMPONENT } from "../project-component.constant"
import { PermissionProjectPage } from "../contexts/PermissionProjectPage.context"
import { TAB_URL_PROJECT_COMPONENT } from "pages/project-component-detail/types"
import { STATUS_BUILD, ProjectComponentStatus } from "components/Status/types"
import ActionIconHistory from "pages/project-component-detail/molecules/ActionIconHistory"
import DotCard from "pages/conversations/atoms/DotCard"
import LabelStatusBuild from "../molecules/LabelStatusBuild"
import { configureStore } from "stores/configureStore"
import { openModal, closeModal } from "reducers/modal"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
import ModalCustom from "components/ModalCustom"
import Button from "components/Button/Button"
import FolderIcon from "assets/images/icons/folder.svg"
import { ReactComponent as IconAction } from "assets/images/icons/icon-action-project.svg"

interface Props {
  item: ProjectComponentDetail
  folders?: Folder[]
  onUpdateDataEdit?: (
    item: ProjectComponentDetail,
    indexCard: number,
    folder?: Folder
  ) => void
  indexCard: number
  onUpdateDataDuplicate?: (
    item: ProjectComponentDetail,
    folder?: Folder
  ) => void
  onUpdateDataArcvhive?: (item: ProjectComponentDetail) => void
  onDeleteComponent?: (item: ProjectComponentDetail) => void
  onDeleteComponentAttachments?: (
    item: ProjectComponentDetail,
    folder?: Folder
  ) => void
  onMoveComponentInOrOutAFolder?: (
    folder: Folder,
    component: ProjectComponentDetail,
    type: "in" | "out"
  ) => void
  isAction?: boolean
  isOwner: boolean
  activeFolder?: Folder
  isNewFolder?: IUseDefaultValueProps
  openToNewTab?: boolean
}
const ComponentDetailCard = (props: Props) => {
  const {
    item,
    indexCard,
    isAction = true,
    onUpdateDataEdit,
    onUpdateDataDuplicate,
    onUpdateDataArcvhive,
    onDeleteComponentAttachments,
    onMoveComponentInOrOutAFolder,
    folders,
    isOwner,
    activeFolder,
    isNewFolder,
    openToNewTab,
  } = props
  const { archiveProject, viewOnlyShare } = useContext(PermissionProjectPage)
  const { isMobile } = useWindowSize()
  const showTippy = useBoolean(false)
  const nameInput = useString()
  const widthInput = useNumber()
  const isEdit = useBoolean(false)
  const isDuplicate = useBoolean(false)
  const isLoading = useBoolean(false)
  const isArchive = useBoolean(false)
  const isSelectFolder = useBoolean(false)
  const refInput = useRef<any>(null)
  const isDeleted = item.status === ProjectComponentStatus.DeletedAttachment
  const [selectedFolder, setSelectedFolder] = useState<Folder | null>(null)

  useEffect(() => {
    nameInput.setValue(item.name)
    let newWidth = 8
    if (item.name.length > 8) {
      newWidth = item.name.length
    }

    if (item.name.length > 25) {
      newWidth = 25
    }
    widthInput.setValue(newWidth)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.name])

  const handleChangeInput = (event) => {
    const newValue = event.target.value
    nameInput.setValue(newValue)
    let newWidth = 8
    if (newValue.length > 8) {
      newWidth = newValue.length
    }

    if (newValue.length > 25) {
      newWidth = 25
    }
    widthInput.setValue(newWidth)
  }

  const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      event.preventDefault()
      handleBlur()
    }
  }

  const handleBlur = () => {
    if (!nameInput.value || nameInput.value === item?.name) {
      isEdit.setValue(false)
      nameInput.setValue(item?.name)
      return
    }
    const dataRequest: UpdateProjectComponentRequest = {
      name: nameInput.value,
      project_id: item?.project_id,
    }
    isLoading.setValue(true)
    putProjectComponentMiddleware(
      item.id,
      dataRequest,
      (
        type: STATUS_RESPONSE,
        messenger: string,
        dataRes?: ProjectComponentDetail
      ) => {
        isLoading.setValue(false)
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        if (type === STATUS_RESPONSE.SUCCESS && dataRes && onUpdateDataEdit) {
          onUpdateDataEdit(dataRes, indexCard, activeFolder)
          isEdit.setValue(false)
          return
        }
        isEdit.setValue(false)
        nameInput.setValue(item?.name)
        widthInput.setValue(item?.name?.length)
      }
    )
  }

  const handleChangeRouter = () => {
    if (
      isEdit.value ||
      isDuplicate.value ||
      isArchive.value ||
      !item.type.key ||
      isDeleted
    ) {
      return
    }
    if (openToNewTab) {
      return window.open(
        `/project/component/${item?.id}/${item.type.key}/history`
      )
    }
    switch (item.type.key) {
      case TYPE_PROJECT_COMPONENT.PCB:
        return pushTo(PATH.projectComponentPCB, {
          idProjectComponent: item?.id,
          titlePage: TAB_URL_PROJECT_COMPONENT.COMPONENT,
          idProjectBuildComponent: "history",
        })
      case TYPE_PROJECT_COMPONENT.BOM:
        return pushTo(PATH.projectComponentBOM, {
          idProjectComponent: item?.id,
          titlePage: TAB_URL_PROJECT_COMPONENT.COMPONENT,
          idProjectBuildComponent: "history",
        })
      default:
        return pushTo(PATH.projectComponentOther, {
          idProjectComponent: item?.id,
          type: item.type.key,
          titlePage: TAB_URL_PROJECT_COMPONENT.COMPONENT,
          idProjectBuildComponent: "history",
        })
    }
  }

  const handleClickTippy = (event) => {
    event.stopPropagation()
    if (
      checkPermissionPage({
        project: archiveProject,
        notificationProject: true,
        viewShare: viewOnlyShare,
        notificationViewShare: true,
      })
    ) {
      return
    }
    showTippy.setValue(!showTippy.value)
  }

  const onClickAction =
    (
      key:
        | "rename"
        | "duplicate"
        | "select_folder"
        | "archive"
        | "delete"
        | "delete_attachment"
    ) =>
    (event) => {
      event.stopPropagation()
      event.preventDefault()
      if (
        checkPermissionPage({
          project: archiveProject,
          notificationProject: true,
          viewShare: viewOnlyShare,
          notificationViewShare: true,
        })
      ) {
        return
      }

      // close tippy layout
      showTippy.setValue(false)

      switch (key) {
        case "archive":
          isArchive.setValue(true)
          break
        case "rename":
          isEdit.setValue(true)
          setTimeout(() => {
            refInput.current.focus()
            refInput.current.select()
          }, 0)
          break
        case "select_folder":
          isSelectFolder.setValue(true)
          break
        case "duplicate":
          if (
            item.last_version_status === STATUS_BUILD.DRAFT &&
            item.project_component_history.status === STATUS_BUILD.DRAFT
          ) {
            toast(
              <LabelNotificationPage
                messenger={MESSENGER_NOTIFICATION.DUPLICATE_DRAFT_COMPONENT}
                type={STATUS_RESPONSE.ERROR}
              />
            )
          } else {
            isDuplicate.setValue(true)
          }
          break
        case "delete":
          configureStore.dispatch(
            openModal({
              type: "Delete",
              props: {
                deleteModal: {
                  title: `Are you sure to delete this component ${item.code}?`,
                  label: `Warning`,
                  onSubmit: onSubmitDeleteComponent(item),
                  titleButton: `Delete`,
                },
              },
            })
          )
          break
        case "delete_attachment":
          configureStore.dispatch(
            openModal({
              type: "Delete",
              props: {
                deleteModal: {
                  title: `When action is confirmed, all attachment files also be removed. This action can not undo.`,
                  label: `Warning`,
                  content: `Press "Delete" to process`,
                  onSubmit: onSubmitDeleteAttachment(item),
                  styleTitle: { textAlign: "center" },
                  titleButton: `Delete`,
                },
              },
            })
          )
          break
      }
    }

  const onSubmitDuplicate = () => {
    if (!item.id) {
      return
    }

    isLoading.setValue(true)

    postDuplicateProjectComponentMiddleware(
      item.id,
      (
        type: STATUS_RESPONSE,
        messenger: string,
        dataRes?: ProjectComponentDetail
      ) => {
        isLoading.setValue(false)
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        if (
          type === STATUS_RESPONSE.SUCCESS &&
          dataRes &&
          onUpdateDataDuplicate
        ) {
          onUpdateDataDuplicate(
            {
              ...dataRes,
              last_version_status: item.last_version_status,
              project_component_history: {
                ...dataRes.project_component_history,
                code:
                  dataRes.project_component_history.status ===
                  STATUS_BUILD.DRAFT
                    ? ""
                    : dataRes.project_component_history.code,
              },
            },
            activeFolder
          )
          isDuplicate.setValue(false)
        }
      }
    )
  }

  const onSubmitDeleteComponent = (data: ProjectComponentDetail) => () => {
    if (!data.id) {
      return
    }
    if (!onDeleteComponentAttachments) return

    deleteComponentOrVersionMiddleware(
      data.id,
      "component",
      (type: STATUS_RESPONSE, messenger: string) => {
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        if (type === STATUS_RESPONSE.SUCCESS) {
          closeModal()
          onDeleteComponentAttachments(data, activeFolder)
        }
      }
    )
  }

  const onSubmitDeleteAttachment = (data: ProjectComponentDetail) => () => {
    if (!data.id) {
      return
    }
    if (isDeleted) {
      return
    }

    deleteProjectComponentAttachment(data.id)
      .then(() => {
        if (onDeleteComponentAttachments) {
          onDeleteComponentAttachments(data, activeFolder)
        }
        toast(
          <LabelNotificationPage
            messenger={"Delete component successfully!"}
            type="success"
          />
        )
        closeModal()
      })
      .catch((error) => {
        toast(
          <LabelNotificationPage
            messenger={
              error.response?.data?.message || "Delete component failed!"
            }
            type="error"
          />
        )
        closeModal()
      })
  }

  const onSubmitArchive = () => {
    if (!item.id) {
      return
    }
    isLoading.setValue(true)
    postArchiveProjectComponentMiddleware(
      item.id,
      item.is_archived === 0 ? true : false,
      (
        type: STATUS_RESPONSE,
        messenger: string,
        dataRes?: ProjectComponentDetail
      ) => {
        isLoading.setValue(false)
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        if (
          type === STATUS_RESPONSE.SUCCESS &&
          dataRes &&
          onUpdateDataArcvhive
        ) {
          onUpdateDataArcvhive(dataRes)
          isArchive.setValue(false)
        }
      }
    )
  }
  const onSubMitMoveToFolder = (type: "in" | "out") => {
    if (!onMoveComponentInOrOutAFolder) return
    if (type === "in") {
      if (!selectedFolder) return
      onMoveComponentInOrOutAFolder(selectedFolder, item, type)
    } else {
      if (!activeFolder) return
      onMoveComponentInOrOutAFolder(activeFolder, item, type)
    }
    isSelectFolder.setValue(false)
  }

  const renderAction = () => {
    if (isDeleted) {
      return null
    }

    if (isAction) {
      return (
        <TippyCustomzie
          containerClass="component-card-menu"
          placement="bottom-end"
          interactive
          disabled={checkPermissionPage({
            project: archiveProject,
            viewShare: viewOnlyShare,
          })}
          arrow={false}
          animation="scale"
          visible={showTippy.value}
          onClickOutside={() => showTippy.setValue(false)}
          content={
            <div
              style={{
                background: "#222222",
                borderRadius: 4,
                paddingTop: 8,
                paddingBottom: 8,
                width: 165,
              }}
            >
              {item.is_archived === 0 ? (
                <React.Fragment>
                  {isMobile ? null : (
                    <ActionItem
                      title="Rename"
                      onClick={onClickAction("rename")}
                    />
                  )}
                  <ActionItem
                    title="Duplicate"
                    onClick={onClickAction("duplicate")}
                  />
                </React.Fragment>
              ) : null}

              <ActionItem
                title={item.is_archived === 0 ? "Archive" : "Unarchive"}
                onClick={onClickAction("archive")}
              />
              <ActionItem
                title={folders ? "Move to a folder" : "Move to root project"}
                onClick={onClickAction("select_folder")}
              />
              {isMobile ? null : item.project_component_history.code === "" ? (
                <ActionItem
                  title={"Delete Draft"}
                  onClick={onClickAction("delete")}
                />
              ) : isOwner ? (
                <ActionItem
                  title={"Delete"}
                  onClick={onClickAction("delete_attachment")}
                />
              ) : null}
            </div>
          }
          allowHTML
        >
          <div className="flex items-center" onClick={handleClickTippy}>
            {archiveProject || viewOnlyShare ? (
              <IconAction
                className={`${
                  checkPermissionPage({
                    project: archiveProject,
                    viewShare: viewOnlyShare,
                  })
                    ? "disabled-icon-button-create-fill"
                    : ""
                }`}
              />
            ) : (
              <ActionIconHistory
                icon="three-dots"
                tooltip={"Action"}
                styleIcon={{
                  marginRight: 0,
                }}
              />
            )}
          </div>
        </TippyCustomzie>
      )
    }
    return null
  }

  return (
    <>
      <div
        className={`bg-white rounded-md px-3 pb-4 pt-2.5 hover-project-card ${
          item.is_archived === 1 ? "archived-project-component" : ""
        } ${
          isDeleted
            ? "archived-project-component bg-neutral3 bg-delete-element bg-cover"
            : item.is_archived === 1
            ? "bg-neutral3 cursor-pointer"
            : "bg-white cursor-pointer"
        }
      `}
        style={{
          border: "1px solid #E4E4E4",
        }}
        onClick={handleChangeRouter}
      >
        <div
          className="flex items-center justify-between"
          style={{
            marginBottom: 21,
          }}
        >
          {item.type ? <TypeComponent label={item.type?.key || ""} /> : <div />}
          {renderAction()}
        </div>

        <div className="flex items-center">
          <p
            className="font-medium text-code"
            style={{
              fontSize: 12,
              color: "#7A7A7A",
              lineHeight: "18px",
            }}
          >
            {item.code}
          </p>

          {item.project_component_history.code ? (
            <React.Fragment>
              <DotCard isDisabled={Boolean(item.is_archived)} />
              <p
                className="font-medium text-code"
                style={{
                  fontSize: 12,
                  color: "#7A7A7A",
                  lineHeight: "18px",
                }}
              >
                {item.project_component_history.code}
              </p>
            </React.Fragment>
          ) : null}
        </div>

        <div
          className="flex items-center mt-1"
          style={{
            marginBottom: 27,
          }}
        >
          {isEdit.value ? (
            <div
              className="relative"
              style={{
                width: "min-content",
                height: 24,
                maxWidth: 260,
              }}
            >
              <span
                className="font-semibold"
                style={{
                  visibility: "hidden",
                  fontSize: 14,
                  color: "#111111",
                  lineHeight: "24px",
                  left: 0,
                  maxWidth: "100%",
                  display: "inline-block",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {nameInput.value}
              </span>
              <input
                className="h-24-custom text-base text-white-05 focus:outline-none font-semibold absolute selector-input"
                style={{
                  width: "100%",
                  left: 0,
                  fontSize: 14,
                  color: "#111111",
                  lineHeight: "24px",
                }}
                value={nameInput.value}
                ref={refInput}
                onChange={handleChangeInput}
                onBlur={handleBlur}
                onKeyPress={onKeyPress}
                autoFocus
              />
            </div>
          ) : (
            <p
              className="font-semibold text-name"
              style={{
                fontSize: 14,
                color: "#111111",
                lineHeight: "24px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {item.name}
            </p>
          )}

          {item.last_version_status &&
          item.last_version_status === STATUS_BUILD.DRAFT &&
          !isDeleted ? (
            <React.Fragment>
              <DotCard />
              <LabelStatusBuild status={item.last_version_status} />
            </React.Fragment>
          ) : null}
        </div>

        <div className="flex flex-col">
          <p className="text-[#7A7A7A] font-medium text-[12px] leading-[18px] text-time">
            {item?.updated_at
              ? `Updated ${formatDateForConversationMessage(item.updated_at)}`
              : "No update"}
          </p>
          <span className="text-[#7A7A7A] font-medium text-[12px] leading-[18px] text-time">
            {`Created date: ${formatDate(item.created_at, "DD MMM, yyyy")}`}
          </span>
        </div>
      </div>

      {isDuplicate.value ? (
        <ModalDelete
          onCloseModal={() => isDuplicate.setValue(false)}
          title={`Are you sure to duplicate this component?`}
          label={`Confirmation`}
          onSubmit={onSubmitDuplicate}
          titleButton="Duplicate"
          colorYellowButton
        />
      ) : null}

      {isArchive.value ? (
        <ModalDelete
          onCloseModal={() => isArchive.setValue(false)}
          title={`Are you sure to ${
            item.is_archived === 0 ? "archive" : "Unarchive"
          } this component?`}
          label={`Confirmation`}
          onSubmit={onSubmitArchive}
          titleButton={item.is_archived === 0 ? "Archive" : "Unarchive"}
          content=""
        />
      ) : null}
      {isSelectFolder.value ? (
        folders ? (
          <ModalCustom
            label={`Move to folder`}
            handleChangeButton={() => () => {
              isSelectFolder.setValue(false)
              setSelectedFolder(null)
            }}
            bodyChildren={
              <div>
                <p
                  className="text-xs font-medium font-display text-[#0A5AF5] cursor-pointer mb-4"
                  onClick={() => {
                    isNewFolder?.setValue(true)
                  }}
                >
                  {" "}
                  + Add new folder
                </p>
                <div className="max-h-[65vh] overflow-auto">
                  {folders.map((folder) => {
                    return (
                      <div
                        key={folder.id}
                        className={`bg-white rounded-md flex justify-between items-center gap-3 px-3 pb-4 pt-2.5 mb-3 ${
                          folder.id === selectedFolder?.id
                            ? "active-project-build"
                            : "hover-project-card"
                        } cursor-pointer`}
                        style={{
                          border: "1px solid #E4E4E4",
                        }}
                        onClick={() => {
                          setSelectedFolder(folder)
                        }}
                      >
                        <div className="flex items-center">
                          <img src={FolderIcon} alt="#" />
                        </div>
                        <div className="flex items-center justify-between w-full h-full">
                          <div className="overflow-hidden">
                            <p
                              className="font-semibold text-name w-[212px]"
                              style={{
                                fontSize: 14,
                                color: "#111111",
                                lineHeight: "24px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {folder.name}
                            </p>
                            <p className="text-xs font-body font-medium leading-[18px]">
                              {folder.components.length} components
                            </p>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>

                <div className="flex items-center justify-between mt-6">
                  <Button
                    title="Cancel"
                    colorBtn="white"
                    onClick={() => {
                      isSelectFolder.setValue(false)
                      setSelectedFolder(null)
                    }}
                  />
                  <Button
                    title="Move to folder"
                    onClick={() => {
                      onSubMitMoveToFolder("in")
                      setSelectedFolder(null)
                      isNewFolder?.setValue(false)
                    }}
                    disabled={folders.length === 0 || !selectedFolder}
                  />
                </div>
              </div>
            }
          />
        ) : (
          <ModalDelete
            onCloseModal={() => isSelectFolder.setValue(false)}
            title={`Are you sure to move this component to root project?`}
            label={`Confirmation`}
            onSubmit={() => {
              onSubMitMoveToFolder("out")
            }}
            titleButton="Yes"
            colorYellowButton
          />
        )
      ) : null}
    </>
  )
}
export default ComponentDetailCard
