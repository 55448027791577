import { useContext } from "react"
import { AnswersPartsDetail } from "../types"
import { showImageUrl, fieldFormDataSpecification } from "helpers/utils"
import { VersionSpecificationContext } from "../contexts/VersionSpecification.context"
import { API_URL } from "config/environments"

interface Props {
  answer: AnswersPartsDetail
  idPart: string
  sequencePart: number
  disabled?: boolean
}
const SingleCheckCardItem = (props: Props) => {
  const { answer, idPart, sequencePart, disabled = false } = props
  const context = useContext(VersionSpecificationContext)

  const handleChangeItem = (newName: string) => () => {
    if (disabled) {
      return
    }
    context?.updateDataForm(
      "formData",
      fieldFormDataSpecification(idPart, sequencePart),
      newName,
      false
    )
  }

  const isActive =
    answer.name ===
    context?.formData[fieldFormDataSpecification(idPart, sequencePart)]
  return (
    <div className="mr-2 mb-2">
      <div
        className="rounded px-1 cursor-pointer min-h-[32px] flex items-center"
        style={{
          border: isActive ? "1px solid #F7AC1B" : "1px solid #E4E4E4",
          boxShadow: isActive
            ? "0px 0px 0px 4px rgba(247, 172, 27, 0.1)"
            : "none",
          backgroundColor: isActive ? "#FFFFFF" : "#F7F7F7",
        }}
        onClick={handleChangeItem(answer.name)}
      >
        {answer.icon ? (
          <img
            src={showImageUrl(answer.icon, API_URL)}
            alt="icon"
            style={{
              height: 24,
              border: answer.name === "White" ? "1px solid #E4E4E4" : "none",
              borderRadius: answer.name === "White" ? 2 : 0,
            }}
            className="mr-2"
          />
        ) : null}
        <p
          className="font-normal"
          style={{
            color: isActive ? "#222222" : "#7A7A7A",
            fontSize: 14,
            lineHeight: "24px",
          }}
        >
          {answer.name}
        </p>
      </div>
    </div>
  )
}

export default SingleCheckCardItem
