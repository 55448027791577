import React, { useCallback, useEffect, useState } from "react"
import PageLayout from "pages/layout/PageLayout"
import { useLocation, useParams } from "react-router-dom"
import { pushTo } from "helpers/history"
import { PATH } from "constants/path"
import { useBoolean, useString } from "helpers/hooks"
import {
  getBuildComponentDetailMiddleware,
  getProjectComponentDetailMiddleware,
} from "pages/project-component/services/api"
import {
  ProjectComponentDetail,
  defaultProjectComponentDetail,
  ProjectBuildDetail,
} from "pages/project-component/types"
import Header from "./organisms/ProjectComponentDetailHeader"
import {
  BuildHistoryDetail,
  dataEditorDefault,
  defaultBuildHistoryDetail,
  FileUrlProp,
  TAB_URL_PROJECT_COMPONENT,
  ViewLinkedBuildHeaders,
} from "./types"
import {
  getBuildHistoriesMiddleware,
  getIsReadBuildComponentMiddleware,
  getProjectBuildListLinkedBuildMiddleware,
  getProjectComponentOtherMiddleware,
  postTabContentMiddleware,
} from "./services"
import {
  getProjectDetailMiddleware,
  getProjectRoleMiddleware,
} from "pages/projects/services/api"
import {
  ProjectDetail,
  defaultProjectDetail,
  STATUS_PROJECT_ROLE,
} from "pages/projects/types"
import InfoBuildDetail from "./organisms/InfoBuildDetail"
import HistoryTreeCard from "../../components/HistoryTree/HistoryTreeCard"
import { TYPE_PROJECT_COMPONENT } from "pages/project-component/project-component.constant"
import { cloneDeep, includes, debounce, remove, isEmpty } from "lodash"
import { InputEditorDefault } from "components/Input/InputEditorDefault"
import AttachmentOtherCard from "./organisms/AttachmentOtherCard"
import { toast } from "react-toastify"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { STATUS_RESPONSE } from "types"
import { PermissionProjectComponentPage } from "./contexts/PermissionProjectComponentPage.context"
import {
  STATUS_BUILD,
  ProjectComponentStatus,
  BuildStatusNumber,
} from "components/Status/types"
import { checkPermissionPage, findHistoryTreeDefault } from "helpers/utils"
import TableBuildCard from "pages/project-component/organisms/TableBuildCard"
import {
  emptyProjectBuildDetail,
  ProjectComponentBuildDetail,
} from "pages/project-build/project-build.type"
import { SectionCustom } from "components/Section/SectionCustom"
import InputEditorDefault2 from "components/Input/InputEditorDefault2"

const OtherTemplate = () => {
  const params = useParams<{
    idProjectComponent: string
    titlePage: string
    idProjectBuildComponent: string
  }>()
  const locationOtherTemplate = useLocation<any>()

  const idProjectComponent = params?.idProjectComponent || ""
  const titlePage = params?.titlePage || ""
  const idProjectBuildComponent = params?.idProjectBuildComponent || ""
  const isLoading = useBoolean()
  const [componentDetail, setComponentDetail] =
    useState<ProjectComponentDetail>(defaultProjectComponentDetail)
  const [projectDetail, setProjectDetail] =
    useState<ProjectDetail>(defaultProjectDetail)
  const idComponentHistory = useString()
  const [histories, setHistories] = useState<BuildHistoryDetail[]>([])
  const [historyDetail, setHistoryDetail] = useState<BuildHistoryDetail>(
    defaultBuildHistoryDetail
  )
  const [dataEditor, setDataEditor] = useState<string>("")
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [originDataEditor, setOriginDataEditor] =
    useState<string>(dataEditorDefault)

  const [listFileUrl, setListFileUrl] = useState<FileUrlProp[]>([])
  const updatedAtComponent = useString()
  const viewOnlyShare = useBoolean(true)
  const archiveProject = useBoolean(true)
  const archiveComponent = useBoolean(true)
  const archiveBuild = useBoolean(true)
  const isViewLinkedBuild = useBoolean(false)
  const isActiveLinedBuild = useBoolean(false)
  const [listViewLinkedBuilds, setListViewLinkedBuilds] = useState<
    ProjectBuildDetail[]
  >([])
  const [projectBuild, setProjectBuild] = useState<ProjectComponentBuildDetail>(
    emptyProjectBuildDetail
  )
  const maxFile = 50
  const isReadComponent = useBoolean(true)
  const isDeleted =
    projectBuild.status === STATUS_BUILD.DELETED ||
    componentDetail.status === ProjectComponentStatus.DeletedAttachment ||
    historyDetail.build_status === BuildStatusNumber.DELETED_ATTACHMENT

  useEffect(() => {
    if (
      !idProjectComponent ||
      !includes(
        [
          TAB_URL_PROJECT_COMPONENT.BUILD_COMPONENT,
          TAB_URL_PROJECT_COMPONENT.COMPONENT,
        ],
        titlePage
      )
    ) {
      pushTo(PATH.projects)
      return
    }
    getProjectComponentDetail()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    idProjectComponent,
    titlePage,
    idProjectBuildComponent,
    locationOtherTemplate.state,
  ])

  const getProjectComponentDetail = async () => {
    isLoading.setValue(true)
    try {
      const dataComponentRes = await getProjectComponentDetailMiddleware(
        idProjectComponent
      )
      if (
        !includes(
          [
            TYPE_PROJECT_COMPONENT.FIRMWARE,
            TYPE_PROJECT_COMPONENT.MECHANICAL,
            TYPE_PROJECT_COMPONENT.MISCELLANEOUS,
            TYPE_PROJECT_COMPONENT.SOFTWARE,
          ],
          dataComponentRes.type.key
        )
      ) {
        pushTo(PATH.projectComponent, {
          idProject: dataComponentRes.project_id,
        })
        return
      }
      const dataProjectDetailRes = await getProjectDetailMiddleware(
        dataComponentRes.project_id
      )
      const dataProjectRole = await getProjectRoleMiddleware(
        dataComponentRes.project_id
      )
      await getHistories(dataComponentRes.id, dataComponentRes.type.key)
      await getDataBuildComponent(
        dataComponentRes.id,
        dataComponentRes.updated_at,
        dataComponentRes.is_read_comment
      )
      setComponentDetail(dataComponentRes)
      setProjectDetail(dataProjectDetailRes)
      viewOnlyShare.setValue(
        dataProjectRole.role === STATUS_PROJECT_ROLE.VIEWER
      )
      archiveProject.setValue(Boolean(dataProjectDetailRes.is_archived))
      archiveComponent.setValue(Boolean(dataComponentRes.is_archived))
      isLoading.setValue(false)
    } catch (error) {
      isLoading.setValue(false)
      pushTo(PATH.projects)
    }
  }
  const getDataBuildComponent = async (
    oldComponentId: string,
    updatedAt: string,
    oldIsReadComment?: boolean
  ) => {
    if (titlePage === TAB_URL_PROJECT_COMPONENT.BUILD_COMPONENT) {
      await getProjectBuildComponent(idProjectBuildComponent)
      await getIsReadProjectBuildComponent(idProjectBuildComponent)
    } else {
      setProjectBuild(emptyProjectBuildDetail)
      isReadComponent.setValue(oldIsReadComment)
      const dataLinkedBuild = await getProjectBuildListLinkedBuildMiddleware(
        oldComponentId
      )
      setListViewLinkedBuilds(dataLinkedBuild)
      isActiveLinedBuild.setValue(dataLinkedBuild.length)
      archiveBuild.setValue(false)
      updatedAtComponent.setValue(updatedAt)
    }
  }

  const getHistories = async (
    oldIdComponent: string,
    oldType: string,
    loading = false,
    findItemCommit = true
  ) => {
    try {
      const dataRes = await getBuildHistoriesMiddleware(
        idProjectBuildComponent && idProjectBuildComponent !== "history"
          ? idProjectBuildComponent
          : oldIdComponent,
        titlePage
      )
      setHistories(dataRes)
      if (dataRes.length) {
        await saveDataHistoryDetail(dataRes, findItemCommit, oldType)
      }
      if (loading) {
        isLoading.setValue(false)
      }
    } catch (error) {
      pushTo(PATH.projects)

      if (loading) {
        isLoading.setValue(false)
      }
    }
  }
  const saveDataHistoryDetail = async (
    newDataRes: BuildHistoryDetail[],
    findItemCommit: boolean,
    oldType: string
  ) => {
    const { idHistory, newHistoryDetail } = findHistoryTreeDefault(
      newDataRes,
      findItemCommit,
      locationOtherTemplate
    )
    setHistoryDetail(newHistoryDetail)
    idComponentHistory.setValue(idHistory)
    await getDataInType(idHistory, oldType)
  }
  const getDataInType = useCallback(
    async (oldIdHistory: string, oldType: string, loading = false) => {
      try {
        const dataRes = await getProjectComponentOtherMiddleware(
          oldIdHistory,
          oldType.toLocaleLowerCase()
        )
        setDataEditor(dataRes.description || "")
        setOriginDataEditor(dataRes.description || "")
        setListFileUrl(dataRes.files)
        if (loading) {
          isLoading.setValue(false)
        }
      } catch (error) {
        if (loading) {
          setDataEditor("")
          // setOriginDataEditor("")
          setListFileUrl([])
          isLoading.setValue(false)
        }
      }
    },
    [idComponentHistory.value]
  )
  const handleChangeHistoryDetail = useCallback(
    (newHistoryDetail: BuildHistoryDetail) => () => {
      if (newHistoryDetail.id === historyDetail.id) {
        return
      }
      isLoading.setValue(true)
      setHistoryDetail(newHistoryDetail)
      idComponentHistory.setValue(newHistoryDetail.id)
      getDataInType(
        newHistoryDetail.id,
        componentDetail.type.key.toLocaleLowerCase(),
        true
      )
      isViewLinkedBuild.setValue(false)
    },
    [
      idComponentHistory,
      isLoading,
      getDataInType,
      componentDetail.type.key,
      isViewLinkedBuild,
      historyDetail,
    ]
  )

  const getProjectBuildComponent = async (
    oldIdProjectBuildComponent: string
  ) => {
    const dataRes = await getBuildComponentDetailMiddleware(
      oldIdProjectBuildComponent
    )
    setProjectBuild(dataRes)
    archiveBuild.setValue(Boolean(dataRes.is_archived))
    updatedAtComponent.setValue(dataRes.updated_at)
  }

  const getIsReadProjectBuildComponent = async (
    oldIdProjectBuildComponent: string
  ) => {
    const dataRes = await getIsReadBuildComponentMiddleware(
      oldIdProjectBuildComponent
    )
    isReadComponent.setValue(dataRes.is_read_comment)
  }

  const updatedAtComponentDetail = (updatedAt: string) => {
    updatedAtComponent.setValue(updatedAt)
  }

  const handleUpdateDataWhenChangeHistory = () => {
    isLoading.setValue(true)
    getHistories(componentDetail.id, componentDetail.type.key, true, false)
    updatedAtComponentDetail(new Date().toISOString())
  }

  const onSubmitAdditional = async (description: string, type: string) => {
    if (historyDetail.status !== "Draft" || !historyDetail.id) {
      return
    }
    isLoading.setValue(true)
    try {
      const formData = new FormData()
      formData.append("description", description)
      await postTabContentMiddleware(historyDetail.id, type, formData)
    } catch (error: any) {
      handleUpdateDataTypeErrorWhenCallAPI(error)
    }
  }
  const debouceOnSubmitAdditionalMessage = useCallback(
    debounce(onSubmitAdditional, 500),
    [historyDetail.id]
  )

  const handleUpdateDataWhenDeleteFile = (oldIdFile: string) => {
    const newFileURls = cloneDeep(listFileUrl)
    remove(newFileURls, (el) => el.id === oldIdFile)
    setListFileUrl(newFileURls)
    updatedAtComponentDetail(new Date().toISOString())
  }
  const handleUpdateDataWhenAddFile = async (
    fileRequest: FormData,
    fileErrors: File[]
  ) => {
    if (!idComponentHistory.value) {
      return
    }
    isLoading.setValue(true)
    try {
      fileErrors.forEach((fileError) => {
        toast(
          <LabelNotificationPage
            messenger={`File ${fileError.name} wrong format! Files allowed: rar,zip,7z`}
            type="warning"
          />
        )
      })
      await postTabContentMiddleware(
        idComponentHistory.value,
        componentDetail.type.key.toLocaleLowerCase(),
        fileRequest
      )
      await handleUpdateDataTypeSuccessWhenCallAPI()
    } catch (error: any) {
      handleUpdateDataTypeErrorWhenCallAPI(error)
    }
  }
  const handleUpdateDataTypeSuccessWhenCallAPI = async () => {
    await getDataInType(
      idComponentHistory.value,
      componentDetail.type.key.toLocaleLowerCase(),
      true
    )
    toast(
      <LabelNotificationPage
        messenger={`Update ${componentDetail.type.key} successfully!`}
        type={STATUS_RESPONSE.SUCCESS}
      />
    )
    updatedAtComponentDetail(new Date().toISOString())
  }
  const handleUpdateDataTypeErrorWhenCallAPI = async (error: any) => {
    let message = error?.response?.data?.message
    if (error.response.status === 413 && !message) {
      message = `The upload of file larger than ${maxFile}MB is not allowed`
    }
    toast(
      <LabelNotificationPage
        messenger={message || `Update ${componentDetail.type.key} failed!`}
        type={STATUS_RESPONSE.ERROR}
      />
    )
    isLoading.setValue(false)
  }
  const onUpdateDataEdit = (item: ProjectComponentDetail) => {
    setComponentDetail(item)
  }

  const onViewLinedBuilds = () => {
    if (!listViewLinkedBuilds.length || !isActiveLinedBuild.value) {
      return
    }
    isViewLinkedBuild.setValue(!isViewLinkedBuild.value)
  }
  const handleChangeHistoryTreeComment = (originIdHistory: string) => {
    const newHistories = cloneDeep(histories)
    const newHistoryDetail = newHistories.find(
      (el) => el.id === originIdHistory
    )
    if (newHistoryDetail) {
      handleChangeHistoryDetail(newHistoryDetail)()
    }
  }

  const renderBodyViewLinkBuild = () => {
    if (isViewLinkedBuild.value) {
      return (
        <div className="flex flex-col mt-6 mr-6">
          <TableBuildCard
            listBuilds={listViewLinkedBuilds}
            customHeaders={ViewLinkedBuildHeaders}
          />
        </div>
      )
    }
    return (
      <React.Fragment>
        <AttachmentOtherCard
          listFileUrl={listFileUrl}
          viewOnly={
            checkPermissionPage({
              project: archiveProject.value,
              viewShare: viewOnlyShare.value,
              component: archiveComponent.value,
              build: archiveBuild.value,
            }) ||
            includes(
              [STATUS_BUILD.COMMITTED, STATUS_BUILD.MERGED],
              historyDetail.status
            )
          }
          typeOther={componentDetail.type.key.toLocaleLowerCase()}
          handleUpdateDataWhenDeleteFile={handleUpdateDataWhenDeleteFile}
          handleUpdateDataWhenAddFile={handleUpdateDataWhenAddFile}
          isDeleted={isDeleted}
          versionId={historyDetail.id}
        />
        <div
          className="flex flex-col mr-6 mt-6 p-3"
          style={{
            border: "1px solid #E4E4E4",
            borderRadius: 4,
            background: "#FFFFFF",
          }}
        >
          <p
            className="font-semibold mb-2"
            style={{
              fontSize: 14,
              lineHeight: "24px",
              color: "#111111",
            }}
          >
            Additional info
          </p>
          <InputEditorDefault2
            readOnly={
              checkPermissionPage({
                project: archiveProject.value,
                viewShare: viewOnlyShare.value,
                component: archiveComponent.value,
                build: archiveBuild.value,
              }) ||
              includes(
                [STATUS_BUILD.COMMITTED, STATUS_BUILD.MERGED],
                historyDetail.status
              )
            }
            setValue={(description) => {
              if (description !== dataEditor) {
                debouceOnSubmitAdditionalMessage(
                  description,
                  componentDetail.type.key.toLocaleLowerCase()
                )
              }
            }}
            value={dataEditor}
            versionId={historyDetail.id}
          />
        </div>
      </React.Fragment>
    )
  }

  return (
    <PageLayout
      childrenHeader={
        <Header
          projectDetail={projectDetail}
          projectComponentDetail={componentDetail}
          updatedAtComponent={updatedAtComponent.value}
          titlePage={titlePage}
          projectBuild={projectBuild}
          idProjectBuildComponent={idProjectBuildComponent}
        />
      }
      heightHeader={48}
    >
      <PermissionProjectComponentPage.Provider
        value={{
          archiveProject: archiveProject.value,
          viewOnlyShare: viewOnlyShare.value,
          archiveComponent: archiveComponent.value,
          archiveBuild: archiveBuild.value,
          isListActionHistory:
            !viewOnlyShare.value && listFileUrl.length ? true : false,
          isNoUpdateFilePCB: includes(
            [STATUS_BUILD.COMMITTED, STATUS_BUILD.MERGED],
            historyDetail.status
          ),
          titlePage,
          idProjectBuildComponent,
        }}
      >
        <div className="flex flex-col h-full justify-between">
          <InfoBuildDetail
            projectComponentDetail={componentDetail}
            projectBuild={projectBuild}
            historyDetail={historyDetail}
            handleUpdateDataWhenChangeHistory={
              handleUpdateDataWhenChangeHistory
            }
            histories={histories}
            onUpdateDataEdit={onUpdateDataEdit}
            isViewLinkedBuild={isViewLinkedBuild.value}
            isReadComponent={isReadComponent.value}
            isNotDownload={listFileUrl.length ? false : true}
            setIsReadComponent={isReadComponent.setValue}
            onViewLinedBuilds={onViewLinedBuilds}
            isActiveLinedBuild={isActiveLinedBuild.value}
            handleChangeHistoryTreeComment={handleChangeHistoryTreeComment}
            isDeleted={isDeleted}
            commitButtonStage={{
              isActive:
                listFileUrl.length > 0 &&
                historyDetail.status === STATUS_BUILD.DRAFT
                  ? true
                  : false,
              tooltipHelper: `Please upload ${componentDetail.type.key.toLocaleLowerCase()} attachment`,
            }}
            setArchiveComponent={archiveComponent.setValue}
          />

          <SectionCustom>
            <HistoryTreeCard
              histories={histories}
              historyDetail={historyDetail}
              handleChangeHistoryDetail={handleChangeHistoryDetail}
              typeComponent={componentDetail.type.key}
              titlePage={titlePage}
            />
            {renderBodyViewLinkBuild()}
          </SectionCustom>
        </div>
      </PermissionProjectComponentPage.Provider>
    </PageLayout>
  )
}
export default OtherTemplate
