import BackgroundLogin from "assets/images/bg-login.png"
import { PageLayoutAuthProps } from "./types"
import { ReactComponent as IconLogo } from "assets/images/logo-tracelium.svg"
import { Link } from "react-router-dom"
import { PATH } from "constants/path"
import { umamiTracking } from "helpers/utils"
import { EVENT } from "constants/events"

const PageLayoutAuth = (props: PageLayoutAuthProps) => {
  const { children, showPrivacy = false } = props

  return (
    <div
      className="flex flex-col items-center justify-between bg-no-repeat bg-cover bg-bottom min-h-screen min-w-full"
      style={{
        backgroundImage: `url(${BackgroundLogin})`,
      }}
    >
      <div className="flex justify-center items-center flex-1 w-full">
        <IconLogo className="my-2 h-[32px] mb-4 md:mb-0" />
      </div>

      <div className="bg-white rounded mb-5 w-webkit-fill md:w-[360px] mx-6">
        {children}
      </div>
      <div className="flex flex-column items-start justify-center flex-1 w-full">
        {showPrivacy ? (
          <div className="flex items-center">
            <Link
              className="font-normal text-13 leading-22 cursor-pointer hover:underline"
              style={{
                color: "#FFFFFF",
                opacity: 0.6,
              }}
              to={{
                pathname: PATH.termsAndServices,
              }}
              onClick={() => umamiTracking(EVENT.TERM)}
            >
              Terms and Conditions
            </Link>
            <div
              className="h-1 w-1 mx-4 bg-white"
              style={{
                opacity: 0.6,
                borderRadius: "50%",
              }}
            />
            <Link
              className="font-normal text-13 leading-22 cursor-pointer hover:underline"
              style={{
                color: "#FFFFFF",
                opacity: 0.6,
              }}
              to={{
                pathname: PATH.policies,
              }}
              onClick={() => umamiTracking(EVENT.POLICY)}
            >
              Privacy Policy
            </Link>
            <div
              className="h-1 w-1 mx-4 bg-white"
              style={{
                opacity: 0.6,
                borderRadius: "50%",
              }}
            />
            <a
              className="font-normal text-13 leading-22 cursor-pointer hover:underline"
              style={{
                color: "#FFFFFF",
                opacity: 0.6,
              }}
              target="_blank"
              href="https://tracelium.com#faq-section"
            >
              FAQ
            </a>
          </div>
        ) : null}
      </div>
    </div>
  )
}
export default PageLayoutAuth
