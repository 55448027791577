import Typography from "components/Typography"
import { MouseEventHandler } from "react"

interface Props {
  title: string
  style?: React.CSSProperties
  isIcon?: boolean
  onClick?: MouseEventHandler
  isActive?: boolean
  className?: string
}
const TabItem = (props: Props) => {
  const { title, isIcon, style, isActive, onClick, className } = props
  return (
    <div
      className={`h-24-custom flex cursor-pointer ${className}`}
      style={style}
      onClick={onClick}
    >
      <Typography
        style={{
          fontSize: 14,
          lineHeight: "24px",
          color: isActive ? "#111111" : "#7A7A7A",
          fontWeight: 600,
        }}
      >
        {title}
      </Typography>
      <div className="h-1.5 w-1.5">
        {isIcon && <div className="h-1.5 w-1.5 bg-error rounded-full"></div>}
      </div>
    </div>
  )
}
export default TabItem
