import { isEmpty, cloneDeep, slice, trim } from "lodash"
import { TableColumnBOMDetail } from "pages/project-component-detail/types"

const removeQuote = (text: string) => {
  let result = ""
  // re-assign text
  result += trim(text)
  // remove first double quote & single qoute
  if (result && (result.indexOf(`"`) === 0 || result.indexOf(`'`) === 0)) {
    result = result.substring(1)
  }
  // remove last double quote & single qoute
  const valueLength = result.length - 1
  if (
    result &&
    (result.indexOf(`"`) === valueLength || result.indexOf(`'`) === valueLength)
  ) {
    result = result.substring(0, valueLength)
  }
  // console.log("result", result);
  /// remove carriage return
  return result.replace(/\r/g, "")
}

export const convertCopiedCellToJSON = (copiedCell: string) => {
  const breakPoint = "-=TRACELIUM-BREAK=-"
  // re-assign text
  let copyOfCopiedCell = ""
  copyOfCopiedCell += copiedCell
  //
  const regexLinefeed = /(")([\w\d\s$&+,:;=?@#|'<>.^*()%!-]+)(")/g
  const result: any = []

  // find value has linefeed
  const linefeedTexts = copyOfCopiedCell.match(regexLinefeed)
  // replace linefeedText \n with breakPoint
  linefeedTexts?.forEach((text) => {
    if (text !== "\t") {
      copyOfCopiedCell = copyOfCopiedCell.replace(
        text,
        text.replaceAll(/\n/g, breakPoint)
      )
    }
  })

  // transform cell to row data
  const rowData = copyOfCopiedCell.split("\n")
  if (!rowData || rowData.length === 0) {
    return false
  }

  // mapping and return JSON data
  rowData.forEach((item) => {
    const cellValues = item.split("\t")
    /// double check length value of cell
    if (cellValues.length > 0) {
      const convertedData: string[] = []
      cellValues.forEach((cellValue) => {
        // assign right value and replace breakPoint to LINE FEED character
        const transformText = cellValue.replaceAll(
          new RegExp(breakPoint, "g"),
          "\n"
        )
        // remove first double quote & single qoute
        convertedData.push(removeQuote(transformText))
      })
      result.push(convertedData)
    }
  })

  return result
}

export const handlePasteFromCell = (
  extraJson: TableColumnBOMDetail[],
  defaultBomValues: TableColumnBOMDetail["values"],
  copiedData: string,
  type: "header" | "body",
  yIndex: number,
  xIndex: number,
  event: any
) => {
  // handle parse copied data to JSON
  const pastedData = convertCopiedCellToJSON(copiedData)
  if (pastedData === false || isEmpty(pastedData)) {
    return false
  }
  if (pastedData.length === 1 && pastedData[0].length === 1) {
    return false
  }
  /// stop other event input changes
  event.stopPropagation()
  event.preventDefault()

  // copy the original JSON data
  const newData = cloneDeep(extraJson)
  const firstRowData = pastedData.shift()

  // if row data length greater than extraJson row then create new columns
  if (firstRowData.length > newData.length - yIndex) {
    for (let k = newData.length - yIndex; k < firstRowData.length; k++) {
      newData.push({
        idColumn: "",
        key: "",
        values: defaultBomValues,
      })
    }
  }

  // pasted to BOM headers
  if (type === "header") {
    for (let i = yIndex; i < newData.length; i++) {
      newData[i].key = firstRowData[i - yIndex]
    }
  }

  if (type === "body") {
    pastedData.unshift(firstRowData)
  }
  // pasted to BOM body
  for (let j = yIndex; j < newData.length; j++) {
    const bodyData = slice(pastedData, 0, newData[j].values.length)
    /// continute paste to body
    bodyData.forEach((row: any, rowIndex: number) => {
      const newValues = cloneDeep(newData[j].values)
      newValues[xIndex + rowIndex] = row[j - yIndex]
      newData[j].values = newValues
    })
  }

  return newData
}
