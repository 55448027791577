import React from "react"
export interface GlobalPermissionProjectComponentPageContext {
  viewOnlyShare: boolean
  archiveProject: boolean
  archiveComponent: boolean
  archiveBuild: boolean
  isListActionHistory: boolean
  isNoUpdateFilePCB: boolean
  titlePage: string
  idProjectBuildComponent: string
}

export const PermissionProjectComponentPage =
  React.createContext<GlobalPermissionProjectComponentPageContext>({
    archiveProject: true,
    viewOnlyShare: true,
    archiveComponent: true,
    archiveBuild: true,
    isListActionHistory: true,
    isNoUpdateFilePCB: true,
    titlePage: "",
    idProjectBuildComponent: "",
  })
