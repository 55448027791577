import { ReactComponent as IconCalendar } from "assets/images/icons/icon-calendar-filter.svg"
import Button from "components/Button/Button"
import { TippyCustomzie, TippyCustomzieProps } from "components/TippyCustomzie"
import { FC } from "react"
import { Calendar, DateRange } from "react-date-range"

export interface CalendarCustomProps extends TippyCustomzieProps {
  currentDate: any
  selectedDate: any
  type?: "calendar" | "date-range"
  label?: string
  placehoder?: string
  showClear?: boolean
  onSelectDate: (date: any) => void
  onOpen: (visible: boolean) => void
  onCancel?: () => void
  onApply?: () => void
  onClear?: () => void
}

export const CalendarCustom: FC<CalendarCustomProps> = ({
  visible,
  label,
  currentDate,
  selectedDate,
  showClear,
  onSelectDate,
  onCancel,
  onApply,
  onClear,
  onOpen,
  type = "calendar",
  placehoder,
  ...props
}) => {
  return (
    <TippyCustomzie
      {...props}
      visible={visible}
      containerClass="component-card-menu"
      placement="bottom-start"
      interactive
      arrow={false}
      animation="scale"
      offset={[0, 0]}
      allowHTML
      content={
        <div
          className="flex flex-col md:block height-screen md:h-auto p-6 bg-white rounded-[6px] md:w-[412px] custom-calendar-conversation"
          style={{
            boxShadow: " 0px 0px 30px rgba(162, 171, 190, 0.3)",
          }}
        >
          {type === "calendar" ? (
            <Calendar
              onChange={(date) => {
                onSelectDate(date)
              }}
              date={currentDate}
              dateDisplayFormat="dd-MM-yyyy"
              showDateDisplay={false}
            />
          ) : (
            <DateRange
              onChange={(date) => {
                onSelectDate(date)
              }}
              dateDisplayFormat="dd-MM-yyyy"
              ranges={currentDate}
              rangeColors={["rgba(253, 190, 68, 0.15)"]}
              editableDateInputs={true}
              showDateDisplay={false}
            />
          )}

          {onCancel || onApply ? (
            <div className="flex flex-1 items-end md:items-center justify-between mt-6 w-full">
              {onCancel ? (
                <Button
                  className="mr-3"
                  title="Cancel"
                  widthBtn="50%"
                  colorBtn="white"
                  onClick={onCancel}
                />
              ) : null}
              {onApply ? (
                <Button title="Apply" widthBtn="50%" onClick={onApply} />
              ) : null}
            </div>
          ) : null}
        </div>
      }
    >
      <div className="flex flex-col mt-6 md:mt-3">
        {label || onClear ? (
          <div className="flex items-center justify-between">
            {label ? (
              <p className="font-semibold text-14 leading-24 text-black md:text-white">
                {label}
              </p>
            ) : null}
            {onClear && showClear ? (
              <p
                onClick={onClear}
                className="font-normal text-13 leading-22 text-brown cursor-pointer hover:underline"
              >
                Clear
              </p>
            ) : null}
          </div>
        ) : null}

        <div className="flex items-center justify-between h-[44px] rounded-4 border-solid border-[1px] border-gray md:border-[#333333] px-3 mt-1 bg-white md:bg-new-black-333333">
          {selectedDate ? (
            selectedDate
          ) : (
            <p className="font-normal text-14 leading-24 color-gray-7a">
              {placehoder ? placehoder : "Select period"}
            </p>
          )}

          <IconCalendar
            className="cursor-pointer"
            onClick={() => onOpen(!!visible)}
          />
        </div>
      </div>
    </TippyCustomzie>
  )
}
