import React from "react"
import { Route, Redirect } from "react-router-dom"
import { PATH } from "constants/path"
import { isLogin } from "config/environments"

const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={() => (!isLogin() ? Component : <Redirect to={PATH.projects} />)}
    />
  )
}

export default PublicRoute
