import React from "react"
import { PATH } from "constants/path"
import { pushTo } from "helpers/history"
import NoDataCard from "pages/project-build/molecules/NoDataCard"
import ComponentDetailInBuild from "pages/project-build/organisms/ComponentDetailInBuild "
import { TYPE_PROJECT_COMPONENT } from "pages/project-component/project-component.constant"
import { ProjectComponentDetail } from "pages/project-component/types"
import { ProjectComponentStatus } from "components/Status/types"

interface Props {
  components: ProjectComponentDetail[]
  idUserInvitee: string
  oldIdConversation: string
  gridCols: string
  isProject: boolean
  isDeleted?: boolean
  isActiveConversation: boolean
}
const ComponentBuildConversationCard = (props: Props) => {
  const {
    components,
    oldIdConversation,
    gridCols,
    isProject,
    isDeleted = false,
    isActiveConversation,
  } = props
  const onSelectComponent =
    (
      newIdComponent: string,
      _isValidToBuild: boolean,
      idProjectBuildComponent: string,
      typeKey: string,
      idProjectComponentHistory: string
    ) =>
    (event) => {
      event.stopPropagation()
      event.preventDefault()

      switch (typeKey) {
        case TYPE_PROJECT_COMPONENT.PCB:
          pushTo(
            isActiveConversation ? PATH.inviteesBuildPCB : PATH.inviteesPCB,
            {
              idProjectComponent: newIdComponent,
              idProjectBuildComponent,
              idProjectComponentHistory,
              idConversation: oldIdConversation,
            },
            {
              breadcrumpProject: isProject,
            }
          )
          break
        case TYPE_PROJECT_COMPONENT.BOM:
          pushTo(
            isActiveConversation ? PATH.inviteesBuildBOM : PATH.inviteesBOM,
            {
              idProjectComponent: newIdComponent,
              idProjectBuildComponent,
              idProjectComponentHistory,
              idConversation: oldIdConversation,
            },
            {
              breadcrumpProject: isProject,
            }
          )
          break
        case TYPE_PROJECT_COMPONENT.MECHANICAL:
          pushTo(
            isActiveConversation
              ? PATH.inviteesBuildMechComponent
              : PATH.inviteesMechComponent,
            {
              idProjectComponent: newIdComponent,
              idProjectBuildComponent,
              idProjectComponentHistory,
              idConversation: oldIdConversation,
            },
            {
              breadcrumpProject: isProject,
            }
          )
          break
        default:
          pushTo(
            isActiveConversation ? PATH.inviteesBuildOther : PATH.inviteesOther,
            {
              type: typeKey,
              idProjectComponent: newIdComponent,
              idProjectBuildComponent,
              idProjectComponentHistory,
              idConversation: oldIdConversation,
            },
            {
              breadcrumpProject: isProject,
            }
          )
          break
      }
    }

  return (
    <React.Fragment>
      {components.length ? (
        <div className="h-full overflow-auto">
          <div className={`my-1 grid ${gridCols} mr-0 md:mr-6 gap-6`}>
            {components.map((el, index) => {
              return (
                <ComponentDetailInBuild
                  key={index}
                  item={el}
                  isHoverCard
                  onSelectComponent={onSelectComponent}
                  isView={false}
                  isDeleted={
                    isDeleted ||
                    el.status === ProjectComponentStatus.DeletedAttachment ||
                    !isActiveConversation
                  }
                />
              )
            })}
          </div>
        </div>
      ) : (
        <div className="h-full">
          <NoDataCard label="" />
        </div>
      )}
    </React.Fragment>
  )
}

export default ComponentBuildConversationCard
