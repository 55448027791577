import { STATUS_BUILD } from "components/Status/types"
import Typography from "components/Typography"
import { API_URL } from "config/environments"
import { useBoolean } from "helpers/hooks"
import { downloadFile } from "helpers/utils"
import { useAppSelector } from "hooks/useApp"
import { ProjectComponentBuildDetail } from "pages/project-build/project-build.type"
import ActionIconHistory from "pages/project-component-detail/molecules/ActionIconHistory"
import LabelStatusBuild from "pages/project-component/molecules/LabelStatusBuild"
import { twMerge } from "tailwind-merge"
import DotCard from "../atoms/DotCard"
import InfoNameAndEmailConversationCard from "../atoms/InfoNameAndEmailConversationCard"
import StatusConversationCard from "../atoms/StatusConversationCard"
import {
  BUILD_INVITEE_DETAIL_STATUS,
  ConversationMessagesDetail,
} from "../conversations.type"

interface Props {
  projectBuild: ProjectComponentBuildDetail
  conversationId: string
  conversationMessages: ConversationMessagesDetail
  viewOnly?: boolean
}
const InfoBuildConversation = (props: Props) => {
  const { projectBuild, conversationMessages, viewOnly, conversationId } = props
  const userInfo = useAppSelector((state) => state.userInfo)

  const isInvitee = userInfo.id === conversationMessages.invitee.user_id

  const isLoading = useBoolean(false)
  const onExportFile = () => {
    if (viewOnly || !projectBuild.id) {
      return
    }
    isLoading.setValue(true)
    downloadFile(
      `${API_URL}/api/project-build/${projectBuild.id}/download?conversation_id=${conversationId}`,
      projectBuild.code ?? new Date().toISOString(),
      true
    )
    isLoading.setValue(false)
  }

  const gapStyles =
    "flex flex-col md:flex-row items-start md:items-center gap-y-3 md:gap-y-0"

  return (
    <div className={twMerge("justify-between px-6 py-3 bg-white", gapStyles)}>
      <div className="flex flex-col">
        <Typography
          variant="h6"
          style={{
            color: "#111111",
            lineHeight: "28px",
            fontWeight: 600,
          }}
        >
          {projectBuild.name}
        </Typography>
        <div className="flex items-center">
          <p
            className="font-semibold"
            style={{
              fontSize: 13,
              lineHeight: "22px",
              color: "#333333",
            }}
          >
            {projectBuild.code}
          </p>
          <DotCard />
          <LabelStatusBuild
            status={
              isInvitee &&
              BUILD_INVITEE_DETAIL_STATUS[
                conversationMessages.invitee.in_production_status
              ] === BUILD_INVITEE_DETAIL_STATUS[3]
                ? STATUS_BUILD.CANCELLED
                : projectBuild.status
            }
          />
        </div>
      </div>
      <div className={twMerge("justify-end ", gapStyles)}>
        <div className="flex flex-col pr-6 border-none md:border-solid md:border-r-[1px] md-border-[#E4E4E4]">
          <InfoNameAndEmailConversationCard
            name={conversationMessages.owner.fullname}
            email={conversationMessages.owner.email}
            label="From:"
          />
          <InfoNameAndEmailConversationCard
            name={conversationMessages.invitee.name}
            email={conversationMessages.invitee.email || "N/A"}
            label="To:"
          />
        </div>
        <StatusConversationCard
          status={
            projectBuild.status === STATUS_BUILD.CANCELLED ||
            projectBuild.status === STATUS_BUILD.DELETED ||
            Boolean(projectBuild.is_archived)
              ? BUILD_INVITEE_DETAIL_STATUS[3]
              : BUILD_INVITEE_DETAIL_STATUS[
                  conversationMessages.invitee.in_production_status
                ]
          }
        />
        {!viewOnly ? (
          <ActionIconHistory
            icon="download"
            tooltip="Download"
            styleIcon={{
              margin: 0,
            }}
            onClick={onExportFile}
            classIcon="hidden md:flex action-icon-download-conversation"
          />
        ) : null}
      </div>
    </div>
  )
}
export default InfoBuildConversation
