import { useEffect } from "react"
import IconCheckCircleSuccess from "assets/images/icons/icon-check-circle-success.svg"
import IconWarning from "assets/images/icons/icon-warning.svg"
import { pushTo } from "helpers/history"
import { PATH } from "constants/path"
import { useLocation } from "react-router-dom"
import { verifyEmailMiddleware } from "./services/api"
import { useBoolean } from "helpers/hooks"
import PageLayoutAuth from "./PageLayoutAuth"
import Button from "components/Button/Button"

const EmailVerification = () => {
  const location = useLocation<any>()
  const isVerify = useBoolean(true)
  const isLoading = useBoolean(false)

  useEffect(() => {
    if (!location.search) {
      return
    }
    verifyEmail(location.search.replace("?verification_token=", ""))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const verifyEmail = (token: string) => {
    isLoading.setValue(true)
    verifyEmailMiddleware(token, () => {
      isLoading.setValue(false)
    })
  }

  const onClickButton = () => {
    pushTo(PATH.login)
  }

  return (
    <PageLayoutAuth>
      <div className="h-full w-full justify-center items-center flex flex-col p-6">
        <img
          src={isVerify.value ? IconCheckCircleSuccess : IconWarning}
          alt="icon"
          style={{
            height: 96,
          }}
        />
        <p
          className="font-bold mt-6 mb-1"
          style={{
            fontSize: 20,
            lineHeight: "32px",
            color: "#111111",
          }}
        >
          {location.pathname === PATH.emailVerification
            ? "Email verification successful!"
            : "Thanks for signing up!"}
        </p>
        <p
          className="font-normal mb-[14px] text-center"
          style={{
            fontSize: 14,
            lineHeight: "24px",
            color: "#111111",
          }}
        >
          We will review your registration and reach out to you via email.
          Please check your inbox and keep an eye out for our email
        </p>
        <Button title="I understand" widthBtn="100%" onClick={onClickButton} />
      </div>
    </PageLayoutAuth>
  )
}
export default EmailVerification
