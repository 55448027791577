import "./index.css"

interface PieChart {
  children: any
  progress: number
  borderSize?: number
  size?: number
  color?: string
  progressBackground?: string
  className?: string
}

const PieChart = (props: PieChart) => {
  const {
    children,
    size = 156,
    borderSize = 16,
    progress = 0,
    color = "#FDBE44",
    progressBackground = "#F1F1F1",
  } = props
  return (
    <div
      className="pie animate"
      style={
        {
          "--p": progress,
          "--b": `${borderSize}px`,
          "--c": color,
          "--w": `${size}px`,
          "--pb": progressBackground,
        } as any
      }
    >
      {children}
    </div>
  )
}
export default PieChart
