import axios, { Axios, AxiosResponse } from "axios"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
import {
  BuildHistoryDetail,
  ComponentType,
  GetTabContentMiddlewareData,
  TableColumnBOMDetail,
} from "pages/project-component-detail/types"
import { STATUS_RESPONSE } from "types"

export const inviteeCommitMiddleware = async (
  project_component_history_id: string,
  conversation_id: string
) => {
  const response: AxiosResponse<{ data: BuildHistoryDetail }> =
    await axios.post(
      `/api/project-component/history/${project_component_history_id}/commit`,
      {
        params: {
          conversation_id,
        },
      }
    )
  return response.data.data
}

export const inviteeCreateDraft = async (
  project_build_component_id: string,
  conversation_id: string
) => {
  const response: AxiosResponse<{ data: BuildHistoryDetail }> =
    await axios.post(
      `/api/project-build-component/${project_build_component_id}/invitee/history/create-and-copy?conversation_id=${conversation_id}`
    )
  return response.data.data
}

export const approveInviteeVersion = async (
  project_component_history_id: string,
  conversation_id: string
) => {
  const response = await axios.post(
    `/api/project-build-component/history/${project_component_history_id}/${conversation_id}/approve`
  )
  return response.data.data
}
export const unapproveInviteeVersion = async (
  project_component_history_id: string,
  conversation_id: string
) => {
  const response = await axios.post(
    `/api/project-build-component/history/${project_component_history_id}/${conversation_id}/unapprove`
  )
  return response.data.data
}
export const revertInviteeVersion = async (
  project_component_history_id: string,
  conversation_id: string,
  data: {
    message: string
  }
) => {
  const response = await axios.post(
    `/api/project-build-component/history/${project_component_history_id}/${conversation_id}/revert`,
    data.message ? data : { message: "" }
  )
  return response.data.data
}

export const deleteInviteeDraft = async (
  project_component_history_id: string,
  conversation_id: string
) => {
  const response = await axios.delete(
    `/api/project-build-component/history/${project_component_history_id}/${conversation_id}/delete`
  )
  return response.data.data
}

export const inviteeUploadFileBOMMiddleware = (
  idHistory: string,
  conversationId: string,
  request: FormData,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  axios
    .post(`/api/invitee/bom/upload/${idHistory}/${conversationId}`, request, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((_response: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.UPLOAD_BOM_FILE_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.UPLOAD_BOM_FILE_ERROR
      )
    })
}

export const inviteeDeleteFileBOMMiddleware = (
  idHistory: string,
  conversationId: string,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  axios
    .delete(`/api/invitee/bom/${idHistory}/${conversationId}/delete-file`)
    .then((_responseDeleteFileBOM: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.DELETE_BOM_FILE_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.DELETE_BOM_FILE_ERROR
      )
    })
}
export const addInviteePcbFileMiddleware = (
  projectComponentHistoryId: string,
  conversationId: string,

  request: FormData,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  axios
    .post(
      `/api/invitee/pcb/upload/${projectComponentHistoryId}/${conversationId}`,
      request
    )
    .then((_response: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.ADD_PCB_FILE_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.status === 413
          ? `The upload of file larger than 50MB is not allowed`
          : error.response?.data?.message ??
              MESSENGER_NOTIFICATION.ADD_PCB_FILE_ERROR
      )
    })
}
export const uploadInviteeBOMFileMiddleware = (
  projectComponentHistoryId: string,
  conversationId: string,
  request: FormData,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  axios
    .post(
      `/api/invitee/pcb/upload-bom/${projectComponentHistoryId}/${conversationId}`,
      request
    )
    .then((_response: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.UPLOAD_BOM_FILE_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.UPLOAD_BOM_FILE_ERROR
      )
    })
}
export const uploadInviteeStackupFileMiddleware = (
  projectComponentHistoryId: string,
  conversationId: string,

  request: FormData,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  axios
    .post(
      `/api/invitee/pcb/upload-stackup/${projectComponentHistoryId}/${conversationId}`,
      request
    )
    .then((_response: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.UPLOAD_STACKUP_FILE_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.UPLOAD_STACKUP_FILE_ERROR
      )
    })
}
export const uploadInviteeAssemblyFileMiddleware = (
  projectComponentHistoryId: string,
  conversationId: string,
  request: FormData,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  axios
    .post(
      `/api/invitee/pcb/upload-assembly/${projectComponentHistoryId}/${conversationId}`,
      request
    )
    .then((_response: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.UPLOAD_ASSEMBLY_FILE_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.UPLOAD_ASSEMBLY_FILE_ERROR
      )
    })
}
export const deleteInviteeFilePCBTemplateMiddleware = (
  idComponentHistory: string,
  conversationId: string,
  url: string,
  callback?: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  axios
    .delete(`/api/invitee/pcb/${idComponentHistory}/${conversationId}/${url}`)
    .then((_response: AxiosResponse) => {
      if (callback) {
        callback(STATUS_RESPONSE.SUCCESS, "")
      }
    })
    .catch((error) => {
      if (callback) {
        callback(STATUS_RESPONSE.ERROR, error.response?.data?.message)
      }
    })
}
export const uploadInviteeAttachmentsMiddleware = (
  historyId: string,
  conversationId: string,
  data: FormData,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  axios
    .post(
      `/api/invitee/pcb/upload-attachments/${historyId}/${conversationId}`,
      data
    )
    .then((_response: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.UPLOAD_ATTACHMENT_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.UPLOAD_ATTACHMENT_ERROR
      )
    })
}

//mech
export const postInviteeTabContentMiddleware = async (
  historyId: string,
  conversationId: string,
  type: string,
  data: FormData
) => {
  return axios.post<{ data: string }>(
    `/api/invitee/${type}/upload/${historyId}/${conversationId}`,
    data
  )
}

export const uploadInvitee3DFile = async (
  historyId: string,
  conversationId: string,
  type: string, // TabType
  newFile: FormData
) => {
  const response = await axios.post<{ data: GetTabContentMiddlewareData }>(
    `/api/invitee/${type}/${historyId}/${conversationId}/upload-3d`,
    newFile
  )
  return response.data.data
}

export const deleteInvitee3DFile = async (
  historyId: string,
  conversationId: string,
  type: string // TabType
) => {
  return axios.delete(
    `/api/invitee/${type}/${historyId}/${conversationId}/delete-3d-file`
  )
}
export const uploadInviteeSubBomFile = async (
  historyId: string,
  conversationId: string,
  type: string, // TabType
  newFile: FormData
) => {
  const response = await axios.post<{ data: GetTabContentMiddlewareData }>(
    `/api/invitee/${type}/${historyId}/${conversationId}/upload-bom`,
    newFile
  )
  return response.data.data
}

export const deleteInviteeSubBomFile = async (
  historyId: string,
  conversationId: string,
  type: string // TabType
) => {
  return axios.delete(
    `/api/invitee/${type}/${historyId}/${conversationId}/delete-bom`
  )
}

export const inviteeBomAddAdditionalColMiddleware = async (
  idHistory: string,
  conversationId: string,
  dataRequest: TableColumnBOMDetail[],
  type: ComponentType = ComponentType.BOM
) => {
  const res = await axios.post(
    `/api/invitee/${type}/${idHistory}/${conversationId}/add-column`,
    {
      data: dataRequest,
    }
  )
  return res.data.data
}
