export enum STATUS_RESPONSE {
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
}

export type UseBooleanProps = {
  value: boolean
  setValue: (value: boolean) => void
}

export interface GeneralLabelValue {
  value: string | number
  label: any
}

export type FormDate = string | number | Date
export const emptyCheckPermissionPageDetail = {
  project: false,
  notificationProject: false,
  viewShare: false,
  notificationViewShare: false,
  component: false,
  notificationComponent: false,
  noUpdateFilePCB: false,
  notificationNoUpdateFilePCB: false,
  build: false,
  notificationBuild: false,
}
export enum VERSION_APPROVED_STATUS {
  PENDING,
  APPROVED,
  UNAPPROVED,
}
