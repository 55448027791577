import { useEffect } from "react"
import { useBoolean } from "helpers/hooks"
import { postComponentHistoryCreateAndCopyMiddleware } from "pages/project-component-detail/services"
import { STATUS_RESPONSE } from "types"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { toast } from "react-toastify"
import { DataErrorWhenEditHistoryTree } from "pages/project-component-detail/types"
import FormLoading from "components/FormLoading/FormLoading"
interface Props {
  onCloseModal: () => void
  openModal: boolean
  idComponent: string
  URLHistory: string
  handleUpdateDataWhenCreate: () => void
  label: string
  openFormErrorWhenEditHistoryTree: (
    newData: DataErrorWhenEditHistoryTree
  ) => void
}

const FormCreate = (props: Props) => {
  const {
    onCloseModal,
    idComponent,
    handleUpdateDataWhenCreate,
    openModal,
    URLHistory,
    label,
    openFormErrorWhenEditHistoryTree,
  } = props
  const isLoading = useBoolean()
  useEffect(() => {
    if (!openModal) {
      return
    }
    onSubmit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal])

  const onSubmit = () => {
    if (!idComponent) {
      return
    }
    isLoading.setValue(true)
    postComponentHistoryCreateAndCopyMiddleware(
      idComponent,
      URLHistory,
      (
        type: STATUS_RESPONSE,
        messenger: string,
        dataRes?: DataErrorWhenEditHistoryTree
      ) => {
        isLoading.setValue(false)
        onCloseModal()
        if (type === STATUS_RESPONSE.SUCCESS) {
          toast(<LabelNotificationPage messenger={messenger} type={type} />)
          handleUpdateDataWhenCreate()
          return
        }
        if (
          dataRes &&
          dataRes.project_component_id &&
          dataRes.statusCode === 400
        ) {
          openFormErrorWhenEditHistoryTree(dataRes)
        } else {
          toast(<LabelNotificationPage messenger={messenger} type={type} />)
        }
      }
    )
  }

  return <FormLoading label={label} />
}

export default FormCreate
