import React from "react"
import PhoneInput, { PhoneInputProps } from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"

interface Props extends PhoneInputProps {
  value: string
  setValue: (value: string) => void
  onEnterKeyPress: (event: React.KeyboardEvent<HTMLDivElement>) => void
}
const InputPhone = (props: Props) => {
  const { setValue, value, onEnterKeyPress } = props
  return (
    <div className="custom-phone-input">
      <PhoneInput
        {...props}
        value={value}
        onChange={setValue}
        onEnterKeyPress={onEnterKeyPress}
        enableSearch
      />
    </div>
  )
}

export default InputPhone
