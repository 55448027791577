import Axios from "axios"
import { SettingRequestProps } from "../types"
import { getUserMiddleware } from "pages/auth/services/api"

export const updateSettingMiddleware = async (request: SettingRequestProps) => {
  return Axios.put(`/api/user/setting`, request).then(() => {
    getUserMiddleware()
    return true
  })
}
export const updateNotificationEmail = async () => {
  const res = await Axios.put(
    `/api/user/setting/reviced-email-from-my-own-messages`
  )
  return res.data
}
