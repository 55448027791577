import Tippy from "@tippyjs/react"
import { Avatar } from "components/Avatar"
import {
  downloadPrivateAsset,
  formatDateForConversationMessage,
} from "helpers/utils"
import { isUndefined } from "lodash"
import DotCard from "../atoms/DotCard"
import { ConversationMessagesDetailItem } from "../conversations.type"
import { ReactComponent as IconMessagerDeleteComponent } from "assets/images/icons/icon-messager-delete-component.svg"
import { ReactComponent as IconMessagerAddComponent } from "assets/images/icons/bell-icon.svg"
import { onRedirectConversationComponent } from "helpers/redirect"

interface Props {
  messages: ConversationMessagesDetailItem
  styleRoot?: React.CSSProperties
  idUserInfo: string
  idInviteeUser: string
  isActiveConversation?: boolean
  conversationId: string
}
const MessagesConversationItemCard = (props: Props) => {
  const { messages, styleRoot, isActiveConversation, conversationId } = props
  const onClickBuildComponent = () => {
    if (
      !isUndefined(messages.is_public_to_conversation) &&
      Boolean(messages.is_public_to_conversation) &&
      messages.commented_from
    ) {
      onRedirectConversationComponent(
        messages.commented_from.project_component_type,
        messages.commented_from.project_component_id,
        messages.commented_from.project_build_component_id,
        messages.commented_from.project_component_history_id,
        conversationId,
        isActiveConversation,

        {
          idHistoryTreeState:
            messages.commented_from.project_component_history_id,
        }
      )
    }
  }

  if (
    !isUndefined(messages.is_notification) &&
    Boolean(messages.is_notification)
  ) {
    const isAdd = messages.content.includes("has been added")
    return (
      <div
        className="mr-6 md:mr-0 md:pr-6 flex items-center px-2 mb-3 last:mb-0"
        style={{
          minHeight: 38,
          background: isAdd
            ? "rgba(86, 160, 18, 0.15)"
            : "rgba(232, 122, 22,0.15)",
          borderRadius: 6,
        }}
      >
        {isAdd ? (
          <IconMessagerAddComponent className="icon-add-messager" />
        ) : (
          <IconMessagerDeleteComponent className="icon-warning-messager" />
        )}

        <p
          className="font-normal text-13 leading-22  pl-1"
          style={{
            color: isAdd ? "#56A012" : "#e87a16",
          }}
        >
          {messages.content}
        </p>
      </div>
    )
  }
  return (
    <div
      className="p-2 flex flex-col mb-3 cursor-pointer relative message-item"
      style={{
        backgroundColor: "#F7F7F7",
        borderRadius: 6,
        ...styleRoot,
      }}
    >
      {!isUndefined(messages.is_public_to_conversation) &&
      Boolean(messages.is_public_to_conversation) &&
      messages.commented_from ? (
        <div className="flex items-center mb-3">
          <p className="font-normal text-13 leading-22 color-black-111111 mr-1">
            Commented on
          </p>
          <p
            onClick={isActiveConversation ? onClickBuildComponent : undefined}
            className={`font-normal text-13 leading-22 ${
              isActiveConversation ? `hover:underline text-blue` : "text-black"
            }`}
          >
            {`${messages.commented_from.project_component_code} / ${messages.commented_from.project_component_history_code}`}
          </p>
        </div>
      ) : null}

      <div className="flex items-center mb-2">
        <Avatar
          width="24px"
          height="24px"
          name={messages.user.fullname}
          avatar={messages.user.avatar}
          textcolor="white"
          contentsize="11px"
          justOneLetter={true}
        />
        <p
          className="font-semibold text-13 leading-22 color-black-111111 pl-2"
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {messages.user.fullname}
        </p>
        {messages.user.email ? (
          <div className="hidden md:flex md:items-center">
            <span className="pl-1"> - </span>
            <p
              className="font-semibold text-13 leading-22 color-black-111111 pl-1"
              style={{
                whiteSpace: "nowrap",
              }}
            >
              {messages.user.email}
            </p>
          </div>
        ) : null}
        <DotCard />
        <p className="text-12 leading-18 color-gray-7a font-normal">
          {messages.created_at
            ? `${formatDateForConversationMessage(messages.created_at)}`
            : "No update"}
        </p>
      </div>
      <div
        style={{
          fontWeight: 400,
          fontSize: 13,
          lineHeight: "22px",
          color: "#111111",
        }}
        className="break-words"
        dangerouslySetInnerHTML={{
          __html: messages.content,
        }}
      ></div>
      {messages.files && messages.files.length ? (
        <div className="flex items-center flex-wrap mt-2">
          {messages.files.map((file, index) => (
            <div
              key={index}
              onClick={() =>
                downloadPrivateAsset(
                  file.file,
                  conversationId
                    ? { conversation_id: conversationId }
                    : undefined
                )
              }
            >
              <Tippy
                className="custom-tippy-menu-left"
                placement="top"
                content={
                  <span
                    className="font-normal"
                    style={{
                      fontSize: 13,
                      lineHeight: "22px",
                      wordBreak: "break-word",
                    }}
                  >
                    {file.file_name}
                  </span>
                }
              >
                <div
                  className="flex items-center h-7 px-2 mr-2 mb-1 cursor-pointer"
                  style={{
                    background: "#F1F1F1",
                    border: "1px solid #E4E4E4",
                    borderRadius: 4,
                    maxWidth: 200,
                  }}
                >
                  <p
                    className="mr-1"
                    style={{
                      fontWeight: 500,
                      fontSize: 12,
                      lineHeight: "18px",
                      color: "#111111",
                      whiteSpace: "nowrap",
                      display: "inline-block",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {file.file_name}
                  </p>
                </div>
              </Tippy>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  )
}
export default MessagesConversationItemCard
