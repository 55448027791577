import { useContext } from "react"
import { SingleTwoLinesCheckProps } from "../types"
import { VersionSpecificationContext } from "../contexts/VersionSpecification.context"
import { fieldFormDataSpecification } from "helpers/utils"

const SingleTwoLinesCheck = (props: SingleTwoLinesCheckProps) => {
  const { item, disabled = false } = props
  const context = useContext(VersionSpecificationContext)

  const handleChangeItem = (newName: string) => () => {
    if (disabled) {
      return
    }
    context?.updateDataForm(
      "formData",
      fieldFormDataSpecification(item.id, item.sequence),
      newName,
      Boolean(item.required)
    )
  }

  return (
    <div className="flex">
      {item.answers.length
        ? item.answers.map((answer, index) => (
            <div
              key={index}
              className="rounded px-2 mr-4 mb-8 cursor-pointer flex justify-center flex-col"
              style={{
                border:
                  answer.name ===
                  context?.formData[
                    fieldFormDataSpecification(item.id, item.sequence)
                  ]
                    ? "1px solid #F7AC1B"
                    : "1px solid #B0B0B0",
                paddingTop: 6,
                paddingBottom: 6,
              }}
              onClick={handleChangeItem(answer.name)}
            >
              <p
                className="font-normal"
                style={{
                  fontSize: 14,
                  lineHeight: "24px",
                  color: "#222222",
                }}
              >
                {answer.name}
              </p>
              <p
                className="font-normal"
                style={{
                  fontSize: 14,
                  lineHeight: "24px",
                  color: "rgb(122, 122, 122)",
                }}
              >
                {answer.placeholder}
              </p>
            </div>
          ))
        : null}
    </div>
  )
}

export default SingleTwoLinesCheck
