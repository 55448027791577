import CheckedDefault from "components/Checked/CheckedDefault"
import { RememberMeCardProps } from "../types"

const RememberMeCard = (props: RememberMeCardProps) => {
  const { title, checked, setChecked } = props
  return (
    <div className="flex items-center">
      <CheckedDefault checked={checked} onClick={() => setChecked(!checked)} />
      <p
        className="font-normal ml-1"
        style={{
          fontSize: 14,
          lineHeight: "24px",
          color: "#111111",
        }}
      >
        {title}
      </p>
    </div>
  )
}

export default RememberMeCard
