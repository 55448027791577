import { useAppSelector } from "hooks/useApp"
import { closeModal } from "reducers/modal"
import ModalDelete from "components/ModalCustom/ModalDelete"

export const ModalController = () => {
  const modalType = useAppSelector((state) => state.modal.type)
  const props = useAppSelector((state) => state.modal.props)
  if (modalType === "Delete") {
    return <ModalDelete onCloseModal={closeModal} {...props.deleteModal} />
  }
  return null
}
