import { useEffect } from "react"
import { useBoolean, useString } from "helpers/hooks"
import FormInputCard from "pages/auth/molecules/FormInputCard"
import MultipleInput from "components/Input/MultipleInput"
import ModalCustom from "components/ModalCustom"
import { postInviteeComponentHistoryCommitMiddleware } from "pages/project-component-detail/services"
import { STATUS_RESPONSE } from "types"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { toast } from "react-toastify"
import Button from "components/Button/Button"

interface Props {
  onCloseModal: () => void
  openModal: boolean
  code: string
  idComponent: string
  handleUpdateDataWhenCommit: () => void
  conversationId: string
}

const FormCommit = (props: Props) => {
  const {
    onCloseModal,
    openModal,
    code,
    idComponent,
    handleUpdateDataWhenCommit,
    conversationId,
  } = props
  const isLoading = useBoolean()
  const message = useString("")

  useEffect(() => {
    if (!openModal) {
      return
    }
    message.setValue("")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal])

  const handleChangeInput = (event) => {
    if (event.target.value.length > 256) {
      return
    }
    message.setValue(event.target.value)
  }

  const onSubmit = () => {
    if (!idComponent) {
      return
    }
    isLoading.setValue(true)
    postInviteeComponentHistoryCommitMiddleware(
      idComponent,
      conversationId,
      {
        message: message.value || "",
      },
      (type: STATUS_RESPONSE, messenger: string) => {
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        isLoading.setValue(false)
        if (type === STATUS_RESPONSE.SUCCESS) {
          handleUpdateDataWhenCommit()
          onCloseModal()
        }
      }
    )
  }

  return (
    <ModalCustom
      label={`Save`}
      handleChangeButton={() => onCloseModal}
      bodyChildren={
        <div className="flex flex-col">
          <FormInputCard title="Message" fontWeightText="font-semibold">
            <MultipleInput
              value={message.value}
              onChange={handleChangeInput}
              rows={3}
              maxLength={256}
            />
          </FormInputCard>
          <p
            className="font-medium"
            style={{
              fontSize: 12,
              lineHeight: "18px",
              color: "#222222",
            }}
          >
            {`You are saving version ${code}.`}
          </p>
          <p
            className="font-medium"
            style={{
              fontSize: 12,
              lineHeight: "18px",
              color: "#222222",
            }}
          ></p>
          <div className="flex items-center justify-between mt-8">
            <Button title="Cancel" colorBtn="white" onClick={onCloseModal} />
            <Button title="Confirm" onClick={onSubmit} />
          </div>
        </div>
      }
    />
  )
}

export default FormCommit
