import { ListTabProps } from "./types"
import TabItem from "./TabItem"

const ListTab = (props: ListTabProps) => {
  const { handleClickTabItem, listTab, activeTab } = props
  return (
    <div className={`flex items-center`}>
      {listTab.length
        ? listTab.map((el, index) => (
            <TabItem
              key={index}
              handleClickTabItem={handleClickTabItem}
              label={el}
              active={index === activeTab ? true : false}
              index={index}
            />
          ))
        : null}
    </div>
  )
}
export default ListTab
