import React from "react"
import PageLayout from "./PageLayout"
import { ReactComponent as Image404 } from "assets/images/404.svg"
import { pushTo } from "helpers/history"
import { PATH } from "constants/path"
import Button from "components/Button/Button"

const NotFoundPage = () => {
  return (
    <PageLayout heightHeader={0} minWidth="min-w-[500px]">
      <div className="h-full flex flex-col items-center justify-center">
        <div className="h-full flex flex-col items-center justify-center m-6">
          <Image404 />
          <div className="flex flex-col text-center">
            <p
              className="font-bold my-3"
              style={{
                fontSize: 28,
                lineHeight: "38px",
                color: "#111111",
              }}
            >
              Oops! We can’t find that page.
            </p>
            <p
              className="font-nornal text-14 leading-24"
              style={{
                color: "#7A7A7A",
              }}
            >
              The link you followed maybe broken, or the page may have removed.
            </p>
          </div>
          <Button
            title="Back to Home"
            onClick={() => pushTo(PATH.projects)}
            styleButton={{
              marginTop: 12,
            }}
          />
        </div>
      </div>
    </PageLayout>
  )
}

export default NotFoundPage
