import { MultipleCheckSpecificationProps } from "../types"
import { useContext } from "react"
import { VersionSpecificationContext } from "../contexts/VersionSpecification.context"
import { fieldFormDataSpecification } from "helpers/utils"
import { ReactComponent as IconActive } from "assets/images/icons/icon-active-via-process.svg"
const MultipleCheckSpecification = (props: MultipleCheckSpecificationProps) => {
  const { item, disabled = false } = props
  const context = useContext(VersionSpecificationContext)

  const handleChangeItem = (newName: string, sequencePart: number) => () => {
    if (disabled) {
      return
    }
    context?.updateDataForm(
      "formData",
      fieldFormDataSpecification(item.id, sequencePart),
      newName ===
        context?.formData[fieldFormDataSpecification(item.id, sequencePart)]
        ? ""
        : newName,
      Boolean(item.required)
    )
  }
  const activeItem = (newName: string, sequencePart: number) => {
    return (
      newName ===
      context?.formData[fieldFormDataSpecification(item.id, sequencePart)]
    )
  }

  return (
    <div
      className="flex items-center"
      style={{
        flexFlow: "row wrap",
      }}
    >
      {item.answers.map((answer, index) => (
        <div className="mr-2 mb-2" key={index}>
          <div
            className="rounded px-1 cursor-pointer min-h-[32px] flex items-center"
            style={{
              border: activeItem(answer.name, answer.sequence)
                ? "1px solid #F7AC1B"
                : "1px solid #E4E4E4",
              boxShadow: activeItem(answer.name, answer.sequence)
                ? "0px 0px 0px 4px rgba(247, 172, 27, 0.1)"
                : "none",
              backgroundColor: activeItem(answer.name, answer.sequence)
                ? "#FFFFFF"
                : "#F7F7F7",
            }}
            onClick={handleChangeItem(answer.name, answer.sequence)}
          >
            <p
              className="font-normal"
              style={{
                color: activeItem(answer.name, answer.sequence)
                  ? "#222222"
                  : "#7A7A7A",
                fontSize: 14,
                lineHeight: "24px",
              }}
            >
              {answer.name}
            </p>
            {activeItem(answer.name, answer.sequence) ? (
              <IconActive className="ml-2" />
            ) : null}
          </div>
        </div>
      ))}
    </div>
  )
}

export default MultipleCheckSpecification
