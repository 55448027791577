import { STATUS_BUILD } from "components/Status/types"
import LabelStatusBuild from "pages/project-component/molecules/LabelStatusBuild"
import { TAB_URL_PROJECT_COMPONENT } from "../types"
import { twMerge } from "tailwind-merge"

interface Props {
  isActiveLinedBuild: boolean
  status: STATUS_BUILD
  titlePage: string
  isViewLinkedBuild: boolean
  onViewLinedBuilds: () => void
  className?: string
}
const ShowViewLinkBuildCard = (props: Props) => {
  const {
    isActiveLinedBuild,
    status,
    titlePage,
    isViewLinkedBuild,
    onViewLinedBuilds,
    className,
  } = props
  if (titlePage === TAB_URL_PROJECT_COMPONENT.COMPONENT) {
    return (
      <div
        className={twMerge("flex items-center", className)}
        onClick={onViewLinedBuilds}
      >
        <p
          className={`font-normal text-13 leading-22 ${
            isActiveLinedBuild ? "cursor-pointer hover:underline" : ""
          }`}
          style={{
            color: isActiveLinedBuild ? "#0A5AF5" : "#C7C7C7",
          }}
        >
          {isViewLinkedBuild
            ? "View files and specifications"
            : "View linked Builds"}
        </p>
      </div>
    )
  }
  return <LabelStatusBuild status={status} />
}
export default ShowViewLinkBuildCard
