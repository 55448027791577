import SimpleTable, { SimpleTableProps } from "components/Table/SimpleTable"
import { StorageUsage } from "./StorageUsage.type"
import { ReactComponent as DownIcon } from "assets/images/icons/icon-select.svg"
import { ReactComponent as UpIcon } from "assets/images/icons/arrow-up.svg"
import { ReactComponent as IconActionThreeDot } from "assets/images/icons/icon-three-dots.svg"
import React, { useEffect, useState } from "react"
import { TippyCustomzie } from "components/TippyCustomzie"
import ActionItem from "pages/projects/molecules/ActionItem"
import { deleteProjectComponentAttachment } from "../project-component/services/api"
import { deleteProjectAttachment } from "../projects/services/api"
import { deleteBuildAttachment } from "../project-build/api.services"
import {
  onRedirectProject,
  onRedirectProjectBuild,
  onRedirectProjectComponent,
} from "helpers/redirect"
import { TAB_URL_PROJECT_COMPONENT } from "pages/project-component-detail/types"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { toast } from "react-toastify"
import { useWindowSize } from "helpers/hooks"
import { configureStore } from "stores/configureStore"
import { openModal, closeModal } from "reducers/modal"
import { deleteNotesFolder } from "pages/conversations/conversations.api"
import DotCard from "pages/conversations/atoms/DotCard"
interface Props {
  type: "Internal" | "Project"
  tableData: StorageUsage[]
  onChangeSummary: (value: boolean) => void
}
const StorageUsageList = (props: Props) => {
  const { type, onChangeSummary, tableData } = props
  const [actives, setActive] = useState([] as string[])
  const [data, setData] = useState(tableData)
  const [tempData, setTempData] = useState(data)
  const [showTippy, setShowTippy] = useState([] as string[])
  const { isMobile } = useWindowSize()
  const userId = localStorage.getItem("user_id")
  const handleClickTippy = (value) => {
    if (showTippy.includes(value)) {
      setShowTippy(showTippy.filter((item) => item !== value))
    } else setShowTippy(showTippy.concat([value]))
  }
  const renderStorageUsageAction = (item: StorageUsage) => {
    return (
      <React.Fragment>
        {type === "Project" ? (
          <ActionItem
            title="Go to detail"
            onClick={() => {
              onClickDetail(item)
            }}
          />
        ) : (
          <ActionItem
            title="Free space"
            onClick={() => {
              handleOpenPopup(item, "freespace")
            }}
          />
        )}
        {isMobile
          ? null
          : type === "Project" && (
              <ActionItem
                title="Delete"
                onClick={() => {
                  handleOpenPopup(item, "delete")
                }}
              />
            )}
      </React.Fragment>
    )
  }
  const handleOpenPopup = (
    item: StorageUsage,
    type: "delete" | "freespace"
  ) => {
    configureStore.dispatch(
      openModal({
        type: "Delete",
        props: {
          deleteModal: {
            title: `When action is confirmed, all attachment files also be removed. This action can not undo.`,
            label: `Warning`,
            content: `Press ${
              type === "delete" ? "Delete" : "Process"
            } to process`,
            onSubmit:
              type === "delete" ? handleDelete(item) : handleFreeSpace(item),
            styleTitle: { textAlign: "center" },
            titleButton: `${type === "delete" ? "Delete" : "Process"}`,
          },
        },
      })
    )
  }
  const handleFreeSpace = (item: StorageUsage) => () => {
    deleteNotesFolder(item.id)
      .then((res) => {
        onChangeSummary(true)
        toast(<LabelNotificationPage messenger={res.message} type="success" />)
        handleClickTippy(item.id)
        closeModal()
      })
      .catch((error) => {
        toast(
          <LabelNotificationPage
            messenger={error.response?.data?.message}
            type="error"
          />
        )
        // handleClickTippy(item.id)
        closeModal()
      })
  }

  const handleDelete = (item: StorageUsage) => () => {
    const deleteItem = (deleteFunction: Function, successMessage: string) => {
      deleteFunction(item.id)
        .then(() => {
          setData(data.filter((usageItem) => usageItem.id !== item.id))
          setTempData(tempData.filter((usageItem) => usageItem.id !== item.id))
          onChangeSummary(true)
          toast(
            <LabelNotificationPage messenger={successMessage} type="success" />
          )
          handleClickTippy(item.id)
          closeModal()
        })
        .catch((error) => {
          toast(
            <LabelNotificationPage
              messenger={error.response?.item?.message || "Delete failed!"}
              type="error"
            />
          )
          handleClickTippy(item.id)
          closeModal()
        })
    }

    if (item.type === "Project") {
      deleteItem(deleteProjectAttachment, "Delete project successfully!")
    } else if (item.type === "Component") {
      deleteItem(
        deleteProjectComponentAttachment,
        "Delete component successfully!"
      )
    } else if (item.type === "Build") {
      deleteItem(deleteBuildAttachment, "Delete build successfully!")
    }
  }
  const onClickDetail = (item: StorageUsage) => {
    if (item.type === "Project") onRedirectProject(item.id)
    if (item.type === "Component")
      onRedirectProjectComponent(
        TAB_URL_PROJECT_COMPONENT.COMPONENT,
        item.sub_type || "",
        item.id,
        "history"
      )
    if (item.type === "Build") {
      onRedirectProjectBuild(
        item.project_id || "",
        item.id,
        item.sub_status || "",
        item.owner === userId,
        item.is_archived
      )
    }
  }
  useEffect(() => {
    setData(tableData)
    setTempData(tableData)
  }, [tableData])

  useEffect(() => {
    setData(
      tempData.map((item) => {
        if (actives.length === 0) {
          return {
            ...item,
            isShow: !item.project_id ? true : false,
          }
        } else {
          return {
            ...item,
            isShow:
              item.project_id && !actives.includes(item.project_id)
                ? false
                : true,
          }
        }
      })
    )
  }, [actives, tempData])
  const columns: SimpleTableProps["columns"] = [
    {
      title: "Name",
      dataIndex: "name",
      render: (columnData: StorageUsage) => {
        return (
          <div
            className={`flex justify-start items-center ${
              !columnData.project_id || type !== "Internal"
                ? "cursor-pointer"
                : ""
            }`}
            onClick={() => {
              if (actives.includes(columnData.id))
                setActive(actives.filter((item) => item !== columnData.id))
              else {
                setActive(actives.concat([columnData.id]))
              }
            }}
          >
            {columnData.project_id || columnData.type === "Conversation" ? (
              <></>
            ) : (
              <div>
                {actives.includes(columnData.id) ? <UpIcon /> : <DownIcon />}
              </div>
            )}
            <p
              className={`ml-2 ${
                columnData.project_id || columnData.type === "Conversation"
                  ? "ml-5"
                  : "ml-2"
              } text-ellipsis whitespace-nowrap overflow-hidden ${
                isMobile ? "max-w-xs" : "max-w-3xl"
              }`}
            >
              {columnData.name}
            </p>
          </div>
        )
      },

      width: `${isMobile ? "40%" : "60%"}`,
    },
    {
      title: "ID",
      dataIndex: "code",
      render: (columnData: StorageUsage) => {
        return columnData.type === "Conversation" ? (
          <span> {columnData.code}</span>
        ) : (
          <span
            className="text-blue hover:underline cursor-pointer"
            onClick={() => {
              onClickDetail(columnData)
            }}
          >
            {columnData.code}
          </span>
        )
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      width: type === "Internal" ? "20%" : "10%",
      render: (columnData: StorageUsage) => columnData.type,
    },
    {
      title: "Size",
      dataIndex: "size",
      width: "15%",
      render: (columnData: StorageUsage) => columnData.size,
    },
    {
      title: "",
      dataIndex: "action",
      align: "right",
      width: "40px",
      render: (columnData: StorageUsage) => {
        return (
          <TippyCustomzie
            containerClass="component-card-menu"
            placement="bottom-end"
            interactive
            arrow={false}
            animation="scale"
            visible={showTippy.includes(columnData.id)}
            onClickOutside={() =>
              setShowTippy(showTippy.filter((item) => item !== columnData.id))
            }
            content={
              <div
                style={{
                  background: "#222222",
                  borderRadius: 4,
                  paddingTop: 8,
                  paddingBottom: 8,
                  width: 120,
                }}
              >
                {renderStorageUsageAction(columnData)}
              </div>
            }
            allowHTML
          >
            <div
              className="flex items-center justify-end cursor-pointer"
              onClick={() => {
                handleClickTippy(columnData.id)
              }}
            >
              <IconActionThreeDot />
            </div>
          </TippyCustomzie>
        )
      },
    },
  ]
  const columnsOnMobile: SimpleTableProps["columns"] = [
    {
      title: "none",
      dataIndex: "name",
      render: (columnData: StorageUsage) => {
        return (
          <div
            className="flex gap-2"
            onClick={() => {
              if (actives.includes(columnData.id))
                setActive(actives.filter((item) => item !== columnData.id))
              else {
                setActive(actives.concat([columnData.id]))
              }
            }}
          >
            {!columnData.project_id && columnData.type !== "Conversation" && (
              <div className="mt-[5px]">
                {actives.includes(columnData.id) ? <UpIcon /> : <DownIcon />}
              </div>
            )}
            <div
              className={`${
                columnData.project_id || columnData.type === "Conversation"
                  ? "ml-4"
                  : ""
              }`}
            >
              <div
                className={`flex justify-start items-center ${
                  !columnData.project_id || type !== "Internal"
                    ? "cursor-pointer"
                    : ""
                } max-w-[185px]`}
              >
                <p
                  className={`text-ellipsis whitespace-nowrap overflow-hidden
              `}
                >
                  {columnData.name}
                </p>
              </div>
              <div className="flex items-center">
                <p className="font-normal text-13 leading-22 color-gray-7a">
                  {columnData.type}
                </p>
                {columnData.type !== "Conversation" && (
                  <>
                    {/* dot */}
                    <DotCard styleRoot={{ marginLeft: 8, marginRight: 8 }} />
                    <p
                      className="text-blue underline cursor-pointer"
                      onClick={() => {
                        onClickDetail(columnData)
                      }}
                    >
                      {columnData.code}
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        )
      },

      width: "60%",
    },
    {
      title: "none",
      dataIndex: "code",
      width: "40%",
      render: (columnData: StorageUsage) => {
        return (
          <div className="flex flex-col items-end">
            <p
              className="text-13 font-semibold text-new-black-333333"
              onClick={() => {
                onClickDetail(columnData)
              }}
            >
              {columnData.size}
            </p>
            <TippyCustomzie
              containerClass="component-card-menu"
              placement="bottom-end"
              interactive
              arrow={false}
              animation="scale"
              visible={showTippy.includes(columnData.id)}
              onClickOutside={() =>
                setShowTippy(showTippy.filter((item) => item !== columnData.id))
              }
              content={
                <div
                  style={{
                    background: "#222222",
                    borderRadius: 4,
                    paddingTop: 8,
                    paddingBottom: 8,
                    width: 120,
                  }}
                >
                  {renderStorageUsageAction(columnData)}
                </div>
              }
              allowHTML
            >
              <div
                className="flex items-center justify-end cursor-pointer"
                onClick={() => {
                  handleClickTippy(columnData.id)
                }}
              >
                <IconActionThreeDot />
              </div>
            </TippyCustomzie>
          </div>
        )
      },
    },
  ]
  const renderColumns = () => {
    if (type === "Internal") {
      columns.splice(1, 1)
    }
    return columns
  }
  return (
    <div className="text-black bg-white border border-border-gray rounded-md overflow-auto">
      <SimpleTable
        customClass="storage-usage-table"
        columns={isMobile ? columnsOnMobile : renderColumns()}
        dataSource={data || []}
        isRequiredTableHeader={isMobile ? false : true}
      />
    </div>
  )
}
export default StorageUsageList
