interface Props {
  label: string
}
const TypeComponent = (props: Props) => {
  const { label } = props
  return (
    <div
      className="flex items-center justify-center rounded px-2 type-component-card"
      style={{
        background: "#333333",
        height: 22,
      }}
    >
      <p
        className="font-semibold text-white"
        style={{
          fontSize: 10,
          lineHeight: "14px",
        }}
      >
        {label}
      </p>
    </div>
  )
}
export default TypeComponent
