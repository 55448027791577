import iconNoData from "assets/images/icons/icon-no-data-component.svg"
import iconNoDataBuild from "assets/images/icons/icon-no-data-build.svg"
import { TAB_PROJECT_COMPONENT } from "../project-component.constant"
import { pushTo } from "helpers/history"
import { PATH } from "constants/path"
import { useContext } from "react"
import { PermissionProjectPage } from "../contexts/PermissionProjectPage.context"
import { checkPermissionPage } from "helpers/utils"
import ButtonHasIcon from "components/Button/ButtonHasIcon"
import ActionItem from "pages/projects/molecules/ActionItem"
import { useBoolean } from "helpers/hooks"
import { TippyCustomzie } from "components/TippyCustomzie"
const NoData = (props: {
  onClickNewComponent: () => void
  textContent?: string
  tab: TAB_PROJECT_COMPONENT
  idProject: string
  isShowButton: boolean
  onClickNewFolder?: () => void
}) => {
  const {
    tab,
    onClickNewComponent,
    textContent = "There are no components to show",
    idProject,
    isShowButton,
    onClickNewFolder,
  } = props
  const { archiveProject, viewOnlyShare } = useContext(PermissionProjectPage)
  const showTippyName = useBoolean()
  const handleClickTippyName = (event) => {
    event.stopPropagation()
    event.preventDefault()
    if (
      checkPermissionPage({
        viewShare: viewOnlyShare,
      })
    ) {
      return
    }
    showTippyName.setValue(!showTippyName.value)
  }
  const onClickDraftBuild = () => {
    if (
      checkPermissionPage({
        project: archiveProject,
        notificationProject: true,
        viewShare: viewOnlyShare,
        notificationViewShare: true,
      })
    ) {
      return
    }
    pushTo(PATH.draftBuild, {
      idProject,
      idProjectBuild: "new-draft",
    })
  }
  const renderButton = () => {
    if (tab === TAB_PROJECT_COMPONENT.FOLDER) {
      return (
        <ButtonHasIcon
          title="New Component"
          disabled={checkPermissionPage({
            project: archiveProject,
            viewShare: viewOnlyShare,
          })}
          widthButton={149}
          onClick={onClickNewComponent}
        />
      )
    }
    if (tab === TAB_PROJECT_COMPONENT.COMPONENT) {
      return (
        <TippyCustomzie
          disabled={checkPermissionPage({
            project: archiveProject,
            viewShare: viewOnlyShare,
          })}
          visible={showTippyName.value}
          containerClass="component-card-menu"
          placement="bottom-start"
          animation="scale"
          offset={[0, 7]}
          allowHTML
          onClickOutside={() => showTippyName.setValue(false)}
          interactive
          content={
            onClickNewFolder && (
              <div className="bg-[#222222] rounded-4 py-2 w-[117px]">
                <ActionItem
                  className="hidden md:flex"
                  title="Folder"
                  onClick={() => {
                    showTippyName.setValue(false)
                    onClickNewFolder()
                  }}
                />
                <ActionItem
                  className="hidden md:flex"
                  title="Component"
                  onClick={() => {
                    showTippyName.setValue(false)
                    onClickNewComponent()
                  }}
                />
              </div>
            )
          }
        >
          <ButtonHasIcon
            title="Create New"
            widthButton={117}
            onClick={handleClickTippyName}
          />
        </TippyCustomzie>
      )
    }
    return (
      <ButtonHasIcon
        title="New Build"
        disabled={checkPermissionPage({
          project: archiveProject,
          viewShare: viewOnlyShare,
        })}
        widthButton={107}
        onClick={onClickDraftBuild}
      />
    )
  }
  return (
    <div className="flex flex-col items-center justify-center h-full">
      <img
        src={tab === TAB_PROJECT_COMPONENT.BUILD ? iconNoDataBuild : iconNoData}
        alt="icon"
        style={{
          height: 168,
        }}
      />
      <p
        className="font-normal my-6"
        style={{
          fontSize: 14,
          lineHeight: "24px",
          color: "#111111",
        }}
      >
        {textContent}
      </p>
      {isShowButton ? (
        <div className="hidden md:block">{renderButton()}</div>
      ) : null}
    </div>
  )
}

export default NoData
