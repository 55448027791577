import Breadcrumbs from "components/Breadcrumbs"
import { BreadcrumbsDetail } from "components/Breadcrumbs/types"
import { PATH } from "constants/path"
import LabelHeader, {
  getContentLabelStyleProps,
} from "pages/project-component/molecules/LabelHeader"
import {
  formatDate,
  formatDateForConversationMessage,
  customLocalStorageHandler,
} from "helpers/utils"
import { ProjectComponentBuildDetail } from "pages/project-build/project-build.type"
import ActivityLogDefault from "components/activity-log/ActivityLogDefault"
import { MODEL_ACTIVITY_LOG } from "components/activity-log/activity-log.type"
import { ProjectDetail } from "pages/projects/types"
import { pushTo } from "helpers/history"
import { onRedirectProjectBuild } from "helpers/redirect"
import { cloneDeep, isUndefined } from "lodash"
import {
  HeaderBreadcrumbInfo,
  HeaderContent,
} from "components/HeaderInfo/HeaderInfo"
import { useBoolean, useWindowSize } from "helpers/hooks"
import { useTippyLayout } from "components/TippyCustomzie"
import { useAppSelector } from "hooks/useApp"
import { NAME_LOCALSTORAGE } from "constants/localStorage"

interface HeaderProps {
  projectBuild: ProjectComponentBuildDetail
  projectDetail: ProjectDetail
  idConversationParams: string
  updatedAtBuild: string
  code: string
}
const HeaderConversationDetail = (props: HeaderProps) => {
  const {
    projectBuild,
    updatedAtBuild,
    idConversationParams,
    code,
    projectDetail,
  } = props
  const { storageData, handleSetLocalStorage } = customLocalStorageHandler(
    NAME_LOCALSTORAGE.CONVERSATIONS
  )
  const user = useAppSelector((state) => state.userInfo)
  const { isMobile } = useWindowSize()
  const contentLabelStyleProps = getContentLabelStyleProps(!isMobile)
  const showProjectInfo = useBoolean()
  const { TippyLayout } = useTippyLayout()

  const onCallbackBuildPage = () => {
    pushTo(PATH.buildOverview, {
      status: projectBuild.status,
      idProject: projectBuild.project_id,
      idProjectBuild: projectBuild.id,
    })
  }
  const listConversationBreadcrumbs: BreadcrumbsDetail[] = [
    {
      name: "Conversations",
      path: PATH.conversations,
    },
    {
      name: projectBuild.name,
      isNoHover: true,
    },
  ]
  const listProjectBreadcrumbs: BreadcrumbsDetail[] = [
    {
      name: projectDetail.name,
      field: "idProject",
      path: PATH.projectComponent,
      value: projectDetail.id,
    },
    {
      name: projectBuild.name,
      onCustomRouter: onCallbackBuildPage,
      isActive: true,
    },
  ]

  if (!projectBuild.id) {
    return null
  }
  const onClickLabelBuild = () => {
    if (!projectDetail.id) {
      return
    }
    onRedirectProjectBuild(
      projectBuild.project_id,
      projectBuild.id,
      projectBuild.status,
      projectBuild.created_by === user.id
    )
  }
  const onClickLabelConversation = () => {
    const newCookies = !isUndefined(storageData) ? cloneDeep(storageData) : {}
    newCookies.projectBuildId = projectBuild.id
    newCookies.conversationId = idConversationParams
    handleSetLocalStorage(newCookies)
    pushTo(PATH.conversations)
  }

  const renderProjectInfo = () => (
    <>
      {code ? (
        <LabelHeader
          {...contentLabelStyleProps}
          title="Convo ID:"
          label={code}
          isClick
          onClickLabel={onClickLabelConversation}
        />
      ) : null}
      <LabelHeader
        {...contentLabelStyleProps}
        title="Build ID:"
        label={projectBuild.code}
        isClick={projectDetail.id ? true : false}
        onClickLabel={onClickLabelBuild}
      />
      <LabelHeader
        {...contentLabelStyleProps}
        title="Created:"
        label={formatDate(projectBuild?.created_at, "DD MMM yyyy")}
      />
      <LabelHeader
        {...contentLabelStyleProps}
        title="Last update:"
        label={
          updatedAtBuild
            ? `${formatDateForConversationMessage(updatedAtBuild)}`
            : "No update"
        }
      />
    </>
  )

  const renderProjectContent = () =>
    isMobile ? (
      <TippyLayout
        visible={showProjectInfo.value}
        headerContent={
          <HeaderContent onClick={() => showProjectInfo.setValue(false)} />
        }
        mainContent={renderProjectInfo()}
      >
        <LabelHeader
          title="Project Info"
          isBorderRight
          // onClickTitle={openLayout}
          onClickTitle={() => {
            showProjectInfo.setValue(true)
          }}
        />
      </TippyLayout>
    ) : (
      renderProjectInfo()
    )

  return (
    <HeaderBreadcrumbInfo>
      <Breadcrumbs
        className="hidden md:flex flex-1"
        listBreadcrumbs={
          projectDetail.id
            ? listProjectBreadcrumbs
            : listConversationBreadcrumbs
        }
      />
      <div className="flex items-center justify-center">
        {renderProjectContent()}
        <ActivityLogDefault
          model={MODEL_ACTIVITY_LOG.CONVERSATION_BUILDS}
          idRelation={idConversationParams}
        />
      </div>
    </HeaderBreadcrumbInfo>
  )
}

export default HeaderConversationDetail
