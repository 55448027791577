import { CheckedDefaultProps } from "./types"
import IconCheckbox from "assets/images/icons/icon-active-checkbox.svg"
import Typography from "components/Typography"
import { twMerge } from "tailwind-merge"

const CheckedDefault = (props: CheckedDefaultProps) => {
  const {
    checked,
    title,
    className = "",
    buttonClass = "",
    tilteClass = "",
    textColor = "white",
    onClick,
    ...otherProps
  } = props
  return (
    <div
      className={twMerge(
        "flex items-center cursor-pointer select-none",
        className
      )}
      onClick={onClick}
    >
      <button
        className={twMerge(
          "flex justify-center items-center	w-5 h-5 rounded-4",
          buttonClass
        )}
        style={{
          border: checked ? "none" : "1px solid #E4E4E4",
          backgroundColor: checked ? "#FDBE44" : "#FFFFFF",
        }}
        {...otherProps}
      >
        {checked ? <img src={IconCheckbox} alt="icon checkbox" /> : null}
      </button>
      {title ? (
        <Typography
          textColor={textColor}
          textClass={twMerge("font-normal font-sm leading-6 ml-1", tilteClass)}
        >
          {title}
        </Typography>
      ) : null}
    </div>
  )
}

export default CheckedDefault
