import { ReactComponent as IconArrowRight } from "assets/images/icons/icon-arrow-right-breadcrumb.svg"
import { useString } from "helpers/hooks"
import React from "react"
import { TAB_HEADER_BREADCRUMB } from "./types"

interface Props {
  renderBreadcrumb: React.ReactNode
  renderIDInfo: React.ReactNode
}
const HeaderBreadcrumbCard = (props: Props) => {
  const { renderBreadcrumb, renderIDInfo } = props
  const active = useString(TAB_HEADER_BREADCRUMB.PROJECT_INFO)
  const onChangeBreadcrumb = (newActive: string) => () => {
    active.setValue(newActive)
  }
  return (
    <div className="flex items-center h-full w-full">
      <div
        className="hidden md:flex items-center h-full cursor-pointer"
        style={{
          width:
            active.value === TAB_HEADER_BREADCRUMB.BREADCRUMB
              ? "calc(100% - 100px)"
              : 100,
          willChange: "width",
          transition: " width 0.5s cubic-bezier(0.2, 0, 0, 1) 0s",
        }}
      >
        <div
          className="flex items-center w-fit overflow-hidden relative"
          onClick={onChangeBreadcrumb(TAB_HEADER_BREADCRUMB.BREADCRUMB)}
        >
          {active.value === TAB_HEADER_BREADCRUMB.BREADCRUMB ? (
            renderBreadcrumb
          ) : (
            <div className="flex items-center hover-header-breadcrumbs">
              <p
                className="font-nornal text-13 leading-22 "
                style={{
                  color: "#7A7A7A",
                }}
              >
                Breadcrumb
              </p>
              <IconArrowRight className="ml-1" />
            </div>
          )}
        </div>
      </div>
      <div
        className="flex items-center justify-end h-full cursor-pointer w-full overflow-x-auto ml-2"
        style={{
          willChange: "width",
          transition: " width 0.5s cubic-bezier(0.2, 0, 0, 1) 0s",
        }}
      >
        <div className="flex items-center h-full w-fit">
          {active.value === TAB_HEADER_BREADCRUMB.PROJECT_INFO ? (
            renderIDInfo
          ) : (
            <div className="hover-header-breadcrumbs">
              <p
                className="font-nornal text-13 leading-22 "
                style={{
                  color: "#7A7A7A",
                }}
                onClick={onChangeBreadcrumb(TAB_HEADER_BREADCRUMB.PROJECT_INFO)}
              >
                ID info
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default HeaderBreadcrumbCard
