import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { STATUS_BUILD } from "components/Status/types"
import SwitchButtonDefault from "components/SwitchButton/SwitchButtonDefault"
import { EVENT } from "constants/events"
import { PATH } from "constants/path"
import { pushTo } from "helpers/history"
import { useBoolean, useNumber, useString, useWindowSize } from "helpers/hooks"
import { umamiTracking } from "helpers/utils"
import { useAppSelector } from "hooks/useApp"
import { cloneDeep, orderBy, remove } from "lodash"
import PageLayout from "pages/layout/PageLayout"
import {
  getProjectBuildAdditionalInfoMiddleware,
  getProjectBuildMiddleware,
} from "pages/project-build/api.services"
import { PermissionProjectBuildPage } from "pages/project-build/contexts/PermissionProjectBuildPage.context"
import AdditionalInfoCard from "pages/project-build/organisms/AdditionalInfoCard"
import {
  emptyProjectBuildDetail,
  MENU_TAB_BUILD,
  ProjectBuildAdditionalDetail,
  ProjectComponentBuildDetail,
} from "pages/project-build/project-build.type"
import {
  getProjectDetailMiddleware,
  getProjectRoleMiddleware,
} from "pages/projects/services/api"
import {
  defaultProjectDetail,
  ProjectDetail,
  STATUS_PROJECT_ROLE,
} from "pages/projects/types"
import { useEffect, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { STATUS_RESPONSE } from "types"
import {
  getConversationMessagesMiddleware,
  getNotificationConversationMiddleware,
  postNotificationConversationMiddleware,
} from "./conversations.api"
import {
  BUILD_INVITEE_DETAIL_STATUS,
  ConversationMessagesDetail,
  emptyConversationMessagesDetail,
} from "./conversations.type"
import HeaderConversationDetail from "./molecules/HeaderConversationDetail"
import InfoBuildConversation from "./molecules/InfoBuildConversation"
import MenuTabConversationCard from "./molecules/MenuTabConversationCard"
import ComponentBuildConversationCard from "./organisms/ComponentBuildConversationCard"
import TabCoversationDetail from "./organisms/TabCoversationDetail"

const ConversationDetail = () => {
  const params = useParams<{
    idConversation: string
    idProjectBuild: string
  }>()
  const locationConversation = useLocation<any>()
  const idConversationParams = params?.idConversation || ""
  const idProjectBuildParams = params?.idProjectBuild || ""
  const [projectBuild, setProjectBuild] = useState<ProjectComponentBuildDetail>(
    emptyProjectBuildDetail
  )
  const { isMobile } = useWindowSize()
  const activeTab = useString(
    isMobile ? MENU_TAB_BUILD.COMPONENT : MENU_TAB_BUILD.CONVERSATION
  )
  const [conversationMessages, setConversationMessages] =
    useState<ConversationMessagesDetail>(emptyConversationMessagesDetail)
  const isLoading = useBoolean(false)
  const [additionals, setAdditionals] = useState<
    ProjectBuildAdditionalDetail[]
  >([])
  const userInfo = useAppSelector((state) => state.userInfo)

  const updatedAtBuild = useString("")
  const sizeWindow = useWindowSize()
  const [projectDetail, setProjectDetail] =
    useState<ProjectDetail>(defaultProjectDetail)
  const pageMessageHook = useNumber(1)
  const rowsMessagePerPageHook = useNumber(50)
  const isMoreDataMessage = useBoolean(true)
  const isLoadingMoreDataMessage = useBoolean(false)
  const isChangeScrollMessageBottom = useBoolean(true)
  const isNotificationMessage = useBoolean(false)
  const viewOnlyShare = useBoolean(false)
  const isDeleted = projectBuild.status === STATUS_BUILD.DELETED
  useEffect(() => {
    if (!idProjectBuildParams || !idConversationParams) {
      pushTo(PATH.conversations)
      return
    }
    if (!userInfo.id) {
      return
    }
    getProjectBuildDetail(idProjectBuildParams, idConversationParams)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProjectBuildParams, idConversationParams, userInfo])

  const getProjectBuildDetail = async (
    newIdBuild: string,
    newIdConversation: string
  ) => {
    try {
      isLoading.setValue(true)
      const dataRes = await getProjectBuildMiddleware(newIdBuild, {
        conversation_id: idConversationParams,
      })
      if (!dataRes) {
        return
      }
      const conversationInfo = await getConversationMessages(newIdConversation)
      await getAdditional(newIdBuild)
      if (conversationInfo.invitee.user_id !== userInfo.id) {
        /// Project data infomation
        const dataProjectDetailRes = await getProjectDetailMiddleware(
          dataRes.project_id,
          idConversationParams
        )
        const dataProjectRole = await getProjectRoleMiddleware(
          dataRes.project_id
        )
        viewOnlyShare.setValue(
          dataProjectRole.role === STATUS_PROJECT_ROLE.VIEWER
        )
        setProjectDetail(dataProjectDetailRes)
      }
      if (
        locationConversation.search &&
        locationConversation.search === `?tab=${MENU_TAB_BUILD.CONVERSATION}`
      ) {
        activeTab.setValue(MENU_TAB_BUILD.CONVERSATION)
      }
      setProjectBuild(dataRes)
      updatedAtBuild.setValue(dataRes.updated_at)
      isLoading.setValue(false)
    } catch (error) {
      isLoading.setValue(false)
      pushTo(PATH.conversations)
    }
  }
  const getConversationMessages = async (
    conversationId: string,
    loading = false
  ) => {
    const paramMessage = {
      page: 1,
      pageSize: rowsMessagePerPageHook.value,
    }

    const dataRes = await getConversationMessagesMiddleware(
      conversationId,
      paramMessage
    )
    await getNotificationConversation(conversationId)
    setConversationMessages({
      ...dataRes,
      messages: orderBy(dataRes.messages, "created_at", "asc"),
    })
    isMoreDataMessage.setValue(
      dataRes.messages.length < rowsMessagePerPageHook.value ? false : true
    )
    pageMessageHook.setValue(1)
    isChangeScrollMessageBottom.setValue(true)
    if (loading) {
      isLoading.setValue(false)
    }
    return dataRes
  }
  const getNotificationConversation = async (conversationId: string) => {
    const dataRes = await getNotificationConversationMiddleware(conversationId)
    isNotificationMessage.setValue(dataRes.is_notified)
  }
  const getAdditional = async (idBuild: string, loading = false) => {
    const dataAdditionalRes = await getProjectBuildAdditionalInfoMiddleware(
      idBuild,
      idConversationParams
    )
    setAdditionals(dataAdditionalRes)
    if (loading) {
      isLoading.setValue(false)
    }
  }

  const onChangeTab = (_newIndex: number, newLabel?: string) => () => {
    if (!newLabel) {
      return
    }
    if (activeTab.value === newLabel) {
      return
    }
    activeTab.setValue(newLabel)
    isLoading.setValue(true)
    if (newLabel === MENU_TAB_BUILD.CONVERSATION) {
      getConversationMessages(idConversationParams, true)
      return
    }
    getAdditional(projectBuild.id, true)
  }
  const updateWhenCreateAdditional = () => {
    isLoading.setValue(true)
    getAdditional(projectBuild.id, true)
    updatedAtBuild.setValue(new Date().toISOString())
  }
  const updateWhenDeleteAdditional = (oldAdditional: string) => {
    const newAdditionals = cloneDeep(additionals)
    remove(newAdditionals, (el) => el.id === oldAdditional)
    setAdditionals(newAdditionals)
    updatedAtBuild.setValue(new Date().toISOString())
  }
  const updateWhenAddMessager = (message: string) => {
    isLoading.setValue(true)
    getConversationMessages(idConversationParams, true)
    updatedAtBuild.setValue(new Date().toISOString())
  }
  const onScrollMessage = async () => {
    if (
      !isMoreDataMessage.value ||
      !conversationMessages.messages.length ||
      isLoadingMoreDataMessage.value
    ) {
      return
    }
    isChangeScrollMessageBottom.setValue(false)
    isLoadingMoreDataMessage.setValue(true)
    const paramScrolls = {
      page: pageMessageHook.value + 1,
      pageSize: rowsMessagePerPageHook.value,
    }
    const dataRes = await getConversationMessagesMiddleware(
      idConversationParams,
      paramScrolls
    )
    const newConversationMessage = cloneDeep(conversationMessages)
    newConversationMessage.messages = orderBy(
      dataRes.messages,
      "created_at",
      "asc"
    ).concat(conversationMessages.messages)
    setConversationMessages(newConversationMessage)
    pageMessageHook.setValue(pageMessageHook.value + 1)
    isMoreDataMessage.setValue(
      dataRes.messages.length < rowsMessagePerPageHook.value ? false : true
    )
    isLoadingMoreDataMessage.setValue(false)
  }
  const onChangeNotification = (originNotification: boolean) => () => {
    if (
      !idConversationParams ||
      BUILD_INVITEE_DETAIL_STATUS[
        conversationMessages.invitee.in_production_status
      ] === BUILD_INVITEE_DETAIL_STATUS[3] ||
      projectBuild.status === STATUS_BUILD.CANCELLED ||
      Boolean(projectBuild.is_archived) ||
      viewOnlyShare.value
    ) {
      return
    }
    if (isNotificationMessage.value) {
      umamiTracking(EVENT.CONVERSATION.DISABLE)
    } else {
      umamiTracking(EVENT.CONVERSATION.ENABLE)
    }
    isNotificationMessage.setValue(!originNotification)
    postNotificationConversationMiddleware(
      idConversationParams,
      isNotificationMessage.value ? "off" : "on",
      (type: STATUS_RESPONSE, messenger: string) => {
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        if (type === STATUS_RESPONSE.ERROR) {
          isNotificationMessage.setValue(originNotification)
        }
      }
    )
  }

  const renderComponent = () => {
    return (
      <ComponentBuildConversationCard
        components={projectBuild.components}
        idUserInvitee={conversationMessages.invitee.user_id}
        oldIdConversation={idConversationParams}
        gridCols={sizeWindow.width > 1395 ? `grid-cols-2` : "grid-cols-1"}
        isProject={
          locationConversation.state &&
          Boolean(locationConversation?.state?.breadcrumpProject)
            ? true
            : false
        }
        isDeleted={isDeleted}
        isActiveConversation={
          !(
            BUILD_INVITEE_DETAIL_STATUS[
              conversationMessages.invitee.in_production_status
            ] === BUILD_INVITEE_DETAIL_STATUS[3] ||
            projectBuild.status === STATUS_BUILD.CANCELLED ||
            Boolean(projectBuild.is_archived)
          )
        }
      />
    )
  }

  const renderTab = () => {
    if (activeTab.value === MENU_TAB_BUILD.COMPONENT) {
      return renderComponent()
    }

    if (activeTab.value === MENU_TAB_BUILD.CONVERSATION) {
      return (
        <TabCoversationDetail
          idConversation={idConversationParams}
          originMessages={conversationMessages.messages}
          isEditorInput={
            !(
              BUILD_INVITEE_DETAIL_STATUS[
                conversationMessages.invitee.in_production_status
              ] === BUILD_INVITEE_DETAIL_STATUS[3] ||
              projectBuild.status === STATUS_BUILD.CANCELLED ||
              Boolean(projectBuild.is_archived) ||
              viewOnlyShare.value
            )
          }
          updateWhenAddMessager={updateWhenAddMessager}
          inviteeUser={conversationMessages.invitee}
          onScrollMessage={onScrollMessage}
          isChangeScrollMessageBottom={isChangeScrollMessageBottom.value}
          isDeleted={isDeleted}
          notYetCommented={conversationMessages.is_not_yet_commented_by_users}
        />
      )
    }

    return (
      <AdditionalInfoCard
        isLabel={false}
        additionals={additionals}
        idProjectBuild={projectBuild.id}
        updateWhenCreateAdditional={updateWhenCreateAdditional}
        updateWhenDeleteAdditional={updateWhenDeleteAdditional}
        isDeleteComment={true}
        isEditorInput={true}
        isDeleted={isDeleted}
        conversationId={idConversationParams}
      />
    )
  }

  const renderSwitchButton = () =>
    !isDeleted && activeTab.value === MENU_TAB_BUILD.CONVERSATION ? (
      <SwitchButtonDefault
        containerClass="h-11"
        checked={isNotificationMessage.value}
        labelButton="Email notification"
        disabled={
          !idConversationParams ||
          BUILD_INVITEE_DETAIL_STATUS[
            conversationMessages.invitee.in_production_status
          ] === BUILD_INVITEE_DETAIL_STATUS[3] ||
          projectBuild.status === STATUS_BUILD.CANCELLED ||
          Boolean(projectBuild.is_archived) ||
          viewOnlyShare.value
        }
        onChange={onChangeNotification(isNotificationMessage.value)}
      />
    ) : null

  return (
    <PageLayout
      childrenHeader={
        <HeaderConversationDetail
          projectBuild={projectBuild}
          projectDetail={projectDetail}
          idConversationParams={idConversationParams}
          updatedAtBuild={updatedAtBuild.value}
          code={conversationMessages.conversation_code}
        />
      }
      heightHeader={48}
      minWidth="min-w-[878px]"
    >
      <PermissionProjectBuildPage.Provider
        value={{
          archiveProject: false,
          archiveBuild: Boolean(projectBuild.is_archived),
          viewOnlyShare: false,
          listStatus: [],
          status: projectBuild.status,
          tabMenu: activeTab.value,
          projectBuild: projectBuild,
        }}
      >
        <div className="h-full flex flex-col">
          <InfoBuildConversation
            projectBuild={projectBuild}
            conversationId={idConversationParams}
            conversationMessages={conversationMessages}
            viewOnly={isDeleted}
          />
          <div
            className="flex flex-1 md:grid w-full overflow-unset md:overflow-hidden"
            style={{
              borderTop: "1px solid #E4E4E4",
              gridTemplateColumns:
                sizeWindow.width > 1395
                  ? "684px calc(100% - 684px)"
                  : "358px calc(100% - 358px)",
            }}
          >
            <div className="hidden md:block pl-6 pt-6 pb-5 border-r border-[#E4E4E4] overflow-hidden">
              {renderComponent()}
            </div>

            <div className="flex flex-col flex-1 px-6 md:px-0 md:pl-6 pb-6 overflow-hidden md:bg-white">
              <div className="h-full flex flex-col">
                <div className="flex flex-col md:flex-row items-start md:items-center justify-between pr-0 md:pr-6">
                  <MenuTabConversationCard
                    onChangeTab={onChangeTab}
                    activeTab={activeTab.value}
                    additional={{
                      isRead: !projectBuild.is_read_additional_info
                        ? projectBuild.is_read_additional_info
                        : false,
                      count: additionals.length,
                    }}
                    conversation={{
                      count: conversationMessages.total_message,
                      isRead: false,
                    }}
                  />
                  <div className="hidden md:block">{renderSwitchButton()}</div>
                </div>

                <div
                  className={`w-full overflow-auto flex-1 ${
                    activeTab.value === MENU_TAB_BUILD.CONVERSATION
                      ? "build-info-form"
                      : ""
                  }`}
                >
                  <div className="block md:hidden mt-[-12px] mb-3">
                    {renderSwitchButton()}
                  </div>
                  {renderTab()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </PermissionProjectBuildPage.Provider>
    </PageLayout>
  )
}
export default ConversationDetail
