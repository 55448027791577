import { TippyCustomzie } from "components/TippyCustomzie"
import { useBoolean } from "helpers/hooks"
import { isUndefined } from "lodash"
import { useEffect } from "react"
import { ReactComponent as IconArrow } from "assets/images/icons/icon-arrow-down-select-tippy.svg"
import { ReactComponent as IconActiveSelect } from "assets/images/icons/icon-active-select.svg"
import { onActiveSelect } from "helpers/utils"

interface SelectTippyProps {
  defaultOpen?: boolean
  optionsSelect: any
  fieldSelect?: string
  fieldActive?: string
  activeSelect: any
  handleChange?: (newValue: any) => void
  placementCustom?:
    | "top-start"
    | "top-end"
    | "bottom-start"
    | "bottom-end"
    | "right-start"
    | "right-end"
    | "left-start"
    | "left-end"
  offsetCustom?: [number, number]
  styleSelectCard?: React.CSSProperties
}

const SelectTippy = (props: SelectTippyProps) => {
  const {
    defaultOpen,
    optionsSelect,
    fieldSelect = "label",
    fieldActive = "value",
    activeSelect,
    placementCustom = "bottom-start",
    offsetCustom = [-8, 15],
    styleSelectCard,
    handleChange,
  } = props
  const showSelect = useBoolean(false)

  useEffect(() => {
    if (isUndefined(defaultOpen)) {
      return
    }
    showSelect.setValue(defaultOpen)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOpen])

  const openSelect = () => {
    showSelect.setValue(!showSelect.value)
  }
  const onOptionChosen = (option) =>
    onActiveSelect(option, fieldActive, activeSelect)
  const onChange = (newOption: any) => () => {
    showSelect.setValue(!showSelect.value)
    if (isUndefined(handleChange)) {
      return
    }
    handleChange(newOption)
  }
  const valueShow = optionsSelect.find(
    (el: any) => el[fieldActive] === activeSelect
  )

  return (
    <TippyCustomzie
      containerClass="component-card-menu"
      placement={placementCustom}
      interactive
      arrow={false}
      animation="scale"
      visible={showSelect.value}
      onClickOutside={() => showSelect.setValue(false)}
      offset={offsetCustom}
      content={
        <div
          className="flex flex-col scrollbar-select"
          style={{
            background: "#222222",
            borderRadius: 4,
            paddingTop: 8,
            paddingBottom: 8,
            minWidth: 120,
            maxWidth: 200,
            maxHeight: 300,
            overflow: "auto",
            ...styleSelectCard,
          }}
        >
          {optionsSelect.map((option: any, index: number) => (
            <div
              key={index}
              className={`flex items-center justify-between px-3 cursor-pointer ${
                onOptionChosen(option) ? "" : "hover-text-select"
              }`}
              onClick={onChange(option)}
              style={{
                backgroundColor: onOptionChosen(option)
                  ? "#FDBE44"
                  : "transparent",
                paddingTop: 5,
                paddingBottom: 5,
              }}
            >
              <span
                className="font-nornal text-13 leading-22 mr-1"
                style={{
                  color: onOptionChosen(option) ? "#111111" : "#FFF",
                }}
              >
                {option[fieldSelect]}
              </span>
              {onOptionChosen(option) ? <IconActiveSelect /> : null}
            </div>
          ))}
        </div>
      }
      allowHTML
    >
      <div
        className="flex items-center cursor-pointer"
        onClick={openSelect}
        style={{
          maxWidth: 200,
        }}
      >
        <p
          className="font-nornal text-14 leading-24 mr-1 color-black-111111"
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {valueShow ? valueShow[fieldSelect] : ""}
        </p>
        <IconArrow />
      </div>
    </TippyCustomzie>
  )
}
export default SelectTippy
