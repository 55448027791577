import ItemWrapper from "./ItemWrapper"
import PieChart from "components/Charts/Pie"
import { UserOverview } from "../dashboard.type"
import { ceil } from "lodash"
import { onRedirectProjectUsage } from "helpers/redirect"

interface TotalSpaceUsageProps {
  data: UserOverview
}

const TotalSpaceUsage = (props: TotalSpaceUsageProps) => {
  const { limited_size, used_percentage, used_size } = props.data
  const progress = ceil(used_percentage)
  return (
    <ItemWrapper className="max-w-auto md:w-auto flex-1 md:min-w-[312px]">
      <div className="flex items-center justify-between mb-[31px]">
        <p className="text-sm font-semibold">Total space usage</p>
        {used_percentage > 0 ? (
          <p
            className="font-nornal text-12 leading-22 hover:underline cursor-pointer ml-2"
            style={{
              color: "#0A5AF5",
            }}
            onClick={onRedirectProjectUsage}
          >
            View Detail
          </p>
        ) : (
          <></>
        )}
      </div>
      <div className="text-center" title={`${used_size} / ${limited_size}`}>
        <PieChart progress={progress}>
          <p className="text-xs font-medium text-brown">{progress}%</p>
          <p className="text-sm font-semibold">{used_size}</p>
        </PieChart>
      </div>
    </ItemWrapper>
  )
}
export default TotalSpaceUsage
