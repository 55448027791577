import { TippyCustomzie } from "components/TippyCustomzie"
import { IUseDefaultValueProps, useBoolean } from "helpers/hooks"
import { isUndefined } from "lodash"
import { useEffect } from "react"
import { ReactComponent as IconArrow } from "assets/images/icons/icon-arrow-down-select-tippy.svg"
import CheckedDefault from "components/Checked/CheckedDefault"

interface SelectMutiTippyProps {
  defaultOpen?: boolean
  optionsSelect: any
  fieldSelect?: string
  fieldActive?: string
  activeSelect: any[]
  handleChange?: (newValue: any) => void
  placementCustom?:
    | "top-start"
    | "top-end"
    | "bottom-start"
    | "bottom-end"
    | "right-start"
    | "right-end"
    | "left-start"
    | "left-end"
  offsetCustom?: [number, number]
  styleSelectCard?: React.CSSProperties
  title: string
  setTippy: IUseDefaultValueProps
}

const SelectMutiTippy = (props: SelectMutiTippyProps) => {
  const {
    defaultOpen,
    optionsSelect,
    fieldSelect = "label",
    fieldActive = "value",
    activeSelect,
    placementCustom = "bottom-end",
    offsetCustom = [0, 10],
    styleSelectCard,
    handleChange,
    title,
    setTippy,
  } = props
  const showSelect = useBoolean(false)
  const changeSelect = useBoolean(false)
  useEffect(() => {
    if (isUndefined(defaultOpen)) {
      return
    }
    showSelect.setValue(defaultOpen)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOpen])

  const openSelect = () => {
    showSelect.setValue(!showSelect.value)
  }
  const onOptionChosen = (option) => {
    return activeSelect?.find(
      (item) => item[fieldActive] === option[fieldActive]
    )
  }
  const onChange = (newOption: any) => () => {
    changeSelect.setValue(!changeSelect.value)
    if (isUndefined(handleChange)) {
      return
    }
    if (onOptionChosen(newOption)) {
      handleChange(
        activeSelect.filter(
          (item) => item[fieldActive] !== newOption[fieldActive]
        )
      )
    } else {
      activeSelect.push(newOption)
      handleChange([...activeSelect])
    }
  }

  return (
    <TippyCustomzie
      containerClass="component-card-menu"
      placement={placementCustom}
      interactive
      arrow={false}
      animation="scale"
      visible={showSelect.value}
      onClickOutside={() => {
        showSelect.setValue(false)
        setTippy.setValue(false)
      }}
      offset={offsetCustom}
      content={
        <div
          className="flex flex-col scrollbar-select w-full"
          style={{
            background: "white",
            borderRadius: 4,
            paddingTop: 8,
            paddingBottom: 8,
            maxHeight: 300,
            overflow: "auto",
            ...styleSelectCard,
          }}
        >
          {optionsSelect.map((option: any, index: number) => (
            <div
              key={index}
              className={`flex items-center justify-between px-3 cursor-pointer hover:bg-[#E4E4E4]
              `}
              onClick={onChange(option)}
              style={{
                paddingTop: 5,
                paddingBottom: 5,
              }}
            >
              <p
                className="font-nornal text-13 leading-22 mr-1 overflow-hidden text-ellipsis"
                style={{
                  color: "#7A7A7A",
                }}
              >
                {option[fieldSelect]}
              </p>
              <CheckedDefault checked={onOptionChosen(option)} />
            </div>
          ))}
        </div>
      }
      allowHTML
    >
      <div
        className={`flex justify-between items-center py-[5px] pl-[12px] pr-[9px] gap-1 rounded-4 border-[1px]  ${
          setTippy.value ? "border-[#FDBE44]" : ""
        } cursor-pointer`}
        onClick={() => {
          openSelect()
          setTippy.setValue(!setTippy.value)
        }}
        style={{
          maxWidth: 184,
          boxShadow: setTippy.value
            ? "0px 0px 0px 4px rgba(247, 172, 27, 0.10)"
            : "",
        }}
      >
        <p
          className="text-xs font-body font-medium"
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: 140,
          }}
        >
          {title} {activeSelect.length === 0 ? " " : ": "}
          {activeSelect.map((item, index) => {
            if (index === activeSelect.length - 1) {
              return <span>{item.label}</span>
            } else return <span>{item.label},</span>
          })}
        </p>
        <IconArrow />
      </div>
    </TippyCustomzie>
  )
}
export default SelectMutiTippy
