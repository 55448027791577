/* eslint-disable react-hooks/exhaustive-deps */
import Button from "components/Button/Button"
import InputDefault from "components/Input/InputDefault"
import ModalCustom from "components/ModalCustom"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { SelectedDefaultProp } from "components/Select/types"
import { EVENT } from "constants/events"
import { useBoolean, useString } from "helpers/hooks"
import { umamiTracking } from "helpers/utils"
import FormInputCard from "pages/auth/molecules/FormInputCard"
import { useEffect } from "react"
import { toast } from "react-toastify"
import { STATUS_RESPONSE } from "types"
import { postProjectComponentMiddleware } from "../services/api"
import { CreateProjectComponentRequest, ProjectComponentDetail } from "../types"

interface Props {
  defaultValue: {
    name: ""
    type: ""
  }
  projectId: string
  onCloseModal: () => void
  cpnTypeOptions: SelectedDefaultProp[]
  onUpdateData: (
    newComponent: ProjectComponentDetail,
    folderId?: string
  ) => void
  folderId?: string
}
const FormNewComponent = (props: Props) => {
  const {
    cpnTypeOptions,
    defaultValue,
    projectId,
    onCloseModal,
    onUpdateData,
    folderId,
  } = props
  const name = useString()
  const isLoading = useBoolean()
  const idType = useString(cpnTypeOptions[0].value)
  useEffect(() => {
    name.setValue(defaultValue.name)
    if (defaultValue.type) {
      idType.setValue(defaultValue.type)
    }
  }, [defaultValue])
  const handleChangeInput = (event) => {
    name.setValue(event.target.value)
  }
  const onSubmit = () => {
    const dataRequest: CreateProjectComponentRequest = {
      name: name.value,
      type: Number(idType.value),
      project_id: projectId,
      group_id: folderId,
    }
    umamiTracking(EVENT.COMPONENT.CREATE)
    if (!projectId) {
      return
    }
    isLoading.setValue(true)
    postProjectComponentMiddleware(
      dataRequest,
      (
        type: STATUS_RESPONSE,
        messenger: string,
        dataRes?: ProjectComponentDetail
      ) => {
        isLoading.setValue(false)
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        if (type === STATUS_RESPONSE.SUCCESS && dataRes) {
          onUpdateData(dataRes, folderId)
        }
      }
    )
  }

  const handleDisableButton = () => {
    if (!name.value || !idType.value) {
      return true
    }
    return false
  }
  const handleChangeType = (newIdType) => () => {
    if (newIdType === idType.value) {
      return
    }
    idType.setValue(newIdType)
  }

  return (
    <ModalCustom
      label={`Create new Component`}
      handleChangeButton={() => onCloseModal}
      bodyChildren={
        <div className="flex flex-col">
          <FormInputCard
            title="Select a component type:"
            required
            fontWeightText="font-semibold"
          >
            <div
              className="grid grid-cols-3"
              style={{
                gridGap: 12,
              }}
            >
              {cpnTypeOptions.length
                ? cpnTypeOptions.map((type, index) => (
                    <div
                      onClick={handleChangeType(type.value)}
                      key={index}
                      className="h-12 flex items-center justify-center cursor-pointer"
                      style={{
                        background: "#F7F7F7",
                        border: `1px solid ${
                          idType.value === type.value ? "#FDBE44" : "#E4E4E4"
                        }`,
                        boxShadow:
                          idType.value === type.value
                            ? "0px 0px 0px 4px rgba(247, 172, 27, 0.1)"
                            : "none",
                        borderRadius: 4,
                      }}
                    >
                      <p
                        className={`font-semibold`}
                        style={{
                          fontSize: 14,
                          lineHeight: "24px",
                          color:
                            idType.value === type.value ? "#111111" : "#7A7A7A",
                        }}
                      >
                        {type.label}
                      </p>
                    </div>
                  ))
                : null}
            </div>
          </FormInputCard>

          <FormInputCard
            title="Component name"
            required
            fontWeightText="font-semibold"
          >
            <InputDefault value={name.value} onChange={handleChangeInput} />
          </FormInputCard>
          <div className="flex items-center justify-between">
            <Button title="Cancel" colorBtn="white" onClick={onCloseModal} />
            <Button
              title="Create"
              disabled={handleDisableButton()}
              onClick={onSubmit}
              isDisabledBtn
            />
          </div>
        </div>
      }
    />
  )
}
export default FormNewComponent
