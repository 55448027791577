import { createStore, combineReducers } from "redux"
import * as reducers from "./reducers"
import { UserInfo } from "pages/auth/types"
import { composeWithDevTools } from "redux-devtools-extension"
import {
  BuildConversationDetail,
  UnreadBuild,
  UnreadConversation,
} from "pages/conversations/conversations.type"
import { modalReducer, ModalState } from "../reducers/modal"
import { LoadingState, loadingReducer } from "../reducers/loading"

const rootReducer = combineReducers({
  ...reducers,
  modal: modalReducer,
  loading: loadingReducer,
})

export const configureStore = createStore(rootReducer, composeWithDevTools())

export interface RootState {
  userInfo: UserInfo
  unreadBuild: UnreadBuild
  unreadConversation: UnreadConversation
  conversationBuildInfo: BuildConversationDetail[]
  modal: ModalState
  loading: LoadingState
}
export type AppDispatch = typeof configureStore.dispatch
