import Tippy from "@tippyjs/react"
import { API_URL, REACT_APP_API_URL_IMAGE } from "config/environments"
import { getFirstLetterInName, showImageUrl } from "helpers/utils"
import { UserWorkingItem } from "../types"

interface Props {
  listUserWorkings: UserWorkingItem[]
}

const ListUserWorkingCard = (props: Props) => {
  const { listUserWorkings } = props

  return (
    <div className="flex items-center">
      {listUserWorkings.length
        ? listUserWorkings.map((el, index) => (
            <UserWorkingItemCard item={el} key={index} />
          ))
        : null}
    </div>
  )
}
export default ListUserWorkingCard

const UserWorkingItemCard = (props: { item: UserWorkingItem }) => {
  const { item } = props
  const URLAvatar =
    item.avatar && item.avatar.includes("public/")
      ? API_URL
      : REACT_APP_API_URL_IMAGE
  return (
    <Tippy
      className="custom-tippy-menu-left"
      placement="bottom"
      content={
        <div className="text-center">
          <p>{item.fullname ?? "N/A"}</p>
          <p>{item.email || "N/A"}</p>
        </div>
      }
    >
      <div
        className={`flex items-center justify-center w-[24px] h-[24px] bg-[#F7AC1B] rounded-full ${
          item.avatar ? "bg-no-repeat bg-center bg-cover" : ""
        } ml-2`}
        style={{
          border: "1.5px solid #65CF4A",
          boxShadow: "0px 0px 0px 2px rgba(101, 207, 74, 0.4)",
          backgroundImage: item.avatar
            ? `url(${showImageUrl(item.avatar, URLAvatar)})`
            : "inherit",
        }}
      >
        {!item.avatar ? (
          <p className="font-medium text-[11px] leading-[18px] text-white cursor-default	">
            {getFirstLetterInName(item.fullname || "N/A", true)}
          </p>
        ) : null}
      </div>
    </Tippy>
  )
}
