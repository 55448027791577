import "./index.css"
import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import reportWebVitals from "./reportWebVitals"
import { AppRouter } from "./routes"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { configureStore } from "stores/configureStore"
import IconCloseNotification from "assets/images/icons/icon-close-notification.svg"
import { ModalController } from "controllers/ModalController"
import BackdropCustomize from "components/BackdropCustomize"
import * as Sentry from "@sentry/react"
import { createBrowserHistory } from "history"
import Fallback from "components/Fallback"

const history = createBrowserHistory()

const traceTargetsEnv = process.env.REACT_APP_TRACE_PROPAGATION_TARGETS || ""
const traceTargets = traceTargetsEnv.split(",").map((target) => {
  if (target.startsWith("regex:")) {
    return new RegExp(target.replace("regex:", ""))
  }
  return target
})

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV5BrowserTracingIntegration({
      history: {
        location: history.location,
        createHref: history.createHref,
        push: history.push,
        replace: history.replace,
      },
    }),
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],

  release: process.env.REACT_APP_SENTRY_RELEASE,

  tracesSampleRate: 1.0,
  tracePropagationTargets: traceTargets,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

const CloseButton = ({ closeToast }) => (
  <img src={IconCloseNotification} alt="icon" onClick={closeToast} />
)
//
ReactDOM.render(
  <React.StrictMode>
    <Provider store={configureStore}>
      <Sentry.ErrorBoundary
        fallback={({ error, componentStack, resetError }) => (
          <Fallback
            error={error as Error}
            componentStack={componentStack}
            resetError={resetError}
          />
        )}
      >
        <AppRouter />
        <ToastContainer
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          bodyClassName="toastBody"
          pauseOnFocusLoss
          draggable
          pauseOnHover
          position="bottom-right"
          closeButton={CloseButton}
        />
        <ModalController />
        <BackdropCustomize />
      </Sentry.ErrorBoundary>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
