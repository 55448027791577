import { STATUS_BUILD } from "components/Status/types"
import { STATUS_CONVERSATION } from "pages/conversations/conversations.type"

export enum MENU_TAB_BUILD {
  MY_BUILDS = "My builds",
  OTHERS = "Others",
}
export const MENU_TAB_BUILD_KEY = {
  [MENU_TAB_BUILD.MY_BUILDS]: "my-builds",
  [MENU_TAB_BUILD.OTHERS]: "others",
}
export const BuildTab: string[] = [
  MENU_TAB_BUILD.MY_BUILDS,
  MENU_TAB_BUILD.OTHERS,
]
export interface ConversationHeaderInfo {
  id: string
  code: string
  project_build_id: string
  project_build_code: string
  project_code: string
  project_build_status: STATUS_BUILD
  cc: string[]
  status: STATUS_CONVERSATION
  message: {
    content: string
    created_at: string
    owner: string
  }
  created_at: string
}
