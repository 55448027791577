import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { reSendEmailMiddleware } from "./services/api"
import { STATUS_RESPONSE } from "types"
import { pushTo } from "helpers/history"
import { PATH } from "constants/path"
import { useBoolean, useNumber } from "helpers/hooks"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { toast } from "react-toastify"
import PageLayoutAuth from "./PageLayoutAuth"
import { ReactComponent as IconConfirmEmail } from "assets/images/icons/icon-confirm-email.svg"

const ConfirmEmail = () => {
  const location = useLocation<any>()
  const isLoading = useBoolean()
  const disableResend = useBoolean(false)
  const timeOutResend = useNumber(60)
  useEffect(() => {
    if (!location.state) {
      pushTo(PATH.login)
    }
  }, [location.state])

  const handleResend = () => {
    if (!location.state || disableResend.value) {
      return
    }
    const type = location.state?.type || ""
    const email = location.state?.email || ""
    isLoading.setValue(true)
    reSendEmailMiddleware(
      type,
      email,
      (typeStatus: STATUS_RESPONSE, messenger) => {
        isLoading.setValue(false)
        toast(
          <LabelNotificationPage
            messenger={
              typeStatus === STATUS_RESPONSE.SUCCESS
                ? "Resend Successfully!"
                : messenger ?? ""
            }
            type={typeStatus}
          />
        )
        disableResend.setValue(true)
        countTimeResend()
      }
    )
  }
  const countTimeResend = () => {
    let timeString = timeOutResend.value
    const interval = setInterval(() => {
      const currentTime = timeString - 1
      if (currentTime === 0) {
        timeOutResend.setValue(60)
        disableResend.setValue(false)
        clearInterval(interval)
        return
      }
      timeString = currentTime
      timeOutResend.setValue(timeString)
    }, 1000)
  }

  return (
    <PageLayoutAuth>
      <div className="h-full w-full justify-center items-center flex flex-col p-5">
        <IconConfirmEmail
          style={{
            height: 120,
          }}
        />
        <p
          className="font-bold my-6"
          style={{
            fontSize: 20,
            lineHeight: "32px",
            color: "#111111",
          }}
        >
          {location.state?.title || "Verify your email"}
        </p>
        <p
          className="font-normal"
          style={{
            fontSize: 14,
            lineHeight: "24px",
            color: "#111111",
          }}
        >
          {location.state?.label ||
            "We have sent you a confirmation link via email:"}
        </p>
        <p
          className="font-semibold pb-10"
          style={{
            fontSize: 14,
            lineHeight: "24px",
            color: "#111111",
          }}
        >
          {location.state?.email}
        </p>
        <p
          className="font-normal"
          style={{
            fontSize: 14,
            lineHeight: "24px",
            color: "#111111",
          }}
        >
          Didn’t receive an email?
          <span
            className="ml-1 cursor-pointer hover:underline"
            style={{
              color: "#0A5AF5",
            }}
            onClick={handleResend}
          >
            Resend
          </span>
          {disableResend.value ? (
            <span className="ml-1">{`in (${timeOutResend.value}s)`}</span>
          ) : null}
        </p>
      </div>
    </PageLayoutAuth>
  )
}
export default ConfirmEmail
