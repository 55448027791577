import Breadcrumbs from "components/Breadcrumbs"
import { BreadcrumbsDetail } from "components/Breadcrumbs/types"
import { HeaderBreadcrumbInfo } from "components/HeaderInfo/HeaderInfo"
import { PATH } from "constants/path"

const StorageUsageHeader = () => {
  const listBreadcrumbs: BreadcrumbsDetail[] = [
    {
      name: "Dashboard",
      path: PATH.dashboard,
    },
    {
      name: "Storage usage detail",
    },
  ]

  return (
    <HeaderBreadcrumbInfo>
      <Breadcrumbs
        listBreadcrumbs={listBreadcrumbs}
        className="hidden md:flex mr-1"
        overflow
      />
    </HeaderBreadcrumbInfo>
  )
}

export default StorageUsageHeader
