import { STATUS_BUILD } from "components/Status/types"
import { isUndefined } from "lodash"
import DotCard from "pages/conversations/atoms/DotCard"
import { BuildHistoryDetail } from "pages/project-component-detail/types"
import LabelStatusBuild from "pages/project-component/molecules/LabelStatusBuild"
import { VERSION_APPROVED_STATUS } from "types"
interface Props {
  history: BuildHistoryDetail
}
const StatusCard = (props: Props) => {
  const { history } = props
  return (
    <div className="flex items-center flex-nowrap">
      <DotCard />
      <LabelStatusBuild
        status={
          !isUndefined(history.from_build_component_id) &&
          history.status === STATUS_BUILD.MERGED
            ? STATUS_BUILD.COMMITTED
            : history.approved_status === VERSION_APPROVED_STATUS.UNAPPROVED
            ? STATUS_BUILD.UNAPPROVE
            : history.approved_status === VERSION_APPROVED_STATUS.PENDING &&
              history.status === STATUS_BUILD.COMMITTED
            ? STATUS_BUILD.SAVE
            : history.status
        }
        rootStyle={{
          fontSize: 13,
          lineHeight: "22px",
          whiteSpace: "nowrap",
        }}
      />
    </div>
  )
}
export default StatusCard
