import { STATUS_INPUT } from "components/Input/types"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
import { validationPassword } from "helpers/utils"
import { useState } from "react"
export const useChangeLabelStatusInputPasswordAndConfirm = () => {
  const [statusInputConfirm, setStatusInputConfirm] = useState({
    status: STATUS_INPUT.DEFAULT,
    label: "",
  })
  const [statusInputPassword, setStatusInputPassword] = useState({
    status: STATUS_INPUT.DEFAULT,
    label: "",
  })

  const getStatusInputConfirm = (newValue: string, oldValue: string) => {
    const newStatusConfirmed = {
      status: STATUS_INPUT.DEFAULT,
      label: "",
    }
    if (newValue) {
      newStatusConfirmed.status =
        newValue === oldValue ? STATUS_INPUT.VALID : STATUS_INPUT.ERROR
      newStatusConfirmed.label =
        newValue === oldValue
          ? MESSENGER_NOTIFICATION.PASSWORD_MATCH
          : MESSENGER_NOTIFICATION.PASSWORD_NOT_MATCH
    }
    setStatusInputConfirm(newStatusConfirmed)
  }
  const getStatusInputPassword = (newValue: string) => {
    const newStatusPassword = {
      status: STATUS_INPUT.DEFAULT,
      label: "",
    }
    if (newValue) {
      newStatusPassword.status = validationPassword(newValue)
        ? STATUS_INPUT.VALID
        : STATUS_INPUT.ERROR
      newStatusPassword.label = validationPassword(newValue)
        ? "Password is valid!"
        : MESSENGER_NOTIFICATION.PASSWORD_VALIDATE
    }

    setStatusInputPassword(newStatusPassword)
  }
  return {
    statusInputConfirm,
    statusInputPassword,
    setStatusInputConfirm,
    setStatusInputPassword,
    getStatusInputConfirm,
    getStatusInputPassword,
  }
}
