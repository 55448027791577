export const TIME_OUT_NOTIFICATION = 3000
export const WIDTH_BODY_WEB = 1437
export const SPACING_WEB_BODY = 32
export const SPACING_WEB_BODY_APP_BAR = 64

export enum PARAM_ORDER {
  ASC = "ASC",
  DESC = "DESC",
}

export const LIST_PRIVACY = ["FAQ", "Privacy Policy", "Term of Use"]

export const COMPONENT_TYPES_OPTIONS = [
  { label: "All Components", value: "0" },
  { label: "PCB", value: "1" },
  { label: "Mechanical", value: "2" },
  { label: "Software", value: "3" },
  { label: "Firmware", value: "4" },
  { label: "Miscellaneous", value: "5" },
]

export const ID_LAYERS = "3cc0d8b5-c9e5-45f8-b2d2-b0342258a07f"
export const ID_INNER_COPPER = "320008fe-2443-4ba5-8e40-0c0c37e2c06c"

export enum EmailList {
  support = "support@tracelium.com",
}

export enum TemplateLink {
  Bom = "https://tracelium-assets-prod.s3.ap-southeast-1.amazonaws.com/template/bom_template.xlsx",
}
