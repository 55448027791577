import Axios, { AxiosResponse } from "axios"
import {
  BuildHistoryDetail,
  ParamsBuildHistoryProps,
  CreateBuildHistoryRequest,
  CreatePCBRequest,
  PCBDetail,
  LayerAsignmentDetail,
  PutLayerAssignmentRequest,
  SpecificationHistoryRequest,
  PCBVersionSpecification,
  PostCommentBodyData,
  GetTabContentMiddlewareData,
  TabType,
  TypeSelectProp,
  BOMDetail,
  SelectCurrencyDetail,
  DataErrorWhenEditHistoryTree,
  TableColumnBOMDetail,
  UserWorkingItem,
  ParamUserWorkingItem,
  ComponentType,
  SortLayerAssignmentRequest,
} from "../types"
import { STATUS_RESPONSE } from "types"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
import { ProjectBuildDetail } from "pages/project-component/types"
import { GetCommentsData } from "components/comment/types"

export const getBuildHistoriesMiddleware = async (
  idProjectComponent: string,
  urlHistory: string
) => {
  const response: AxiosResponse<{
    data: BuildHistoryDetail[]
  }> = await Axios.get(
    `/api/project-${urlHistory}/${idProjectComponent}/histories`
  )
  return response.data.data
}
export const getProjectComponentHistoryMiddleware = async (
  idProjectComponent: string,
  idConversation?: string
) => {
  let url = `/api/project-component/history/${idProjectComponent}`
  if (idConversation) {
    url += `?conversation_id=${idConversation}`
  }

  const response: AxiosResponse<{
    data: BuildHistoryDetail
  }> = await Axios.get(url)
  return response.data.data
}

export const getBuildComponentHistoriesMiddleware = async (
  idProjectBuildComponent: string,
  params?: ParamsBuildHistoryProps
) => {
  const response: AxiosResponse<{
    data: BuildHistoryDetail[]
  }> = await Axios.get(
    `/api/project-build-component/${idProjectBuildComponent}/histories`,
    {
      params,
    }
  )
  return response.data.data
}

export const postBuildHistoryMiddleware = (
  request: CreateBuildHistoryRequest,
  callback: (
    type: STATUS_RESPONSE,
    messenger: string,
    dataRes?: BuildHistoryDetail
  ) => void
) => {
  Axios.post(`/api/project-component/history`, request)
    .then(
      (
        response: AxiosResponse<{
          data: BuildHistoryDetail
        }>
      ) => {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.POST_BUILD_HISTORY_SUCCESS,
          response.data.data
        )
      }
    )
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.POST_BUILD_HISTORY_ERROR
      )
    })
}

export const postPCBMiddleware = (
  projectComponentHistoryId: string,
  request: CreatePCBRequest,
  options: any,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.post(`/api/pcb/upload/${projectComponentHistoryId}`, request, {
    headers: {},
    ...options,
  })
    .then((_response: AxiosResponse) => {
      callback(STATUS_RESPONSE.SUCCESS, MESSENGER_NOTIFICATION.POST_PCB_SUCCESS)
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ?? MESSENGER_NOTIFICATION.POST_PCB_ERROR
      )
    })
}

export const addPcbFileMiddleware = (
  projectComponentHistoryId: string,
  request: FormData,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.post(`/api/pcb/upload/${projectComponentHistoryId}`, request)
    .then((_response: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.ADD_PCB_FILE_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.status === 413
          ? `The upload of file larger than 50MB is not allowed`
          : error.response?.data?.message ??
              MESSENGER_NOTIFICATION.ADD_PCB_FILE_ERROR
      )
    })
}
export const uploadBOMFileMiddleware = (
  projectComponentHistoryId: string,
  request: FormData,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.post(`/api/pcb/upload-bom/${projectComponentHistoryId}`, request)
    .then((_response: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.UPLOAD_BOM_FILE_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.UPLOAD_BOM_FILE_ERROR
      )
    })
}
export const uploadStackupFileMiddleware = (
  projectComponentHistoryId: string,
  request: FormData,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.post(`/api/pcb/upload-stackup/${projectComponentHistoryId}`, request)
    .then((_response: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.UPLOAD_STACKUP_FILE_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.UPLOAD_STACKUP_FILE_ERROR
      )
    })
}
export const uploadAssemblyFileMiddleware = (
  projectComponentHistoryId: string,
  request: FormData,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.post(`/api/pcb/upload-assembly/${projectComponentHistoryId}`, request)
    .then((_response: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.UPLOAD_ASSEMBLY_FILE_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.UPLOAD_ASSEMBLY_FILE_ERROR
      )
    })
}

export const getPCBMiddleware = async (
  idHistory: string,
  idConversation?: string
) => {
  let url = `/api/pcb/${idHistory}`
  if (idConversation) {
    url += `?conversation_id=${idConversation}`
  }
  const response: AxiosResponse<{
    data: PCBDetail
  }> = await Axios.get(url)
  return response.data.data
}

export const deletePcbFile = async (idHistory: string) => {
  const response: AxiosResponse<{
    data: string
  }> = await Axios.delete(`/api/pcb/${idHistory}`)
  return response.data.data
}

export const getPCBLayerAssignmentsMiddleware = async (
  idConversation?: string
) => {
  let url = `/api/pcb/layer-assignments`
  if (idConversation) {
    url += `?conversation_id=${idConversation}`
  }
  const response: AxiosResponse<{
    data: LayerAsignmentDetail[]
  }> = await Axios.get(url)
  return response.data.data
}

export const putLayerAssignmentMiddleware = (
  request: PutLayerAssignmentRequest,
  callback?: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.put(`/api/pcb/layer-assignments/update`, request)
    .then((_response: AxiosResponse) => {
      if (callback) {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.PUT_ASIGNMENT_PCB_SUCCESS
        )
      }
    })
    .catch((error) => {
      if (callback) {
        callback(
          STATUS_RESPONSE.ERROR,
          error.response?.data?.message ??
            MESSENGER_NOTIFICATION.PUT_ASIGNMENT_PCB_ERROR
        )
      }
    })
}

export const putInviteeLayerAssignmentMiddleware = (
  request: PutLayerAssignmentRequest,
  conversationId?: string,
  callback?: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.put(
    `/api/invitee/pcb/${conversationId}/layer-assignments/update`,
    request
  )
    .then((_response: AxiosResponse) => {
      if (callback) {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.PUT_ASIGNMENT_PCB_SUCCESS
        )
      }
    })
    .catch((error) => {
      if (callback) {
        callback(
          STATUS_RESPONSE.ERROR,
          error.response?.data?.message ??
            MESSENGER_NOTIFICATION.PUT_ASIGNMENT_PCB_ERROR
        )
      }
    })
}
export const putSortLayers = (
  request: SortLayerAssignmentRequest,
  callback?: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.put(`/api/pcb/layer-assignments/sort`, request)
    .then((_response: AxiosResponse) => {
      if (callback) {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.PUT_SORT_PCB_LAYER_SUCCESS
        )
      }
    })
    .catch((error) => {
      if (callback) {
        callback(
          STATUS_RESPONSE.ERROR,
          error.response?.data?.message ??
            MESSENGER_NOTIFICATION.PUT_SORT_PCB_LAYER_ERROR
        )
      }
    })
}

export const postDuplicateBuildHistoryMiddleware = (
  idHistory: string,
  callback: (
    type: STATUS_RESPONSE,
    messenger: string,
    dataRes?: BuildHistoryDetail
  ) => void
) => {
  Axios.post(`/api/project-component/history/${idHistory}/duplicate`)
    .then(
      (
        response: AxiosResponse<{
          data: BuildHistoryDetail
        }>
      ) => {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.POST_DUPLICATE_BUILD_HISTORY_SUCCESS,
          response.data.data
        )
      }
    )
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.POST_DUPLICATE_BUILD_HISTORY_ERROR
      )
    })
}

export const getSpecificationHistoryMiddleware = async (
  idVersion: string,
  params?: ParamsBuildHistoryProps
) => {
  const response: AxiosResponse<{
    data: PCBVersionSpecification
  }> = await Axios.get(`/api/specification/history/${idVersion}`, {
    params,
  })
  return response.data.data
}

export const postSpecificationHistoryMiddleware = (
  idVersion: string,
  dataRequest: SpecificationHistoryRequest[],
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  console.log("run in this")

  Axios.post(`/api/specification/history/${idVersion}/submit`, {
    data: dataRequest,
  })
    .then((_response: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.POST_SAVE_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ?? MESSENGER_NOTIFICATION.POST_SAVE_ERROR
      )
    })
}
export const postInviteeSpecificationHistoryMiddleware = (
  idVersion: string,
  dataRequest: SpecificationHistoryRequest[],
  callback: (type: STATUS_RESPONSE, messenger: string) => void,
  conversationId?: string
) => {
  Axios.post(
    `/api/invitee/specification/history/${idVersion}/${conversationId}/submit`,
    {
      data: dataRequest,
    }
  )
    .then((_response: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.POST_SAVE_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ?? MESSENGER_NOTIFICATION.POST_SAVE_ERROR
      )
    })
}

export const putSpecificationHistoryMiddleware = (
  idVersion: string,
  dataRequest: SpecificationHistoryRequest[],
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  console.log("run in this")

  Axios.put(`/api/specification/history/${idVersion}/update`, {
    data: dataRequest,
  })
    .then((_responseSpecification: AxiosResponse) => {
      callback(STATUS_RESPONSE.SUCCESS, "Save Specification successfully!")
    })
    .catch((errorPutSpecification) => {
      callback(
        STATUS_RESPONSE.ERROR,
        errorPutSpecification.response?.data?.message ??
          MESSENGER_NOTIFICATION.POST_SAVE_ERROR
      )
    })
}
export const putInviteeSpecificationHistoryMiddleware = (
  idVersion: string,
  dataRequest: SpecificationHistoryRequest[],
  callback: (type: STATUS_RESPONSE, messenger: string) => void,
  conversationId?: string
) => {
  Axios.put(
    `/api/invitee/specification/history/${idVersion}/${conversationId}/update`,
    {
      data: dataRequest,
    }
  )
    .then((_responseSpecification: AxiosResponse) => {
      callback(STATUS_RESPONSE.SUCCESS, "Save Specification successfully!")
    })
    .catch((errorPutSpecification) => {
      callback(
        STATUS_RESPONSE.ERROR,
        errorPutSpecification.response?.data?.message ??
          MESSENGER_NOTIFICATION.POST_SAVE_ERROR
      )
    })
}

// Comment
export const postComment = async (id: string, data: PostCommentBodyData) => {
  const response: AxiosResponse<{
    data: GetCommentsData
  }> = await Axios.post(`/api/project-component/${id}/comment`, data)
  return response.data.data
}

export const getComments = async (id: string) => {
  const response: AxiosResponse<{
    data: GetCommentsData[]
  }> = await Axios.get(`/api/project-component/${id}/comment`)
  return response.data.data
}

export const deleteComment = async (commentId: string) => {
  const response: AxiosResponse<{
    data: string
  }> = await Axios.delete(`/api/project-component/comment/${commentId}`)
  return response.data.data
}

export const resolveComment = async (commentId: string) => {
  const response: AxiosResponse<{
    data: GetCommentsData
  }> = await Axios.post(`/api/project-component/comment/${commentId}/resolve`)
  return response.data.data
}

// Other tabs
export const getTabContentMiddleware = async (
  historyId: string,
  type: TabType
) => {
  const response: AxiosResponse<{
    data: GetTabContentMiddlewareData
  }> = await Axios.get(`/api/${type}/${historyId}`)
  return response.data.data
}
// upload mechanical 3d file
export const upload3DFile = async (
  historyId: string,
  type: string, // TabType
  newFile: FormData
) => {
  const response = await Axios.post<{ data: GetTabContentMiddlewareData }>(
    `/api/${type}/${historyId}/upload-3d`,
    newFile
  )
  return response.data.data
}

export const delete3DFile = async (
  historyId: string,
  type: string // TabType
) => {
  return Axios.delete(`/api/${type}/${historyId}/delete-3d-file`)
}

export const getComponentSubBOMMiddleware = async (
  idHistory: string,
  type: string, // TabType
  idConversation?: string
) => {
  let url = `/api/${type}/${idHistory}/get-bom`
  if (idConversation) {
    url += `?conversation_id=${idConversation}`
  }

  const response: AxiosResponse<{
    data: BOMDetail
  }> = await Axios.get(url)
  return response.data.data
}

// upload mechanical sub bom file
export const uploadSubBomFile = async (
  historyId: string,
  type: string, // TabType
  newFile: FormData
) => {
  const response = await Axios.post<{ data: GetTabContentMiddlewareData }>(
    `/api/${type}/${historyId}/upload-bom`,
    newFile
  )
  return response.data.data
}

export const deleteSubBomFile = async (
  historyId: string,
  type: string // TabType
) => {
  return Axios.delete(`/api/${type}/${historyId}/delete-bom`)
}

export const postTabContentMiddleware = async (
  historyId: string,
  type: string,
  data: FormData
) => {
  return Axios.post<{ data: string }>(`/api/${type}/upload/${historyId}`, data)
}

// component types
export const getComponentTypesMiddleware = async () => {
  const response: AxiosResponse<{
    data: TypeSelectProp[]
  }> = await Axios.get(`/api/project-component/types`)
  return response.data.data
}

export const getSummaryComponentAndBuildMiddleware = async (
  idProject: string
) => {
  const response: AxiosResponse<{
    data: {
      components: number
      builds: number
    }
  }> = await Axios.get(`/api/project/${idProject}/summary`)
  return response.data.data
}

export const postNewVersionMiddleware = async (
  id: string,
  type: "project-build-component" | "project-component"
) => {
  const response: AxiosResponse<{
    data
  }> = await Axios.post(
    `/api/project${
      type === "project-build-component" ? "-build-" : "-"
    }component/${id}/history/create-and-copy`
  )
  return response.data.data
}

export const postMergeBuildCpnHistory = async (historyId: string) => {
  const response: AxiosResponse<{
    data
  }> = await Axios.post(
    `/api/project-build-component/history/${historyId}/merge`
  )
  return response.data.data
}

export const deleteFilePCBTemplateMiddleware = (
  idComponentHistory: string,
  url: string,
  callback?: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.delete(`/api/pcb/${idComponentHistory}/${url}`)
    .then((_response: AxiosResponse) => {
      if (callback) {
        callback(STATUS_RESPONSE.SUCCESS, "")
      }
    })
    .catch((error) => {
      if (callback) {
        callback(STATUS_RESPONSE.ERROR, error.response?.data?.message)
      }
    })
}

export const postComponentHistoryCommitMiddleware = (
  idComponent: string,

  data: {
    message: string
  },
  callback?: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.post(`/api/project-component/history/${idComponent}/commit`, data)
    .then((_response: AxiosResponse) => {
      if (callback) {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.COMPONENT_COMMIT_SUCCESS
        )
      }
    })
    .catch((error) => {
      if (callback) {
        callback(
          STATUS_RESPONSE.ERROR,
          error.response?.data?.statusCode === 404
            ? "The draft no longer exists."
            : MESSENGER_NOTIFICATION.COMPONENT_COMMIT_ERROR
        )
      }
    })
}

export const postInviteeComponentHistoryCommitMiddleware = (
  idComponent: string,
  conversationId: string,
  data: {
    message: string
  },
  callback?: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.post(
    `/api/project-build-component/history/${idComponent}/${conversationId}/commit`,
    data
  )
    .then((_response: AxiosResponse) => {
      if (callback) {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.COMPONENT_SAVE_SUCCESS
        )
      }
    })
    .catch((error) => {
      if (callback) {
        callback(
          STATUS_RESPONSE.ERROR,
          error.response?.data?.statusCode === 404
            ? "The draft no longer exists."
            : MESSENGER_NOTIFICATION.COMPONENT_SAVE_ERROR
        )
      }
    })
}

export const postComponentHistoryCreateAndCopyMiddleware = (
  idHistory: string,
  URLHistory: string,
  callback?: (
    type: STATUS_RESPONSE,
    messenger: string,
    dataRes?: DataErrorWhenEditHistoryTree
  ) => void
) => {
  Axios.post(`/api/project-${URLHistory}/${idHistory}/history/create-and-copy`)
    .then(() => {
      if (callback) {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.POST_BUILD_HISTORY_SUCCESS
        )
      }
    })
    .catch((error) => {
      if (callback) {
        callback(
          STATUS_RESPONSE.ERROR,
          error.response?.data?.message ??
            MESSENGER_NOTIFICATION.POST_BUILD_HISTORY_ERROR,
          error.response?.data
        )
      }
    })
}
export const postComponentHistoryRevertMiddleware = (
  idHistory: string,
  URLHistory: string,
  data: {
    message: string
  },
  callback?: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.post(
    `/api/project-${URLHistory}/history/${idHistory}/revert`,
    data.message ? data : { message: "" }
  )
    .then(() => {
      if (callback) {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.POST_REVERT_BUILD_HISTORY_SUCCESS
        )
      }
    })
    .catch((error) => {
      if (callback) {
        callback(
          STATUS_RESPONSE.ERROR,
          error.response?.data?.message ??
            MESSENGER_NOTIFICATION.POST_REVERT_BUILD_HISTORY_ERROR
        )
      }
    })
}
export const postComponentHistoryTranscendMiddleware = (
  idComponent: string,
  data: {
    message: string
  },
  callback?: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.post(
    `/api/project-component/history/${idComponent}/transcend`,
    data.message ? data : { message: "" }
  )
    .then(() => {
      if (callback) {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.POST_TRANSCEND_BUILD_HISTORY_SUCCESS
        )
      }
    })
    .catch((error) => {
      if (callback) {
        callback(
          STATUS_RESPONSE.ERROR,
          error.response?.data?.message ??
            MESSENGER_NOTIFICATION.POST_TRANSCEND_BUILD_HISTORY_ERROR
        )
      }
    })
}
export const deleteComponentOrVersionMiddleware = (
  idComponent: string,
  type: "component" | "version",
  callback?: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.delete(
    `/api/project-component/${idComponent}/delete-component-or-version`
  )
    .then(() => {
      if (callback) {
        callback(STATUS_RESPONSE.SUCCESS, `Delete ${type} successfully!`)
      }
    })
    .catch((error) => {
      if (callback) {
        callback(
          STATUS_RESPONSE.ERROR,
          error.response?.data?.message ?? `Delete ${type} failed!`
        )
      }
    })
}

export const getComponentCommentMiddleware = async (urlComponent: string) => {
  const response: AxiosResponse<{
    data: GetCommentsData[]
  }> = await Axios.get(`/api/${urlComponent}`)
  return response.data.data
}
export const postComponentCommentMiddleware = (
  urlComment: string,
  data: {
    project_component_history_id: string
    content: string
    conversation_id?: string
    is_public_to_conversation?: boolean
  },
  callback: (
    type: STATUS_RESPONSE,
    messenger: string,
    data?: GetCommentsData
  ) => void
) => {
  Axios.post(urlComment, data)
    .then(
      (
        responseComment: AxiosResponse<{
          data: GetCommentsData
        }>
      ) => {
        if (callback) {
          callback(STATUS_RESPONSE.SUCCESS, "", responseComment.data.data)
        }
      }
    )
    .catch((error) => {
      if (callback) {
        callback(
          STATUS_RESPONSE.ERROR,
          error.response?.data?.message ?? "Comment failed!"
        )
      }
    })
}
export const postComponentCommentResolveMiddleware = (
  urlComment: string,

  callback: (
    type: STATUS_RESPONSE,
    messenger: string,
    data?: GetCommentsData
  ) => void
) => {
  Axios.post(urlComment)
    .then(
      (
        response: AxiosResponse<{
          data: GetCommentsData
        }>
      ) => {
        if (callback) {
          callback(STATUS_RESPONSE.SUCCESS, "", response.data.data)
        }
      }
    )
    .catch((error) => {
      if (callback) {
        callback(
          STATUS_RESPONSE.ERROR,
          error.response?.data?.message ?? "Resolve failed!"
        )
      }
    })
}
export const getProjectComponentOtherMiddleware = async (
  idHistory: string,
  type: string,
  idConversation?: string
) => {
  let url = `/api/${type}/${idHistory}`
  if (idConversation) {
    url += `?conversation_id=${idConversation}`
  }

  const response: AxiosResponse<{
    data: GetTabContentMiddlewareData
  }> = await Axios.get(url)
  return response.data.data
}
export const getProjectComponentBOMMiddleware = async (idHistory: string) => {
  const response: AxiosResponse<{
    data: BOMDetail
  }> = await Axios.get(`/api/bom/${idHistory}`)
  return response.data.data
}
export const getPCBSubBOMMiddleware = async (
  idHistory: string,
  idConversation?: string
) => {
  let url = `/api/pcb/get-bom/${idHistory}`
  if (idConversation) {
    url += `?conversation_id=${idConversation}`
  }

  const response: AxiosResponse<{
    data: BOMDetail
  }> = await Axios.get(url)
  return response.data.data
}
export const getProjectComponentConversationBOMMiddleware = async (
  idHistory: string,
  idConversation: string
) => {
  const response: AxiosResponse<{
    data: BOMDetail
  }> = await Axios.get(`/api/bom/${idHistory}/${idConversation}`)
  return response.data.data
}
export const getHistoriesBuildComponentConvoMiddleware = async (
  idProjectBuildComponent: string,
  idConversation: string
) => {
  const response: AxiosResponse<{
    data: BuildHistoryDetail[]
  }> = await Axios.get(
    `/api/project-build-component/${idProjectBuildComponent}/histories/conversation`,
    {
      params: {
        conversation_id: idConversation,
      },
    }
  )
  return response.data.data
}

export const postBOMUpdateFileMiddleware = (
  idHistory: string,
  request: FormData,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.post(`/api/bom/upload/${idHistory}`, request, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
    .then((_responsePOSTBOMUpdateFile: AxiosResponse) => {
      callback(STATUS_RESPONSE.SUCCESS, "Upload file BOM successfully!")
    })
    .catch((errorBOMUpdateFile) => {
      callback(
        STATUS_RESPONSE.ERROR,
        errorBOMUpdateFile.response?.data?.message ??
          MESSENGER_NOTIFICATION.UPLOAD_BOM_FILE_ERROR
      )
    })
}

export const deleteFileBOMMiddleware = (
  idHistory: string,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.delete(`/api/bom/${idHistory}/delete-file`)
    .then((_responseDeleteFileBOM: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.DELETE_BOM_FILE_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.DELETE_BOM_FILE_ERROR
      )
    })
}

export const deleteFileOtherMiddleware = (
  idFile: string,
  type: string,
  callback: (type: STATUS_RESPONSE, messenger: string) => void,
  conversationId?: string
) => {
  if (conversationId) {
    return Axios.delete(
      `/api/invitee/${type}/${conversationId}/delete-file/${idFile}`
    )
      .then((_responseDeleteFileOther: AxiosResponse) => {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.DELETE_FILE_OTHER_SUCCESS
        )
      })
      .catch((error) => {
        callback(
          STATUS_RESPONSE.ERROR,
          error.response?.data?.message ??
            MESSENGER_NOTIFICATION.DELETE_FILE_OTHER_ERROR
        )
      })
  }
  Axios.delete(`/api/${type}/delete-file/${idFile}`)
    .then((_responseDeleteFileOther: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.DELETE_FILE_OTHER_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.DELETE_FILE_OTHER_ERROR
      )
    })
}

export const postBOMAddSupplierMiddleware = async (
  idHistory: string,
  type: ComponentType = ComponentType.BOM
) => {
  return Axios.post(`/api/${type}/${idHistory}/add-supplier`)
}
export const postBOMAddColumnMiddleware = (
  idHistory: string,
  dataRequest: TableColumnBOMDetail[],
  callback: (type: STATUS_RESPONSE, messenger: string) => void,
  type: ComponentType = ComponentType.BOM
) => {
  Axios.post(`/api/${type}/${idHistory}/add-column`, {
    data: dataRequest,
  })
    .then(() => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.ADD_COLUMN_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ?? MESSENGER_NOTIFICATION.ADD_COLUMN_ERROR
      )
    })
}

export const deletBOMRemoveColumnMiddleware = (
  idHistory: string,
  dataRequest: {
    key: string
  },
  callback: (type: STATUS_RESPONSE, messenger: string) => void,
  type: ComponentType = ComponentType.BOM
) => {
  Axios.delete(`/api/${type}/${idHistory}/remove-column`, {
    data: dataRequest,
  })
    .then(() => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        `Delete column ${dataRequest.key.replace(
          "_addColumn",
          ""
        )} successfully!`
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          `Delete column ${dataRequest.key.replace("_addColumn", "")} failed!`
      )
    })
}

export const getBOMExportMiddleware = async (idHistory: string) => {
  const response: AxiosResponse<{
    data: string
  }> = await Axios.get(`/api/bom/${idHistory}/export`)
  return response.data.data
}

export const postBOMSyncDataMiddleware = (
  idHistory: string,
  callback: (type: STATUS_RESPONSE, messenger: string) => void,
  type: ComponentType = ComponentType.BOM
) => {
  Axios.post(`/api/${type}/${idHistory}/add-supplier`)
    .then(() => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.SYNC_DATA_BOM_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.SYNC_DATA_BOM_ERROR
      )
    })
}
export const putBOMUpdateCellMiddleware = (
  idHistory: string,
  dataRequest: {
    index: number
    key: string
    value: string
  },
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.put(`/api/bom/${idHistory}/update-cell`, dataRequest)
    .then(() => {
      callback(STATUS_RESPONSE.SUCCESS, "")
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.UPDATE_BOM_COLUMN_ERROR
      )
    })
}
export const putBOMUpdateColumnMiddleware = (
  idHistory: string,
  dataRequest: {
    key: string
    new_key: string
  },
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.put(`/api/bom/${idHistory}/update-column`, dataRequest)
    .then(() => {
      callback(STATUS_RESPONSE.SUCCESS, "")
    })
    .catch((errorUpdateColumn) => {
      callback(
        STATUS_RESPONSE.ERROR,
        errorUpdateColumn.response?.data?.message ??
          MESSENGER_NOTIFICATION.UPDATE_BOM_COLUMN_ERROR
      )
    })
}

export const getProjectBuildListLinkedBuildMiddleware = async (
  idComponent: string,
  params?: ParamsBuildHistoryProps
) => {
  const response: AxiosResponse<{
    data: ProjectBuildDetail[]
  }> = await Axios.get(
    `api/project-build/get-list-linked-build/${idComponent}`,
    {
      params,
    }
  )
  return response.data.data
}

export const postBuildComponentMergeMiddleware = (
  idHistory: string,
  data: {
    message: string
  },
  callback?: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.post(
    `/api/project-build-component/history/${idHistory}/merge`,
    data.message ? data : null
  )
    .then(() => {
      if (callback) {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.POST_MERGE_BUILD_HISTORY_SUCCESS
        )
      }
    })
    .catch((error) => {
      if (callback) {
        callback(
          STATUS_RESPONSE.ERROR,
          error.response?.data?.message ??
            MESSENGER_NOTIFICATION.POST_MERGE_BUILD_HISTORY_ERROR
        )
      }
    })
}

export const getBOMCurrenciesMiddleware = async (listCurrencies: string[]) => {
  const response: AxiosResponse<{
    data: SelectCurrencyDetail[]
  }> = await Axios.get(`/api/bom/currencies`, {
    params: {
      filter: {
        symbols: listCurrencies,
      },
    },
  })
  return response.data.data
}

export const postInviteeBOMComponentSyncMiddleware = (
  idProjectComponentHistory: string,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.post(`/api/bom/${idProjectComponentHistory}/sync`)
    .then(() => {
      callback(STATUS_RESPONSE.SUCCESS, "Sync BOM detail table successfully!")
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ?? "Sync BOM detail table failed!"
      )
    })
}

export const getPCBBOMMiddleware = async (idHistory: string) => {
  const response: AxiosResponse<{
    data: any
  }> = await Axios.get(`/api/pcb/get-bom/${idHistory}`)
  return response.data.data
}

export const getIsReadBuildComponentMiddleware = async (
  idHistory: string,
  idConversation?: string
) => {
  let url = `/api/project-build-component/${idHistory}/get-is-read`
  if (idConversation) {
    url += `?conversation_id=${idConversation}`
  }
  const response: AxiosResponse<{
    data: {
      is_read_invitee_comment: boolean
      is_read_comment: boolean
    }
  }> = await Axios.get(url)
  return response.data.data
}

export const getListUserWorkingMiddleware = (
  requestParams: ParamUserWorkingItem,
  callback: (dataRes: UserWorkingItem[]) => void
) => {
  Axios.post(`/api/user/current-active`, requestParams)
    .then(
      (
        response: AxiosResponse<{
          data: UserWorkingItem[]
        }>
      ) => {
        callback(response.data.data)
      }
    )
    .catch((_error) => {
      callback([])
    })
}

export const uploadAttachmentsMiddleware = (
  historyId: string,
  data: FormData,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.post(`/api/pcb/upload-attachments/${historyId}`, data)
    .then((_response: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.UPLOAD_ATTACHMENT_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.UPLOAD_ATTACHMENT_ERROR
      )
    })
}
