import ItemWrapper from "./ItemWrapper"
import { UserOverview } from "../dashboard.type"

interface UserPlanProps {
  data: UserOverview
}

const UserPlan = (props: UserPlanProps) => {
  const {
    tier,
    limited_size,
    live_project_count,
    project_limit,
    component_per_project_limit,
  } = props.data
  return (
    <ItemWrapper className="max-w-auto min-w-[312px]">
      <p className="text-sm font-semibold">Your current plan</p>
      <p className="text-base mb-[24px] font-semibold">{tier}</p>
      <p className="text-xs font-medium">Storage limit</p>
      <p className="text-sm mb-[12px] font-semibold">{limited_size}</p>
      <p className="text-xs font-medium">Projects used</p>
      <p className="text-sm font-semibold mb-[12px]">
        {live_project_count}/{project_limit} project
        {project_limit > 1 ? "s" : ""}
      </p>
      <p className="text-xs font-medium">Component per project limit</p>
      <p className="text-sm font-semibold">
        {component_per_project_limit} component
        {component_per_project_limit > 1 ? "s" : ""}
      </p>
    </ItemWrapper>
  )
}
export default UserPlan
