import React, { useEffect, useState } from "react"
import PageLayoutAuth from "./PageLayoutAuth"
import InputDefault from "components/Input/InputDefault"
import FormInputCard from "./molecules/FormInputCard"
import Header from "./atoms/Header"
import { PATH } from "constants/path"
import { InviteSentSignUpRequest } from "./types"
import { isValidEmail, validationPassword } from "helpers/utils"
import { useBoolean, useString } from "helpers/hooks"
import { cloneDeep } from "lodash"
import { inviteSentigSnUpMiddleware } from "./services/api"
import { STATUS_RESPONSE } from "types"
import { pushTo } from "helpers/history"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { toast } from "react-toastify"
import { useLocation } from "react-router-dom"
import queryString from "query-string"
import { useChangeLabelStatusInputPasswordAndConfirm } from "./auth.hook"
import FormInputSignUpCard from "./molecules/FormInputSignUpCard"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { ACTION_RECAPTCHA } from "constants/actionReCaptcha"
const InviteSentSignUp = () => {
  const location = useLocation()
  const [request, setRequest] = useState<{
    confirmedPassword: string
    password: string
    fullname: string
  }>({
    confirmedPassword: "",
    password: "",
    fullname: "",
  })
  const checkbox = useBoolean()
  const isLoading = useBoolean()
  const emailSignUp = useString("example@yopmail.com")
  const verificationToken = useString()
  const {
    statusInputConfirm,
    statusInputPassword,
    getStatusInputConfirm,
    getStatusInputPassword,
  } = useChangeLabelStatusInputPasswordAndConfirm()
  const { executeRecaptcha } = useGoogleReCaptcha()

  useEffect(() => {
    const parsed = queryString.parse(location.search)
    emailSignUp.setValue(parsed?.email)
    verificationToken.setValue(parsed?.token)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const handleChangeInput =
    (key: "fullname" | "confirmedPassword" | "password") => (event) => {
      setRequest({
        ...request,
        [key]: event.target.value,
      })

      if (key === "password") {
        if (request.confirmedPassword) {
          getStatusInputConfirm(event.target.value, request.confirmedPassword)
        }
        getStatusInputPassword(event.target.value)
      }
      if (key === "confirmedPassword") {
        getStatusInputConfirm(event.target.value, request.password)
      }
    }

  const handleDisableButton = () => {
    const newRequest = cloneDeep(request)
    let isDisable = false
    Object.values(newRequest).forEach((el, index) => {
      if (!el) {
        isDisable = true
      } else {
        switch (Object.keys(newRequest)[index]) {
          case "email":
            isDisable = isValidEmail(el)
            break
          case "password":
            isDisable = !validationPassword(el)
            break
        }
      }
    })
    if (request.password !== request.confirmedPassword) {
      return true
    }
    return isDisable
  }

  const onClickButton = async () => {
    if (!checkbox.value) {
      toast(
        <LabelNotificationPage
          messenger={MESSENGER_NOTIFICATION.CHECKBOX_SIGN_UP}
          type="error"
        />
      )
      return
    }

    if (!executeRecaptcha) {
      toast(
        <LabelNotificationPage
          messenger={MESSENGER_NOTIFICATION.RECAPTCHA_AVAILABLE}
          type="error"
        />
      )
      return
    }

    isLoading.setValue(true)
    try {
      const token = await executeRecaptcha(ACTION_RECAPTCHA.SIGN_UP)
      const dataRequest: InviteSentSignUpRequest = {
        ...request,
        email: emailSignUp.value,
        captcha: token,
      }
      inviteSentigSnUpMiddleware(
        verificationToken.value,
        dataRequest,
        (typeSignUp: STATUS_RESPONSE, messenger) => {
          isLoading.setValue(false)
          if (typeSignUp === STATUS_RESPONSE.SUCCESS) {
            if (!isValidEmail(emailSignUp.value)) {
              toast(
                <LabelNotificationPage
                  messenger="Email incorrect format!"
                  type="error"
                />
              )
              return
            }
            return pushTo(PATH.signingUpMessage)
          }

          toast(
            <LabelNotificationPage messenger={messenger ?? ""} type="error" />
          )
        }
      )
    } catch (error) {
      isLoading.setValue(false)
    }
  }

  const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      event.preventDefault()
      if (handleDisableButton()) {
        return
      }
      onClickButton()
    }
  }

  return (
    <PageLayoutAuth>
      <div className="h-full w-full flex flex-col p-6">
        <Header title="Sign up to Tracelium" />
        <div className="flex flex-col mt-6">
          <p
            className="font-normal"
            style={{
              fontSize: 14,
              lineHeight: "24px",
              color: "#111111",
            }}
          >
            You are signing up an account with the email:
          </p>
          <p
            className="font-semibold"
            style={{
              fontSize: 14,
              lineHeight: "24px",
              color: "#111111",
            }}
          >
            {emailSignUp.value}
          </p>
        </div>

        <div className="mt-6">
          <FormInputCard title="Full Name" required>
            <InputDefault
              value={request.fullname}
              onChange={handleChangeInput("fullname")}
              onKeyPress={onKeyPress}
            />
          </FormInputCard>
          <FormInputCard
            title="Password"
            required
            isTippy
            label={MESSENGER_NOTIFICATION.PASSWORD_VALIDATE}
          >
            <InputDefault
              type="password"
              isPassword
              value={request.password}
              onChange={handleChangeInput("password")}
              onKeyPress={onKeyPress}
              status={statusInputPassword.status}
              labelStatus={statusInputPassword.label}
            />
          </FormInputCard>
          <FormInputCard title="Confirm password" required>
            <InputDefault
              type="password"
              isPassword
              value={request.confirmedPassword}
              onChange={handleChangeInput("confirmedPassword")}
              onKeyPress={onKeyPress}
              status={statusInputConfirm.status}
              labelStatus={statusInputConfirm.label}
            />
          </FormInputCard>
        </div>
        <FormInputSignUpCard
          checkbox={checkbox}
          onClickButton={onClickButton}
          handleDisableButton={handleDisableButton()}
        />
      </div>
    </PageLayoutAuth>
  )
}
export default InviteSentSignUp
