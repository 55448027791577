import Button from "components/Button/Button"
import ModalCustom from "components/ModalCustom"
import { ReleaseNoteProps } from "./types/index"
import UpdateLog from "assets/images/update-log.png"
import "./releaseNotes.css"
import { RELEASE_NOTE_URL } from "config/environments"
import { formatDate } from "helpers/utils"

const ReleaseNotes = (props: ReleaseNoteProps) => {
  const { noteInfo, onCloseNotes } = props
  return (
    <ModalCustom
      label={`Update of ${formatDate(noteInfo.created_at, "MMM yyyy")}`}
      widthModal={400}
      handleChangeButton={() => onCloseNotes}
      bodyChildren={
        <div className="w-full max-h-[80vh] flex flex-col items-center justify-center gap-8">
          <img src={UpdateLog} alt="icon" />
          <div
            className=" w-full h-full font-body font-normal text-black leading-6 release-notes overflow-auto"
            dangerouslySetInnerHTML={{
              __html: noteInfo.notes,
            }}
          ></div>
          <div
            className="flex items-center justify-center "
            onClick={() => {
              onCloseNotes()
              window.open(RELEASE_NOTE_URL)
            }}
          >
            <Button
              widthBtn={"360px"}
              title="View all"
              colorBtn="yellow"
            ></Button>
          </div>
        </div>
      }
    ></ModalCustom>
  )
}
export default ReleaseNotes
