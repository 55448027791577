import Button from "components/Button/Button"
import CheckedDefault from "components/Checked/CheckedDefault"
import { PATH } from "constants/path"
import React from "react"
import { Link } from "react-router-dom"
interface Props {
  checkbox: {
    value: boolean
    setValue: (newCheckbox: boolean) => void
  }
  onClickButton: () => void
  handleDisableButton: boolean
}
const FormInputSignUpCard = (props: Props) => {
  const { checkbox, onClickButton, handleDisableButton } = props

  return (
    <React.Fragment>
      <div className="mt-3 mb-6 flex items-start">
        <CheckedDefault
          checked={checkbox.value}
          onClick={() => checkbox.setValue(!checkbox.value)}
        />
        <p
          className="ml-1 font-normal"
          style={{
            fontSize: 14,
            lineHeight: "24px",
            color: "#111111",
          }}
        >
          By clicking on this button, I agree with Tracelium's{" "}
          <Link
            className="cursor-pointer hover:underline"
            style={{
              color: "#0A5AF5",
            }}
            to={{
              pathname: PATH.termsAndServices,
            }}
            target="_blank"
          >
            Terms and Conditions
          </Link>{" "}
          and{" "}
          <Link
            className="cursor-pointer hover:underline"
            style={{
              color: "#0A5AF5",
            }}
            to={{
              pathname: PATH.policies,
            }}
            target="_blank"
          >
            Privacy Policy
          </Link>
          {"."}
        </p>
      </div>
      <Button
        id="sign-up"
        title="Create account"
        onClick={onClickButton}
        disabled={handleDisableButton}
        widthBtn="100%"
        isDisabledBtn
      />
    </React.Fragment>
  )
}
export default FormInputSignUpCard
