import { STATUS_BUILD } from "components/Status/types"
import { IUseDefaultValueProps, useBoolean } from "helpers/hooks"
import {
  ConversationDetail,
  CustomStatus,
  GROUP_BY_OPTIONS_KEY,
  MENU_TAB_CONVERSATION,
} from "../conversations.type"
import ConversationCard, {
  renderCustomStatusColor,
} from "../molecules/ConversationCard"
import { ReactComponent as SelectIcon } from "assets/images/icons/icon-arrow-down-select-tippy.svg"
import LabelStatusBuild from "pages/project-component/molecules/LabelStatusBuild"
import { useContext, useEffect, useRef, useState } from "react"
import { ConversationsPageContext } from "../contexts/ConversationsPage.context"
interface Props {
  listConversation: ConversationDetail[]
  updateDataWhenChangeFavorite: (dataRes: ConversationDetail) => void
  activeMenu: MENU_TAB_CONVERSATION
  onClickConversation: (dataRes: ConversationDetail) => () => void
  activeConversationId: string
  updateDataWhenChangeCustomStatus: (
    type: "edit" | "delete",
    customStatus: CustomStatus
  ) => void
  updateDataWhenChangeConvoCustomStatus: (
    buildId: string,
    customStatus?: CustomStatus
  ) => void
  updateDataWhenArchive: (dataRes: ConversationDetail) => void
  showLayout: IUseDefaultValueProps
  openNotes: IUseDefaultValueProps
  groupOtion: string
}
const ConversationColumn = (props: Props) => {
  const {
    listConversation,
    activeMenu,
    activeConversationId,
    updateDataWhenChangeFavorite,
    onClickConversation,
    showLayout,
    updateDataWhenChangeCustomStatus,
    updateDataWhenChangeConvoCustomStatus,
    updateDataWhenArchive,
    openNotes,
    groupOtion,
  } = props
  const { customStatuses } = useContext(ConversationsPageContext)
  const containerRef = useRef<any>()
  const isLoading = useBoolean()
  useEffect(() => {
    if (!activeConversationId || isLoading.value) return
    srollInToView()
  }, [activeConversationId])
  const srollInToView = () => {
    const index = listConversation.findIndex(
      (item) => item.id === activeConversationId
    )
    if (index === -1) return
    const itemRef = containerRef.current?.children[index]
    if (itemRef) {
      containerRef.current.scrollTop = itemRef.offsetTop - 131
    }
    isLoading.setValue(true)
  }
  const [collapseBuilStatus, setCollapseBuilStatus] = useState({
    [STATUS_BUILD.COMPLETED]: false,
    [STATUS_BUILD.RFQ]: false,
    [STATUS_BUILD.CANCELLED]: false,
    [STATUS_BUILD.TEST_SETUP]: false,
    [STATUS_BUILD.IN_PRODUCTION]: false,
    [STATUS_BUILD.IN_PROGRESS]: false,
    [STATUS_BUILD.COMMITTED]: false,
  })
  const mappingCustomStatus = customStatuses.reduce((result, status) => {
    const id = status.id
    result[id] = status
    return result
  }, {})

  const [collapseCustomStatus, setCollapseCustomStatus] = useState(
    customStatuses.reduce(
      (acc, status) => {
        acc[status.id] = false
        return acc
      },
      { none: false }
    )
  )
  const renderGroupByStatus = () => {
    const groups = listConversation.reduce((acc, obj) => {
      const category =
        groupOtion === "build_status"
          ? obj.project_build_status
          : obj.custom_status?.id || "none"
      if (!acc[category]) {
        acc[category] = []
      }
      acc[category].push(obj)
      return acc
    }, {})

    return (
      <div className="pr-6 my-1">
        {Object.keys(groups).map((status, key) => {
          return (
            <div key={key}>
              <div className="flex items-center mb-[7px]">
                <div
                  className="flex p-[6px] hover-action-icon cursor-pointer"
                  onClick={() =>
                    groupOtion === "build_status"
                      ? setCollapseBuilStatus({
                          ...collapseBuilStatus,
                          [status]: !collapseBuilStatus[status],
                        })
                      : setCollapseCustomStatus({
                          ...collapseCustomStatus,
                          [status]: !collapseCustomStatus[status],
                        })
                  }
                >
                  <SelectIcon />
                </div>
                {groupOtion === "build_status" ? (
                  <LabelStatusBuild
                    status={status as STATUS_BUILD}
                    rootStyle={{
                      fontWeight: 600,
                      lineHeight: "22px",
                      fontSize: "13px",
                    }}
                  ></LabelStatusBuild>
                ) : (
                  <div className="flex items-center">
                    <div
                      className={`px-2 py-1 rounded-[20px] min-w-[70px] max-h-6 flex items-center justify-center text-xs ${renderCustomStatusColor(
                        mappingCustomStatus[status]?.color
                      )}`}
                    >
                      <p className=" flex justify-center w-full h-full overflow-x-hidden overflow-ellipsis whitespace-nowrap ">
                        {mappingCustomStatus[status]?.name || "none"}
                      </p>
                    </div>
                  </div>
                )}

                <p
                  style={{
                    fontWeight: 600,
                    lineHeight: "22px",
                    fontSize: "13px",
                    marginLeft: 4,
                  }}
                >{`(${groups[status]?.length || 0})`}</p>
              </div>
              <div
                className={`${
                  collapseBuilStatus[status] || collapseCustomStatus[status]
                    ? "hidden"
                    : ""
                }`}
              >
                {(groups[status] as ConversationDetail[]).map(
                  (conversation, index) => (
                    <ConversationCard
                      item={conversation}
                      key={conversation.id}
                      styleRoot={{
                        marginBottom:
                          index === listConversation.length - 1 ? 0 : 12,
                      }}
                      isViewBuild={
                        // activeMenu === MENU_TAB_CONVERSATION.MY_BUILDS
                        //   ? true
                        //   : false
                        false
                      }
                      activeConversationId={activeConversationId}
                      updateDataWhenChangeFavorite={
                        updateDataWhenChangeFavorite
                      }
                      updateDataWhenArchive={updateDataWhenArchive}
                      onClickConversation={(data) => () => {
                        onClickConversation(data)()
                        showLayout?.setValue(true)
                      }}
                      updateDataWhenChangeCustomStatus={
                        updateDataWhenChangeCustomStatus
                      }
                      updateDataWhenChangeConvoCustomStatus={
                        updateDataWhenChangeConvoCustomStatus
                      }
                      isDeleted={
                        conversation.project_build_status ===
                        STATUS_BUILD.DELETED
                      }
                      openNotes={openNotes}
                      activeMenu={activeMenu}
                    />
                  )
                )}
              </div>
            </div>
          )
        })}
      </div>
    )
  }
  return (
    <>
      {GROUP_BY_OPTIONS_KEY[groupOtion] ===
        GROUP_BY_OPTIONS_KEY.latest_update ||
      GROUP_BY_OPTIONS_KEY[groupOtion] ===
        GROUP_BY_OPTIONS_KEY.oldest_update ? (
        <div ref={containerRef} className="overflow-auto">
          {listConversation.map((conversation, index) => (
            <div className="pr-6 my-1 ">
              <ConversationCard
                item={conversation}
                key={index}
                styleRoot={{
                  marginBottom: index === listConversation.length - 1 ? 0 : 12,
                }}
                isViewBuild={
                  // activeMenu === MENU_TAB_CONVERSATION.MY_BUILDS ? true : false
                  true
                }
                activeConversationId={activeConversationId}
                updateDataWhenChangeFavorite={updateDataWhenChangeFavorite}
                updateDataWhenArchive={updateDataWhenArchive}
                onClickConversation={(data) => () => {
                  onClickConversation(data)()
                  showLayout?.setValue(true)
                }}
                updateDataWhenChangeCustomStatus={
                  updateDataWhenChangeCustomStatus
                }
                updateDataWhenChangeConvoCustomStatus={
                  updateDataWhenChangeConvoCustomStatus
                }
                isDeleted={
                  conversation.project_build_status === STATUS_BUILD.DELETED
                }
                openNotes={openNotes}
                activeMenu={activeMenu}
              />
            </div>
          ))}
        </div>
      ) : (
        renderGroupByStatus()
      )}
    </>
  )
}
export default ConversationColumn
