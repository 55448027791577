import React, { useContext, useRef } from "react"
import { useBoolean, useString, useNumber, useWindowSize } from "helpers/hooks"
import { TippyCustomzie } from "components/TippyCustomzie"
import { Folder } from "../types"
import { checkPermissionPage } from "helpers/utils"
import ActionItem from "pages/projects/molecules/ActionItem"
import { toast } from "react-toastify"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { STATUS_RESPONSE } from "types"
import { putFolderMiddleware } from "../services/api"
import { pushTo } from "helpers/history"
import { PATH } from "constants/path"
import { PermissionProjectPage } from "../contexts/PermissionProjectPage.context"
import ActionIconHistory from "pages/project-component-detail/molecules/ActionIconHistory"
import FolderIcon from "assets/images/icons/folder.svg"
import ModalDelete from "components/ModalCustom/ModalDelete"
import { ReactComponent as IconAction } from "assets/images/icons/icon-action-project.svg"

interface Props {
  item: Folder
  onUpdateDataEdit?: (name: string, indexCard: number) => void
  indexCard: number
  onChangeTabToFolder: () => void
  onDeleteFolder: (item: Folder) => void
  isAction?: boolean
  isOwner: boolean
}
const FolderCard = (props: Props) => {
  const {
    item,
    indexCard,
    isAction = true,
    onUpdateDataEdit,
    onChangeTabToFolder,
    onDeleteFolder,
    isOwner,
  } = props
  const { archiveProject, viewOnlyShare } = useContext(PermissionProjectPage)
  const { isMobile } = useWindowSize()
  const showTippy = useBoolean(false)
  const nameInput = useString(item.name)
  const widthInput = useNumber()
  const isEdit = useBoolean(false)
  const isLoading = useBoolean(false)
  const isDelete = useBoolean(false)
  const refInput = useRef<any>(null)
  const isDeleted = false

  const handleChangeRouter = () => {
    pushTo(
      PATH.folder,
      { idProject: item.project_id, idFolder: item.id },
      { folderName: item.name }
    )
    onChangeTabToFolder()
  }

  const handleChangeInput = (event) => {
    const newValue = event.target.value
    nameInput.setValue(newValue)
    let newWidth = 8
    if (newValue.length > 8) {
      newWidth = newValue.length
    }

    if (newValue.length > 25) {
      newWidth = 25
    }
    widthInput.setValue(newWidth)
  }

  const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      event.preventDefault()
      handleBlur()
    }
  }

  const handleBlur = () => {
    if (
      !nameInput.value ||
      nameInput.value === item?.name ||
      nameInput.value.trim().length === 0
    ) {
      isEdit.setValue(false)
      nameInput.setValue(item?.name)
      return
    }
    isLoading.setValue(true)
    putFolderMiddleware(
      item.id,
      nameInput.value,
      (type: STATUS_RESPONSE, messenger: string) => {
        isLoading.setValue(false)
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        if (type === STATUS_RESPONSE.SUCCESS && onUpdateDataEdit) {
          onUpdateDataEdit(nameInput.value, indexCard)
          isEdit.setValue(false)
          return
        }
        isEdit.setValue(false)
        nameInput.setValue(item?.name)
        widthInput.setValue(item?.name?.length)
      }
    )
  }

  const handleClickTippy = (event) => {
    event.stopPropagation()
    if (
      checkPermissionPage({
        project: archiveProject,
        notificationProject: true,
        viewShare: viewOnlyShare,
        notificationViewShare: true,
      })
    ) {
      return
    }
    showTippy.setValue(!showTippy.value)
  }

  const onClickAction = (key: "rename" | "delete") => (event) => {
    event.stopPropagation()
    event.preventDefault()
    if (
      checkPermissionPage({
        project: archiveProject,
        notificationProject: true,
        viewShare: viewOnlyShare,
        notificationViewShare: true,
      })
    ) {
      return
    }

    // close tippy layout
    showTippy.setValue(false)

    switch (key) {
      case "rename":
        isEdit.setValue(true)
        setTimeout(() => {
          refInput.current.focus()
          refInput.current.select()
        }, 0)
        break
      case "delete":
        isDelete.setValue(true)
        break
    }
  }

  const renderAction = () => {
    if (isDeleted) {
      return null
    }

    if (isAction && !isMobile) {
      return (
        <TippyCustomzie
          containerClass="component-card-menu"
          placement="bottom-end"
          interactive
          arrow={false}
          animation="scale"
          visible={showTippy.value}
          onClickOutside={() => showTippy.setValue(false)}
          content={
            <div
              style={{
                background: "#222222",
                borderRadius: 4,
                paddingTop: 8,
                paddingBottom: 8,
                width: 165,
              }}
            >
              <React.Fragment>
                {isMobile ? null : (
                  <ActionItem
                    title="Rename"
                    onClick={onClickAction("rename")}
                  />
                )}
              </React.Fragment>

              {isMobile ? null : isOwner ? (
                <ActionItem
                  title={"Delete"}
                  onClick={onClickAction("delete")}
                />
              ) : null}
            </div>
          }
          allowHTML
        >
          <div className="flex items-center" onClick={handleClickTippy}>
            {archiveProject || viewOnlyShare ? (
              <IconAction
                className={`${
                  checkPermissionPage({
                    project: archiveProject,
                    viewShare: viewOnlyShare,
                  })
                    ? "disabled-icon-button-create-fill"
                    : ""
                }`}
              />
            ) : (
              <ActionIconHistory
                icon="three-dots"
                tooltip={"Action"}
                styleIcon={{
                  marginRight: 0,
                }}
              />
            )}
          </div>
        </TippyCustomzie>
      )
    }
    return null
  }

  return (
    <>
      <div
        className={`bg-white rounded-md flex justify-between items-center gap-3 px-3 pb-4 pt-2.5 hover-project-card cursor-pointer`}
        style={{
          border: "1px solid #E4E4E4",
        }}
        onClick={handleChangeRouter}
      >
        {/* img */}
        <div className="flex items-center w-6">
          <img src={FolderIcon} alt="#" />
        </div>
        <div className="flex items-center justify-between w-full h-full">
          <div className="overflow-hidden">
            {isEdit.value ? (
              <div
                className="relative"
                style={{
                  width: "min-content",
                  height: 24,
                  maxWidth: 260,
                }}
              >
                <span
                  className="font-semibold"
                  style={{
                    visibility: "hidden",
                    fontSize: 14,
                    color: "#111111",
                    lineHeight: "24px",
                    left: 0,
                    maxWidth: "100%",
                    display: "inline-block",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {nameInput.value}
                </span>
                <input
                  className="h-24-custom text-base text-white-05 focus:outline-none font-semibold absolute selector-input"
                  style={{
                    width: "100%",
                    left: 0,
                    fontSize: 14,
                    color: "#111111",
                    lineHeight: "24px",
                  }}
                  value={nameInput.value}
                  onBlur={handleBlur}
                  onKeyPress={onKeyPress}
                  ref={refInput}
                  onChange={handleChangeInput}
                  autoFocus
                />
              </div>
            ) : (
              <p
                className="font-semibold text-name w-[212px]"
                style={{
                  fontSize: 14,
                  color: "#111111",
                  lineHeight: "24px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {item.name}
              </p>
            )}
            <p className="text-xs font-body font-medium leading-[18px]">
              {item.components.length}{" "}
              {item.components.length === 1 ? "component" : "components"}
            </p>
          </div>
          {renderAction()}
        </div>
      </div>

      {isDelete.value ? (
        <ModalDelete
          onCloseModal={() => isDelete.setValue(false)}
          title={`Are you sure to delete this folder?`}
          titleButton="Delete"
          label={`Confirmation`}
          onSubmit={onDeleteFolder}
          styleContent={{ textAlign: "center" }}
          content="This action cannot be undone. All components inside this folder will be deleted."
        />
      ) : null}
    </>
  )
}
export default FolderCard
