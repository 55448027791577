import HashLoader from "react-spinners/HashLoader"
import { useAppSelector } from "hooks/useApp"

const BackdropCustomize = () => {
  const isLoading = useAppSelector((state) => state.loading.isLoading)
  if (!isLoading) {
    return null
  }
  return (
    <div
      className="inset-0 flex fixed items-center justify-center bg-black-05"
      style={{
        zIndex: 10001,
      }}
    >
      <HashLoader color="#F7AC1B" loading={true} size={50} />
    </div>
  )
}
export default BackdropCustomize
