import { ProjectComponentDetail } from "pages/project-component/types"
import NoDataCard from "../molecules/NoDataCard"
import ComponentDetailInBuild from "./ComponentDetailInBuild "
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { toast } from "react-toastify"
import { twMerge } from "tailwind-merge"

interface Props {
  components: ProjectComponentDetail[]
  className: string
  onRemoveComponent: (idProjectBuildComponent: string) => (event) => void
}
const ComponentBuildCard = (props: Props) => {
  const { components, className, onRemoveComponent } = props
  const onSelectComponent: any = () => {
    toast(
      <LabelNotificationPage
        messenger={"Please commit the build first!"}
        type="warning"
      />
    )
  }
  return (
    <div className="h-full w-full">
      {components.length ? (
        <div className="h-full w-full overflow-auto">
          <div
            className={twMerge(
              "grid grid-cols-1 mr-6 gap-6 py-5 px-0",
              className
            )}
          >
            {components.map((el, index) => {
              return (
                <ComponentDetailInBuild
                  key={index}
                  item={el}
                  isRemove
                  isHoverCard
                  onRemoveComponent={onRemoveComponent}
                  onSelectComponent={() => onSelectComponent}
                  isShowStatus
                />
              )
            })}
          </div>
        </div>
      ) : (
        <NoDataCard />
      )}
    </div>
  )
}

export default ComponentBuildCard
