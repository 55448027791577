import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { useBoolean, useNumber, useString, useWindowSize } from "helpers/hooks"
import { useAppSelector } from "hooks/useApp"
import { cloneDeep, compact, isUndefined, toArray } from "lodash"
import { GetUsersInProjectProp } from "pages/project-component/types"
import { useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"
import { closeModal, openModal } from "reducers/modal"
import { configureStore } from "stores/configureStore"
import { STATUS_RESPONSE } from "types"
import PageLayout from "../layout/PageLayout"
import NoData from "./molecules/NoData"
import FormModalActionProjectCard from "./organisms/FormModalActionProjectCard"
import FormProject from "./organisms/FormProject"
import HeaderProject from "./organisms/HeaderProject"
import ProjectCard from "./organisms/ProjectCard"
import { LIST_STATUS_PROJECT, TAB_PROJECT } from "./project.constant"
import {
  archiveProjectMiddleware,
  deleteProjectAttachment,
  favoriteProjectMiddleware,
  getCategoriesMiddleware,
  getProjectCountMiddleware,
  getProjectsMiddleware,
} from "./services/api"
import {
  CategoryDetail,
  defaultCategoryDetail,
  defaultProjectDetail,
  ParamsProjectsProps,
  ProjectCount,
  ProjectDetail,
} from "./types"
import { customLocalStorageHandler } from "helpers/utils"
import { NAME_LOCALSTORAGE } from "constants/localStorage"

const Projects = () => {
  const { storageData, handleSetLocalStorage } = customLocalStorageHandler(
    NAME_LOCALSTORAGE.PROJECT
  )
  const userInfo = useAppSelector((state) => state.userInfo)
  const dataStorageProject: {
    category: string
    status: { id: string; label: string; name: string; value: string }[]
    isDeleted: boolean
    isArchived: boolean
    tabMenu: TAB_PROJECT
    sharing: number
  } = !isUndefined(storageData)
    ? storageData
    : {
        category: "",
        status: [{ id: "", label: "All", name: "All", value: "0" }],
        isDeleted: false,
        isArchived: true,
        tabMenu: "my_project",
        sharing: -1,
      }

  /// handle loading to show data when scrolling
  const isLoading = useBoolean()

  const [arrProject, setArrProject] = useState<ProjectDetail[]>([])
  const pageHook = useNumber(1)
  const rowsPerPageHook = useNumber(24)
  const [projectDetail, setProjectDetail] =
    useState<ProjectDetail>(defaultProjectDetail)
  const [categories, setCategories] = useState<CategoryDetail[]>([])

  const { isMobile } = useWindowSize()

  /// for project card
  const isArchive = useBoolean(false)
  const isShare = useBoolean(false)
  ///

  /// for project filter
  const [applyFilter, setApplyFilter] = useState<boolean>(false)

  const selectSharing = useNumber(dataStorageProject?.sharing ?? -1)
  const search = useString()
  const showArchive = useBoolean(dataStorageProject.isArchived)
  const showDeletedAttachment = useBoolean(dataStorageProject.isDeleted)
  const [selectStatusProject, setSelectStatusProject] = useState<any>(
    dataStorageProject?.status && !Array.isArray(dataStorageProject.status)
      ? dataStorageProject.status
      : { 0: Number(LIST_STATUS_PROJECT[0].value) }
  )

  // const [selectCategory, setSelectCategory] = useState<CategoryDetail>(
  //   dataStorageProject?.category
  //     ? (dataStorageProject.category)
  //     : defaultCategoryDetail
  // )
  const selectCategoryId = useString(dataStorageProject?.category || "")
  const [tabProject, setTabProject] = useState<TAB_PROJECT>(
    dataStorageProject?.tabMenu
      ? dataStorageProject.tabMenu
      : TAB_PROJECT.MY_PROJECT
  )
  ///

  const isMoreData = useBoolean(true)
  const isOpenFormDialog = useBoolean(false)
  const [projectCount, setProjectCount] = useState<ProjectCount>({
    my_projects: 0,
    shared_with_me_projects: 0,
    favorite_projects: 0,
  })
  const isReadShareProject = useBoolean(false)
  const ref = useRef<HTMLDivElement>(null)

  /// using firstLoading to prevent api called double
  const firstLoading = useBoolean(true)
  const callAPI = useBoolean(false)

  useEffect(() => {
    if (!firstLoading.value && !callAPI.value) {
      return
    }

    firstLoading.setValue(false)

    // get project info
    getProjects()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callAPI.value])

  useEffect(() => {
    if (!userInfo.id) {
      return
    }
    isReadShareProject.setValue(Boolean(userInfo.has_new_shared_project))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.id])

  /// because api called based on multi dependencies
  /// this func used one state to call api
  const handleCallAPI = () => {
    callAPI.setValue(true)

    /// set state value back to default value for using it next time
    setTimeout(() => {
      callAPI.setValue(false)
    }, 50)
  }

  const getProjects = async () => {
    const params = changeProjectParams(1)

    const url = urlTabProjectAndCategory(tabProject)
    isLoading.setValue(true)

    try {
      const dataRes = await getProjectsMiddleware(params, url.urlProject)
      await getProjectCount()
      await getCategories(url.urlCategory)

      setArrProject(dataRes)

      isLoading.setValue(false)

      isMoreData.setValue(dataRes.length < rowsPerPageHook.value ? false : true)

      if (tabProject === TAB_PROJECT.SHARED) {
        isReadShareProject.setValue(false)
      }

      const newStorageData = {
        category: selectCategoryId.value,
        status: selectStatusProject,
        isDeleted: showDeletedAttachment.value,
        isArchived: showArchive.value,
        tabMenu: tabProject,
        sharing: selectSharing.value,
      }

      handleSetLocalStorage(newStorageData)
    } catch (error) {
      isLoading.setValue(false)
    }
  }

  const getCategories = async (newURL: string) => {
    try {
      const dataRes = await getCategoriesMiddleware(newURL)
      const newDataRes: CategoryDetail[] = dataRes.map((el) => {
        return {
          ...el,
          label: el.name,
          value: el.id,
        }
      })
      newDataRes.unshift(defaultCategoryDetail)
      setCategories(newDataRes)
    } catch (error) {
      setCategories([])
    }
  }

  const getProjectCount = async () => {
    try {
      const dataRes = await getProjectCountMiddleware()
      if (dataRes) {
        setProjectCount({
          favorite_projects: dataRes.favorite_projects,
          my_projects: dataRes.my_projects,
          shared_with_me_projects: dataRes.shared_with_me_projects,
        })
      }
    } catch (error) {
      setProjectCount({
        favorite_projects: 0,
        my_projects: 0,
        shared_with_me_projects: 0,
      })
    }
  }
  const urlTabProjectAndCategory = (tab: TAB_PROJECT) => {
    let newURLProject = "get-list"
    let newURLCategory = "my-projects"
    switch (tab) {
      case TAB_PROJECT.FAVORITE:
        newURLProject = "get-list-favorite"
        newURLCategory = "favorite-projects"
        break
      case TAB_PROJECT.SHARED:
        newURLProject = "get-shared-with-me"
        newURLCategory = "projects-shared-with-me"

        break
    }
    return {
      urlProject: `/api/project/${newURLProject}`,
      urlCategory: `/api/category/get-list/${newURLCategory}`,
    }
  }

  const handleChangeButton = (
    key: "share" | "edit" | "archive" | "favorite" | "delete_attachment",
    data: ProjectDetail,
    callback: () => void
  ) => {
    setProjectDetail(data)
    switch (key) {
      case "share":
        isShare.setValue(true)
        break
      case "edit":
        isOpenFormDialog.setValue(true)
        break
      case "archive":
        isArchive.setValue(true)
        break
      case "favorite":
        callAPIFavorte(data)
        break
      case "delete_attachment":
        configureStore.dispatch(
          openModal({
            type: "Delete",
            props: {
              deleteModal: {
                title: `When action is confirmed, all attachment files also be removed. This action can not undo.`,
                label: `Warning`,
                content: `Press "Delete" to process`,
                onSubmit: onSubmitDeleteAttachment(data),
                styleTitle: { textAlign: "center" },
                titleButton: "Delete",
              },
            },
          })
        )
        break
    }
    callback()
  }

  const onSubmitDeleteAttachment = (data: ProjectDetail) => () => {
    deleteProjectAttachment(data.id)
      .then(() => {
        toast(
          <LabelNotificationPage
            messenger={"Delete project successfully!"}
            type="success"
          />
        )
        getProjects()
        closeModal()
      })
      .catch((error) => {
        toast(
          <LabelNotificationPage
            messenger={
              error.response?.data?.message || "Delete project failed!"
            }
            type="error"
          />
        )
        closeModal()
      })
  }

  const onArchiveProject = () => {
    if (!projectDetail?.id) {
      return
    }

    isLoading.setValue(true)

    archiveProjectMiddleware(
      projectDetail.id,
      projectDetail.is_archived,
      (type: STATUS_RESPONSE, messenger: string) => {
        if (type === STATUS_RESPONSE.SUCCESS) {
          isArchive.setValue(false)
          if (pageHook.value !== 1) {
            pageHook.setValue(1)
            return
          }
          getProjects()
        }
        toast(<LabelNotificationPage messenger={messenger} type={type} />)

        isLoading.setValue(false)
      }
    )
  }

  const callAPIFavorte = (data: ProjectDetail) => {
    if (!data.id) {
      return
    }

    isLoading.setValue(true)

    favoriteProjectMiddleware(
      data.id,
      (
        type: STATUS_RESPONSE,
        messenger: string,
        newProject?: ProjectDetail
      ) => {
        if (type === STATUS_RESPONSE.SUCCESS && newProject) {
          const newData = cloneDeep(arrProject)
          const index = newData.findIndex((el) => el.id === data.id)
          newData[index] = newProject
          if (tabProject === TAB_PROJECT.FAVORITE) {
            newData.splice(index, 1)
          }
          setArrProject(newData)
          getProjectCount()
        } else {
          toast(<LabelNotificationPage messenger={messenger} type={type} />)
        }

        isLoading.setValue(false)
      }
    )
  }

  const onChangeTab = (newTab: TAB_PROJECT) => () => {
    if (newTab === tabProject) {
      return
    }
    pageHook.setValue(1)
    handleCallAPI()
    selectCategoryId.setValue("")
    setTabProject(newTab)
    if (newTab === TAB_PROJECT.SHARED) {
      isReadShareProject.setValue(false)
    }
  }

  const onChangeShowArchive = () => {
    pageHook.setValue(1)
    showArchive.setValue(!showArchive.value)
    if (!showArchive.value && showDeletedAttachment.value) {
      selectSharing.setValue(-1)
      setSelectStatusProject({ 0: Number(LIST_STATUS_PROJECT[0].value) })
      selectCategoryId.setValue("")
    }
    showDeletedAttachment.setValue(false)
  }

  const onChangeShowDeleteAttachment = () => {
    pageHook.setValue(1)
    if (!showDeletedAttachment.value) {
      showArchive.setValue(false)
      selectSharing.setValue(-2)
      setSelectStatusProject(-1)
      selectCategoryId.setValue("-1")
    } else {
      showArchive.setValue(true)
      selectSharing.setValue(-1)
      setSelectStatusProject({ 0: Number(LIST_STATUS_PROJECT[0].value) })
      selectCategoryId.setValue("")
    }
    showDeletedAttachment.setValue(!showDeletedAttachment.value)
  }

  const onChangeSelected = (
    key: "status" | "category" | "sharing",
    selected: any
  ) => {
    pageHook.setValue(1)
    switch (key) {
      case "status":
        setSelectStatusProject((prev) => {
          if (
            (prev[0] === undefined && selected[0] === 0) ||
            Object.keys(selected).length === 0
          ) {
            return { 0: 0 }
          }
          if (selected[0] === 0 && Object.keys(selected).length > 1) {
            delete selected[0]
          }

          return selected
        })
        break

      case "sharing":
        selectSharing.setValue(selected.value)
        break

      default:
        selectCategoryId.setValue(selected)

        // if (!isMobile) {
        //   handleCallAPI()
        // }
        break
    }
  }

  const onCloseModal = () => {
    isOpenFormDialog.setValue(false)
  }
  const onClickNewProject = () => {
    isOpenFormDialog.setValue(true)
    setProjectDetail(defaultProjectDetail)
  }
  const handleChangeData = () => {
    pageHook.setValue(1)
    getProjects()
  }

  const changeProjectParams = (defaultPage?: number) => {
    const status = compact(toArray(selectStatusProject))
    const newParams: ParamsProjectsProps = {
      page: defaultPage ?? pageHook.value + 1,
      pageSize: rowsPerPageHook.value,
      category_ids: JSON.stringify(
        selectCategoryId.value && selectCategoryId.value !== "-1"
          ? [selectCategoryId.value]
          : []
      ),
      status: JSON.stringify(status.length === 0 ? [0] : status),
      is_archived: showArchive.value ? 1 : 0,
      is_deleted: showDeletedAttachment.value ? 1 : 0,
      is_shared: selectSharing.value,
      search: search.value,
    }
    if (!selectCategoryId.value) {
      delete newParams.category_ids
    }
    if (!showArchive.value) {
      delete newParams.is_archived
    }
    if (selectSharing.value < -1) {
      delete newParams.is_shared
    }
    if (selectStatusProject.value < 0) {
      delete newParams.status
    }
    if (tabProject === TAB_PROJECT.SHARED) {
      delete newParams.is_shared
    }
    return newParams
  }
  const handleScroll = async () => {
    const offsetHeight = ref.current?.offsetHeight
    const scrollHeight = ref.current?.scrollHeight
    const scrollTop = ref.current?.scrollTop
    if (Number(scrollTop) + Number(offsetHeight) > Number(scrollHeight) - 10) {
      if (!isMoreData.value || !arrProject.length || isLoading.value) {
        return
      }

      isLoading.setValue(true)

      try {
        const params = changeProjectParams()
        const url = urlTabProjectAndCategory(tabProject)
        const dataRes = await getProjectsMiddleware(params, url.urlProject)
        isMoreData.setValue(
          dataRes.length < rowsPerPageHook.value ? false : true
        )
        pageHook.setValue(pageHook.value + 1)
        const newData = cloneDeep(arrProject).concat(dataRes)
        setArrProject(newData)

        isLoading.setValue(false)
      } catch (error) {
        isLoading.setValue(false)
      }
    }
  }
  const contentNoDataTabProject = (tab: TAB_PROJECT) => {
    let newContent =
      // !Number(selectStatusProject.id) && !selectCategory.id
      !projectCount.my_projects &&
      tabProject &&
      tabProject === TAB_PROJECT.MY_PROJECT
        ? "You have not created any Project!"
        : "No projects found."
    switch (tab) {
      case TAB_PROJECT.FAVORITE:
      case TAB_PROJECT.SHARED:
        newContent = "No projects found!"
        break
    }
    return newContent
  }
  const onCloseFormShare =
    (newUserRole: GetUsersInProjectProp[], projectId: string) => () => {
      isShare.setValue(false)
      const newProjects = cloneDeep(arrProject)
      const index = newProjects.findIndex((el) => el.id === projectId)
      if (index > -1) {
        newProjects[index].role_users = newUserRole
        setArrProject(newProjects)
      }
    }

  const renderBody = () => {
    if (!arrProject.length) {
      if (isLoading.value) {
        return null
      }

      return (
        <NoData
          onClick={onClickNewProject}
          context={contentNoDataTabProject(tabProject)}
          showButton={
            !projectCount.my_projects &&
            tabProject &&
            tabProject === TAB_PROJECT.MY_PROJECT
            // !Number(selectStatusProject.id) && !selectCategory.id
          }
        />
      )
    }

    return (
      <div className="grid grid-cols-auto-fill-min-310 gap-6 my-1 mr-6">
        {arrProject.map((el, index) => {
          return (
            <ProjectCard
              isDiable={tabProject === TAB_PROJECT.SHARED}
              key={index}
              item={el}
              handleChangeButton={handleChangeButton}
              loggedUserId={userInfo.id}
            />
          )
        })}
      </div>
    )
  }

  return (
    <PageLayout
      contentClass="max-sm-project-content"
      childrenHeader={
        <HeaderProject
          tab={tabProject}
          onChangeTab={onChangeTab}
          showArchive={showArchive}
          showDeletedAttachment={showDeletedAttachment}
          selectedStatusProject={selectStatusProject}
          setSelectStatusProject={setSelectStatusProject}
          selectSharing={selectSharing}
          onChangeShowArchive={onChangeShowArchive}
          onChangeShowDeleteAttachment={onChangeShowDeleteAttachment}
          arrCategory={categories}
          selectCategoryId={selectCategoryId}
          // selectedCategory={selectCategory}
          onChangeSelected={onChangeSelected}
          onClickNewProject={onClickNewProject}
          projectCount={projectCount}
          isReadShareProject={isReadShareProject.value}
          setApplyFilter={setApplyFilter}
          applyFilter={applyFilter}
          handleCallAPI={handleCallAPI}
          search={search}
          // setSelectCategory={setSelectCategory}
        />
      }
      minWidth="min-w-[1280px]"
    >
      <div className="pl-6 h-full pt-6 pb-5">
        <div className="h-full overflow-auto" ref={ref} onScroll={handleScroll}>
          {renderBody()}
        </div>
      </div>

      {isOpenFormDialog.value && (
        <FormProject
          openModal={isOpenFormDialog.value}
          handleChangeData={handleChangeData}
          defaultRequest={{
            id: projectDetail.id,
            name: projectDetail.name,
            description: projectDetail.description,
            selectCategories: projectDetail.categories.length
              ? projectDetail.categories.map((el) => {
                  return {
                    id: el.id,
                    name: el.name,
                    label: el.name,
                    value: el.id,
                  }
                })
              : [],
          }}
          onCloseModal={onCloseModal}
        />
      )}
      <FormModalActionProjectCard
        isArchive={isArchive}
        isShare={isShare}
        isLoading={isLoading}
        projectDetail={projectDetail}
        onArchiveProject={onArchiveProject}
        onCloseFormShare={onCloseFormShare}
      />
    </PageLayout>
  )
}
export default Projects
