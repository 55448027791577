import React from "react"
import { checkPermissionPage } from "helpers/utils"
import { ReactComponent as IconEditNameComponent } from "assets/images/icons/icon-edit-name-component.svg"

interface Props {
  isEditNameBuild: boolean
  newNameBuild: string
  projectBuildName: string
  handleChangeInput: (event) => void
  handleBlur: () => void
  refInput: any
  onKeyPress: (event: React.KeyboardEvent<HTMLDivElement>) => void
  archiveProject: boolean
  archiveBuild: boolean
  viewOnlyShare: boolean
  idProjectBuild: string
  onChangeEditNameBuild: () => void
}
const NameBuildCard = (props: Props) => {
  const {
    isEditNameBuild,
    newNameBuild,
    projectBuildName,
    refInput,
    handleChangeInput,
    handleBlur,
    onKeyPress,
    archiveProject,
    archiveBuild,
    viewOnlyShare,
    idProjectBuild,
    onChangeEditNameBuild,
  } = props
  const rendericonEditNameBuild = () => {
    if (
      checkPermissionPage({
        project: archiveProject,
        build: archiveBuild,
        viewShare: viewOnlyShare,
      }) ||
      !idProjectBuild
    ) {
      return null
    }
    return (
      <IconEditNameComponent
        className="ml-1 display-icon-edit-name-component cursor-pointer"
        onClick={onChangeEditNameBuild}
      />
    )
  }
  return (
    <div className="flex items-center hover-show-icon-edit-name-component">
      {isEditNameBuild ? (
        <div
          className="relative"
          style={{
            width: "min-content",
            height: 26,
            maxWidth: 600,
          }}
        >
          <span
            className="font-semibold"
            style={{
              visibility: "hidden",
              whiteSpace: "pre",
              fontSize: 16,
              lineHeight: "26px",
              color: "#111111",
              left: 0,
              maxWidth: "100%",
              display: "inline-block",
            }}
          >
            {newNameBuild}
          </span>
          <input
            className="h-26-custom text-base text-white-05 focus:outline-none font-semibold absolute selector-input"
            style={{
              width: "100%",
              left: 0,
              fontSize: 16,
              lineHeight: "26px",
              color: "#111111",
            }}
            value={newNameBuild}
            onChange={handleChangeInput}
            onBlur={handleBlur}
            onKeyPress={onKeyPress}
            autoFocus
            ref={refInput}
          />
        </div>
      ) : (
        <React.Fragment>
          <p
            className="font-semibold "
            style={{
              fontSize: 16,
              lineHeight: "26px",
              color: "#111111",
            }}
          >
            {projectBuildName}
          </p>
          {rendericonEditNameBuild()}
        </React.Fragment>
      )}
    </div>
  )
}
export default NameBuildCard
