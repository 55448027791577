import { STATUS_BUILD } from "components/Status/types"
import { includes, some } from "lodash"
import { BuildHistoryDetail, TAB_URL_PROJECT_COMPONENT } from "./types"

export const checkDisableButtonHistoryTree = (
  histories: BuildHistoryDetail[],
  historyDetail: BuildHistoryDetail,
  titlePage: string
) => {
  /// index of history
  const newMasterHistories =
    titlePage === TAB_URL_PROJECT_COMPONENT.COMPONENT
      ? histories.filter((el) => !el.project_build_component_id)
      : histories
  const indexMasterHistory = newMasterHistories.findIndex(
    (el) => el.id === historyDetail.id
  )
  // if history have draft already or index history is not = 0 => disabled
  const isDisableEditHistory =
    some(histories, ["status", STATUS_BUILD.DRAFT]) ||
    (historyDetail.project_build_component_id &&
      titlePage === TAB_URL_PROJECT_COMPONENT.COMPONENT) ||
    indexMasterHistory > 0

  const isDisableRevertHistory =
    indexMasterHistory > 0 &&
    includes(
      [STATUS_BUILD.COMMITTED, STATUS_BUILD.MERGED],
      historyDetail.status
    )
      ? false
      : true
  const isDisableTranscendHistory = includes(
    [STATUS_BUILD.COMMITTED, STATUS_BUILD.MERGED],
    historyDetail.status
  )
    ? false
    : true

  const isDisableMergeHistory =
    indexMasterHistory !== histories.length - 1 &&
    histories.length > 1 &&
    historyDetail.project_build_component_id &&
    ((!Boolean(historyDetail.is_merged) &&
      includes([STATUS_BUILD.COMMITTED], historyDetail.status)) ||
      (Boolean(historyDetail.is_merged) &&
        includes([STATUS_BUILD.MERGED], historyDetail.status) &&
        titlePage === TAB_URL_PROJECT_COMPONENT.COMPONENT))
      ? false
      : true
  return {
    isDisableEditHistory,
    isDisableRevertHistory,
    isDisableTranscendHistory,
    isDisableMergeHistory,
  }
}
