import { TippyCustomzie } from "components/TippyCustomzie"
import { useNumber } from "helpers/hooks"
import { ReactComponent as IconActionTable } from "assets/images/icons/icon-action-table-bom.svg"
import { cloneDeep } from "lodash"
import { useEffect, useState } from "react"
import { TableColumnBOMDetail } from "../types"
import { handlePasteFromCell } from "../../../helpers/bom"
import Tippy from "@tippyjs/react"

interface Props {
  dataAdditionalJson: TableColumnBOMDetail[]
  defaultBomValues: TableColumnBOMDetail["values"]
  setDataAdditionalJson?: (newValue: TableColumnBOMDetail[]) => void
  isDisable: boolean
  handleOpenModelDelete?: (type: string) => void
  isAddColumn: boolean
  lengthInviteeBOM: number
  isUpdateColumn?: {
    value: boolean
    setValue: (value: boolean) => void
  }
}
const TableAdditionalJSON = (props: Props) => {
  const {
    dataAdditionalJson,
    isDisable,
    isAddColumn,
    handleOpenModelDelete,
    setDataAdditionalJson,
    lengthInviteeBOM,
    isUpdateColumn,
    defaultBomValues,
  } = props
  const fieldActive = useNumber(-1)
  const [listActive, setListActive] = useState<boolean[]>([])

  useEffect(() => {
    setListActive(dataAdditionalJson.map(() => false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(dataAdditionalJson)])

  const handleOpenDeleteColumn = (header: string) => (event) => {
    event.preventDefault()
    event.stopPropagation()
    if (handleOpenModelDelete) {
      handleOpenModelDelete(header)
    }
    handleOnClickOutside()
  }
  const handleClickAddColumn = (newIdx: number) => () => {
    const newListActive = cloneDeep(listActive)
    newListActive[newIdx] = !newListActive[newIdx]
    setListActive(newListActive)
    fieldActive.setValue(newIdx)
  }

  const handleChangeInputHeader = (oldIndex: number) => (event) => {
    const newData = cloneDeep(dataAdditionalJson)
    newData[oldIndex].key = event.target.value
    if (setDataAdditionalJson) {
      isUpdateColumn?.setValue(true)
      setDataAdditionalJson(newData)
    }
  }
  const handleChangeInputBody =
    (oldIndex: number, oldIndexRow: number) => (event) => {
      const newData = cloneDeep(dataAdditionalJson)
      newData[oldIndex].values[oldIndexRow] = event.target.value
      if (setDataAdditionalJson) {
        isUpdateColumn?.setValue(true)
        setDataAdditionalJson(newData)
      }
    }
  const handleOnClickOutside = () => {
    const newListActive = cloneDeep(listActive)
    setListActive(newListActive.map((_el) => false))
    fieldActive.setValue(-1)
  }

  const onPaste =
    (type: "header" | "body", indexColumn: number, indexRow: number) =>
    (event: any) => {
      const copiedData = event.clipboardData.getData("text/plain")
      if (copiedData === "") return
      const newData = handlePasteFromCell(
        dataAdditionalJson,
        defaultBomValues,
        copiedData,
        type,
        indexColumn,
        indexRow,
        event
      )
      if (setDataAdditionalJson && newData) {
        isUpdateColumn?.setValue(true)
        setDataAdditionalJson(newData)
      }
    }

  return (
    <div
      className="flex items-center"
      style={{
        borderRight: "1px solid #E4E4E4",
        borderTop: "1px solid #E4E4E4",
        borderBottom: "1px solid #E4E4E4",
        marginTop: 10,
        backgroundColor: "#FFFFFF",
        paddingBottom: 0,
        borderTopRightRadius: isAddColumn || lengthInviteeBOM ? 0 : 6,
        borderBottomRightRadius: isAddColumn || lengthInviteeBOM ? 0 : 6,
      }}
    >
      {dataAdditionalJson.map((additionalJson, indexColumn: number) => (
        <div
          key={indexColumn}
          className="flex flex-col"
          style={{
            border:
              fieldActive.value === indexColumn ? "1px solid #FDBE44" : "none",
          }}
        >
          <div
            className={`flex relative cursor-pointer ${
              !isDisable ? "hover-disabled-button-action-table" : ""
            }`}
            style={{
              height: 48,
              width: 170,
              borderBottom: "1px solid #E4E4E4",
            }}
          >
            <input
              className="focus:outline-none font-semibold px-3 focus-input-table"
              placeholder="Empty"
              value={additionalJson.key}
              onChange={handleChangeInputHeader(indexColumn)}
              onPaste={onPaste("header", indexColumn, 0)}
              disabled={isDisable}
              style={{
                height: 47,
                width: 170,
                fontSize: 14,
                lineHeight: "24px",
                color: "#111111",
                backgroundColor: "transparent",
              }}
            />
            <TippyCustomzie
              containerClass="component-card-menu absolute top-0 left-0 w-full h-[1px]"
              placement="top"
              interactive
              arrow={false}
              animation="scale"
              visible={listActive[indexColumn]}
              onClickOutside={handleOnClickOutside}
              offset={[0, 10]}
              content={
                <div
                  className="flex items-center"
                  onClick={handleOpenDeleteColumn(
                    `${additionalJson.key}${additionalJson.idColumn}`
                  )}
                  style={{
                    background: "#222222",
                    borderRadius: 4,
                    padding: "0px 12px",
                    width: 140,
                    height: 48,
                  }}
                >
                  <p
                    className="font-normal"
                    style={{
                      fontSize: 13,
                      lineHeight: "22px",
                      color: "#EA4545",
                    }}
                  >
                    Delete
                  </p>
                </div>
              }
              allowHTML
            >
              <div
                className={`absolute flex items-center justify-center cursor-pointer ${
                  fieldActive.value === indexColumn
                    ? "active-button-action-table"
                    : "disabled-button-action-table"
                }`}
                onClick={handleClickAddColumn(indexColumn)}
                style={{
                  top: -10,
                  left: "calc(50% - 14px)",
                  height: 20,
                  width: 28,
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #E4E4E4",
                  borderRadius: 4,
                }}
              >
                <IconActionTable />
              </div>
            </TippyCustomzie>
          </div>
          <div>
            {additionalJson.values.map((row, indexRow) => (
              <div
                className={`flex items-center`}
                style={{
                  height: 48,
                  width: 170,
                  backgroundColor: indexRow % 2 !== 0 ? "#F7F7F7" : "#FFFFFF",
                }}
                key={indexRow}
              >
                {isDisable ? (
                  row?.length > 43 ? (
                    <Tippy
                      className="custom-tippy-menu-left"
                      placement="top"
                      content={
                        <span
                          className="font-normal"
                          style={{
                            fontSize: 13,
                            lineHeight: "22px",
                          }}
                        >
                          {row}
                        </span>
                      }
                    >
                      <p className="font-normal text-ellipsis-2 px-3 font-normal text-black">
                        {row}
                      </p>
                    </Tippy>
                  ) : (
                    <p className="font-normal text-ellipsis-2 px-3 font-normal text-black">
                      {row}
                    </p>
                  )
                ) : (
                  <input
                    className="focus:outline-none font-normal px-3 focus-input-table"
                    value={row}
                    disabled={isDisable}
                    placeholder="Empty"
                    onChange={handleChangeInputBody(indexColumn, indexRow)}
                    onPaste={onPaste("body", indexColumn, indexRow)}
                    style={{
                      height: 48,
                      width: 170,
                      fontSize: 14,
                      lineHeight: "24px",
                      color: "#111111",
                      backgroundColor: "transparent",
                    }}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}
export default TableAdditionalJSON
