import { useContext } from "react"
import CheckedDefault from "components/Checked/CheckedDefault"
import { PartsSpecificationDetail } from "../types"
import { VersionSpecificationContext } from "../contexts/VersionSpecification.context"
import { fieldFormDataSpecification } from "helpers/utils"

interface Props {
  item: PartsSpecificationDetail
  disabled?: boolean
}

const CheckboxSingle = (props: Props) => {
  const { item, disabled = false } = props
  const context = useContext(VersionSpecificationContext)
  const handleChangeCheckbox = (newCheckbox: boolean) => () => {
    context?.updateDataForm(
      "formData",
      fieldFormDataSpecification(item.id, item.sequence),
      !newCheckbox,
      Boolean(item.required)
    )
  }
  return (
    <div className="flex items-center mb-8">
      <CheckedDefault
        disabled={disabled}
        checked={Boolean(
          context?.formData[fieldFormDataSpecification(item.id, item.sequence)]
        )}
        onClick={handleChangeCheckbox(
          context?.formData[
            fieldFormDataSpecification(item.id, item.sequence)
          ] ?? false
        )}
      />
      {item.helper_text ? (
        <p
          style={{
            fontSize: 14,
            lineHeight: "24px",
            color: "#111111",
          }}
          className="ml-2"
        >
          {item.helper_text}
        </p>
      ) : null}
    </div>
  )
}

export default CheckboxSingle
