export const isLogin = () => {
  const accessToken = localStorage.getItem("access_token")
  if (!accessToken) {
    return false
  }
  return true
}

export const API_URL = process.env.REACT_APP_API_URL || ""

export const API_URL_RECAPTCHA = process.env.REACT_APP_API_URL_RECAPTCHA || ""
export const REACT_APP_API_URL_IMAGE = process.env.REACT_APP_API_URL_IMAGE || ""
export const REACT_APP_API_QUANTITY = process.env.REACT_APP_API_QUANTITY || ""
export const REACT_APP_API_CURRENCIES =
  process.env.REACT_APP_API_CURRENCIES || ""
export const REACT_APP_API_VERSION = process.env.REACT_APP_API_VERSION || ""
export const REACT_APP_API_MENU_WEB = process.env.REACT_APP_API_MENU_WEB || ""
export const UMAMI_URL = process.env.REACT_APP_UMAMI_URL || ""
export const UMAMI_TRACKING_ID = process.env.REACT_APP_UMAMI_TRACKING_ID || ""
export const RELEASE_NOTE_URL = process.env.REACT_APP_RELEASE_NOTE_URL || ""
