import { useContext } from "react"
import { AdditionalOptionsSpecificationProps } from "../types"
import InfoSingleCard from "../atoms/InfoSingleCard"
import SelectDefault from "components/Select/SelectDefault"
import { VersionSpecificationContext } from "../contexts/VersionSpecification.context"
import { fieldFormDataSpecification } from "helpers/utils"
import InputDefault from "components/Input/InputDefault"
import CheckedDefault from "components/Checked/CheckedDefault"

const AdditionalOptionsSpecification = (
  props: AdditionalOptionsSpecificationProps
) => {
  const { item, disabled = false } = props
  const partSelects =
    item.parts && item.parts.length
      ? item.parts.filter((el) => el.type === "select")
      : []
  const partCheckbox =
    item.parts && item.parts.length
      ? item.parts.filter((el) => el.type === "checkbox")
      : []
  const partSingleInput =
    item.parts && item.parts.length
      ? item.parts.filter((el) => el.type === "single input")
      : []
  const context = useContext(VersionSpecificationContext)

  const handleChangeSelect =
    (idPath: string, newSequence: number) => (valueSelect) => {
      context?.updateDataForm(
        "formData",
        fieldFormDataSpecification(idPath, newSequence),
        valueSelect.id,
        Boolean(item.required)
      )
    }
  const handleChangeChecked = (
    idPath: string,
    newChecked: boolean,
    newSequence: number
  ) => {
    context?.updateDataForm(
      "formData",
      fieldFormDataSpecification(idPath, newSequence),
      !newChecked,
      Boolean(item.required)
    )
  }
  return (
    <div
      className="flex flex-col pt-3 mb-2"
      style={{
        borderTop: "1px solid #E4E4E4",
      }}
    >
      <div className="flex mb-3">
        <p
          className="font-semibold"
          style={{
            fontSize: 14,
            lineHeight: "24px",
            color: "#111111",
          }}
        >
          {item.name}
        </p>
      </div>
      <div className="flex flex-col">
        <div
          className="flex items-center"
          style={{
            flexFlow: "wrap",
          }}
        >
          {partSelects.length
            ? partSelects.map((part, index) => {
                const newAnswers = part.answers.map((el) => {
                  return {
                    ...el,
                    label: el.name,
                    value: el.id,
                  }
                })
                return (
                  <div className={`flex flex-col mb-8 mr-8`} key={index}>
                    <div
                      className="flex items-center"
                      style={{
                        width: 307,
                      }}
                    >
                      <InfoSingleCard
                        name={part.name}
                        tooltip={part.tooltip}
                        styleName={{
                          fontWeight: 400,
                        }}
                      />
                    </div>
                    <SelectDefault
                      isDisabled={disabled}
                      options={newAnswers}
                      selectedOption={newAnswers.find(
                        (el) =>
                          el.id ===
                          context?.formData[
                            fieldFormDataSpecification(part.id, part.sequence)
                          ]
                      )}
                      handleChange={handleChangeSelect(part.id, part.sequence)}
                      filed="name"
                      rootClasses="h-10 rounded border-select flex items-center "
                      controlWidth={300}
                    />
                  </div>
                )
              })
            : null}
        </div>
        <div className="flex items-center">
          {partSingleInput.length
            ? partSingleInput.map((partSingle, index) => {
                return (
                  <div className={`flex flex-col`} key={index}>
                    <div className="flex items-center w-257-custom">
                      <InfoSingleCard
                        name={partSingle.name}
                        tooltip={partSingle.tooltip}
                      />
                    </div>

                    <InputDefault
                      disabled={disabled}
                      placeholder={partSingle.placeholder}
                      value={
                        context?.formData[
                          fieldFormDataSpecification(
                            partSingle.id,
                            partSingle.sequence
                          )
                        ] ?? ""
                      }
                      onChange={(e) =>
                        context?.updateDataForm(
                          "formData",
                          fieldFormDataSpecification(
                            partSingle.id,
                            partSingle.sequence
                          ),
                          e.target?.value,
                          Boolean(item.required)
                        )
                      }
                      classCustom={`h-40-custom border-input bg-white rounded px-4 focus:outline-none mr-2`}
                    />
                  </div>
                )
              })
            : null}
        </div>
      </div>
      <div
        className="grid grid-cols-1 md:grid-cols-3"
        style={{
          maxWidth: 1015,
        }}
      >
        {partCheckbox.length
          ? partCheckbox.map((part, index) => {
              return (
                <div
                  className="flex items-center mb-3 cursor-pointer"
                  key={index}
                  onClick={() => {
                    if (!disabled) {
                      handleChangeChecked(
                        part.id,
                        context?.formData[
                          fieldFormDataSpecification(part.id, part.sequence)
                        ],
                        part.sequence
                      )
                    }
                  }}
                >
                  <CheckedDefault
                    checked={
                      context?.formData[
                        fieldFormDataSpecification(part.id, part.sequence)
                      ]
                    }
                  />
                  <InfoSingleCard
                    isHideMaxWidth
                    name={part.name}
                    tooltip={part.tooltip}
                    styleName={{
                      fontWeight: 400,
                      marginLeft: 4,
                    }}
                    rootClass="flex items-center"
                  />
                </div>
              )
            })
          : null}
      </div>
    </div>
  )
}

export default AdditionalOptionsSpecification
